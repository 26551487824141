import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterFooter, FilterHeader, FilterStack } from '../../../../../chakra-lib/layout';
import { useSelector } from 'react-redux';
import { getFilterParams } from '../../../../../chakra-lib/utils';
import { getURLFromFilterParams } from '../../../../../chakra-lib/utils';
import { InputSearch } from '../../../../../chakra-lib';
import { TranslationContext } from '../../../../../translation';
import { Button, Text } from '@chakra-ui/react';
import { FILTER_MEDICAL_FACILITY_PARAMS } from '../../../../../config/medicalFacility.config';
import { ROLES } from '../../../../../model/roles';
import { Private } from '../../../../../components/Private';

export const MedicalFacilityListFilter = ({ params }) => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const { text } = useSelector(state => state.medicalFacility.filter);
  const countList = useSelector(state => state.medicalFacility.totalItems);
  const userAuth = useSelector(state => state.authentication.user);
  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0];
    const filedValue = param[fieldName];
    const objFilterParams = getFilterParams(FILTER_MEDICAL_FACILITY_PARAMS, params);
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue
    })
    history.push(`/vaccination/medical-facility${urlFilterParams}`);
  }

  const handleResetFilter = () => {
    history.push(`/vaccination/medical-facility`)
  }

  return (
    <>
      <Private
        role={userAuth.role}
        hasAnyRole={[ROLES.Administrator]}
      >
        <FilterHeader>
          <Button
            width="100%"
            onClick={() => history.push(`/vaccination/medical-facility/new`)}
            colorScheme="blue"
          >
            {getTranslation('test.action.buttonCreate')}
          </Button>
        </FilterHeader>
      </Private>
      <FilterStack>
        <InputSearch
          label={getTranslation('global.search')}
          name="text"
          value={text}
          onChange={e => handleFilter({ text: e })}
        />
      </FilterStack>
      <FilterFooter>
        <Button
          size="sm"
          width="100%"
          onClick={handleResetFilter}
        >Очистить фильтр</Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
    </>
  )
};
