import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LaboratoryUpdate } from './LaboratoryUpdate/LaboratoryUpdate';
import { LaboratoryList } from './LaboratoryList/LaboratoryList';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <LaboratoryUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <LaboratoryUpdate/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <LaboratoryList/>
      </Route>
    </Switch>
  );
};

export default Routes;
