import React, { useContext, useEffect, useReducer } from 'react';
import { WorkScheduleTimeItem } from './work-schedule-time-item';
import { Flex, Grid, Text } from '@chakra-ui/react';
import { isEmpty } from '../deprecated/utilities/is-empty';
import { SpecialDateUpdate } from './special-date-update';
import { TYPE_DAYS } from '../../model/days';
import { TranslationContext } from '../../translation';

const initialState = {
    weekdays: [
        [], [], [], [], [], [], []
    ],
    specialDates: []
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_WEEKDAYS': {
            const newItems = [...state.weekdays];
            newItems[action.index] = action.value;
            return {
                ...state,
                weekdays: newItems
            };
        }
        case 'ADD_SPECIAL_DATE': {
            const newItems = state.specialDates.concat([action.value])
            return {
                ...state,
                specialDates: newItems
            }
        }
        case 'UPDATE_SPECIAL_DATE': {
            const newItems = [...state.specialDates];
            newItems[action.index] = action.value;
            return {
                ...state,
                specialDates: newItems
            };
        }
        case 'DELETE_SPECIAL_DATE': {
            const newItems = state.specialDates.filter((item, index) => index !== action.index);
            return {
                ...state,
                specialDates: newItems
            };
        }
        case 'UPDATE_STORE': {
            return {
                ...state,
                ...action.value
            }
        }
        default:
            throw new Error();
    }
};

export const WorkScheduleMemo = ({ value = {}, onChange = null }) => {
    const { getTranslation, language } = useContext(TranslationContext);
    const [state, setState] = useReducer(reducer, isEmpty(value) ? initialState : value);
    const setWeekDays = (value, index) => {
        setState({ type: 'UPDATE_WEEKDAYS', value, index });
    };
    const setSpecialDate = (value) => {
        setState({ type: 'ADD_SPECIAL_DATE', value });
    };
    const updateSpecialDate = (value, index) => {
        setState({ type: 'UPDATE_SPECIAL_DATE', value, index });
    };
    const deleteSpecialDate = (index) => {
        setState({ type: 'DELETE_SPECIAL_DATE', index });
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        onChange && onChange(state)
    }, [state]);

    useEffect(() => {
        setState({ type: 'UPDATE_STORE', value });
    }, [value])

    return (
        <>
            <Text fontSize="1.17rem" fontWeight="semibold">
                {getTranslation('workSchedule.heading.schedule')}
            </Text>
            <Grid gridRowGap="8px" mb={2}>
                {state.weekdays.map((item, index) => (
                    <WorkScheduleTimeItem
                        key={index}
                        value={item}
                        index={index}
                        onChange={(value, index) => setWeekDays(value, index)}
                        label={TYPE_DAYS[language][index]}
                    />
                ))}
            </Grid>
            <Text fontSize="1.17rem" fontWeight="semibold">
                {getTranslation('workSchedule.heading.holidays')}
            </Text>
            <Flex flexWrap="wrap">
                {state.specialDates.map((item, index) => (
                    <SpecialDateUpdate
                        key={index}
                        index={index}
                        value={item}
                        onDelete={deleteSpecialDate}
                        onChange={(e, index) => updateSpecialDate(e, index)}
                    />
                ))}
                <SpecialDateUpdate
                    onChange={e => setSpecialDate(e)}
                />
            </Flex>
        </>
    );
};

export const WorkSchedule = React.memo(WorkScheduleMemo);
