import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';

const PAGE_SIZE = 20;
const START_PAGE = 1;

export const ACTION_TYPES = {
    FETCH_USER_LIST: 'user/FETCH_USER_LIST',
    RESET_USER_LIST: 'user/RESET_USER_LIST',
    FETCH_USER: 'user/FETCH_USER',
    CREATE_USER: 'user/CREATE_USER',
    UPDATE_USER: 'user/UPDATE_USER',
    RESET_USER: 'user/RESET_USER',
    UPDATE_FILTER: 'user/UPDATE_FILTER',
    RESET_FILTER: 'user/RESET_FILTER',
    RESET: 'user/RESET'
};

const initialState = {
    items: [],
    item: {},
    loading: false,
    loadingUpdate: false,
    errorMessage: '',
    totalItems: 0,
    totalPages: 0,
    activePage: START_PAGE,
    filter: {
        text: ''
    }
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_USER_LIST):
        case REQUEST(ACTION_TYPES.FETCH_USER): {
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_USER):
        case REQUEST(ACTION_TYPES.UPDATE_USER):{
            return {
                ...state,
                loadingUpdate: true,
                errorMessage: ''
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_USER_LIST):
        case FAILURE(ACTION_TYPES.FETCH_USER):
        case FAILURE(ACTION_TYPES.CREATE_USER):
        case FAILURE(ACTION_TYPES.UPDATE_USER):{
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errorMessage: action.payload.response.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_USER_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loading: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage'],
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_USER): {
            return {
                ...state,
                item: action.payload.data,
                loading: false
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_USER): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_USER): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            }
        }
        case ACTION_TYPES.RESET_USER_LIST: {
            return {
                ...state,
                items: [],
                loading: false,
                loadingUpdate: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE
            }
        }
        case ACTION_TYPES.RESET_USER: {
            return {
                ...state,
                item: {},
                loading: false,
                loadingUpdate: false
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                loadingUpdate: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/user`;

export const getUserList = () => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.FETCH_USER_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getUserListNavigation = (dataRequest) => {
    const { pageSize, page } = dataRequest;
    const requestUrl = `${API_URL}?pageSize=${pageSize}&page=${page}`;
    return {
        type: ACTION_TYPES.FETCH_USER_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getUserListSearch = (page) => (dispatch, getState) => {
    const filter = getState().user.filter;
    const requestUrl = `${API_URL}/findtext?pageSize=${PAGE_SIZE}&page=${page}`;
    dispatch({
        type: ACTION_TYPES.FETCH_USER_LIST,
        payload: axios.post(requestUrl, {
            text: checkParam(filter.text)
        })
    });
};

export const updateFilter = (params) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        filter: {
            ...params
        }
    });
    dispatch(getUserListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().user.activePage;
    dispatch(getUserListSearch(activePage + 1))
};

export const resetFilter = () => {
    return {
        type: ACTION_TYPES.RESET_FILTER
    };
};

export const getUser = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_USER,
        payload: axios.get(requestUrl)
    };
};


export const createUser = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_USER,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateUser = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.UPDATE_USER,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const resetUserList = () => {
    return {
        type: ACTION_TYPES.RESET_USER_LIST
    };
};

export const resetUser = () => {
    return {
        type: ACTION_TYPES.RESET_USER
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
