import React from 'react';
import { useFetch } from '../deprecated/use-fetch';
import { options } from './config';

const OPTION_URL = {
    'address' : 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    'fio': 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio'
}

export const DaData = ({ typeOption = null, initialQuery, render }) => {
    const {
        data,
        isLoading,
        updateParams
    } = useFetch(OPTION_URL[typeOption], options(initialQuery))
    const resultData = data && (data.suggestions.length ? data.suggestions : null);
    const onUpdateQuery = value => {
        updateParams(options(value, typeOption))
    }
    return (
        <>{render(resultData, isLoading, onUpdateQuery)}</>
    );
};
