import { FAILURE, REQUEST, SUCCESS } from './actions-type';

import axios from 'axios';
import { API_SERVER_URL } from './config';

export const ACTION_TYPES = {
    LOGIN: 'authentication/LOGIN',
    LOGOUT: 'authentication/LOGOUT',
    CHECK: 'authentication/CHECK',
    RESET: 'authentication/RESET'
};

const initialState = {
    user: {},
    loading: false,
    errorMessage: '',
    error: false
};

export const authenticationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.LOGIN):
        case REQUEST(ACTION_TYPES.CHECK):{
            return {
                ...state,
                loading: true,
                errorMessage: '',
                error: false
            }
        }
        case FAILURE(ACTION_TYPES.LOGIN): {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.response.data,
                error: true
            }
        }
        case FAILURE(ACTION_TYPES.CHECK):{
            return {
                ...state,
                loading: false,
                error: true
            }
        }
        case SUCCESS(ACTION_TYPES.CHECK): {
            return {
                ...state,
                user: action.payload.data,
                loading: false,
                errorMessage: '',
                error: false
            }
        }
        case SUCCESS(ACTION_TYPES.LOGIN): {
            return {
                ...state,
                user: action.payload.data,
                loading: false,
                error: false
            }
        }
        case SUCCESS(ACTION_TYPES.LOGOUT): {
            return {
                ...state,
                user: {},
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                user: {},
                errorMessage: '',
                error: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}`;

export const loginAuth = (dataRequest) => {
    const requestUrl = `${API_URL}/login`;
    return {
        type: ACTION_TYPES.LOGIN,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const checkAuth = () => {
    const requestUrl = `${API_URL}/getLogin`;
    return {
        type: ACTION_TYPES.CHECK,
        payload: axios.get(requestUrl)
    };
};

export const resetAuth = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};

export const logoutAuth = () => {
    const requestUrl = `${API_URL}/logout`;
    return {
        type: ACTION_TYPES.LOGOUT,
        payload: axios.get(requestUrl)
    };
};
