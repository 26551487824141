import React, { useContext } from 'react'
import { TYPE_RESULT_VARIANT_COLOR } from 'components/test-result/config'
import { useTestResult } from 'components/test-result'
import { TranslationContext } from 'translation'
import { Badge } from '@chakra-ui/react'

export const ItemTestResult = ({ invalid, result, testingDate }) => {
  const { getTranslation } = useContext(TranslationContext)
  const { getResult } = useTestResult()

  if (invalid) {
    return (
      <Badge
        padding="1px 10px"
        borderRadius={100}
        fontSize="0.68rem"
        colorScheme="gray"
        color="gray.400"
      >
        {getTranslation('resultTest.invalid')}
      </Badge>
    )
  }

  return (
    <Badge
      padding="1px 10px"
      borderRadius={100}
      fontSize="0.68rem"
      colorScheme={TYPE_RESULT_VARIANT_COLOR[getResult(result, testingDate).resultTestType]}
    >
      {getResult(result, testingDate).resultTestText}
    </Badge>
  )
}
