import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import OfficeCard from './OfficeCard'
import OfficeList from './OfficeList'

const Routes = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <OfficeCard />
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <OfficeCard />
      </Route>
      <Route path={`${match.url}/:params?`}>
        <OfficeList />
      </Route>
    </Switch>
  )
}

export default Routes;
