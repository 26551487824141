import React from 'react';
import { InputMask } from '../InputMask';

export const InputMaskForm = React.forwardRef((
  {
    field,
    form,
    sizeInput,
    mask,
    label,
    ...props
  }, ref) => {
  return (
    <InputMask
      name={field.name}
      label={label}
      mask={mask}
      isInvalid={form.errors[field.name] && form.touched[field.name]}
      {...field}
      {...props}
    />
  );
});
