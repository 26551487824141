import * as Yup from 'yup';
import { isINNLegalEntity, isKPP, isOGTN } from '../validation';

export const laboratorySchemaRu = Yup.object().shape({
    name: Yup.string().min(4, 'Required').max(256),
    shortName: Yup
        .string()
        .max(20)
        .required('Required')
    ,
    telephone: Yup.string().required('Required'),
    oid: Yup.string().required('Required'),
    inn: Yup
        .string()
        .test('innValid', 'innValid', value => isINNLegalEntity(value))
        .required(),
    company: Yup.string().required('Required'),
    kpp: Yup
        .string()
        .test('kppValid', 'kppValid', value => isKPP(value))
        .required(),
    ogrn: Yup
        .string()
        .test('ogtnValid', 'ogtnValid', value => isOGTN(value))
        .required(),
    ownership: Yup.string().required('Required'),
    addressLegal: Yup.string().required('Required'),
});

export const laboratorySchemaAm = Yup.object().shape({
    name: Yup.string().max(256).required('Required'),
    shortName: Yup.string().max(20).required('Required'),
    telephone: Yup.string().required('Required'),
    oid: Yup.string().required('Required'),
    inn: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    ogrn: Yup.string().required('Required'),
    ownership: Yup.string().required('Required'),
    addressLegal: Yup.string().required('Required'),
});

export const laboratorySchemaBy = Yup.object().shape({
    name: Yup.string().min(5, 'Required').max(256),
    shortName: Yup.string().required('Required').max(20),
    telephone: Yup.string().required('Required'),
    oid: Yup.string().required('Required'),
    inn: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    ownership: Yup.string().required('Required'),
    addressLegal: Yup.string().required('Required'),
});
