import axios from 'axios';
import { useCallback, useState } from 'react';
import { API_SERVER_URL } from 'store/config';

export const useFetchReportSettings = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const request = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsSuccess(false);

      const result = await axios.get(`${API_SERVER_URL}/reportaccessrole/list`);
      setData(result?.data);

      setIsSuccess(true);
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    reportListSettings: data,
    reportListSettingsIsLoading: isLoading,
    reportListSettingsIsLoaded: isSuccess,
    fetchReportListSettings: request
  }
}
