import React from 'react';
import {
  CardContainer, Container,
  ContentContainer,
  ContentContainerColumn, EntityState, GridField, GridFieldHeading, GridFieldItem,
  HeadingContainer, RightAside
} from '../../../../chakra-lib/layout';
import { Heading } from '../../../../chakra-lib';
import { Button, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import usePushFetchItem from '../services/usePushFetchItem';
import { useHistory, useParams } from 'react-router-dom';
import { pushStatusColor } from '../constants/pushStatusColor';
import { pushStatusText } from '../constants/pushStatusText';
import { pushStatusType } from '../constants/pushStatusType';
import usePushSendItem from '../services/usePushSendItem';
import { getDateTimeReadFormat } from '../../../../chakra-lib/utils/dateFormatting';

export const PushCard = () => {
  const history = useHistory();
  const {id} = useParams();
  const {isLoading, data, fetchItem} = usePushFetchItem(id)
  const {sendItem, isLoadingSend} = usePushSendItem()
  const isReadyItem = !isEmpty(data);

  const handleBack = () => {
    history.goBack();
  };

  const handleEdit = () => {
    history.push(`/integration/push/edit/${id}`)
  }

  const handleSend = async () => {
    await sendItem(id)
    await fetchItem()
  }

  return (
    <Container>
      <CardContainer indentTop={70} onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading fontSize="1.4rem">PUSH сообщение</Heading>
            </HeadingContainer>
            <GridField>
              <GridFieldHeading>Данные сообщения</GridFieldHeading>
              <GridFieldItem
                label="Наименование на русском"
                isLoading={isLoading}
                isReady={isReadyItem}
              >
                {data?.title?.ru}
              </GridFieldItem>
              <GridFieldItem
                label="Наименование на английском"
                isLoading={isLoading}
                isReady={isReadyItem}
              >
                {data?.title?.en}
              </GridFieldItem>
              <GridFieldItem
                label="Сообщение на русском"
                isLoading={isLoading}
                isReady={isReadyItem}
              >
                {data?.body?.ru}
              </GridFieldItem>
              <GridFieldItem
                label="Сообщение на английском"
                isLoading={isLoading}
                isReady={isReadyItem}
              >
                {data?.body?.en}
              </GridFieldItem>
              <GridFieldItem
                label="Платформа"
                isLoading={isLoading}
                isReady={isReadyItem}
              >
                {isEmpty(data?.platform) ? 'iOS и Android' : data.platform}
              </GridFieldItem>
              <GridFieldItem
                label="Версия приложения"
                isLoading={isLoading}
                isReady={isReadyItem}
              >
                {isEmpty(data?.version) ? 'Все версии' : data.version}
              </GridFieldItem>
            </GridField>
            {data?.status === pushStatusType.created && (
              <Button
                variant="outline"
                onClick={handleEdit}
              >
                Редактировать
              </Button>
            )}
            {data?.status === pushStatusType.sent && (
              <GridField>
                <GridFieldHeading>Статистика</GridFieldHeading>
                <GridFieldItem
                  label="Дата рассылки"
                  isLoading={isLoading}
                  isReady={isReadyItem}
                >
                  {getDateTimeReadFormat(data?.sentAt)}
                </GridFieldItem>
                <GridFieldItem
                  label="Количество получателей"
                  isLoading={isLoading}
                  isReady={isReadyItem}
                >
                  {data?.recipientsCount}
                </GridFieldItem>
              </GridField>
            )}
          </ContentContainerColumn>
          <ContentContainerColumn>
            <RightAside>
              <VStack spacing={5}>
                <EntityState
                  isLoading={isLoading}
                  colorScheme={pushStatusColor[data?.status]}
                >
                  {pushStatusText[data?.status]}
                </EntityState>
                <Button
                  colorScheme="blue"
                  width="100%"
                  isDisabled={isLoading || data?.status !== pushStatusType.created}
                  onClick={handleSend}
                  isLoading={isLoadingSend}
                >
                  Запустить рассылку
                </Button>
              </VStack>
            </RightAside>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
