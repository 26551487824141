import React, { useContext, useState } from 'react';
import { TranslationContext } from "translation";
import { useSelector } from "react-redux";

import { FormSection } from 'chakra-lib/layout';
import { Field } from 'formik';
import { UITextareaForm } from 'components/textarea';
import { Box, Button, Collapse } from '@chakra-ui/react';
import { CheckboxForm, InputForm, SelectForm, Tab, TabList, TabPanel, TabPanels, Tabs } from 'chakra-lib';

export const Recovered = () => {
  const { getTranslation } = useContext(TranslationContext);
  const [hiddenSettings, setHiddenSettings] = useState(true)
  const loading = useSelector(state => state.country.loadingItem);

  return (
    <FormSection>
      <Tabs variant="soft-rounded">
        <TabList>
          <Tab padding="0.4rem 0.8rem" mr={1}>
            {getTranslation('country.tabs.testingInfo.inRussian')}
          </Tab>
          <Tab padding="0.4rem 0.8rem" mr={1}>
            {getTranslation('country.tabs.testingInfo.inEnglish')}
          </Tab>
        </TabList>
        <TabPanels mt="16px">
          <TabPanel padding={0}>
            <Field
              name="recoveredInfoRu"
              component={UITextareaForm}
              isDisabled={loading}
              minHeight="500px"
              placeholder={getTranslation('country.field.testingInfoRu.placeholder')}
              fontFamily="Menlo, monospace"
            />
          </TabPanel>
          <TabPanel padding={0}>
            <Field
              name="recoveredInfoEn"
              component={UITextareaForm}
              isDisabled={loading}
              minHeight="500px"
              placeholder={getTranslation('country.field.testingInfoEn.placeholder')}
              fontFamily="Menlo, monospace"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Field
        name="recoveredType"
        label="Активность"
        component={SelectForm}
        isSearchable={false}
        options={[
          { value: 'QrDisabled', label: 'Выключено' },
          { value: 'QrEnabled', label: 'Включено'},
          { value: 'trueVaccination', label: 'Включено через вакцинацию'},
        ]}
      />
      <Field
        name="recoveredQrEnabled"
        component={CheckboxForm}
        label={getTranslation('country.tabs.recovered.setting.on')}
      />
      <Box mt="20px">
        <Button variant="outline" size="sm" onClick={() => setHiddenSettings(e => !e)}>{getTranslation('country.tabs.description.otherSettings')}</Button>
      </Box>
      <Collapse in={!hiddenSettings}>
        <FormSection mb={0}>
          <Field
            name="recoveredUrl"
            label={getTranslation('country.tabs.recovered.setting.qr')}
            component={InputForm}
            isDisabled={loading}
          />
        </FormSection>
      </Collapse>
    </FormSection>
  );
};

export default Recovered
