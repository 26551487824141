import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

export const Symbol = ({ children }) => {
  const themeBg = useColorModeValue('rgb(237, 242, 247)', 'var(--chakra-colors-whiteAlpha-200)');
  return (
        <Box
            width="fit-content"
            backgroundColor={themeBg}
            borderRadius="0.25rem"
            borderWidth="1px 1px 3px"
            fontSize="0.8rem"
            fontWeight="700"
            paddingLeft="0.5em"
            paddingRight="0.5em"
            whiteSpace="nowrap"
            minHeight="20px"
            textAlign="center"
            marginLeft="5px"
            fontFamily="Menlo, monospace"
            >{children}</Box>
    );
};
