import React, {
  ReactElement,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  memo,
  ForwardedRef,
} from 'react'
import {
  UseTableOptions,
  UsePaginationOptions,
  UseRowSelectOptions,
  UseRowStateOptions,
  useTable,
  useRowSelect,
  usePagination,
  Row,
  TableInstance,
} from 'react-table'
import {
  BodyCell,
  HeaderCell,
  HeaderRow,
  TableWrap,
  BodyRow,
  NoDataCell,
  TBody,
  THeader,
  ColumnConfig,
} from './elements'
import { Spinner } from '../Spinner'
import { useTemplateGridColumn } from './hooks'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Center } from '@chakra-ui/react'

interface TableHooksOptions<D extends object>
  extends UseTableOptions<D>,
    UsePaginationOptions<D>,
    UseRowSelectOptions<D>,
    UseRowStateOptions<D> {}

export interface TableProps<D extends object> extends TableHooksOptions<D> {
  isLoading?: boolean
  hasNext?: boolean
  onNextPage: () => void
  useRowSelect?: boolean
  storageId?: string
  onRowSelect?: (instance: TableInstance<D>) => void
  onRowClick?: (row: Row<D>, event: React.MouseEvent) => void
}

interface TableComponent {
  <D extends object>(props: TableProps<D>, ref: ForwardedRef<TableInstance<D>>): ReactElement<
    any,
    any
  > | null

  displayName?: string
}

const Table: TableComponent = (props, ref) => {
  const mounted = useRef(false)

  const {
    isLoading,
    onRowClick,
    onRowSelect,
    onNextPage,
    hasNext = false,
    storageId,
    initialState,
    ...options
  } = props

  const hiddenColumnsStorage = localStorage.getItem(`hiddenColumns.${storageId}`)
  const hiddenColumns = hiddenColumnsStorage
    ? JSON.parse(hiddenColumnsStorage)
    : initialState?.hiddenColumns || []

  const instance = useTable(
    {
      autoResetSelectedRows: false,
      initialState: {
        ...initialState,
        hiddenColumns,
      },
      ...options,
    },
    useTemplateGridColumn,
    usePagination,
    useRowSelect
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    data,
    state,
    allColumns,
  } = instance

  useEffect(() => {
    mounted.current && onRowSelect && onRowSelect(instance)
  }, [state.selectedRowIds])

  useEffect(() => {
    if (storageId) {
      const hiddenColumns = JSON.stringify(state.hiddenColumns)
      localStorage.setItem(`hiddenColumns.${storageId}`, hiddenColumns)
    }
  }, [state.hiddenColumns])

  const onRowClickHandle = useCallback(
    (row) => (event: React.MouseEvent) => {
      onRowClick && onRowClick(row, event)
    },
    []
  )

  useImperativeHandle(ref, () => instance)

  useEffect(() => {
    mounted.current = true
  }, [])

  return (
    <TableWrap id="scrollableDiv" {...getTableProps()}>
      {storageId && (
        <Box zIndex={11} position="absolute" right="13px" top="4px">
          <ColumnConfig allColumns={allColumns} />
        </Box>
      )}
      <THeader>
        {headerGroups.map((headerGroup) => (
          <HeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <HeaderCell {...column.getHeaderProps()}>{column.render('Header')}</HeaderCell>
            ))}
          </HeaderRow>
        ))}
      </THeader>

      {data?.length ? (
        <TBody {...getTableBodyProps()}>
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            hasMore={hasNext}
            dataLength={data.length}
            next={onNextPage}
            pullDownToRefreshThreshold={300}
            loader={<Spinner />}
          >
            {rows.map((row) => {
              prepareRow(row)

              return (
                <BodyRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const preventRowClick = cell.column.preventRowClick

                    const noDataRender = cell.column.NoDataCell ? (
                      cell.column.NoDataCell(cell)
                    ) : (
                      <NoDataCell />
                    )

                    const hasValue =
                      ![null, undefined, ''].includes(cell.value) || cell.column.id === 'selection'

                    return (
                      <BodyCell
                        preventRowClick={preventRowClick}
                        onClick={onRowClickHandle(row)}
                        {...cell.getCellProps()}
                      >
                        {hasValue ? cell.render('Cell') : noDataRender}
                      </BodyCell>
                    )
                  })}
                </BodyRow>
              )
            })}
          </InfiniteScroll>
        </TBody>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <Center width="100%" height="200px" color="gray.400">
          Нет данных
        </Center>
      )}
    </TableWrap>
  )
}

Table.displayName = 'Table'
export default memo(forwardRef(Table)) as TableComponent
