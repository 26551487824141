import { api } from '../../../../api';
import { useQuery } from '../../../../components/useQuery';
import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

const usePushUpdateItem = () => {
  const toast = useToast();
  const {
    isError,
    isLoading,
    isSuccess,
    request
  } = useQuery((e) => api.push.updateItem(e))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'PUSH сообщение успешно обновлено',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isSuccess])

  return {
    updateItem: request,
    isLoadingUpdate: isLoading
  }
}

export default usePushUpdateItem;
