import { Icon } from '@chakra-ui/react';
import React from 'react';

export const IconGeo = (props) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g fill="currentColor">
        <path
          fill="currentColor"
          clipRule="evenodd"
          fillRule="evenodd"
          d="M8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10zm0-7a3 3 0 100-6 3 3 0 000 6z"
        />
      </g>
    </Icon>
  );
};
