import React from 'react'
import { Select } from '../Select'

export const SelectForm = ({ field, form, options, isMulti = false, onChange, ...props }) => {
  const { error, touched } = form.getFieldMeta(field.name)

  const handleChange = (option) => {
    onChange && onChange(option.value)

    if (option) {
      form.setFieldValue(field.name, isMulti ? option.map((item) => item.value) : option.value)
    } else {
      form.setFieldValue(field.name, '')
    }
  }

  const getValue = () => {
    if (options && field.value !== undefined) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value)
    } else {
      return isMulti ? [] : ''
    }
  }

  const onBlur = () => {
    if (!touched) {
      form.setTouched({ ...form.touched, [field.name]: true })
    }
  }

  return (
    <Select
      isForm
      {...field}
      value={getValue()}
      onBlur={onBlur}
      onChange={handleChange}
      options={options}
      isMulti={isMulti}
      isInvalid={error && (touched || form.submitCount > 0)}
      {...props}
    />
  )
}
