import React, { useContext } from 'react';
import {
  Button,
  ModalBody, ModalFooter, TabList, TabPanels, Tabs,
  useDisclosure
} from '@chakra-ui/react';
import { ModalForm } from '../../../../../../../components/deprecated/modal';
import { Tab, TabPanel } from '../../../../../../../chakra-lib';
import { Field, Formik } from 'formik';
import { TranslationContext } from '../../../../../../../translation';
import { FormSection } from '../../../../../../../components/deprecated/layout';
import { UITextareaForm } from '../../../../../../../components/textarea';
import useFetch from './useFetch';
import useUpdate from './useUpdate';

export const FormSamplesBanner = () => {
  const {getTranslation} = useContext(TranslationContext)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const { data, isLoading, fetchSetting } = useFetch()
  const { updateSetting, isLoadingUpdate } = useUpdate()

  const handleOpen = async () => {
    await fetchSetting()
    onOpen()
  }

  const handleForm = async (values, actions) => {
    await updateSetting(values)
    onClose();
  };

  return (
    <>
      <Button
        width="100%"
        isLoading={isLoading}
        onClick={handleOpen}
      >
        Баннер
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={onClose}
        heading="Баннер"
      >
        <Formik
          initialValues={{
            ru: data?.ru,
            en: data?.en,
          }}
          onSubmit={handleForm}
          enableReinitialize={true}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Tabs variant="soft-rounded" size="sm">
                  <TabList>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inRussian')}
                    </Tab>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inEnglish')}
                    </Tab>
                  </TabList>
                  <TabPanels mt={2}>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          name="ru"
                          minHeight={260}
                          component={UITextareaForm}
                          isDisabled={isLoading}
                          placeholder={getTranslation('settingInformation.field.valueRu.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          name="en"
                          minHeight={260}
                          component={UITextareaForm}
                          isDisabled={isLoading}
                          placeholder={getTranslation('settingInformation.field.valueEn.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalBody>
              <ModalFooter justifyContent="flex-start">
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isDisabled={!props.dirty}
                    isLoading={isLoading || isLoadingUpdate}
                  >
                    {getTranslation('global.update')}
                  </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  )
}
