import React from 'react';
import { Alert as AlertChakra, AlertIcon } from '@chakra-ui/react';

export const Alert = ({ status = 'warning', children, ...props }) => {
    return (
        <AlertChakra status={status} borderRadius={6} {...props}>
            <AlertIcon />
            {children}
        </AlertChakra>
    );
};
