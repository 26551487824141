import {
  getDateReadFormat,
  getTimeReadFormat,
} from 'components/deprecated/utilities/date-formatting'
import { TEST_FIELDS } from '../Test.constants'
import { makeColumns } from './TestList.config'

export const makeExcelColumns = ({ getTranslation, role, getResult, hiddenColumns }) => {
  const tableColumns = makeColumns({ getTranslation, role })

  return tableColumns
    .map((item) => ({
      key: item.id,
      accessor: item.accessor,
      header: item.Header,
    }))
    .map((item) => {
      if (
        [
          TEST_FIELDS.testingDate,
          TEST_FIELDS.resultDate,
          TEST_FIELDS.resultSetDate,
          TEST_FIELDS.createdAt,
        ].includes(item.key)
      ) {
        return {
          ...item,
          accessor: ({ testingDate }) =>
            testingDate && `${getDateReadFormat(testingDate)}, ${getTimeReadFormat(testingDate)}`,
        }
      }

      if (item.key === TEST_FIELDS.result) {
        return {
          ...item,
          accessor: ({ invalid, result, testingDate }) =>
            invalid
              ? getTranslation('resultTest.invalid')
              : getResult(result, testingDate).resultTestText,
        }
      }

      return item
    })
    .filter((item) => item.header)
    .filter((item) => !hiddenColumns.includes(item.key))
}
