import React, { useEffect, useState } from 'react'
import OfficeUpdate from './OfficeUpdate'
import OfficeView from './OfficeView'

import { useDispatch, useSelector } from 'react-redux'
import { getOffice, resetOffice } from 'store/office.reducer'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { Spinner } from 'chakra-lib'
import { ROLES } from 'model/roles'

const Routes = () => {
  const dispatch = useDispatch()
  const userAuth = useSelector((state) => state.authentication.user)

  const { id } = useParams()
  const [isNew] = useState(!id)
  const item = useSelector((state) => state.office.item)
  const loading = useSelector((state) => state.office.loading)
  const isReadyItem = !isEmpty(item)

  useEffect(() => {
    if (isNew) {
      dispatch(resetOffice())
    } else {
      dispatch(getOffice(id))
    }
    return () => {
      dispatch(resetOffice())
    }
  }, [])

  return loading ? (
    <Spinner />
  ) : userAuth.role === ROLES.Administrator && !item.nationalBlocked ? (
    <OfficeUpdate data={item} loading={loading} isNew={isNew} />
  ) : (
    <OfficeView data={item} loading={loading} isReadyItem={isReadyItem} />
  )
}

export default Routes
