import React, { useContext, useEffect } from 'react';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, GridField, GridFieldHeading, GridFieldItem,
  HeadingContainer, RightAside
} from '../../../../chakra-lib/layout';
import { Heading } from '../../../../chakra-lib';
import { isEmpty } from 'lodash';
import { ContentDate } from '../../../../components/content-date';
import { TranslationContext } from '../../../../translation';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLaboratory,
  resetLaboratory,
} from '../../../../store/laboratory.reducer';
import { getCountry, resetCountry } from '../../../../store/country.reducer';
import { RequisitesShow } from '../../../../components/requisites';
import { getOffice, resetOffice } from '../../../../store/office.reducer';
import { MapPosition } from '../../../../components/map/map-position';
import { WorkScheduleView } from '../../../../components/work-schedule-view';

export const OfficeCard = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const item = useSelector(state => state.office.item);
  const loading = useSelector(state => state.office.loading);
  const itemLaboratory = useSelector(state => state.laboratory.item);
  const loadingLaboratory = useSelector(state => state.laboratory.loading);
  const itemCountry = useSelector(state => state.country.item);
  const loadingCountryItem = useSelector(state => state.country.loadingItem);
  const isReadyItem = !isEmpty(item);
  const isReadyItemLaboratory = !isEmpty(itemLaboratory);
  const isReadyItemCountry = !isEmpty(itemCountry);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getOffice(id));
    return () => {
      dispatch(resetOffice());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(item) && item.laboratoryId) {
      dispatch(getLaboratory(item.laboratoryId));
    }
    return () => {
      dispatch(resetLaboratory());
    }
  }, [item]);

  useEffect(() => {
    if (!isEmpty(itemLaboratory) && itemLaboratory.countryId) {
      dispatch(getCountry(itemLaboratory.countryId));
    }
    return () => {
      dispatch(resetCountry());
    }
  }, [itemLaboratory]);

  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">{getTranslation('office.heading.show')}</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.data')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('office.field.titleRu.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.title?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.titleEn.placeholder')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.title?.en}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.shortTitleRu.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.shortTitle?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.shortTitleEn.placeholder')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.shortTitle?.en}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.telephone.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.telephone && item.telephone}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.siteUrl.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.siteUrl && item.siteUrl}
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.location')}</GridFieldHeading>
                <GridFieldItem
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.address && item.address.ru}
                </GridFieldItem>
                <GridFieldItem
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.address && item.address.en}
                </GridFieldItem>
                <MapPosition
                  latitude={item.coordinates && item.coordinates.lat ? item.coordinates.lat : undefined}
                  longitude={item.coordinates && item.coordinates.lng ? item.coordinates.lng : undefined}
                />
              </GridField>
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.requisites')}</GridFieldHeading>
                <RequisitesShow
                  item={item}
                  loading={loadingCountryItem}
                  isReadyItem={isReadyItemCountry}
                  initialCountry={itemCountry.countryCode}
                />
              </GridField>
              {(item.details?.comment?.ru || item.details?.comment?.ru) && (
                <GridField>
                  <GridFieldHeading>{getTranslation('office.section.heading.comment')}</GridFieldHeading>
                  {item.details?.comment?.ru && (
                    <GridFieldItem
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.details.comment.ru}
                    </GridFieldItem>
                  )}
                  {item.details?.comment?.en && (
                    <GridFieldItem
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.details?.comment?.en}
                    </GridFieldItem>
                  )}
                </GridField>
              )}
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.laboratory')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('laboratory.field.name.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.name && itemLaboratory.name}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.shortName.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.shortName && itemLaboratory.shortName}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.telephone.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.telephone && itemLaboratory.telephone}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.siteUrl.label')}
                  isLoading={loadingLaboratory}
                  isReady={isReadyItemLaboratory}
                >
                  {itemLaboratory.siteUrl && itemLaboratory.siteUrl}
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.country')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('country.field.nameRu.label')}
                  isLoading={loadingCountryItem}
                  isReady={isReadyItemCountry}
                >
                  {itemCountry.name && itemCountry.name.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('country.field.shortNameRu.label')}
                  isLoading={loadingCountryItem}
                  isReady={isReadyItemCountry}
                >
                  {itemCountry.shortName && itemCountry.shortName.ru}
                </GridFieldItem>
              </GridField>
            </ContentContainerColumn>
            <ContentContainerColumn>
              <RightAside>
                <WorkScheduleView
                  isLoading={loading}
                  value={!isEmpty(item) && item.workingHours}
                />
                <ContentDate item={item} loading={loading} />
              </RightAside>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
    </>
  )
}
