import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useWindowHeight } from '../../useWindowHeight';

export const ContainerApp = ({ children }) => {
  const windowHeight = useWindowHeight();
  return (
    <Flex
      flexDirection="column"
      width="100%"
      minWidth="1100px"
      height={`${windowHeight}px`}
    >
      {children}
    </Flex>
  );
};
