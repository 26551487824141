import React from 'react'

import { Text } from 'chakra-lib'
import { ItemIcon } from 'chakra-lib/Snippets'
import TestingDate from 'pages/laboratory/Test/TestList/Cells/TestingDate'
import { ItemTestResult } from './Cells/ItemTestResult'
import { IconPersonLines } from 'components/icons'
import { TEST_FIELDS, TEST_FIELDS_TRANSLATION } from '../Test.constants'
import { getDateReadFormat, getTimeReadFormat } from 'chakra-lib/utils/dateFormatting'

export const makeColumns = ({ getTranslation }) =>
  [
    {
      id: 'Иконка',
      accessor: 'id',
      gridWidth: 50,
      Cell: () => <ItemIcon icon={IconPersonLines} />,
    },
    {
      id: TEST_FIELDS.internalId,
      accessor: 'internalId',
      gridWidth: 'minmax(150px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.testType,
      accessor: ({ testTypeTitle }) => testTypeTitle?.ru,
      gridWidth: 'minmax(150px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.passport,
      accessor: 'passport',
      gridWidth: 200,
    },
    {
      id: TEST_FIELDS.officeTitleRu,
      accessor: ({ officeTitle }) => officeTitle?.ru,
      gridWidth: 200,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.officeTitleEn,
      accessor: ({ officeTitle }) => officeTitle?.en,
      gridWidth: 200,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: TEST_FIELDS.officeAddress,
      accessor: ({ officeAddress }) => officeAddress?.ru,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
      gridWidth: 200,
    },
    {
      id: TEST_FIELDS.testingDate,
      accessor: 'testingDate',
      gridWidth: 230,
      Cell: ({ value }) => <TestingDate testingDate={value} />,
    },
    {
      id: TEST_FIELDS.resultDate,
      accessor: 'resultDate',
      gridWidth: 200,
      Cell: ({ value }) => `${getDateReadFormat(value)}, ${getTimeReadFormat(value)}`,
    },
    {
      id: TEST_FIELDS.resultSetDate,
      accessor: 'resultSetDate',
      gridWidth: 200,
      Cell: ({ value }) => `${getDateReadFormat(value)}, ${getTimeReadFormat(value)}`,
    },
    {
      id: TEST_FIELDS.createdAt,
      accessor: 'createdAt',
      gridWidth: 200,
      Cell: ({ value }) => `${getDateReadFormat(value)}, ${getTimeReadFormat(value)}`,
    },
    {
      id: TEST_FIELDS.result,
      accessor: 'id',
      gridWidth: 200,
      Cell: ({ row: { original } }) => {
        return (
          <ItemTestResult
            invalid={original.invalid}
            result={original.result}
            testingDate={original.testingDate}
          />
        )
      },
    },
  ].map(mapItemHeader({ getTranslation }))

export const mapItemHeader =
  ({ getTranslation }) =>
  (item) => {
    const translateId = TEST_FIELDS_TRANSLATION[item.id]

    if (translateId) {
      let translate = getTranslation(translateId)
      const isOfficeField = [
        TEST_FIELDS.officeTitleRu,
        TEST_FIELDS.officeTitleEn,
        TEST_FIELDS.officeAddress,
      ].includes(item.id)

      if (isOfficeField) {
        translate = 'Офис. ' + translate
      }

      return { ...item, Header: translate }
    }

    return item
  }