import React from 'react'
import moment from 'moment'

import { Text, Link } from 'chakra-lib'
import { ItemBadge, ItemIcon } from 'chakra-lib/Snippets'
import { IconCollection } from 'components/icons'
import { LABORATORY_FIELDS, LABORATORY_FIELDS_TRANSLATION } from '../Laboratory.constants'
import { DATE_FORMAT } from 'components/deprecated/utilities/date-formatting'

const TYPE_COLOR_RESULT = {
  true: 'red',
  false: 'green',
}

export const makeColumns = ({ getTranslation }) => {
  const TYPE_DISABLED = {
    true: getTranslation('disabled.true'),
    false: getTranslation('disabled.false'),
  }

  return [
    {
      id: 'Иконка',
      accessor: 'id',
      gridWidth: 50,
      Cell: () => <ItemIcon icon={IconCollection} />,
    },
    {
      id: LABORATORY_FIELDS.name,
      accessor: 'name',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.shortName,
      accessor: 'shortName',
      gridWidth: 'minmax(200px, 1fr)',
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.telephone,
      accessor: 'telephone',
      gridWidth: 180,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.siteUrl,
      accessor: 'siteUrl',
      gridWidth: 200,
      Cell: ({ value }) => (
        <Text isTruncated>
          <Link isExternal>{value}</Link>
        </Text>
      ),
      preventRowClick: true,
    },
    {
      id: LABORATORY_FIELDS.countryRu,
      accessor: 'countryRu',
      gridWidth: 220,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.addressLegal,
      accessor: ({ details }) => details?.addressLegal,
      gridWidth: 220,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.countryShortRu,
      accessor: 'countryShortRu',
      gridWidth: 180,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.oid,
      accessor: ({ details }) => details?.oid,
      gridWidth: 180,
    },
    {
      id: LABORATORY_FIELDS.inn,
      accessor: ({ details }) => details?.inn,
      gridWidth: 180,
    },
    {
      id: LABORATORY_FIELDS.company,
      accessor: ({ details }) => details?.company,
      gridWidth: 220,
      Cell: ({ value }) => <Text isTruncated>{value}</Text>,
    },
    {
      id: LABORATORY_FIELDS.kpp,
      accessor: ({ details }) => details?.addressLegal,
      gridWidth: 180,
    },
    {
      id: LABORATORY_FIELDS.ogrn,
      accessor: ({ details }) => details?.ogrn,
      gridWidth: 180,
    },
    {
      id: LABORATORY_FIELDS.ownership,
      accessor: ({ details }) => details?.ownership,
      gridWidth: 180,
    },
    {
      id: LABORATORY_FIELDS.createdAt,
      accessor: ({ createdAt }) => moment(createdAt).format(DATE_FORMAT),
      gridWidth: 180,
    },
    {
      id: LABORATORY_FIELDS.state,
      accessor: 'disabled',
      gridWidth: 180,
      Cell: ({ value }) => (
        <ItemBadge colorScheme={TYPE_COLOR_RESULT[value]}>{TYPE_DISABLED[value]}</ItemBadge>
      ),
    },
  ].map((item) => {
    const translateId = LABORATORY_FIELDS_TRANSLATION[item.id]

    if (translateId) {
      let translate = getTranslation(translateId)

      if ([LABORATORY_FIELDS.countryRu, LABORATORY_FIELDS.countryShortRu].includes(item.id)) {
        translate = 'Страна. ' + translate
      }

      return { ...item, Header: translate }
    }
    if (translateId) {
      let translate = getTranslation(translateId)
      return { ...item, Header: translate }
    }

    return item
  })
}