import { useState } from 'react'
import { api } from 'api'
import { useQuery } from 'components/useQuery'
import toast from 'utils/toast'

export const useTestTypeList = () => {
  const [data, setData] = useState()

  const {
    request: fetchList,
    isLoading,
    next,
  } = useQuery(
    async (params) => {
      return await api.testType.fetchList(params)
    },
    {
      onSuccess: (data) => {
        setData(data)
      },
      nextPagination: true,
    }
  )

  const { request: createItem, isLoading: isCreating } = useQuery(
    async (values) => {
      return await api.testType.createItem(values)
    },
    {
      onSuccess: (data) => {
        setData((state) => ({
          ...state,
          list: [data, ...state.list],
        }))

        toast.showSuccess({ description: 'Вид теста успешно создан' })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при создании',
          description: err.message,
        })
      },
    }
  )

  const { request: updateItem, isLoading: isUpdating } = useQuery(
    async (values) => {
      return await api.testType.updateItem(values)
    },
    {
      onSuccess: (data) => {
        setData((state) => {
          const newList = state.list.map((item) => (item.id === data.id ? data : item))

          return {
            ...state,
            list: newList,
          }
        })
        toast.showSuccess({ description: `${data.shortTitle.ru} успешно обновлен` })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при обновлении',
          description: err.message,
        })
      },
    }
  )

  return {
    data,
    fetchList,
    next,
    updateItem,
    isLoading,
    isUpdating,
    createItem,
    isCreating,
  }
}

