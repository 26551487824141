import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { VaccineList } from './VaccineList/VaccineList';
import { VaccineCard } from './VaccineCard/VaccineCard';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/show/:id`} exact>
        <VaccineCard/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <VaccineList/>
      </Route>
    </Switch>
  );
};

export default Routes;
