export const checkParam = (param, split) => {
  if (!param) {
    return undefined
  }
  const result =  param.replace(/^\s*/,'').replace(/\s*$/,'');
  if (split) {
    return result.split(split);
  }
  return result;
};
