import axios from 'axios';
import { useCallback, useState } from 'react';
import { API_SERVER_URL } from 'store/config';

export const useFetchItemSettings = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const request = useCallback(async (value) => {
    try {
      setIsLoading(true);
      setIsSuccess(false);

      const result = await axios.post(`${API_SERVER_URL}/reportaccessrole/one`, value);
      setData(result?.data);

      setIsSuccess(true);
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    reportSettings: data,
    reportSettingsIsLoading: isLoading,
    reportSettingsIsLoaded: isSuccess,
    fetchReportSettings: request
  }
}
