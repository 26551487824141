import React from 'react'

import { Box, Flex, useColorModeValue } from '@chakra-ui/react'

const Register = ({ children, rightPanel }) => {
  const themeBorderColor = useColorModeValue('gray.100', 'gray.700')

  return (
    <Flex h="100%">
      <Box overflow="hidden" flexGrow={1} borderRightWidth="1px" borderColor={themeBorderColor}>
        {children}
      </Box>
      {rightPanel && (
        <Box w="290px" minW="290px">
          {rightPanel}
        </Box>
      )}
    </Flex>
  )
}

export default Register