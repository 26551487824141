import { Hooks, Meta, TableProps } from 'react-table'

export const useTemplateGridColumn = <D extends object>(hooks: Hooks<D>): void => {
  hooks.getRowProps.push(useGridPropsHook)
  hooks.getHeaderGroupProps.push(useGridPropsHook)
}

const useGridPropsHook = <D extends object>(props: TableProps, { instance }: Meta<D>) => {
  const { columns } = instance

  const templateColumnsArr = columns.reduce((acc, item) => {
    const { gridWidth, gridMinWidth, gridMaxWidth, isVisible } = item
    let value = getWidthValue(gridWidth)

    if (!isVisible) {
      value = ''
    }

    if (gridMinWidth || gridMaxWidth) {
      value = `minmax(${getWidthValue(gridMinWidth)}, ${getWidthValue(gridMaxWidth)})`
    }

    acc.push(value)
    return acc
  }, [] as string[])

  const templateColumns = templateColumnsArr.join(' ')

  return {
    templateColumns,
    ...props,
  }
}

function getWidthValue(width?: number | string) {
  if (typeof width === 'string') {
    return width
  }

  if (typeof width === 'number') {
    return width.toString() + 'px'
  }

  return '1fr'
}