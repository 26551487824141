export const ROLES = {
    Administrator: 'Administrator',
    User: 'User',
    Insurer: 'Insurer',
    UserCreator: 'UserCreator',
    UserResultSetter: 'UserResultSetter',
    UserEditor: 'UserEditor',
    UserLab: 'UserLab',
    UserPrivileged: 'UserPrivileged',
    api: 'api'
}

export const rolesTranslatePath = {
    [ROLES.Administrator]: 'role.administrator',
    [ROLES.User]: 'role.user',
    [ROLES.Insurer]: 'role.insurer',
    [ROLES.UserCreator]: 'role.userCreator',
    [ROLES.UserPrivileged]: 'role.userPrivileged',
    [ROLES.UserEditor]: 'role.userEditor',
    [ROLES.UserResultSetter]: 'role.userResultSetter',
    [ROLES.UserLab]: 'role.userLab',
    [ROLES.api]: 'role.api',
}
