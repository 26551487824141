import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterFooter, FilterHeader, FilterStack, FilterStackGroup } from '../../../../../chakra-lib/layout';
import { useSelector } from 'react-redux';
import { getFilterParams } from '../../../../../chakra-lib/utils';
import { getURLFromFilterParams } from '../../../../../chakra-lib/utils';
import { InputDate, InputSearch, Select } from '../../../../../chakra-lib';
import { TranslationContext } from '../../../../../translation';
import { Button, Text } from '@chakra-ui/react';
import { FILTER_VACCINATION_PARAMS } from '../../../../../config/vaccination.config';
import { ROLES } from '../../../../../model/roles';
import { Private } from '../../../../../components/Private';
import { getDateReadFormat } from '../../../../../components/deprecated/utilities/date-formatting';
import { getChangeValueSelect, getValueSelect } from '../../../../../chakra-lib/utils/select';
import { isEmpty } from 'lodash';

export const VaccinationListFilter = ({params}) => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const {
    text,
    fromDate,
    toDate,
    medicalFacilityIdList,
    vaccineIdList
  } = useSelector(state => state.vaccination.filter);
  const countList = useSelector(state => state.vaccination.totalItems);
  const userAuth = useSelector(state => state.authentication.user);
  const itemsMedicalFacility = useSelector(state => state.medicalFacility.items);
  const loadingMedicalFacility = useSelector(state => state.medicalFacility.loading);
  const vaccineItems = useSelector(state => state.vaccine.items);
  const vaccineLoading = useSelector(state => state.vaccine.loading);
  const itemsMedicalFacilitySelect = !isEmpty(itemsMedicalFacility) && itemsMedicalFacility.map(item => ({
    value: item.id,
    label: item.title?.ru
  }));
  const vaccineItemsSelect = !isEmpty(vaccineItems) && vaccineItems.map(item => ({
    value: item?.id,
    label: item?.name?.ru
  }));
  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0];
    const filedValue = param[fieldName];
    const objFilterParams = getFilterParams(FILTER_VACCINATION_PARAMS, params);
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue
    })
    history.push(`/vaccination/list${urlFilterParams}`);
  }

  const handleResetFilter = () => {
    history.push(`/vaccination/list`)
  }

  return (
    <>
      <Private
        role={userAuth.role}
        hasAnyRole={[ROLES.Administrator, ROLES.UserCreator]}
      >
        <FilterHeader>
          <Button
            width="100%"
            onClick={() => history.push(`/vaccination/list/new`)}
            colorScheme="blue"
          >
            {getTranslation('test.action.buttonCreate')}
          </Button>
        </FilterHeader>
      </Private>
      <FilterStack>
        <InputSearch
          label={getTranslation('global.search')}
          name="text"
          value={text}
          onChange={e => handleFilter({text: e})}
        />
        <Select
          isMulti
          isLoading={vaccineLoading}
          name="vaccineIdList"
          closeMenuOnSelect={false}
          label="Вакцина"
          placeholder="Выберите"
          options={vaccineItemsSelect}
          value={getValueSelect(vaccineItemsSelect, vaccineIdList)}
          onChange={e => handleFilter({ vaccineIdList: getChangeValueSelect(e) })}
        />
        {(userAuth.role === ROLES.Administrator || (userAuth.role === ROLES.UserEditor && isEmpty(userAuth.medicalFacilityId))) && (
          <Select
            isMulti
            isLoading={loadingMedicalFacility}
            name="medicalFacilityIdList"
            closeMenuOnSelect={false}
            label="Медицинские учреждения"
            placeholder="Выберите"
            options={itemsMedicalFacilitySelect}
            value={getValueSelect(itemsMedicalFacilitySelect, medicalFacilityIdList)}
            onChange={e => handleFilter({ medicalFacilityIdList: getChangeValueSelect(e) })}
          />
        )}
        <FilterStackGroup>
          <InputDate
            value={fromDate}
            showCurrentDate={false}
            name="dateFrom"
            placement="bottom"
            onChange={e => handleFilter({ fromDate: e })}
            label={getTranslation('filter.dateFrom')}
            placeholder={getDateReadFormat()}
          />
          <InputDate
            value={toDate}
            showCurrentDate={false}
            name="dateTo"
            placement="bottom"
            onChange={e => handleFilter({ toDate: e })}
            placeholder={getDateReadFormat()}
          />
        </FilterStackGroup>
      </FilterStack>
      <FilterFooter>
        <Button
          size="sm"
          width="100%"
          onClick={handleResetFilter}
        >Очистить фильтр</Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
    </>
  )
};
