import { Icon } from '@chakra-ui/react';
import React from 'react';

export const IconList = (props) => {
  return (
    <Icon viewBox="0 0 12 14" {...props}>
      <g fill="currentColor">
        <path
          fill="currentColor"
          clipRule="evenodd"
          fillRule="evenodd"
          d="M1.08,0A1.08,1.08,0,0,0,0,1.08V13.64a1.08,1.08,0,0,0,1.08,1.08H11.44a1.07,1.07,0,0,0,1.07-1.08V1.08A1.07,1.07,0,0,0,11.44,0ZM9.93,4.36H2.5A.55.55,0,0,1,2,3.81a.55.55,0,0,1,.55-.55H9.93a.54.54,0,0,1,.55.55A.55.55,0,0,1,9.93,4.36ZM2.55,7.83H10a.55.55,0,0,0,.55-.55A.55.55,0,0,0,10,6.73H2.55A.55.55,0,0,0,2,7.28.55.55,0,0,0,2.55,7.83Zm4.76,3.48H2.55A.55.55,0,0,1,2,10.76a.55.55,0,0,1,.55-.55H7.31a.55.55,0,0,1,.55.55A.55.55,0,0,1,7.31,11.31Z"
        />
      </g>
    </Icon>
  );
};
