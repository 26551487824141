/**
 * Возвращает результат парсинга x-pagination заголовока
 * @returns {{ currentPage: number, totalPages: number, pageSize: number, totalCount: number, hasPrevious: boolean, hasNext: boolean }}
 */
export const getXPaginationInfo = (response) => {
  const state = JSON.parse(response.headers["x-pagination"]);

  if (!state) {
    return {};
  }

  const {
    CurrentPage,
    TotalPages,
    PageSize,
    TotalCount,
    HasPrevious,
    HasNext
  } = state;

  return {
    currentPage: CurrentPage,
    totalPages: TotalPages,
    pageSize: PageSize,
    totalCount: TotalCount,
    hasPrevious: HasPrevious,
    hasNext: HasNext
  };
};
