import React from 'react';
import { Icon } from '@chakra-ui/react';

export const IconCollection = (props) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g fill="currentColor" clipRule="evenodd" fillRule="evenodd">
        <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7z" />
        <path d="M2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />
      </g>
    </Icon>
  );
};
