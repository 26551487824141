import React from 'react';
import { useSelector } from 'react-redux';
import { TYPE_COUNTRY } from '../../model/country';
import { RequisitesFormRu } from './ru/requisites-form-ru';
import { RequisitesFormAm } from './am/requisites-form-am';
import { RequisitesFormBy } from './by/requisites-form-by';
import { RequisitesShowRu } from './ru/requisites-show-ru';
import { RequisitesShowAm } from './am/requisites-show-am';
import { RequisitesShowBy } from './by/requisites-show-by';

export const RequisitesForm = ({ loading }) => {
    const country = useSelector(state => state.settings.country);
    switch (country) {
        case TYPE_COUNTRY.ru: {
            return <RequisitesFormRu loading={loading}/>
        }
        case TYPE_COUNTRY.am: {
            return <RequisitesFormAm loading={loading}/>
        }
        case TYPE_COUNTRY.by: {
            return <RequisitesFormBy loading={loading}/>
        }
        default: return <RequisitesFormRu loading={loading}/>
    }
};

export const RequisitesShow = ({ item, loading, isReadyItem, initialCountry = null }) => {
    const country = useSelector(state => state.settings.country);
    const countryActive = initialCountry ? initialCountry : country;
    switch (countryActive) {
        case TYPE_COUNTRY.ru: {
            return <RequisitesShowRu item={item} loading={loading} isReadyItem={isReadyItem}/>
        }
        case TYPE_COUNTRY.am: {
            return <RequisitesShowAm item={item} loading={loading} isReadyItem={isReadyItem}/>
        }
        case TYPE_COUNTRY.by: {
            return <RequisitesShowBy item={item} loading={loading} isReadyItem={isReadyItem}/>
        }
        default: return <RequisitesShowRu item={item} loading={loading} isReadyItem={isReadyItem}/>
    }
}
