import { combineReducers } from 'redux';
import { testReducer } from './test.reducer';
import { authenticationReducer } from './authentication.reducer';
import { officeReducer } from './office.reducer';
import { laboratoryReducer } from './laboratory.reducer';
import { settingsReducer } from './settings.reducer';
import { countryReducer } from './country.reducer';
import { userReducer } from './user.reducer';
import { reportReducer } from './report.reducer';
import { settingsDocumentsReducer } from './settings-documents.reducer';
import { settingsInformationReducer } from './settings-information.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { faqReducer } from './faq.reducer';
import { vaccineReducer } from './vaccine.reducer';
import { settingsSMTPReducer } from './settings-smtp.reducer';
import { vaccinationReducer } from './vaccination.reducer';
import { medicalFacilityReducer } from './medicalFacility.reducer';

export const rootReducer = combineReducers({
    user: userReducer,
    test: testReducer,
    office: officeReducer,
    laboratory: laboratoryReducer,
    authentication: authenticationReducer,
    country: countryReducer,
    settings: settingsReducer,
    settingsDocuments: settingsDocumentsReducer,
    settingsInformation: settingsInformationReducer,
    report: reportReducer,
    faq: faqReducer,
    vaccine: vaccineReducer,
    vaccination: vaccinationReducer,
    settingsSMTP: settingsSMTPReducer,
    medicalFacility: medicalFacilityReducer,
    loadingBar: loadingBarReducer
});
