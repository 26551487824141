import React from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

export const SectionMenuItem = ({to, children, ...props}) => {
  const themeBgSelected = useColorModeValue('gray.50', 'gray.600');
  const themeBgHover = useColorModeValue('gray.100', 'gray.600');
  const themeBgActive = useColorModeValue('gray.200', 'gray.800');
  const themeBgActiveSelected = useColorModeValue('blue.100', 'blue.300');
  const themeColorFontTitleSelected = useColorModeValue('blue.500', 'white');
  const themeColorFontTitle = useColorModeValue('black', 'white');
  const location = useLocation();
  const {pathname} = location;
  const isSelected = pathname.includes(to);
  return (
   <Link to={to}>
     <Flex
       mr="10px"
       alignItems="center"
       fontSize="0.9rem"
       pl="10px"
       pr="10px"
       role="group"
       position="relative"
       height="32px"
       lineHeight={1}
       whiteSpace="nowrap"
       overflow="hidden"
       backgroundColor={isSelected ? themeBgSelected : 'transparent'}      borderRadius={6}
       transition="0.2s"
       cursor="pointer"
       _active={{
         backgroundColor: isSelected ? themeBgActiveSelected : themeBgActive,
       }}
       _focus={{
         outline: 'none',
         boxShadow: 'outline',
         borderColor: 'gray.300'
       }}
       _hover={{
         backgroundColor: !isSelected && themeBgHover,
       }}
       {...props}
     >
       <Box
         fontWeight="normal"
         transition="0.2s"
         color={isSelected ? themeColorFontTitleSelected : themeColorFontTitle}
         flex={1}
       >
         {children}
       </Box>
     </Flex>
   </Link>
  )
}
