import React, { forwardRef, memo } from 'react'
import { TableToggleCommonProps } from 'react-table'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'

export interface CheckboxProps extends TableToggleCommonProps {}

export const Checkbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps>(
    ({ indeterminate, checked, onChange, ...props }, ref) => {
      return (
        <ChakraCheckbox
          ref={ref}
          isChecked={checked}
          isIndeterminate={indeterminate}
          onChange={onChange}
          {...props}
        />
      )
    }
  )
)

Checkbox.displayName = 'TableCheckbox'