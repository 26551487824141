import { useContext, useEffect, useState } from 'react'
import { api } from 'api'
import { useQuery } from 'components/useQuery'
import toast from 'utils/toast'
import { TranslationContext } from 'translation'

export const useTestCard = (id) => {
  const [data, setData] = useState()
  const { getTranslation } = useContext(TranslationContext)

  const {
    request: fetchItem,
    isLoading: itemIsLoading,
    isSuccess: itemIsSuccess,
  } = useQuery(
    async (params) => {
      return await api.tests.fetchItem(id, params)
    },
    {
      onSuccess: (response) => {
        setData(response)
      },
    }
  )

  const {
    request: updateItem,
    isLoading: openIsLoading,
    isSuccess: openIsSuccess,
  } = useQuery(
    async (values) => {
      return await api.tests.updateItem(values)
    },
    {
      onSuccess: (data) => {
        setData(data)
        toast.showSuccess({ description: 'Тест успешно обновлен' })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при обновлении',
          description: err.message,
        })
      },
    }
  )

  const {
    request: setInvalidItem,
    isLoading: setInvalidIsLoading,
    isSuccess: setInvalidIsSuccess,
  } = useQuery(
    async () => {
      return await api.tests.setInvalidItem(id)
    },
    {
      onSuccess: () => {
        toast.showSuccess({
          title: getTranslation('test.toast.addResult.invalidTest.title'),
          description: getTranslation('test.toast.addResult.invalidTest.description'),
        })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при обновлении',
          description: err.message,
        })
      },
    }
  )

  const {
    request: sendEmail,
    isLoading: sendEmailIsLoading,
    isSuccess: sendEmailIsSuccess,
  } = useQuery(
    async (values) => {
      return await api.tests.sendEmail(id, values)
    },
    {
      onSuccess: () => {
        toast.showSuccess({
          title: getTranslation('test.toast.sendEmail.success.title'),
          description: getTranslation('test.toast.sendEmail.success.description'),
        })
      },
      onError: () => {
        toast.showError({
          title: getTranslation('test.toast.sendEmail.error.title'),
          description: getTranslation('test.toast.sendEmail.error.description'),
        })
      },
    }
  )

  const {
    request: sendConsentEmail,
    isLoading: sendConsentEmailIsLoading,
    isSuccess: sendConsentEmailIsSuccess,
  } = useQuery(
    async () => {
      return await api.tests.sendConsentEmail(id)
    },
    {
      onSuccess: () => {
        toast.showSuccess({
          title: getTranslation('test.toast.sendEmail.success.title'),
          description: getTranslation('test.toast.sendConsentEmail.success.description'),
        })
      },
    }
  )

  const {
    request: eraseItem,
    isLoading: eraseIsLoading,
    isSuccess: eraseIsSuccess,
  } = useQuery(
    async (values) => {
      return await api.tests.eraseItem(id, values)
    },
    {
      onSuccess: (data) => {
        setData(data)
        toast.showSuccess({ description: 'Данные клиента успешно удалены' })
      },
      onError: (err) => {
        toast.showError({
          title: 'Произошла ошибка при обновлении',
          description: err.message,
        })
      },
    }
  )

  useEffect(() => {
    fetchItem(id)
  }, [])

  return {
    data,
    isLoading:
      itemIsLoading ||
      openIsLoading ||
      eraseIsLoading ||
      sendEmailIsLoading ||
      sendConsentEmailIsLoading ||
      setInvalidIsLoading,
    isSuccess:
      itemIsSuccess ||
      openIsSuccess ||
      eraseIsSuccess ||
      sendEmailIsSuccess ||
      sendConsentEmailIsSuccess ||
      setInvalidIsSuccess,
    fetchItem,
    updateItem,
    eraseItem,
    sendEmail,
    sendConsentEmail,
    setInvalidItem,
  }
}
