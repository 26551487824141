import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNextPage, resetFilter, resetOfficeList, updateFilter } from 'store/office.reducer'
import { getFilterParams } from 'chakra-lib/utils'
import { FILTER_OFFICE_PARAMS } from 'config/office.config'
import { OfficeListFilter } from './elemenst/OfficeListFilter'
import { getLaboratoryList, resetLaboratoryList } from 'store/laboratory.reducer'
import { exportExcelFile } from 'utils/exportExcelFile'
import Register from 'components/layout/Register'
import { STORAGE_TABLE } from 'model/localStorage'
import { makeExcelColumns } from './OfficeList.excel'
import { makeColumns } from './OfficeList.config'
import { OFFICE_FIELDS } from '../Office.constants'
import { getCountryAllList } from 'store/country.reducer'

export const OfficeList = () => {
  const tableRef = useRef()
  const history = useHistory()
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const { params } = useParams()
  const activePage = useSelector((state) => state.office.activePage)
  const itemsCountry = useSelector((state) => state.country.itemsAll)
  const itemsOffice = useSelector((state) => state.office.items)
  const itemsLaboratory = useSelector((state) => state.laboratory.items)
  const loading = useSelector((state) => state.laboratory.loading)
  const totalPages = useSelector((state) => state.office.totalPages)
  const userAuth = useSelector((state) => state.authentication.user)

  const isReadySnippets = Boolean(
    itemsOffice &&
      itemsOffice.length &&
      itemsLaboratory &&
      itemsLaboratory.length &&
      itemsCountry &&
      itemsCountry.length
  )

  const items = useMemo(() => {
    if (isReadySnippets) {
      const withLaboratoryItems = itemsOffice.map((item) => ({
        ...item,
        ...mapLaboratoryValues(itemsLaboratory, item.laboratoryId),
      }))

      return withLaboratoryItems.map((item) => ({
        ...item,
        ...mapCountryValues(itemsCountry, item.countryId),
      }))
    }

    return []
  }, [isReadySnippets, itemsOffice, itemsLaboratory, itemsCountry])

  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_OFFICE_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])

  useEffect(() => {
    dispatch(getCountryAllList())
    dispatch(getLaboratoryList())
    return () => {
      dispatch(resetFilter())
      dispatch(resetOfficeList())
      dispatch(resetLaboratoryList())
    }
  }, [])

  const exportExcelHandle = useCallback(() => {
    const hiddenColumns = tableRef.current.state.hiddenColumns

    exportExcelFile(items, {
      sheetName: 'Office',
      columns: makeExcelColumns({ getTranslation, hiddenColumns }),
    })
  }, [items])

  const columns = useMemo(() => makeColumns({ getTranslation }), [])

  const onRowClick = useCallback(({ original }) => {
    history.push(`/integration/office/show/${original.id}`)
  }, [])

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        OFFICE_FIELDS.shortTitleRu,
        OFFICE_FIELDS.siteUrl,
        OFFICE_FIELDS.addressRu,
        OFFICE_FIELDS.addressLegal,
        OFFICE_FIELDS.inn,
        OFFICE_FIELDS.oid,
        OFFICE_FIELDS.ogrn,
        OFFICE_FIELDS.kpp,
        OFFICE_FIELDS.company,
        OFFICE_FIELDS.ownership,
        OFFICE_FIELDS.countryRu,
        OFFICE_FIELDS.countryShortRu,
        OFFICE_FIELDS.laboratoryName,
        OFFICE_FIELDS.laboratoryShortName,
        OFFICE_FIELDS.laboratoryTelephone,
        OFFICE_FIELDS.laboratorySiteUrl,
      ],
    }),
    []
  )

  return (
    <Register rightPanel={<OfficeListFilter params={params} onExportExcel={exportExcelHandle} />}>
      <Table
        ref={tableRef}
        data={items}
        columns={columns}
        isLoading={loading}
        hasNext={activePage < totalPages}
        onNextPage={() => dispatch(getNextPage())}
        onRowClick={onRowClick}
        initialState={initialState}
        storageId={`${STORAGE_TABLE.laboratoryOffice}.${userAuth.id}`}
      />
    </Register>
  )
}

const mapLaboratoryValues = (items = [], id) => {
  const item = items.find((item) => item.id === id)

  return {
    countryId: item.countryId,
    laboratoryName: item.name,
    laboratoryShortName: item.shortName,
    laboratorySiteUrl: item.siteUrl,
    laboratoryTelephone: item.telephone,
    laboratoryInn: item.details ? (item.details.inn ? item.details.inn : '') : '',
  }
}

const mapCountryValues = (items = [], id) => {
  const item = items.find((item) => item.id === id)

  return {
    countryRu: item.name ? (item.name.ru ? item.name.ru : '') : '',
    countryShortRu: item.shortName ? (item.shortName.ru ? item.shortName.ru : '') : '',
  }
}