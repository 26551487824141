import React, { useContext, useEffect, useMemo } from 'react'
import { Button, useToast, Collapse, useBoolean } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { TranslationContext } from 'translation'
import { Modal, SelectForm } from 'chakra-lib'
import { Form, FormGrid, FormSection } from 'chakra-lib/layout'
import {
  getDateReadFormat,
  getDateTimeToSubmit,
  getDiffDateHours,
  getDiffDateMinutes,
  getTimeReadFormat,
} from 'chakra-lib/utils/dateFormatting'
import { ROLES } from 'model/roles'
import { UIInputTimeForm } from 'components/deprecated/input'
import { UIInputDateForm } from 'components/deprecated/input-date'
import { getAccess } from 'components/Private'
import { useTestTypeList } from 'pages/laboratory/Test/TestCard/_services'

export const SendResultForm = ({ item, onSendResult, onInvalidResult, isDisabled = true }) => {
  const { getTranslation } = useContext(TranslationContext)
  const [isOpenSetResult, openSetResult] = useBoolean()
  const toast = useToast()
  const currentTime = useSelector((state) => state.settings.time)
  const testValidHours = useSelector((state) => state.settings.testValidHours)
  const user = useSelector((state) => state.authentication.user)
  const testingDate = item?.testingDate
  const loadingUpdate = useSelector((state) => state.test.loadingUpdate)
  const userAuth = useSelector((state) => state.authentication.user)

  const {
    response: testTypes,
    request: fetchTestTypes,
    isLoading: testTypesIsLoading,
  } = useTestTypeList()

  useEffect(() => {
    fetchTestTypes()
  }, [])

  const submitForm = async (values) => {
    if (values.result === 'invalid') {
      onInvalidResult(values)
      openSetResult.off()
      return
    }

    const dataRequest = {
      ...item,
      resultDate: getDateTimeToSubmit(`${values.resultDate} ${values.resultTime}`),
      result: values.result === 'true',
      testTypeId: values.testTypeId,
    }

    onSendResult(dataRequest)
    openSetResult.off()
  }

  const handleOpen = () => {
    if (item?.result === null) {
      openSetResult.on()
    } else {
      if (user.role === ROLES.Administrator || user.role === ROLES.UserPrivileged) {
        openSetResult.on()
      } else {
        toast({
          title: getTranslation('test.toast.addResult.errorRepeat.title'),
          description: getTranslation('test.toast.addResult.errorRepeat.description'),
          status: 'warning',
          duration: 9000,
          isClosable: true,
        })
      }
    }
  }

  const validate = (values) => {
    if (values.result === 'invalid') {
      return {}
    }

    const errors = {}
    const resultDateToSubmit = getDateTimeToSubmit(`${values.resultDate} ${values.resultTime}`)
    const diffHours = getDiffDateHours(resultDateToSubmit, testingDate)
    const diffMinutes = getDiffDateMinutes(resultDateToSubmit, testingDate)

    if (!values.result) {
      errors.result = 'Required'
    }

    if (!values.testTypeId) {
      errors.testTypeId = 'Required'
    }

    if (diffMinutes <= 0 || diffHours > Number(testValidHours)) {
      errors.resultTime = 'Error Date'
      errors.resultDate = 'Error Time'
    }

    return errors
  }

  const resultOptions = useMemo(() => {
    let result = [
      { value: 'false', label: getTranslation('resultTest.false') },
      { value: 'true', label: getTranslation('resultTest.true') },
    ]

    if (getAccess(userAuth.role, [ROLES.Administrator])) {
      result.push({ value: 'invalid', label: getTranslation('resultTest.invalid') })
      return result
    }

    if (item?.result === null) {
      result.push({ value: 'invalid', label: getTranslation('resultTest.invalid') })
      return result
    }

    return result
  }, [getTranslation, userAuth.role, item?.result])

  const testTypeOptions = useMemo(() => {
    if (testTypes?.list) {
      return testTypes.list.map((item) => ({
        label: item?.title.ru,
        value: item?.id,
      }))
    }

    return []
  }, [testTypes])

  return (
    <>
      <Button width="100%" onClick={handleOpen} colorScheme="blue" isDisabled={isDisabled}>
        {getTranslation('test.action.buttonSetResult')}
      </Button>
      <Modal
        isOpen={isOpenSetResult}
        onClose={openSetResult.off}
        title={getTranslation('test.heading.resultTest')}
      >
        <Formik
          initialValues={{
            resultDate: getDateReadFormat(currentTime),
            resultTime: getTimeReadFormat(currentTime),
            result: '',
            testTypeId: testTypes?.list[0].id || undefined,
          }}
          onSubmit={submitForm}
          validate={validate}
        >
          {(props) => (
            <Form dataForm={props} onSubmit={props.handleSubmit}>
              <FormSection>
                <Field
                  autoFocus
                  name="result"
                  label={getTranslation('test.field.resultTest.label')}
                  component={SelectForm}
                  placeholder={getTranslation('test.field.resultTest.placeholder')}
                  isSearchable={false}
                  options={resultOptions}
                />
                <Field
                  name="testTypeId"
                  label={getTranslation('test.field.testType.label')}
                  component={SelectForm}
                  isLoading={testTypesIsLoading}
                  placeholder={getTranslation('test.field.testType.placeholder')}
                  isSearchable={false}
                  options={testTypeOptions}
                />
                <Collapse in={props.values.result !== 'invalid'}>
                  <FormGrid columns={2}>
                    <Field
                      label={getTranslation('global.time')}
                      name="resultTime"
                      component={UIInputTimeForm}
                      placeholder="00:00"
                    />
                    <Field
                      label={getTranslation('global.date')}
                      name="resultDate"
                      component={UIInputDateForm}
                      placeholder="01.01.2020"
                    />
                  </FormGrid>
                </Collapse>
              </FormSection>
              <FormSection mb="20px">
                <Button colorScheme="blue" type="submit" isLoading={loadingUpdate}>
                  {getTranslation('global.confirm')}
                </Button>
              </FormSection>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
