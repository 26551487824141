import React from 'react';
import { Flex, Grid, Box, Text } from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import { getDateReadFormat } from '../../components/deprecated/utilities/date-formatting';

const getCountryName = (code) => {
  switch (code) {
    case 'am':
      return 'Республики Армения'
    case 'by':
      return 'Республики Беларусь'
    case 'ru':
      return 'Российской Федерации'
    default:
      return '';
  }
}

const GridContainer = ({children}) => {
  return (
    <Grid
      mb={3}
      borderTopWidth={1}
      borderLeftWidth={1}
      borderRightWidth={1}
      borderColor={'#000000'}
    >
      {children}
    </Grid>
  );
};


const GridItem = ({label, children}) => {
  return (
    <Box
      display="grid"
      borderBottomWidth={1}
      borderColor={'#000000'}
      gridTemplateColumns="280px 1fr"
      gap="10px"
    >
      <Box padding="2px 10px" borderRightWidth={1} borderColor="#000000" paddingRight="10px">
        {label}
      </Box>
      <Box padding="2px 10px">
        {children}
      </Box>
    </Box>
  )
}

export class TestStatement extends React.Component {
  render() {
    return (
      <Box width="100%" padding="80px" color="#000000" fontSize="0.7rem">
        <Box fontSize="1rem" fontWeight="bold" mb={5}>
          Согласие на лабораторное исследование
          №{this.props.item.internalId} от {getDateReadFormat(this.props.item.testingDate)}
        </Box>
        <Box fontWeight="bold" mb={2}>Данные клиента</Box>

        <GridContainer>
          <GridItem label="ФИО">{this.props.item.fio ? this.props.item.fio : ''}</GridItem>
          <GridItem label="Паспорт">{this.props.item.passport ? this.props.item.passport : ''}</GridItem>
          <GridItem label="Телефон">{this.props.item.telephone ? this.props.item.telephone : ''}</GridItem>
          <GridItem label="Электронная почта">{this.props.item.email ? this.props.item.email : ''}</GridItem>
        </GridContainer>

        <Box fontWeight="bold" mb={2}>Данные исследования</Box>

        <GridContainer>
          <GridItem label="Код исследования">{this.props.item.internalId ? this.props.item.internalId : ''}</GridItem>
          <GridItem label="Дата\время взятия биоматериала">{getDateReadFormat(this.props.item.testingDate)}</GridItem>
        </GridContainer>

        <Box borderWidth={1} borderColor="#000000" mb={6}>
          <Grid mt={5} pb={6} templateColumns="280px 1fr" alignItems="center" borderBottomWidth={1}
                borderColor="#000000">
            <Flex justifyContent="center" alignItems="center">
              <QRCode value={this.props.item.id ? `https://app.nocovid.su/#${this.props.item.id}` : ''}/>
            </Flex>
            <Box padding="0 20px 0 0">
              Отсканируйте этот код с помощью Приложения Путешествую без COVID-19, для получения результатов
              исследования в мобильном приложении. (Предоставлением данного QR кода любому лицу вы соглашаетесь
              на получение данным лицом результатов лабораторного исследования, а также данных указанных ниже,
              данный QR код может читаться только приложением Путешествую без COVID-19)
            </Box>
          </Grid>
          <Box padding={2} fontSize="0.6rem">
            ВНИМАНИЕ! Данный QR код не предназначен для предъявления контролирующим органам, проверяющим наличие
            отрицательного ПЦР теста на COVID-19. Для представления контролирующим органам необходимо обязательно
            отсканировать данный QR код в приложении «Путешествую без COVID-19», получить результат ПЦР теста в виде
            «зеленого» QR кода с результатами исследования и полученный результат в виде «зеленого» QR кода представить
            ответственному контролеру на границе
          </Box>
        </Box>

        <Box fontSize="1rem" fontWeight="bold" mb={5}>Согласие на обработку персональных данных</Box>

        <Box>
          <Box mb={3}>
            Я, {this.props.item.fio ? this.props.item.fio : '________________________________________________________'},
            паспорт {this.props.item.passport ? this.props.item.passport : '____________________________'},
            даю свое согласие на обработку моих персональных данных, в том числе в информационной системе
            Путешествую Без COVID-19. Согласие касается фамилии, имени, отчества, типе документа, удостоверяющем
            личность (его серии, номере, дате и месте выдачи), результатов лабораторного исследования.
          </Box>

          <Box mb={3}>
            До моего сведения доведено, что {this.props.laboratoryName ? this.props.laboratoryName : ''} гарантирует
            обработку моих персональных
            данных в соответствии с действующим
            законодательством {this.props.country ? getCountryName(this.props.country) : ''}. Срок хранения персональных
            сведений соответствует сроку хранения Медицинских документов в соответствии
            законодательством {this.props.country ? getCountryName(this.props.country) : ''}.
            Данные могут используются только в целях проведения и предоставления результатов лабораторного исследования.
            Согласие может быть отозвано в любой момент по моему письменному заявлению.
          </Box>

          <Box mb={3}>
            Также я соглашаюсь на предоставление, хранение и обработку нижеследующих данных уполномоченному
            органу {this.props.country ? getCountryName(this.props.country) : ''}: 4 последние цифры номера паспорта,
            лаборатория выполнившая исследование, дата и время лабораторного исследования/взятия биоматериала, номер и
            результат лабораторного исследования.
          </Box>

          <Box mb={3}>
            Подтверждаю, что ознакомлен с механизмом подтверждения результатов лабораторного исследования, а именно,
            предоставлением любому лицу QR кода, полученный в мобильном приложении Путешествую без COVID-19 я соглашаюсь
            на возможность сверки этим лицом данных полученных в мобильном приложении с данными находящихся у
            уполномоченного
            органа {this.props.country ? getCountryName(this.props.country) : ''}, через каналы связи, международной
            финансовой организации Евразийский банк развития.
          </Box>

          <Box mb={6}>
            Подтверждаю, что, давая согласие я действую без принуждения, по собственной воле и в своих интересах.
          </Box>
        </Box>

        <Grid gridAutoFlow="column" gap="16px">
          <Text>
            Дата: _________
          </Text>
          <Text>
            Подпись: _________________
          </Text>
          <Text>
            Расшифровка подписи:______________________
          </Text>
        </Grid>
      </Box>
    );
  }
}
