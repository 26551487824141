import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UserList } from './UserList/UserList';
import { UserUpdate } from './UserUpdate/UserUpdate';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <UserUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <UserUpdate/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <UserList/>
      </Route>
    </Switch>
  );
};

export default Routes;
