import React, { useContext, useState } from 'react'
import { Button, ModalBody, useDisclosure, Box } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { FormGrid, FormSection } from 'components/deprecated/layout'
import { ModalForm } from 'components/deprecated/modal'
import * as Yup from 'yup'
import { TranslationContext } from 'translation'
import { UIInputDateForm } from 'components/deprecated/input-date'
import { getDateTimeToSubmit } from 'components/deprecated/utilities/date-formatting'
import download from 'downloadjs'
import { CheckboxForm, SelectForm } from 'chakra-lib'

const formSchema = Yup.object().shape({
  dateFrom: Yup.string().required('Required'),
  dateTo: Yup.string().required('Required'),
})

export const ReportLaboratoryUser = () => {
  const { getTranslation } = useContext(TranslationContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const itemsOffice = useSelector((state) => state.office.items)
  const [loading, setLoading] = useState(false)
  const handleForm = async (values) => {
    const dataRequest = {
      offices: values.offices.length ? values.offices : itemsOffice.map((item) => item.id),
      dateFrom: getDateTimeToSubmit(values.dateFrom),
      dateTo: getDateTimeToSubmit(values.dateTo),
      groupByOffice: values.groupByOffice,
    }
    setLoading(true)
    fetch('/adminapi/reports/laboratory_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(dataRequest),
    }).then((res) =>
      res.blob().then((blob) => {
        setLoading(false)
        download(
          blob,
          `${getTranslation('reports.action.laboratoryUser')} ${values.dateFrom}-${
            values.dateTo
          }.xlsx`
        )
      })
    )
  }

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen} width="100%">
        {getTranslation('reports.action.laboratoryUser')}
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={handleCloseModal}
        heading={getTranslation('reports.heading.laboratoryUser')}
      >
        <Formik
          initialValues={{
            offices: [],
            dateFrom: '',
            dateTo: '',
            groupByOffice: false,
          }}
          onSubmit={handleForm}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormSection mb={0}>
                  <FormGrid columns={2}>
                    <Field
                      label={getTranslation('global.dateFrom')}
                      name="dateFrom"
                      component={UIInputDateForm}
                    />
                    <Field
                      label={getTranslation('global.dateTo')}
                      name="dateTo"
                      component={UIInputDateForm}
                    />
                  </FormGrid>
                  <Field
                    name="offices"
                    isMulti
                    placeholderColor="#333333"
                    placeholder={getTranslation('global.all')}
                    component={SelectForm}
                    label={getTranslation('reports.field.OfficeId.label')}
                    options={[
                      ...(itemsOffice &&
                        itemsOffice.map((item) => ({
                          value: item.id,
                          label: item.title ? item.title?.ru : '<Нет имени>',
                        }))),
                    ]}
                  />
                  <Field
                    name="groupByOffice"
                    component={CheckboxForm}
                    label={getTranslation('reports.field.groupByOffice.label')}
                  />
                </FormSection>
                <Box padding="1.5rem 0 0.5rem">
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isDisabled={!props.dirty}
                    isLoading={loading}
                  >
                    {getTranslation('global.download')}
                  </Button>
                </Box>
              </ModalBody>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  )
}
