import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Image, Box, Text } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Logo from 'images/logo512.png'
import { ContainerApp, Header, NavigationMenuItem } from 'chakra-lib/layout'
import { ProfileWidget } from 'components/ProfileWidget'
import { isEmpty } from 'lodash'
import { Private, PrivateRoute } from 'components/Private'
import { ROLES } from 'model/roles'
import { LoadingSettings } from 'components/deprecated/layout'
import { Login } from 'login'
import { TranslationContext } from 'translation'
import Office from './Office'
import Laboratory from './Laboratory'
import Test from './Test'
import User from './User'
import { getCountry } from 'store/settings.reducer'

export const National = () => {
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const userAuth = useSelector((state) => state.authentication.user)
  const testValidHours = useSelector((state) => state.settings.testValidHours)
  const country = useSelector((state) => state.settings.country)
  const version = useSelector((state) => state.settings.version)
  const versionBack = useSelector((state) => state.settings.versionBack)
  const { url } = useRouteMatch()
  const panelName = 'National'
  const isAuthenticated = !isEmpty(userAuth)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCountry())
    }
  }, [isAuthenticated])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.querySelector('html').style.overflow = 'hidden'
  }, [])

  return (
    <>
      {isAuthenticated ? (
        testValidHours && country ? (
          <>
            <Helmet>
              <title>{`COVID ${panelName}`}</title>
            </Helmet>
            <ContainerApp>
              <Header
                renderLogo={
                  <>
                    <Image src={Logo} width="44px" height="44px" alt="" />
                    <Box ml={3} lineHeight="1.4">
                      <Box>
                        <Text fontWeight="semibold" fontSize="1.1rem">
                          {panelName}
                        </Text>
                        <Text
                          fontSize="0.5rem"
                          color="gray.400"
                        >{`front: ${version} back: ${versionBack}`}</Text>
                      </Box>
                    </Box>
                  </>
                }
                renderNavigationItem={
                  <>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/national/laboratory`}>
                        {getTranslation('menu.laboratory')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/national/office`}>
                        {getTranslation('menu.office')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator, ROLES.User]}>
                      <NavigationMenuItem to={`/national/test`}>
                        {getTranslation('menu.test')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/national/user`}>
                        {getTranslation('menu.user')}
                      </NavigationMenuItem>
                    </Private>
                  </>
                }
                renderProfileWidget={<ProfileWidget />}
              />
              <Box overflow="hidden" flexGrow={1}>
                <Switch>
                  <PrivateRoute
                    path={`${url}/laboratory`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Laboratory />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/test`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator, ROLES.User]}
                  >
                    <Test />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/office`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Office />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/user`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <User />
                  </PrivateRoute>
                  <Route path={`/`}>
                    <Redirect to="/national/laboratory" />
                  </Route>
                </Switch>
              </Box>
            </ContainerApp>
          </>
        ) : (
          <LoadingSettings />
        )
      ) : (
        <Login />
      )}
    </>
  )
}
