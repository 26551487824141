import React from 'react'
import { Checkbox, FormControl } from '@chakra-ui/react'

export const UICheckboxForm = ({ field, form, label, ...props }) => {
  const handleCheckbox = (e) => form.setFieldValue(field.name, e.target.checked)
  return (
    <FormControl>
      <Checkbox isChecked={form.values[field.name] || false} onChange={handleCheckbox} {...props}>
        {label}
      </Checkbox>
    </FormControl>
  )
}
