import React, { useContext } from 'react'

import { TranslationContext } from 'translation'
import { ModalForm } from 'components/deprecated/modal'
import TestTypeForm from 'pages/integration/dictionaries/TestType/TestTypeCard/TestTypeForm'
import { ModalBody } from '@chakra-ui/react'

const TestTypeCard = ({ data, isOpen, onSubmit, onClose }) => {
  const { getTranslation } = useContext(TranslationContext)

  return (
    <ModalForm
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      heading={getTranslation('testType.heading')}
    >
      <ModalBody>
        <TestTypeForm data={data} onSubmit={onSubmit} />
      </ModalBody>
    </ModalForm>
  )
}

export default TestTypeCard