import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';
import { getDateTimeToSubmit } from '../chakra-lib/utils/dateFormatting';

const PAGE_SIZE = 20;
const START_PAGE = 1;

export const ACTION_TYPES = {
    FETCH_TEST_LIST: 'test/FETCH_TEST_LIST',
    RESET_TEST_LIST: 'test/RESET_TEST_LIST',
    FETCH_TEST: 'test/FETCH_TEST',
    CREATE_TEST: 'test/CREATE_TEST',
    UPDATE_TEST: 'test/UPDATE_TEST',
    FETCH_TEST_HISTORY: 'test/FETCH_TEST_HISTORY',
    RESET_TEST_HISTORY: 'test/RESET_TEST_HISTORY',
    SEND_EMAIL: 'test/SEND_EMAIL',
    UPDATE_FILTER: 'test/UPDATE_FILTER',
    RESET_FILTER: 'test/RESET_FILTER',
    RESET_TEST: 'test/UPDATE_TEST',
    FETCH_TEST_NUMBER: 'test/FETCH_TEST_NUMBER',
    RESET_TEST_NUMBER: 'test/RESET_TEST_NUMBER',
    RESET: 'test/RESET'
};

const initialState = {
    items: [],
    item: {},
    itemHistory: [],
    loading: false,
    loadingUpdate: false,
    loadingSendEmail: false,
    errorSendEmail: false,
    successSendEmail: false,
    errorMessage: '',
    testNumber: '',
    totalItems: 0,
    totalPages: 0,
    activePage: START_PAGE,
    filter: {
        text: '',
        internalId: '',
        officesIds: '',
        fromDate: '',
        toDate: ''
    }
};

export const testReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.SEND_EMAIL): {
            return {
                ...state,
                loadingSendEmail: true,
                successSendEmail: false,
                errorSendEmail: false
            }
        }
        case REQUEST(ACTION_TYPES.FETCH_TEST_HISTORY):
        case REQUEST(ACTION_TYPES.FETCH_TEST_NUMBER):
        case REQUEST(ACTION_TYPES.FETCH_TEST_LIST):
        case REQUEST(ACTION_TYPES.FETCH_TEST): {
            return {
                ...state,
                loading: true,
                errorMessage: ''
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_TEST):
        case REQUEST(ACTION_TYPES.UPDATE_TEST):{
            return {
                ...state,
                loadingUpdate: true,
                errorMessage: ''
            }
        }
        case FAILURE(ACTION_TYPES.SEND_EMAIL): {
            return {
                ...state,
                errorSendEmail: true,
                loadingSendEmail: false,
                successSendEmail: false
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_TEST_HISTORY):
        case FAILURE(ACTION_TYPES.FETCH_TEST_NUMBER):
        case FAILURE(ACTION_TYPES.FETCH_TEST_LIST):
        case FAILURE(ACTION_TYPES.FETCH_TEST):
        case FAILURE(ACTION_TYPES.CREATE_TEST):
        case FAILURE(ACTION_TYPES.UPDATE_TEST): {
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errorMessage: action.payload.response.data
            }
        }
        case SUCCESS(ACTION_TYPES.SEND_EMAIL): {
            return {
                ...state,
                errorSendEmail: false,
                loadingSendEmail: false,
                successSendEmail: true
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_TEST_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loading: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage'],
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_TEST): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_TEST): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_TEST): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_TEST_NUMBER): {
            return {
                ...state,
                testNumber: action.payload.data?.number,
                loading: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_TEST_HISTORY): {
            return {
                ...state,
                itemHistory: action.payload.data,
                loading: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            }
        }
        case ACTION_TYPES.RESET_TEST_NUMBER: {
            return {
                ...state,
                loading: false,
                testNumber: ''
            }
        }
        case ACTION_TYPES.RESET_TEST_HISTORY: {
            return {
                ...state,
                loading: false,
                itemHistory: []
            }
        }
        case ACTION_TYPES.RESET_TEST_LIST: {
            return {
                ...state,
                items: [],
                loading: false,
                loadingUpdate: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE,
            }
        }
        case ACTION_TYPES.RESET_TEST: {
            return {
                ...state,
                item: {},
                loading: false,
                loadingUpdate: false,
                errorSendEmail: false,
                loadingSendEmail: false,
                successSendEmail: false
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                errorSendEmail: false,
                loadingSendEmail: false,
                successSendEmail: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/test`;


export const getTestList = () => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.FETCH_TEST_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getTestListNavigation = (dataRequest) => {
    const { pageSize, page } = dataRequest;
    const requestUrl = `${API_URL}/?pageSize=${pageSize}&page=${page}`;
    return {
        type: ACTION_TYPES.FETCH_TEST_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getTestListSearch = (page = START_PAGE) => (dispatch, getState) => {
    const filter = getState().test.filter;
    const requestUrl = `${API_URL}/findtext?pageSize=${PAGE_SIZE}&page=${page}`;
    dispatch({
        type: ACTION_TYPES.FETCH_TEST_LIST,
        payload: axios.post(requestUrl, {
            text: checkParam(filter.text),
            internalId: checkParam(filter.internalId),
            officesIds: checkParam(filter.officesIds, ','),
            fromDate: checkParam(getDateTimeToSubmit(filter.fromDate)),
            toDate: checkParam(getDateTimeToSubmit(filter.toDate)),
            states: checkParam(filter.byResult, ',')
        })
    })
};

export const updateFilter = (params) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        filter: {
            ...params
        }
    });
    dispatch(getTestListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().test.activePage;
    dispatch(getTestListSearch(activePage + 1))
};

export const getTest = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_TEST,
        payload: axios.get(requestUrl)
    };
};

export const createTest = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_TEST,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateTest = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.UPDATE_TEST,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const setInvalidTest = (id) => {
    const requestUrl = `${API_URL}/${id}/setinvalid`;
    return {
        type: ACTION_TYPES.UPDATE_TEST,
        payload: axios.get(requestUrl)
    };
};


export const sendEmail = (id) => {
    const requestUrl = `${API_URL}/${id}/sendmail`;
    return {
        type: ACTION_TYPES.SEND_EMAIL,
        payload: axios.get(requestUrl)
    };
};

export const getTestNumber = () => {
    const requestUrl = `${API_URL}/number`;
    return {
        type: ACTION_TYPES.FETCH_TEST_NUMBER,
        payload: axios.get(requestUrl)
    };
};

export const getTestHistory = (id) => {
    const requestUrl = `${API_URL}/${id}/history`;
    return {
        type: ACTION_TYPES.FETCH_TEST_HISTORY,
        payload: axios.get(requestUrl)
    };
};

export const resetTestHistory = () => {
    return {
        type: ACTION_TYPES.RESET_TEST_HISTORY
    };
};

export const resetFilter = () => {
    return {
        type: ACTION_TYPES.RESET_FILTER
    };
};

export const resetTestNumber = () => {
    return {
        type: ACTION_TYPES.RESET_TEST_NUMBER
    };
};

export const resetTestList = () => {
    return {
        type: ACTION_TYPES.RESET_TEST_LIST
    };
};

export const resetTest = () => {
    return {
        type: ACTION_TYPES.RESET_TEST
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
