import React, { useCallback, useContext, useEffect, useRef } from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  GridField,
  GridFieldHeading,
  GridFieldItem,
  HeadingContainer,
  RightAside,
} from 'chakra-lib/layout'
import { SymbolLine } from 'components/symbol-line'
import { Private } from 'components/Private'
import { ROLES } from 'model/roles'
import { TranslationContext } from 'translation'
import { TestStatement } from 'content/TestStatement'
import { Box, Button, HStack, useBoolean } from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOffice, resetOffice } from 'store/office.reducer'
import { getDateReadFormat, getTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import { Heading, Modal } from 'chakra-lib'
import { FieldTelephone } from './elements/FieldTelephone'
import { FieldPassport } from './elements/FieldPassport'
import { FieldEmail } from './elements/FieldEmail'
import { FieldFio } from './elements/FieldFio'
import { isEmpty } from 'lodash'
import { ActionPanel } from './ActionPanel'
import { useTestCard } from './_services'
import { useReactToPrint } from 'react-to-print'

export const TestCard = () => {
  const { getTranslation } = useContext(TranslationContext)
  const componentRef = useRef()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()

  const {
    data: itemTest,
    isLoading: isLoadingTest,
    updateItem,
    sendEmail,
    sendConsentEmail,
    eraseItem,
    setInvalidItem,
  } = useTestCard(id)

  const user = useSelector((state) => state.authentication.user)
  const country = useSelector((state) => state.settings.country)
  const settingsLaboratory = useSelector((state) => state.settings.settingsLaboratory)
  const itemOffice = useSelector((state) => state.office.item)
  const isLoadingOffice = useSelector((state) => state.office.loading)

  const isReadyItem = !isEmpty(itemTest)
  const isReadyItemOffice = !isEmpty(itemOffice)
  const [removeClientInfoConfirm, setClientInfoConfirm] = useBoolean()

  const sendEmailHandle = () => {
    sendEmail()
  }

  const sendConsentEmailHandle = () => {
    sendConsentEmail()
  }

  const onSendResultHandle = (values) => {
    updateItem(values)
  }

  const onInvalidResultHandle = () => {
    setInvalidItem()
  }

  const printHandle = useReactToPrint({
    content: () => componentRef.current,
  })

  const removeClientInfoHandle = useCallback(() => {
    eraseItem()
    setClientInfoConfirm.off()
  }, [])

  useEffect(() => {
    if (!isEmpty(itemTest) && user.role === ROLES.Administrator && itemTest?.officeId) {
      dispatch(getOffice(itemTest.officeId))
    }
    return () => {
      dispatch(resetOffice())
    }
  }, [itemTest])

  const handleBack = () => {
    history.goBack()
  }

  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">{`${getTranslation('test.heading.show')} №${
                  itemTest?.internalId ? itemTest.internalId : ''
                }`}</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading>{getTranslation('test.section.heading.client')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('test.field.fio.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  <FieldFio item={itemTest} updateItem={updateItem} />
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.passport.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  {itemTest?.passport ? (
                    <SymbolLine>{itemTest.passport}</SymbolLine>
                  ) : (
                    <FieldPassport item={itemTest} updateItem={updateItem} />
                  )}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.telephone.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  <FieldTelephone item={itemTest} updateItem={updateItem} />
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.email.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  <FieldEmail item={itemTest} updateItem={updateItem} />
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>{getTranslation('test.section.heading.test')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('test.field.testType.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  {itemTest?.testTypeTitle && itemTest.testTypeTitle.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.internalId.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  {itemTest?.internalId && itemTest.internalId}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.testingDate.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  {itemTest?.testingDate &&
                    `${getDateReadFormat(itemTest.testingDate)}, ${getTimeReadFormat(
                      itemTest.testingDate
                    )}`}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.resultDate.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  {itemTest?.resultDate &&
                    `${getDateReadFormat(itemTest.resultDate)}, ${getTimeReadFormat(
                      itemTest.resultDate
                    )} `}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('test.field.resultSetDate.label')}
                  isLoading={isLoadingTest}
                  isReady={isReadyItem}
                >
                  {itemTest?.resultSetDate &&
                    `${getDateReadFormat(itemTest.resultSetDate)}, ${getTimeReadFormat(
                      itemTest.resultSetDate
                    )}`}
                </GridFieldItem>
              </GridField>
              <Private role={user.role} hasAnyRole={[ROLES.Administrator]}>
                <GridField>
                  <GridFieldHeading>
                    {getTranslation('test.section.heading.office')}
                  </GridFieldHeading>
                  <GridFieldItem
                    label={getTranslation('office.field.titleRu.label')}
                    isLoading={isLoadingTest}
                    isReady={isReadyItem}
                  >
                    {itemOffice.title?.ru}
                  </GridFieldItem>
                  <GridFieldItem
                    label={getTranslation('office.field.titleEn.placeholder')}
                    isLoading={isLoadingTest}
                    isReady={isReadyItem}
                  >
                    {itemOffice.title?.en}
                  </GridFieldItem>
                  <GridFieldItem
                    label={getTranslation('office.field.address.label')}
                    isLoading={isLoadingOffice}
                    isReady={isReadyItemOffice}
                  >
                    {itemOffice.address && itemOffice.address.ru}
                  </GridFieldItem>
                </GridField>
              </Private>
            </ContentContainerColumn>
            <ContentContainerColumn>
              <RightAside>
                <ActionPanel
                  data={itemTest}
                  loading={isLoadingTest}
                  onSendEmail={sendEmailHandle}
                  onPrint={printHandle}
                  onSendConsentEmail={sendConsentEmailHandle}
                  onClientInfoRemove={setClientInfoConfirm.on}
                  onSendResult={onSendResultHandle}
                  onInvalidResult={onInvalidResultHandle}
                />
              </RightAside>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
      <Box display="none">
        {itemTest && (
          <TestStatement
            item={itemTest}
            laboratoryName={settingsLaboratory.name}
            country={country}
            ref={componentRef}
          />
        )}
      </Box>
      <Modal
        title={getTranslation('test.action.removeCustomerInfo') + '?'}
        isOpen={removeClientInfoConfirm}
        onClose={setClientInfoConfirm.off}
        footer={
          <HStack>
            <Button colorScheme="blue" type="submit" width="100%" onClick={removeClientInfoHandle}>
              {getTranslation('global.confirm')}
            </Button>
            <Button variant="outline" type="submit" width="100%" onClick={setClientInfoConfirm.off}>
              {getTranslation('global.close')}
            </Button>
          </HStack>
        }
      />
    </>
  )
}
