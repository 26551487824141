import React, { useContext } from 'react'

import { Flex, IconButton, Tooltip } from '@chakra-ui/react'
import { LockIcon } from '@chakra-ui/icons'
import { TranslationContext } from 'translation'

export const Actions = ({ onDelete, isActive, isDisabled }) => {
  const { getTranslation } = useContext(TranslationContext)

  return (
    <Flex>
      <Tooltip label={isActive ? getTranslation('global.unblock') : getTranslation('global.block')}>
        <IconButton
          isDisabled={isDisabled}
          aria-label="disabled"
          color={isActive && 'red'}
          icon={<LockIcon />}
          variant="outline"
          size="sm"
          isRound={true}
          fontSize="0.76rem"
          onClick={onDelete}
        />
      </Tooltip>
    </Flex>
  )
}
