import React from 'react';
import { Flex, Box } from '@chakra-ui/react';

const getStateTab = (errors = {}, fields) => {
    for (let key in errors) {
        if (errors.hasOwnProperty(key)) {
            if (fields.includes(key)) {
                return true
            }
        }
    }
    return false;
}

export const TabCheck = ({ form = {}, fields, children}) => {
    const { submitCount, errors } = form;
    const isShow = getStateTab(errors, fields) && submitCount > 0;
    return (
        <Flex alignItems="center" fontWeight={isShow && 'semibold'}>
            {children}
            {isShow && (
                <Box marginLeft={2} marginTop="2px" width="6px" height="6px" backgroundColor="red.500" borderRadius={100} />
            )}
        </Flex>
    )
}
