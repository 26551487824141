import axios from 'axios'
import { API_SERVER_URL } from 'store/config'
import { getXPaginationInfo } from 'api/utils'

const ENTITY = 'testtype'

export const testType = Object.freeze({
  async fetchList(params) {
    const response = await axios.request({
      url: `${API_SERVER_URL}/${ENTITY}`,
      method: 'GET',
      params,
    })

    return {
      list: response.data,
      ...getXPaginationInfo(response),
    }
  },
  async fetchItem(id, params) {
    const response = await axios.get(`${API_SERVER_URL}/${ENTITY}/${id}`, { params })
    return response.data
  },
  async createItem(values) {
    const response =  await axios.post(`${API_SERVER_URL}/${ENTITY}`, values)
    return response.data
  },
  async updateItem(values) {
    const response =  await axios.put(`${API_SERVER_URL}/${ENTITY}`, values)
    return response.data
  },
})
