import React from 'react'
import { Checkbox, FormControl } from '@chakra-ui/react'

export const CheckboxForm = ({ field, form, label, ...props }) => {
  const { value } = form.getFieldMeta(field.name)
  const handleCheckbox = (e) => form.setFieldValue(field.name, e.target.checked)

  return (
    <FormControl>
      <Checkbox isChecked={value || false} onChange={handleCheckbox} {...props}>
        {label}
      </Checkbox>
    </FormControl>
  )
}
