import axios from 'axios';
import { useCallback, useState } from 'react';
import { API_SERVER_URL } from '../../../../../../store/config';

export const useAttentionMessageFetch = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const request = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsSuccess(false);
      const result = await axios.get(`${API_SERVER_URL}/settings/attention_message`);
      setData(result?.data);
      setIsSuccess(true);
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    dataFetch: data,
    isLoadingFetch: isLoading,
    isSuccessFetch: isSuccess,
    requestFetch: request
  }
}
