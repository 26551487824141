import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { VaccinationList } from './VaccinationList/VaccinationList';
import { VaccinationUpdate } from './VaccinationUpdate/VaccinationUpdate';
import { VaccinationCard } from './VaccinationCard/VaccinationCard';
import { PhaseUpdate } from './VaccinationUpdate/PhaseUpdate';
import { ROLES } from '../../../model/roles';
import { PrivateRoute } from '../../../components/Private';
import { useSelector } from 'react-redux';

const Routes = () => {
  const match = useRouteMatch();
  const userAuth = useSelector(state => state.authentication.user);
  return (
    <Switch>
      <PrivateRoute
        path={`${match.url}/new`}
        role={userAuth.role}
        hasAnyRole={[ROLES.Administrator, ROLES.UserCreator]}
        exact
      >
        <VaccinationUpdate/>
      </PrivateRoute>
      <Route path={`${match.url}/show/:id`} exact>
        <VaccinationCard/>
      </Route>
      <PrivateRoute
        path={`${match.url}/edit/:id`}
        role={userAuth.role}
        hasAnyRole={[ROLES.Administrator, ROLES.UserEditor]}
        exact
      >
        <VaccinationUpdate/>
      </PrivateRoute>
      <PrivateRoute
        path={`${match.url}/show/:id/phase/new`}
        role={userAuth.role}
        hasAnyRole={[ROLES.Administrator, ROLES.UserCreator, ROLES.UserEditor]}
        exact
      >
        <PhaseUpdate/>
      </PrivateRoute>
      <PrivateRoute
        path={`${match.url}/show/:id/phase/:phaseId`}
        role={userAuth.role}
        hasAnyRole={[ROLES.Administrator, ROLES.UserEditor]}
        exact
      >
        <PhaseUpdate/>
      </PrivateRoute>
      <Route path={`${match.url}/:params?`}>
        <VaccinationList/>
      </Route>
    </Switch>
  );
};

export default Routes;
