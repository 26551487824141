import React from 'react';
import { Avatar, Grid, Box, Switch, Collapse } from '@chakra-ui/react';

export const CountrySelection = ({ items, selected, isLoadingCountry = true, onChange }) => {
    return (
        <Collapse in={!isLoadingCountry}>
            <Grid gridRowGap={4} padding="8px 0">
                {items.map((item, index) => (
                    <Grid
                        key={index}
                        templateColumns="48px 1fr 36px"
                        alignItems="center"
                        padding="0 6px"
                    >
                        <Avatar
                            width="32px"
                            height="32px"
                            cursor="pointer"
                            name={item.name && item.name.ru}
                            src={item.flag}
                        />
                        <Box>
                            {item.shortName && item.shortName.ru}
                        </Box>
                        <Switch
                            isChecked={selected.includes(item.id)}
                            onChange={e => onChange(e, item.id)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Collapse>
    );
};
