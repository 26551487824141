import { useContext, useEffect } from 'react';
import { getTime } from '../../store/settings.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getDiffDateHours } from '../deprecated/utilities/date-formatting';
import { TranslationContext } from '../../translation';

export const useTestResult = () => {
    const { getTranslation } = useContext(TranslationContext);
    const dispatch = useDispatch();
    const currentTime = useSelector(state => state.settings.time);
    const testValidHours = useSelector(state => state.settings.testValidHours);

    const getDiffDate = (testingDate) => getDiffDateHours(currentTime, testingDate);

    const getResult = (resultTest, testingDate) => {
        if (currentTime === '' || testValidHours === '') {
            return {
                resultTestText: null,
                resultTestType: 'delay',
            }
        }
        const diffDate = getDiffDateHours(currentTime, testingDate);
        if (diffDate > testValidHours) {
            return {
                resultTestText: getTranslation('global.expired'),
                resultTestType: 'delay',
            }
        }
        if (resultTest === null) {
            return {
                resultTestText: getTranslation('global.pending'),
                resultTestType: 'pending',
            }
        }
        return {
            resultTestText: resultTest ? getTranslation('resultTest.true') : getTranslation('resultTest.false'),
            resultTestType: resultTest ? 'true' : 'false',
        }
    };

    const getRemainingTime = (resultDiffDate) => {
        const time = testValidHours - resultDiffDate;
        return time < 0 ? getTranslation('global.timeout') : `${getTranslation('global.timeLeft')}: ${time} ${getTranslation('global.hour')}`;
    };

    const getRemainingPercent = (resultDiffDate) => {
        const percent = 100 - ((resultDiffDate / testValidHours) * 100);
        return percent < 0 ? 0 : percent;
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        currentTime === '' && dispatch(getTime());
    }, []);

    return { testValidHours, currentTime, getResult, getDiffDate, getRemainingTime, getRemainingPercent }
};
