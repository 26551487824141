import React, { useRef } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import DayPicker from 'react-day-picker'
import FocusLock from 'react-focus-lock'
import 'react-day-picker/lib/style.css'
import './style.scss'
import {
  FIRST_DAY_OF_WEEK,
  LABELS,
  locale,
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  maskDate,
} from './config'
import MaskedInput from 'react-text-mask'
import { getDateToPicker, getDateReadFormat, isInvalidDate } from '../utilities/date-formatting'
import { CalendarIcon } from '@chakra-ui/icons'

export const UIInputDateForm = ({
  field,
  form,
  placeholder = null,
  label = null,
  disabledDays = undefined,
  ...props
}) => {
  const initialFocusRef = useRef()
  const handleDayClick = (day, { selected }) =>
    selected ? undefined : form.setFieldValue(field.name, getDateReadFormat(day))
  return (
    <FormControl isInvalid={form.errors[field.name] && form.touched[field.name]}>
      {label && (
        <FormLabel htmlFor={field.name} fontSize="0.84rem" width="100%" mb="6px">
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <MaskedInput
          mask={maskDate}
          placeholder={placeholder}
          value={field.value}
          onBlur={(e) => {
            isInvalidDate(e.target.value)
              ? form.setFieldValue(field.name, e.target.value)
              : form.setFieldValue(field.name, field.value)
          }}
          {...props}
          render={(ref, props) => <Input id={field.name} ref={ref} {...props} />}
        />

        <Popover initialFocusRef={initialFocusRef} placement="top">
          <PopoverTrigger>
            <InputRightElement height="100%" padding="0 10px">
              <IconButton
                aria-label="Календарь"
                icon={<CalendarIcon />}
                size="xs"
                variant="ghost"
                color="gray.400"
              />
            </InputRightElement>
          </PopoverTrigger>
          <PopoverContent zIndex={4} padding="16px 12px 4px 12px" width="auto">
            <PopoverArrow />
            <PopoverBody>
              <FocusLock returnFocus persistentFocus={false}>
                <DayPicker
                  className="UIDatePicker"
                  locale={locale}
                  months={MONTHS[locale]}
                  weekdaysLong={WEEKDAYS_LONG[locale]}
                  weekdaysShort={WEEKDAYS_SHORT[locale]}
                  firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                  labels={LABELS[locale]}
                  numberOfMonths={1}
                  onDayClick={handleDayClick}
                  selectedDays={getDateToPicker(field.value)}
                  ref={initialFocusRef}
                  disabledDays={disabledDays}
                />
              </FocusLock>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </InputGroup>
    </FormControl>
  )
}
