import React from 'react'
import { Box, HStack, Switch } from '@chakra-ui/react'

export const UISwitchForm = ({ field, form, label, ...props }) => {
  const onChangeHandle = (e) => form.setFieldValue(field.name, e.target.checked)

  return (
    <HStack spacing={2} alignItems="center">
      <Box>
        <Switch isChecked={form.values[field.name]} onChange={onChangeHandle} {...props} />
      </Box>
      <Box>{label}</Box>
    </HStack>
  )
}
