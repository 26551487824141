import React, { useContext } from 'react';
import { Field } from 'formik';
import { TranslationContext } from '../../../translation';
import { InputForm, SelectForm } from '../../../chakra-lib';

export const RequisitesFormBy = ({ loading, }) => {
    const { getTranslation } = useContext(TranslationContext);
    return (
        <>
            <Field
                name="inn"
                label={getTranslation('requisites.unp.label')}
                component={InputForm}
                isDisabled={loading}
            />
            <Field
                name="company"
                label={getTranslation('requisites.company.label')}
                component={InputForm}
                isDisabled={loading}
            />
            <Field
                name="ownership"
                label={getTranslation('requisites.ownership.label')}
                component={SelectForm}
                placeholder={getTranslation('requisites.ownership.placeholder')}
                isSearchable={false}
                options={[
                    { value: 'Государственная', label: getTranslation('requisites.ownership.option.political') },
                    { value: 'Частная', label: getTranslation('requisites.ownership.option.private') },
                    { value: 'Муниципальная', label: getTranslation('requisites.ownership.option.municipal') }
                ]}
            />
            <Field
                name="addressLegal"
                label={getTranslation('requisites.addressLegal.label')}
                component={InputForm}
                isDisabled={loading}
            />
        </>
    );
};
