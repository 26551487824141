import React, { useContext, useEffect, useState } from 'react';
import {
  CardContainer, Container,
  ContentContainer,
  ContentContainerColumn, Form, FormGrid, FormHeading, FormSection,
  HeadingContainer
} from '../../../../../chakra-lib/layout';
import {
  Alert,
  CheckboxForm,
  Heading, Input,
  InputForm,
  Modal,
  Snippets,
  SnippetsHeading,
  Tab,
  SnippetsItem, TabPanel
} from '../../../../../chakra-lib';
import { Field, FieldArray, Formik } from 'formik';
import {
  Box,
  Button,
  Flex,
  IconButton,
  TabList,
  TabPanels,
  Tabs,
  Text, useToast
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { createFaq, getFaq, resetFaq, updateFaq } from '../../../../../store/faq.reducer';
import { TranslationContext } from '../../../../../translation';
import { truncate } from '../../../../../components/deprecated/utilities/truncate';
import { HeadingItem, ItemIcon, ItemLink } from '../../../../../chakra-lib/Snippets';
import { IconList } from '../../../../../components/icons';
import { DeleteIcon } from '@chakra-ui/icons';
import { ItemBox } from '../../../../../chakra-lib/Snippets/items/ItemBox';
import { UITextareaForm } from '../../../../../components/textarea';

export const FaqUpdate = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const {id} = useParams();
  const isNew = !id;
  const [activeIndex, setActiveIndex] = useState(-1);
  const item = useSelector(state => state.faq.item);
  const loading = useSelector(state => state.faq.loading);
  const loadingUpdate = useSelector(state => state.faq.loadingUpdate);
  const errorMessage = useSelector(state => state.faq.errorMessage);
  const close = () => {
    setActiveIndex(-1);
  }
  const handleBack = () => {
    history.goBack();
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isNew) {
      dispatch(resetFaq());
    } else {
      dispatch(getFaq(id));
    }
    return () => {
      dispatch(resetFaq());
    };
  }, []);
  const handleForm = async (values) => {
    const dataRequest = {
      chapter: {
        ru: values.chapterRu,
        en: values.chapterEn
      },
      hidden: values.hidden,
      orderNr: Number(values.orderNr),
      questions: values.questions
    };
    if (isNew) {
      await dispatch(createFaq(dataRequest));
      toast({
        title: 'Готово',
        description: `Вопросы созданы`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    } else {
      await dispatch(updateFaq(dataRequest, id));
      toast({
        title: 'Готово',
        description: `Вопросы обновлены`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    }
  };
  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание раздела' : 'Обновление раздела'}
              </Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="30px">
                {errorMessage?.error}
              </Alert>
            )}
            <Formik
              initialValues={{
                chapterRu: !isEmpty(item) ? (item.chapter ? item.chapter.ru : '') : '',
                chapterEn: !isEmpty(item) ? (item.chapter ? item.chapter.en : '') : '',
                questions: !isEmpty(item) ? item.questions : [],
                hidden: item.hidden,
                orderNr: item.orderNr !== undefined ? String(item.orderNr) : '0'
              }}
              enableReinitialize={true}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew ? getTranslation('user.action.buttonCreate') : getTranslation('user.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      autoFocus
                      name="chapterRu"
                      label="Наименование раздела на русском"
                      component={InputForm}
                      isDisabled={loading}
                    />
                    <Field
                      name="chapterEn"
                      component={InputForm}
                      label="Наименование раздела на английском"
                      isDisabled={loading}
                    />
                    <FormGrid columns={2}>
                      <Field
                        type="number"
                        name="orderNr"
                        component={InputForm}
                        label="Сортировка раздела"
                        isDisabled={loading}
                      />
                      {!isNew && (
                        <Input
                          name="ID"
                          label="ID"
                          value={item.id}
                          isReadOnly
                        />
                      )}
                    </FormGrid>
                    <Field
                      name="hidden"
                      component={CheckboxForm}
                      label="Отключить"
                    />
                  </FormSection>
                  <FormSection>
                    <FormHeading>Вопросы</FormHeading>
                    <FieldArray
                      name="questions"
                      render={arrayHelpers => (
                        <>
                          {props.values.questions && props.values.questions.length > 0 ? (
                            <Snippets
                              columns="42px minmax(200px, 1fr) 80px"
                            >
                              <SnippetsHeading>
                                <HeadingItem/>
                                <HeadingItem>Заголовок</HeadingItem>
                                <HeadingItem/>
                              </SnippetsHeading>
                              {props.values.questions.map((friend, index) => (
                                <React.Fragment key={index}>
                                  <SnippetsItem onClick={() => setActiveIndex(index)}>
                                    <ItemIcon icon={IconList}/>
                                    <ItemLink>
                                      <Box> {truncate(props.values.questions[index].question.ru, 50)}</Box>
                                      <Text fontSize="0.76rem" color="gray.400">
                                        {truncate(props.values.questions[index].question.en, 50)}
                                      </Text>
                                    </ItemLink>
                                    <ItemBox justifyContent="flex-end">
                                      <IconButton
                                        aria-label="Menu"
                                        icon={<DeleteIcon/>}
                                        variant="outline"
                                        size="sm"
                                        isRound={true}
                                        fontSize="0.76rem"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          arrayHelpers.remove(index);
                                        }}
                                      />
                                    </ItemBox>
                                  </SnippetsItem>
                                  <Modal
                                    title="Вопрос"
                                    isOpen={activeIndex === index}
                                    onClose={close}
                                    footer={
                                      <Button
                                        colorScheme="blue"
                                        type="submit"
                                        onClick={() => close()}
                                      >
                                        Закрыть
                                      </Button>
                                    }
                                  >
                                    <Tabs variant="soft-rounded" size="sm">
                                      <TabList>
                                        <Tab padding="0.4rem 0.8rem" mr={1}>
                                          {getTranslation('settingDocuments.tabs.description.inRussian')}
                                        </Tab>
                                        <Tab padding="0.4rem 0.8rem" mr={1}>
                                          {getTranslation('settingDocuments.tabs.description.inEnglish')}
                                        </Tab>
                                      </TabList>
                                      <TabPanels>
                                        <TabPanel pb={0}>
                                          <FormSection mb={0}>
                                            <Field
                                              autoFocus
                                              name={`questions[${activeIndex}].question.ru`}
                                              component={InputForm}
                                              placeholder={getTranslation('settingDocuments.field.titleRu.placeholder')}
                                            />
                                            <Field
                                              name={`questions[${activeIndex}].answer.ru`}
                                              component={UITextareaForm}
                                              isDisabled={loading}
                                              minHeight="400px"
                                              placeholder={getTranslation('settingDocuments.field.valueRu.placeholder')}
                                            />
                                          </FormSection>
                                        </TabPanel>
                                        <TabPanel pb={0}>
                                          <FormSection mb={0}>
                                            <Field
                                              autoFocus
                                              name={`questions[${activeIndex}].question.en`}
                                              component={InputForm}
                                              placeholder={getTranslation('settingDocuments.field.titleEn.placeholder')}
                                            />
                                            <Field
                                              name={`questions[${activeIndex}].answer.en`}
                                              component={UITextareaForm}
                                              isDisabled={loading}
                                              minHeight="400px"
                                              placeholder={getTranslation('settingDocuments.field.valueEn.placeholder')}
                                            />
                                          </FormSection>
                                        </TabPanel>
                                      </TabPanels>
                                    </Tabs>
                                  </Modal>
                                </React.Fragment>
                              ))}
                            </Snippets>
                          ) : (
                            <Flex
                              textAlign="center"
                              alignItems="center"
                              justifyContent="center"
                              height="40px"
                              fontSize="0.9rem"
                              color="gray.400"
                            >
                              Нет вопросов
                            </Flex>
                          )}
                          <Button
                            size="sm"
                            width="100%"
                            disabled={loading}
                            onClick={() => arrayHelpers.push({
                              question: {
                                ru: 'Заголовок',
                                en: 'Title'
                              },
                              answer: {
                                ru: 'Ответ',
                                en: 'Answer'
                              }
                            })}
                          >
                            Добавить
                          </Button>
                        </>
                      )}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
