import React, { useContext, useEffect } from 'react';
import { TranslationContext } from '../../../../translation';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, useToast } from '@chakra-ui/react';
import { isEmpty } from '../../../../components/deprecated/utilities/is-empty';
import { getDetailValue, setDetailValue } from '../../../../components/deprecated/utilities/details-field';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form, FormGrid, FormSection, HeadingContainer
} from '../../../../chakra-lib/layout';
import { CheckboxForm, Heading, InputForm } from '../../../../chakra-lib';
import { Alert } from '../../../../components/deprecated/alert';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import {
  createMedicalFacility, getMedicalFacility,
  resetMedicalFacility,
  updateMedicalFacility
} from '../../../../store/medicalFacility.reducer';

export const validationSchema = Yup.object().shape({
  titleRu: Yup.string().required('Required'),
  shortTitleRu: Yup.string().required('Required'),
  oid: Yup.string().required('Required')
});

export const MedicalFacilityUpdate = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const toast = useToast();
  // eslint-disable-next-line no-unused-vars
  const isNew = !id;
  const item = useSelector(state => state.medicalFacility.item);
  const loading = useSelector(state => state.medicalFacility.loading);
  const loadingUpdate = useSelector(state => state.medicalFacility.loadingUpdate);
  const errorMessage = useSelector(state => state.medicalFacility.errorMessage);

  const handleBack = () => {
    history.goBack();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isNew) {
      dispatch(resetMedicalFacility());
    } else {
      dispatch(getMedicalFacility(id));
    }
    return () => {
      dispatch(resetMedicalFacility());
    };
  }, []);

  const handleForm = async (values, actions) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      title: {
        ru: values.titleRu,
        en: values.titleEn
      },
      shortTitle: {
        ru: values.shortTitleRu,
        en: values.shortTitleEn
      },
      siteUrl: values.siteUrl,
      telephone: values.telephone,
      address: {
        ru: values.addressRu,
        en: values.addressEn,
      },
      disabled: values.disabled,
      details: {
        oid: setDetailValue(values, 'oid')
      }
    };
    if (isNew) {
      await dispatch(createMedicalFacility(dataRequest));
      toast({
        title: getTranslation('office.toast.newOffice.success.title'),
        description: `${getTranslation('office.toast.newOffice.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    } else {
      await dispatch(updateMedicalFacility(id, dataRequest));
      toast({
        title: getTranslation('office.toast.updateOffice.success.title'),
        description: `${getTranslation('office.toast.updateOffice.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    }
  };
  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание медицинского учреждения' : 'Обновление медицинского учреждения'}
              </Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="30px">
                {errorMessage?.error}
              </Alert>
            )}
            <Formik
              initialValues={{
                titleRu: item?.title?.ru,
                titleEn: item?.title?.en,
                shortTitleRu: item?.shortTitle?.ru,
                shortTitleEn: item?.shortTitle?.en,
                lat: !isEmpty(item) ? (item.coordinates ? item.coordinates.lat : '') : '',
                lng: !isEmpty(item) ? (item.coordinates ? item.coordinates.lng : '') : '',
                siteUrl: item.siteUrl,
                telephone: item.telephone,
                disabled: item.disabled,
                oid: getDetailValue(item, 'oid'),
              }}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew ? getTranslation('office.action.buttonCreate') : getTranslation('office.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      autoFocus
                      name="titleRu"
                      label={getTranslation('office.field.titleRu.label')}
                      component={InputForm}
                      placeholder={getTranslation('office.field.titleRu.placeholder')}
                      isDisabled={loading}
                    />
                    <Field
                      name="titleEn"
                      component={InputForm}
                      placeholder={getTranslation('office.field.titleEn.placeholder')}
                      isDisabled={loading}
                    />
                  </FormSection>
                  <FormSection>
                    <Field
                      name="shortTitleRu"
                      label={getTranslation('office.field.shortTitleRu.label')}
                      component={InputForm}
                      placeholder={getTranslation('office.field.shortTitleRu.placeholder')}
                      isDisabled={loading}
                    />
                    <Field
                      name="shortTitleEn"
                      component={InputForm}
                      placeholder={getTranslation('office.field.shortTitleEn.placeholder')}
                      isDisabled={loading}
                    />
                  </FormSection>
                  <FormSection>
                    <FormGrid columns={2}>
                      <Field
                        name="siteUrl"
                        label={getTranslation('office.field.siteUrl.label')}
                        component={InputForm}
                        placeholder={getTranslation('office.field.siteUrl.placeholder')}
                        isDisabled={loading}
                      />
                      <Field
                        name="telephone"
                        label={getTranslation('office.field.telephone.label')}
                        component={InputForm}
                        placeholder={getTranslation('office.field.telephone.placeholder')}
                        isDisabled={loading}
                      />
                    </FormGrid>
                    <Field
                      name="disabled"
                      component={CheckboxForm}
                      label={getTranslation('office.field.disabled.label')}
                    />
                  </FormSection>
                  <FormSection>
                    <Field
                      name="oid"
                      label={getTranslation('requisites.oid.label')}
                      component={InputForm}
                      isDisabled={loading}
                    />
                    <Box fontSize="0.7rem" color="gray.400">
                      Заполняется согласно Приложения «В12». О ведении идентификаторов объектов Минздрава России, назначаемых
                      в рамках российского национального сегмента международного дерева идентификаторов объектов»"
                    </Box>
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
