import { isEmpty } from './is-empty';

export const getDetailValue = (item, field) => {
    if (!isEmpty(item) && !isEmpty(item.details)) {
        if (item.details[field]) {
            return item.details[field]
        } else {
            return ''
        }
    } else {
        return '';
    }
};

export const setDetailValue = (values, field) => {
    if (values[field] !== '') {
        return values[field]
    } else {
        return null
    }
}
