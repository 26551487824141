import React, { useContext, useEffect } from 'react';
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../chakra-lib/layout';
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from '../../../../chakra-lib';
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from '../../../../chakra-lib/Snippets';
import { IconPerson } from '../../../../components/icons';
import { TranslationContext } from '../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFilterParams } from '../../../../chakra-lib/utils';
import { getNextPage, resetFilter, resetUserList, updateFilter } from '../../../../store/user.reducer';
import { UserListFilter } from './elements/UserListFilter';
import { FILTER_USER_PARAMS } from '../../../../config/user.config';

const TYPE_COLOR_DISABLED = {
  'true': 'red',
  'false': 'green'
}

export const UserList = () => {
  const { getTranslation } = useContext(TranslationContext);
  const dispatch = useDispatch();
  const {params} = useParams();
  const activePage = useSelector(state => state.user.activePage);
  const items = useSelector(state => state.user.items);
  const totalPages = useSelector(state => state.user.totalPages);

  const TYPE_DISABLED = {
    'true': getTranslation('disabled.true'),
    'false': getTranslation('disabled.false')
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_USER_PARAMS, params);
      dispatch(updateFilter(objFilterParams));
    } else {
      dispatch(updateFilter());
    }
  }, [params]);
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
      dispatch(resetUserList());
    };
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(200px, 1fr) 320px 220px 160px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>{getTranslation('user.snippets.heading.login')}</HeadingItem>
              <HeadingItem>{getTranslation('user.snippets.heading.name')}</HeadingItem>
              <HeadingItem>{getTranslation('user.snippets.heading.role')}</HeadingItem>
              <HeadingItem>{getTranslation('user.snippets.heading.state')}</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={items}
              onNext={() => dispatch(getNextPage())}
            >
              {(item, index) => (
                <SnippetsItem to={`/national/user/edit/${item.id}`}>
                  <ItemIcon icon={IconPerson} />
                  <ItemLink>{item.username}</ItemLink>
                  <ItemText>{item.name}</ItemText>
                  <ItemText>{item.role}</ItemText>
                  <ItemText>
                    <ItemBadge
                      colorScheme={TYPE_COLOR_DISABLED[item.disabled]}
                    >
                      {TYPE_DISABLED[item.disabled]}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <UserListFilter params={params}/>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
