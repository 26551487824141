import React, { useContext } from 'react'
import { isEmpty } from '../deprecated/utilities/is-empty'
import { TYPE_RESULT_COLOR, TYPE_RESULT_VARIANT_COLOR } from './config'
import { Flex, Progress, Box, Skeleton, Text, Collapse, useColorModeValue } from '@chakra-ui/react'
import { useTestResult } from './use-test-result'
import { TranslationContext } from 'translation'

export const WidgetTestResult = ({ item, isLoading, ...props }) => {
  const { getTranslation } = useContext(TranslationContext)
  const { testValidHours, getResult, getDiffDate, getRemainingTime, getRemainingPercent } =
    useTestResult()
  const resultDiffDate = getDiffDate(item.testingDate)
  const { resultTestText, resultTestType } = getResult(item.result, item.testingDate)
  const isShowValidHours = !isNaN(resultDiffDate) && !isEmpty(item) && testValidHours !== null
  const themeContainerBg = useColorModeValue('gray.50', 'gray.600')

  const remainingTime = getRemainingTime(resultDiffDate)
  const remainingPercent = getRemainingPercent(resultDiffDate)

  const invalid = item.invalid

  return (
    <Box {...props}>
      <Flex
        justifyContent="center"
        alignItems="center"
        height="70px"
        {...(isLoading && {
          backgroundColor: themeContainerBg,
          borderRadius: 6,
        })}
      >
        {isLoading || !testValidHours ? (
          <Skeleton
            borderRadius={8}
            height="12px"
            width="100px"
            startColor="#EDF2F7"
            endColor="#E2E8F0"
          />
        ) : invalid ? (
          <Flex
            backgroundColor={themeContainerBg}
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            borderRadius={6}
          >
            <Box textAlign="center">
              <Text fontSize="0.9rem" textTransform="uppercase" fontWeight="700" color="gray.400">
                {getTranslation('resultTest.invalid')}
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            borderTopLeftRadius={6}
            borderTopRightRadius={6}
            borderBottomLeftRadius={remainingPercent > 0 ? 0 : 6}
            borderBottomRightRadius={remainingPercent > 0 ? 0 : 6}
            backgroundColor={isEmpty(item) ? 'gray.100' : TYPE_RESULT_COLOR[resultTestType].colorBg}
            color={isEmpty(item) ? 'gray.400' : TYPE_RESULT_COLOR[resultTestType].colorFont}
          >
            <Box textAlign="center">
              <Text fontSize="0.9rem" textTransform="uppercase" fontWeight="700">
                {isEmpty(item) ? getTranslation('global.noData') : resultTestText}
              </Text>
            </Box>
          </Flex>
        )}
      </Flex>
      <Collapse in={isShowValidHours && !invalid}>
        {remainingPercent > 0 && (
          <Progress
            hasStripe
            isAnimated
            height="5px"
            colorScheme={TYPE_RESULT_VARIANT_COLOR[resultTestType]}
            borderBottomLeftRadius={6}
            borderBottomRightRadius={6}
            value={remainingPercent}
          />
        )}
        <Box
          color="gray.400"
          fontSize="0.7rem"
          lineHeight="1.6"
          transition="0.3s"
          overflow="hidden"
          textAlign="center"
          marginTop={2}
        >
          {remainingTime}
        </Box>
      </Collapse>
    </Box>
  )
}
