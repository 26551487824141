import React from 'react';
import { UIInput } from './input';
import { isValidTime } from '../utilities/date-formatting';

export const UIInputTimeForm = React.forwardRef((
{
    field,
    form,
    sizeInput,
    label = null,
    ...props
}, ref) => {
    const handleInput = (e) => {
        isValidTime(e.target.value) ? form.setFieldValue(field.name, e.target.value) : form.setFieldValue(field.name, field.value);
    }
    return (
        <UIInput
            name={field.name}
            label={label}
            isInvalid={form.errors[field.name] && form.touched[field.name]}
            mask={[/[0-9]/,/[0-9]/,':', /[0-9]/, /[0-9]/]}
            value={field.value}
            onBlur={handleInput}
            {...props}
        />
    );
});
