import React, { useEffect, useState } from 'react';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, FormFooter, FormSection, GridField, GridFieldHeading, GridFieldItem,
  HeadingContainer
} from '../../../../chakra-lib/layout';
import { Heading, Tab, TabList, TabPanel, TabPanels, Tabs, TextArea } from '../../../../chakra-lib';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getVaccine, resetVaccine, updateVaccine } from '../../../../store/vaccine.reducer';
import { Button, useToast } from '@chakra-ui/react';
import { ROLES } from '../../../../model/roles';
import { Private } from '../../../../components/Private';

export const VaccineCard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const {id} = useParams();
  const userAuth = useSelector(state => state.authentication.user);
  const item = useSelector(state => state.vaccine.item);
  const loading = useSelector(state => state.vaccine.loading);
  const [countryManufacturer, setCountryManufacturer] = useState('');
  const isReadyItem = !isEmpty(item);
  const handleBack = () => {
    history.goBack();
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getVaccine(id));
    return () => {
      dispatch(resetVaccine());
    }
  }, []);

  useEffect(() => {
    setCountryManufacturer(item?.countryManufacturer);
  }, [item]);

  const handleCountryManufacturer = async () => {
    const dataRequest = {
      countryManufacturer
    }
    await dispatch(updateVaccine(dataRequest, id));
    toast({
      title: 'Готово',
      description: `Вакцина успешно обновлена`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  return (
    <Container>
      <CardContainer indentTop={70} onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading fontSize="1.4rem">Вакцина</Heading>
            </HeadingContainer>
            <Tabs variant="enclosed-colored">
              <TabList>
                <Tab>Информация</Tab>
                <Tab>Страна-производитель</Tab>
              </TabList>
              <TabPanels>
                <TabPanel pb={0}>
                  <GridField>
                    <GridFieldItem
                      label="Наименование на русском"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.name?.ru}
                    </GridFieldItem>
                    <GridFieldItem
                      label="Наименование на английском"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.name?.en}
                    </GridFieldItem>
                    <GridFieldItem
                      label="Дозировка"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.phases}
                    </GridFieldItem>
                    <GridFieldItem
                      label="Срок действия в днях"
                      isLoading={loading}
                      isReady={isReadyItem}
                    >
                      {item.daysValid}
                    </GridFieldItem>
                  </GridField>
                  {!isEmpty(item.vaccineNames) && (
                    <GridField>
                      <GridFieldHeading>Другие имена</GridFieldHeading>
                      {item.vaccineNames.map(vaccine => (
                        <GridFieldItem
                          key={vaccine.id}
                          isLoading={loading}
                          isReady={isReadyItem}
                        >
                          {vaccine.name}
                        </GridFieldItem>
                      ))}
                    </GridField>
                  )}
                </TabPanel>
                <TabPanel pb={0}>
                  <FormSection>
                    <TextArea
                      value={countryManufacturer}
                      onChange={e => setCountryManufacturer(e.target.value.slice(0, 120))}
                      label="Страна-производитель"
                      isReadOnly={userAuth.role !== ROLES.Administrator}
                    />
                  </FormSection>
                  <Private
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <FormFooter>
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isLoading={loading}
                        onClick={handleCountryManufacturer}
                      >
                        Сохранить
                      </Button>
                    </FormFooter>
                  </Private>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
