import React from 'react';
import { Symbol } from './symbol';
import { Flex, Box } from '@chakra-ui/react';

export const SymbolLine = ({ children = null }) => {
    const content = children ? children.split('') : [];
    if (content.length > 12) {
        return children;
    } else {
        return (
            <Flex>
                {content.map((item, index) => (
                    item === ' ' ? <Box key={index} width="10px"/> : <Symbol key={index}>{item}</Symbol>
                ))}
            </Flex>
        );
    }
};
