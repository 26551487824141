import { api } from 'api'
import { useQuery } from 'components/useQuery'

export const useTestTypeList = () => {
  const state = useQuery(async (params) => {
    return await api.testType.fetchList(params)
  })

  return state
}

