import React from 'react';
import { Flex } from '@chakra-ui/react';

export const Container = ({children, size = 'full', ...props}) => {
  const WIDTH = {
    'full': '100%',
    'md': '1100px',
    'xs': '700px'
  }
  return (
    <Flex
      width={WIDTH[size]}
      flexDirection="column"
      ml="auto"
      mr="auto"
      flex={1}
      {...props}
    >
      {children}
    </Flex>
  );
};
