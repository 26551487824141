import React, { memo } from 'react'

import { Box, BoxProps, Grid } from '@chakra-ui/react'

export const TableWrap: React.FC<BoxProps> = memo(({ children, ...props }) => {
  return (
    <Box position="relative" overflow="hidden" w="100%" h="100%">
      <Grid alignContent="flex-start" h="100%" overflow="auto" {...props}>
        {children}
      </Grid>
    </Box>
  )
})

TableWrap.displayName = 'TableWrap'