import React, { useContext } from 'react';
import { TranslationContext } from '../../../../translation';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { Heading, InputForm, SelectForm } from '../../../../chakra-lib';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormSection, HeadingContainer
} from '../../../../chakra-lib/layout';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import usePushFetchItem from '../services/usePushFetchItem';
import usePushUpdateItem from '../services/usePushUpdateItem';
import usePushCreateItem from '../services/usePushCreateItem';

const formSchema = Yup.object().shape({
  bodyRu: Yup.string().required('Required'),
  bodyEn: Yup.string().required('Required'),
  titleRu: Yup.string().required('Required'),
  titleEn: Yup.string().required('Required'),
});

export const PushUpdate = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const {id} = useParams();
  const isNew = !id;

  const { isLoading, data } = usePushFetchItem(id)
  const { updateItem, isLoadingUpdate } = usePushUpdateItem()
  const { createItem, isLoadingCreate } = usePushCreateItem()

  const handleBack = () => {
    history.goBack();
  };

  const handleForm = async (values) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      body: {
        ru: values.bodyRu,
        en: values.bodyEn
      },
      title: {
        ru: values.titleRu,
        en: values.titleEn
      },
      platform: values.platform,
      version: values.version
    };
    if (isNew) {
      await createItem(dataRequest)
    } else {
      await updateItem(dataRequest)
    }
    handleBack();
  };
  return (
    <Container>
      <CardContainer onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание PUSH сообщения' : 'Обновление PUSH сообщения'}
              </Heading>
            </HeadingContainer>
            <Formik
              initialValues={{
                bodyRu: data?.body?.ru,
                bodyEn: data?.body?.en,
                titleRu: data?.title?.ru,
                titleEn: data?.title?.en,
                platform: data?.platform,
                version: data?.version
              }}
              enableReinitialize={true}
              validationSchema={formSchema}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={isLoadingUpdate || isLoadingCreate}
                    >
                      {isNew ? getTranslation('user.action.buttonCreate') : getTranslation('user.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      autoFocus
                      name="titleRu"
                      label="Наименование на русском"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="titleEn"
                      component={InputForm}
                      label="Наименование на английском"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                  <FormSection>
                    <Field
                      name="bodyRu"
                      label="Сообщение на русском"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="bodyEn"
                      component={InputForm}
                      label="Сообщение на английском"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                  <FormSection>
                    <FormGrid columns={2}>
                      <Field
                        isDisabled={isLoading}
                        name="platform"
                        label="Платформа"
                        component={SelectForm}
                        placeholder="Выберите платформу"
                        isSearchable={false}
                        options={[
                          { value: '', label: 'iOS и Android' },
                          { value: 'ios', label: 'iOS' },
                          { value: 'android', label: 'Android' },
                        ]}
                      />
                      <Field
                        type="number"
                        name="version"
                        component={InputForm}
                        label="Версия приложения"
                        isDisabled={isLoading}
                        placeholder="Все версии"
                      />
                    </FormGrid>
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
