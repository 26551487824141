import React from 'react';
import { UIInput } from './input';

export const UIInputForm = React.forwardRef((
{
    field,
    form,
    sizeInput,
    label = null,
    ...props
}, ref) => {
    return (
        <UIInput
            name={field.name}
            label={label}
            isInvalid={form.errors[field.name] && form.touched[field.name]}
            {...field}
            {...props}
        />
    );
});

/*
export const UIInputFormNull = React.forwardRef(({ field, form, sizeInput, ...props }, ref) => {
    return (
        <FormControl isInvalid={form.errors[field.name] && form.touched[field.name]}>
            {props.label && (
                <FormLabel htmlFor={field.name} {...props} fontSize="sm">
                    {props.label}
                </FormLabel>
            )}
            <InputMaskFrom {...field} id={field.name} {...props} size={sizeInput}/>
        </FormControl>
    );
});*/
