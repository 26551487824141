import React, { useContext, useEffect } from 'react';
import {
    Button,
    ModalBody,
    useToast,
    useDisclosure, ModalFooter,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormSection } from '../../../../../components/deprecated/layout';
import { ModalForm } from '../../../../../components/deprecated/modal';
import { getInsuranceUrl, setInsuranceUrl } from '../../../../../store/settings.reducer';
import { TranslationContext } from '../../../../../translation';
import { InputForm } from '../../../../../chakra-lib';

export const SettingsInsuranceMemo = () => {
    const { getTranslation } = useContext(TranslationContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.settings.loading);
    const insuranceUrl = useSelector(state => state.settings.insuranceUrl);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(getInsuranceUrl());
    }, [])

    const handleForm = async (values, actions) => {
        const dataRequest = {
            key: 'insurance_url',
            value: values.insuranceUrl
        };
        await dispatch(setInsuranceUrl(dataRequest));
        toast({
            title: getTranslation('settingInsurance.toast.updateLimits.success.title'),
            description: `${getTranslation('settingInsurance.toast.updateLimits.success.description')}`,
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
        onClose();
    };
    return (
        <>
            <Button
                width="100%"
                onClick={onOpen}
            >
                {getTranslation('settingInsurance.action.buttonSettingLimits')}
            </Button>
            <ModalForm
                isOpen={isOpen}
                onClose={onClose}
                heading={getTranslation('settingInsurance.heading.settingLimits')}
            >
                <Formik
                    initialValues={{
                        insuranceUrl
                    }}
                    onSubmit={handleForm}
                    enableReinitialize={true}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <ModalBody>
                                <FormSection mb={0}>
                                    <Field
                                        autoFocus
                                        name="insuranceUrl"
                                        label={getTranslation('settingInsurance.field.insuranceUrl.label')}
                                        component={InputForm}
                                        placeholder="URL"
                                    />
                                </FormSection>
                            </ModalBody>
                            <ModalFooter justifyContent="flex-start">
                                <Button
                                    colorScheme="blue"
                                    type="submit"
                                    isDisabled={!props.dirty}
                                    isLoading={loading}
                                >
                                    {getTranslation('global.update')}
                                </Button>
                            </ModalFooter>
                        </form>
                    )}
                </Formik>
            </ModalForm>
        </>
    );
};

export const SettingsInsurance = React.memo(SettingsInsuranceMemo);
