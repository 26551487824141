import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    FormLabel,
    Input,
    FormControl,
    InputGroup,
    InputRightElement, Spinner, useColorModeValue } from '@chakra-ui/react';
import { useDebounce } from 'use-debounce';
import { ItemSearch } from './item-search';

export const UIInputSearchDropdownMemo = React.forwardRef((
{
    name,
    isInvalid = undefined,
    label = '',
    resultData,
    onUpdateQuery = null,
    onClickMenuItem = null,
    isLoading = false,
    inputValue = '',
    inputOnChange = null,
    onBlurFormControl = null,
    onBlurInput = null,
    ...props
}, ref) => {
    const [inputState, setInputState] = useState(inputValue);
    const [menuOpenState, setMenuOpenState] = useState(false);
    const [confirmedResult, setConfirmedResult] = useState(null);
    const [itemState, setItemState] = useState(null);
    const [value] = useDebounce(inputState, 400);
    const inputEl = useRef(null);
    const [focusIndex, setFocusIndex] = useState(0);
    const showResult = resultData && inputState && menuOpenState && confirmedResult !== inputState;
    const themeControlBorderColor = useColorModeValue('#E2E8F0', 'rgba(255, 255, 255, 0.16)');
    const themeBgMenuList = useColorModeValue('#ffffff', '#2D3748');

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setInputState(inputValue)
    }, [inputValue]);

    useEffect(() => {
        onUpdateQuery && onUpdateQuery(value);
    }, [value]);

    useEffect(() => {
        if (focusIndex === -1 && inputEl) {
            inputEl.current.focus();
        }
        // eslint-disable-next-line
    }, [focusIndex]);

    useEffect(() => {
        itemState && confirmedResult && onBlurFormControl && onBlurFormControl(itemState);
    }, [confirmedResult])

    const updateFocus = (offset) => {
        if (!showResult && resultData) {
            return
        }
        if (resultData && resultData.length - 1 === focusIndex) {
            if ( offset === -1 ) {
                setFocusIndex(value => value + offset);
            }
            return
        }
        if (focusIndex === -1) {
            if ( offset === 1 ) {
                setFocusIndex(value => value + offset);
            }
            return
        }
        setFocusIndex(value => value + offset);
    };

    const handleKeyDown = (e) => {
        switch (e.which) {
            //case 27: // Escape
            case 38: // Up Arrow
            {
                if (e.altKey) {
                    return;
                }
                updateFocus(-1);
                break;
            }
            // case 32: // Space
            // case 13: // Enter Key
            //case 9:
            case 40: // Down Arrow
            {
                if (e.altKey) {
                    return;
                }
                updateFocus(1);
                break;
            }
            default:
                return;
        }
        e.preventDefault();
    };

    const handleItemSearch = (item) => {
        if (item.value === inputState) {
            setConfirmedResult(inputState);
        }
        setItemState(item)
        setInputState(item.value);
        setFocusIndex(-1);
        inputOnChange && (inputOnChange(item.value))
    }
    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setMenuOpenState(false);
            onBlurFormControl && onBlurFormControl(itemState);
        }
    }
    const handleInputKeyDown = (e) => {
        switch (e.which) {
            case 13:
            {
                setConfirmedResult(inputState);
                break;
            }
            default:
                return;
        }
        e.preventDefault();
    };
    const handleInput = (e) => {
        const newValue = e.target.value;
        if (newValue !== confirmedResult) {
            setConfirmedResult(null)
        }
        setInputState(newValue);
        inputOnChange && (inputOnChange(newValue))
    };
    const handleInputBlur = (e) => {
        onBlurInput && onBlurInput(e.target.value)
    }
    return (
        <FormControl
            isInvalid={isInvalid}
            onFocus={() => setMenuOpenState(true)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
        >
            {label && (
                <FormLabel
                    htmlFor={name}
                    fontSize="0.9rem"
                    mb={1}
                    //fontWeight="normal"
                >
                    {label}
                </FormLabel>
            )}
            <Box position="relative">
                <Box>
                    <InputGroup>
                        <Input
                            id={name}
                            onFocus={() => setFocusIndex(-1)}
                            value={inputState}
                            onChange={handleInput}
                            onKeyDown={handleInputKeyDown}
                            onBlur={handleInputBlur}
                            //tabIndex={0}
                            ref={inputEl}
                            {...props}
                        />
                        {isLoading && (
                            <InputRightElement children={<Spinner color="blue.500" size="xs" />} />
                        )}
                    </InputGroup>
                </Box>
                {showResult && (
                    <Box
                        role="select"
                        width="100%"
                        backgroundColor={themeBgMenuList}
                        position="absolute"
                        maxHeight="280px"
                        zIndex="99"
                        marginTop={1}
                        borderWidth={1}
                        borderColor={themeControlBorderColor}
                        borderRadius="0.25rem"
                        boxShadow="0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06)"
                        overflowY="scroll"
                    >
                        {resultData && resultData.map((item, index) => (
                            <ItemSearch
                                key={index}
                                onClick={() => handleItemSearch(item)}
                                onEnterItem={handleItemSearch}
                                tabIndex={index}
                                focused={focusIndex === index}
                                focusIndex={focusIndex}
                                item={item}
                            >
                                {item.value}
                            </ItemSearch>
                        ))}
                    </Box>
                )}
            </Box>
        </FormControl>
    );
});

export const UIInputSearchDropdown = React.memo(UIInputSearchDropdownMemo);
