import React, { useEffect, useState } from 'react';
import { lang } from './lang';
import { useLocalStorage } from '../components/deprecated/use-local-storage';

export const TranslationContext = React.createContext({});

export const Translation = ({ children }) => {
    const [localLanguage, setLocalLanguage] = useLocalStorage('language', 'ru');
    const [language, setLanguage] = useState(localLanguage);

    const getTranslation = (key) => {
        return lang[key][language];
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setLocalLanguage(language);
    }, [language]);

    return (
        <TranslationContext.Provider value={{
            getTranslation,
            language,
            setLanguage
        }}>
            {children}
        </TranslationContext.Provider>
    )
};

/*const newLangRes = () => {
  const result = {};
  const MapObj = (obj, filedName) => {
    Object.entries(obj).map(([name, value]) => {
      if (value.hasOwnProperty('ru')) {
        result[`${filedName}${name}`] = value
        return;
      }
      if (typeof value === 'object') {
        MapObj(value, `${filedName}${name}.`);
        return;
      }
      result[`${filedName}`.slice(0, -1)] = value
    })
  }
  MapObj(translationResource, '');
  return result;
}*/
