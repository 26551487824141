import React, { useContext } from 'react';
import { Button, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
import {
    getDateReadFormat,
    getDateToSubmit,
    getMoment,
    isValidTime, twoDigits
} from '../deprecated/utilities/date-formatting';
import { ModalForm } from '../deprecated/modal';
import { Field, Formik } from 'formik';
import { FormGrid, FormSection } from '../deprecated/layout/form';
import { UIInput } from '../deprecated/input';
import { UIInputDateForm } from '../deprecated/input-date';
import * as Yup from 'yup';
import { UITag } from '../deprecated/tag';
import { TranslationContext } from '../../translation';

const formSchema = Yup.object().shape({
    time: Yup.string().required('Required'),
    date: Yup.string().required('Required')
});

const getDateTimePeriod = (value) => {
    const date = getDateReadFormat(value.date);
    const timeValue = value.workTimes[0];
    const fromHours = timeValue ? twoDigits(timeValue.fromHours) : null;
    const fromMinutes = timeValue ? twoDigits(timeValue.fromMinutes) : null;
    const toHours = timeValue ? twoDigits(timeValue.toHours) : null;
    const toMinutes = timeValue ? twoDigits(timeValue.toMinutes) : null;
    return `${date}: ${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`;
}

const getTimePeriod = (value) => {
    const timeValue = value.workTimes[0];
    const fromHours = timeValue ? twoDigits(timeValue.fromHours) : null;
    const fromMinutes = timeValue ? twoDigits(timeValue.fromMinutes) : null;
    const toHours = timeValue ? twoDigits(timeValue.toHours) : null;
    const toMinutes = timeValue ? twoDigits(timeValue.toMinutes) : null;
    return `${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`;
}

export const SpecialDateUpdate = ({ value = null, onChange = null, onDelete = null, ...props }) => {
    const { getTranslation } = useContext(TranslationContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleForm = async (values, actions) => {
        const [newFrom, newTo] = values.time ? values.time.split(' - ') : ['09:00','18:00'];
        const [newFromHours, newFromMinutes] = newFrom.split(':');
        const [newToHours, newToMinutes] = newTo.split(':');
        const dataRequest = {
            date: getDateToSubmit(values.date),
            workTimes: [{
                        fromHours: Number(newFromHours),
                        fromMinutes: Number(newFromMinutes),
                        toHours: Number(newToHours),
                        toMinutes: Number(newToMinutes)
                    }]
        };
        onChange && onChange(dataRequest, props.index);
        onClose();
    };
    const handleTimeBlur = (e, props) => {
        const value = e.target.value;
        const [newFrom, newTo] = value ? value.split(' - ') : ['09:00','18:00'];
        if (isValidTime(newFrom) && isValidTime(newTo)) {
            const [newFromHours, newFromMinutes] = newFrom.split(':');
            const [newToHours, newToMinutes] = newTo.split(':');
            if ((newFromHours === newToHours) && (newFromMinutes === newToMinutes)) {
                props.setFieldValue('time', props.values.time);
                return;
            }
            props.setFieldValue('time', e.target.value);
        } else {
            props.setFieldValue('time', props.values.time);
        }
    }
    return (
        <>
            {value ? (
                <UITag onClick={onOpen} onClickTagClose={() => onDelete(props.index)}>{getDateTimePeriod(value)}</UITag>
            ) : (
                <UITag onClick={onOpen}>{getTranslation('global.add')}</UITag>
            )}
            <ModalForm
                isOpen={isOpen}
                onClose={onClose}
                heading={value ? getTranslation('workSchedule.heading.holiday') : getTranslation('workSchedule.heading.holidayAdd')}
            >
                <Formik
                    initialValues={{
                        date: value ? getDateReadFormat(value.date) : getDateReadFormat(getMoment()),
                        time: value ? getTimePeriod(value) : '09:00 - 18:00'
                    }}
                    onSubmit={handleForm}
                    validationSchema={formSchema}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit}>
                            <ModalBody>
                                <FormSection mb={0}>
                                    <FormGrid columns={2}>
                                        <Field
                                            autoFocus
                                            label={getTranslation('global.date')}
                                            name="date"
                                            component={UIInputDateForm}
                                            placeholder="01.01.2020"
                                        />
                                        <UIInput
                                            label={getTranslation('global.time')}
                                            name="time"
                                            mask={[/[0-9]/,/[0-9]/,':', /[0-9]/, /[0-9]/,' ', '-', ' ', /[0-9]/,/[0-9]/,':', /[0-9]/, /[0-9]/]}
                                            placeholder="00:00 - 00:00"
                                            onBlur={e => handleTimeBlur(e, props)}
                                            value={props.values.time}
                                        />
                                    </FormGrid>
                                </FormSection>
                            </ModalBody>
                            <ModalFooter justifyContent="flex-start">
                                <Button colorScheme="blue" mr={3} type="submit">
                                    {value ? getTranslation('global.update') : getTranslation('global.add')}
                                </Button>
                            </ModalFooter>
                        </form>
                    )}
                </Formik>
            </ModalForm>
        </>
    );
};
