import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { ContainerApp, Header, NavigationMenuItem } from 'chakra-lib/layout'
import { Box, Image, Text } from '@chakra-ui/react'
import Logo from 'images/logo512.png'
import { Private, PrivateRoute } from 'components/Private'
import { ROLES } from 'model/roles'
import { ProfileWidget } from 'components/ProfileWidget'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { LoadingSettings } from 'components/deprecated/layout'
import { Login } from 'login'
import { TranslationContext } from 'translation'
import { useSelector } from 'react-redux'
import { isEmpty } from 'components/deprecated/utilities/is-empty'
import Country from './Country'
import Laboratory from './Laboratory'
import Office from './Office'
import User from './User'
import Dictionaries from './dictionaries'
import Push from './Push'

export const Integration = () => {
  const { getTranslation } = useContext(TranslationContext)
  const userAuth = useSelector((state) => state.authentication.user)
  const version = useSelector((state) => state.settings.version)
  const testValidHours = useSelector((state) => state.settings.testValidHours)
  const { url } = useRouteMatch()
  const panelName = 'Integration'
  const isAuthenticated = !isEmpty(userAuth)
  const versionBack = useSelector((state) => state.settings.versionBack)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.querySelector('html').style.overflow = 'hidden'
  }, [])

  return (
    <>
      {isAuthenticated ? (
        testValidHours ? (
          <>
            <Helmet>
              <title>{`COVID ${panelName}`}</title>
            </Helmet>
            <ContainerApp>
              <Header
                renderLogo={
                  <>
                    <Image src={Logo} width="44px" height="44px" alt="" />
                    <Box ml={3} lineHeight="1.4">
                      <Box>
                        <Text fontWeight="semibold" fontSize="1.1rem">
                          {panelName}
                        </Text>
                        <Text
                          fontSize="0.5rem"
                          color="gray.400"
                        >{`front: ${version} back: ${versionBack}`}</Text>
                      </Box>
                    </Box>
                  </>
                }
                renderNavigationItem={
                  <>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator, ROLES.User]}>
                      <NavigationMenuItem to={`/integration/country`}>
                        {getTranslation('menu.country')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/integration/laboratory`}>
                        {getTranslation('menu.laboratory')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/integration/office`}>
                        {getTranslation('menu.office')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/integration/user`}>
                        {getTranslation('menu.user')}
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/integration/dictionaries`}>
                        Справочники
                      </NavigationMenuItem>
                    </Private>
                    <Private role={userAuth.role} hasAnyRole={[ROLES.Administrator]}>
                      <NavigationMenuItem to={`/integration/push`}>Сообщения</NavigationMenuItem>
                    </Private>
                  </>
                }
                renderProfileWidget={<ProfileWidget />}
              />
              <Box overflow="hidden" flexGrow={1}>
                <Switch>
                  <PrivateRoute
                    path={`${url}/laboratory`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Laboratory />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/office`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Office />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/country`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.User, ROLES.Administrator]}
                  >
                    <Country />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/user`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <User />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/dictionaries`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Dictionaries />
                  </PrivateRoute>
                  <PrivateRoute
                    path={`${url}/push`}
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator]}
                  >
                    <Push />
                  </PrivateRoute>
                  <Route path={`/`}>
                    <Redirect to="/integration/country" />
                  </Route>
                </Switch>
              </Box>
            </ContainerApp>
          </>
        ) : (
          <LoadingSettings />
        )
      ) : (
        <Login />
      )}
    </>
  )
}
