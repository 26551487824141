import { useCallback, useEffect, useRef, useState } from 'react'

const initialValues = {
  response: undefined,
  request: undefined,
  error: undefined,
  isIdle: false,
  isLoading: false,
  isError: false,
}

export const useQuery = (query, options = {}) => {
  const { onRequest, onSuccess, onError, initialParams, nextPagination } = options
  const successRef = useRef(false)
  const prevParamsRef = useRef()
  const prevResponseRef = useRef()

  const [state, setState] = useState(initialValues)

  async function request(params) {
    prevParamsRef.current = params

    try {
      onRequest && onRequest()
      setState((state) => ({
        ...state,
        isIdle: true,
        isLoading: true,
        isError: false,
      }))

      prevResponseRef.current = state?.response

      let response = await query(params)

      if (nextPagination) {
        const list = prevResponseRef.current?.list.length
          ? prevResponseRef.current?.list.concat(response?.list)
          : response?.list

        response = { ...response, list }
      }

      onSuccess && onSuccess(response)
      successRef.current = true

      setState((state) => ({
        ...state,
        isIdle: true,
        isLoading: false,
        response,
      }))
    } catch (error) {
      if (error instanceof TypeError) throw error
      onError && onError(error)
      successRef.current = false

      setState((state) => ({
        ...state,
        isIdle: true,
        isLoading: false,
        isError: true,
        error,
      }))
    }
  }

  const update = useCallback(() => request(prevParamsRef.current), [prevParamsRef.current])

  const next = useCallback(() => {
    const limit = parseInt(initialParams.limit)
    const skip = parseInt(prevResponseRef.current?.skip)

    request({
      ...initialParams,
      ...prevParamsRef.current,
      skip: skip ? skip + limit : limit,
    })
  }, [prevParamsRef.current, prevResponseRef.current, initialParams])

  useEffect(() => {
    successRef.current = false
    prevResponseRef.current = null
  })

  return {
    ...state,
    isSuccess: successRef.current,
    request,
    update,
    ...(nextPagination && { next }),
  }
}

export default useQuery;