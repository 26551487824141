import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { VaccineListFilter } from './elements/VaccineListFilter';
import { FILTER_VACCINE_PARAMS } from '../../../../config/vaccine.config';
import { getFilterParams } from '../../../../chakra-lib/utils';
import { getNextPage, resetFilter, resetVaccineList, updateFilter } from '../../../../store/vaccine.reducer';
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../chakra-lib/layout';
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from '../../../../chakra-lib';
import { HeadingItem, ItemIcon, ItemLink, ItemText } from '../../../../chakra-lib/Snippets';
import { Box, Text } from '@chakra-ui/react';
import { truncate } from '../../../../components/deprecated/utilities/truncate';
import { IconCollection } from '../../../../components/icons';

export const VaccineList = () => {
  const dispatch = useDispatch();
  const {params} = useParams();
  const activePage = useSelector(state => state.vaccine.activePage);
  const items = useSelector(state => state.vaccine.items);
  const totalPages = useSelector(state => state.vaccine.totalPages);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_VACCINE_PARAMS, params);
      dispatch(updateFilter(objFilterParams));
    } else {
      dispatch(updateFilter());
    }
  }, [params]);
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
      dispatch(resetVaccineList());
    };
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(200px, 1fr) 160px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>Имя</HeadingItem>
              <HeadingItem>Фазы</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={items}
              onNext={() => dispatch(getNextPage())}
            >
              {(item, index) => (
                <SnippetsItem to={`/vaccination/vaccine/show/${item.id}`}>
                  <ItemIcon icon={IconCollection} />
                  <ItemLink>
                    <Box> {truncate(item.name?.ru, 50)}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {truncate(item.name?.en, 50)}
                    </Text>
                  </ItemLink>
                  <ItemText>{item.phases}</ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <VaccineListFilter params={params}/>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
