import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { OfficeList } from './OfficeList/OfficeList';
import { OfficeCard } from './OfficeCard/OfficeCard';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/show/:id`} exact>
        <OfficeCard/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <OfficeList/>
      </Route>
    </Switch>
  );
};

export default Routes;
