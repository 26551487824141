import React, { memo, useCallback, useContext, useMemo, useRef } from 'react';
import { SelectForm } from 'chakra-lib';
import { TranslationContext } from 'translation';
import * as Yup from 'yup';

import { Button } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { FormSection } from 'components/deprecated/layout';
import { ROLES, rolesTranslatePath } from "model/roles";
import { testReports, testReportsTranslatePath, testReportsUrl } from "model/reports";
import { useFetchItemSettings, useUpdateSettings } from "pages/laboratory/Test/TestList/elements/SettingsReports/hooks";

const { Administrator, User, UserPrivileged, UserLab, UserResultSetter, UserCreator } = ROLES

const formSchema = Yup.object().shape({
  report: Yup.string().required('Required'),
});

const SettingsReportsForm = () => {
  const formRef = useRef({});
  const { getTranslation } = useContext(TranslationContext);
  const { updatedData, settingsIsUpdating, updateSettings } = useUpdateSettings();
  const { reportSettings, reportSettingsIsLoading, fetchReportSettings } = useFetchItemSettings();
  const loading = reportSettingsIsLoading || settingsIsUpdating

  const onSubmitHandle = useCallback(({ report, roles }) => {
    updateSettings({
      url: report,
      roles: roles?.length ? roles : null,
    })
  }, [])

  const onChangeReport = useCallback((value) => {
    fetchReportSettings({ url: value })
  }, [])

  const reportsListOptions = Object.values(testReports).map((item) => ({
    label: getTranslation(testReportsTranslatePath[item]), value: testReportsUrl[item]
  }))

  const rolesListOptions = [Administrator, User, UserPrivileged, UserLab, UserResultSetter, UserCreator].map((item) => ({
    label: getTranslation(rolesTranslatePath[item]), value: item
  }))

  const initialValues = useMemo(() => {
    if (updatedData) {
      return {
        ...formRef.current.values,
        roles: updatedData?.roles ?? []
      }
    }

    return {
      ...formRef.current.values,
      roles: reportSettings?.roles ?? []
    }
  }, [reportSettings, updatedData])

  return (<Formik
    initialValues={initialValues}
    onSubmit={onSubmitHandle}
    validationSchema={formSchema}
    enableReinitialize
  >
    {props => {
      formRef.current = props

      return (<form onSubmit={props.handleSubmit}>
        <FormSection mb="1.5rem">
          <Field
            name="report"
            placeholderColor="#333333"
            placeholder={getTranslation('global.all')}
            component={SelectForm}
            label={getTranslation('reports.update.reports')}
            options={reportsListOptions}
            onChange={onChangeReport}
          />
          <Field
            name="roles"
            isMulti
            isLoading={loading}
            placeholderColor="#333333"
            placeholder={getTranslation('global.all')}
            component={SelectForm}
            label={getTranslation('reports.update.roles')}
            options={rolesListOptions}
          />
        </FormSection>
        <Button
          type="submit"
          colorScheme="blue"
          isDisabled={!props.dirty || !props.isValid || loading}
        >
          {getTranslation('global.update')}
        </Button>
      </form>)
    }}
  </Formik>);
};

export default memo(SettingsReportsForm)