import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { LaboratoryCard } from './LaboratoryCard/LaboratoryCard';
import { LaboratoryList } from './LaboratoryList/LaboratoryList';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/show/:id`} exact>
        <LaboratoryCard/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <LaboratoryList/>
      </Route>
    </Switch>
  );
};

export default Routes;
