import React from 'react';
import { Box, Flex, Grid, useColorModeValue } from '@chakra-ui/react';
import { Container } from '../Container';
import { NavigationMenu } from '../NavigationMenu';

export const Header = ({ renderLogo, subTitle, renderNavigationItem, renderProfileWidget }) => {
  const themeBgSubTitle = useColorModeValue('gray.50', 'var(--chakra-colors-whiteAlpha-200)');
  return (
    <Box
      borderBottomWidth={1}
    >
      {subTitle && (
        <Flex
          alignItems="center"
          height="20px"
          fontSize="0.7rem"
          backgroundColor={themeBgSubTitle}
          zIndex={10}
          color="gray.400"
          padding="0 18px"
        >
          {subTitle}
        </Flex>
      )}
      <Container pl="16px" pr="26px">
        <Grid
          height="70px"
          justifyContent="space-between"
          alignItems="center"
          templateColumns="1fr 1fr"
          width="100%"
        >
          <Flex alignItems="center">
            <Flex alignItems="center" flex="none">
              {renderLogo}
            </Flex>
            <NavigationMenu ml="9px">
              {renderNavigationItem}
            </NavigationMenu>
          </Flex>
          <Box justifySelf="flex-end">
            {renderProfileWidget}
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};
