import React from 'react';
import { extendTheme, ChakraProvider } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools'
import createCache from '@emotion/cache';
import createRgbaHexToFuncPlugin from 'rgba-hex-to-func';
import createCssVarRevertPlugin from 'css-vars-revert';
import { CacheProvider } from '@emotion/react';

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
})

// Create a custom cache with plugins
const ie11StyleAwareCache = createCache({
  key: 'nocovid',
  stylisPlugins: [
    createCssVarRevertPlugin(),
    createRgbaHexToFuncPlugin(),
  ],
});

export const theme = extendTheme({
  breakpoints,
  styles: {
    global: {
      'html, body': {}
    },
  },
});

export const ThemeCache = ({children}) => {
  return (
    <CacheProvider value={ie11StyleAwareCache}>
      <ChakraProvider theme={theme}>
        {children}
      </ChakraProvider>
    </CacheProvider>
  )
}
