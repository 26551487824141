import React, { useContext } from 'react';
import { TranslationContext } from '../../../../../translation';
import { useHistory, useParams } from 'react-router-dom';
import { Button, VStack } from '@chakra-ui/react';
import { CheckboxForm, Heading, InputForm, TextAreaForm } from '../../../../../chakra-lib';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, EntityState,
  Form,
  FormSection, HeadingContainer, RightAside
} from '../../../../../chakra-lib/layout';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import useCreateItem from './services/useCreateItem';
import useUpdateItem from './services/useUpdateItem';
import useFetchItem from './services/useFetchItem';
import { DropZoneFile } from '../../../../../components/DropZoneFile';
import { API_SERVER_URL } from '../../../../../store/config';
import { downloadURI } from '../../../../../utils/downloadURI';

const formSchema = Yup.object().shape({
  titleRu: Yup.string().required('Required'),
  titleEn: Yup.string().required('Required'),
  fileBody: Yup.string().required('Required'),
});

const TYPE_COLOR_DISABLED = {
  'true': 'red',
  'false': 'green'
}

const TYPE_DISABLED = {
  'true': 'Скрытый',
  'false': 'Активен'
};

export const FormSampleUpdate = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const {id} = useParams();
  const isNew = !id;

  const { isLoading, data } = useFetchItem(id)
  const { updateItem, isLoadingUpdate } = useUpdateItem(id)
  const { createItem, isLoadingCreate } = useCreateItem()

  const handleBack = () => {
    history.goBack();
  };

  const handleForm = async (values) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      description: {
        ru: values.descriptionRu,
        en: values.descriptionEn
      },
      title: {
        ru: values.titleRu,
        en: values.titleEn
      },
      hidden: values.hidden,
      fileBody: values.fileBody
    };
    if (isNew) {
      await createItem(dataRequest)
    } else {
      await updateItem(dataRequest)
    }
    handleBack();
  };

  const handleDownload = () => {
    downloadURI(`${API_SERVER_URL}/form_sample/${id}/download`, 'image.jpg');
  }

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание документа' : 'Обновление документа'}
              </Heading>
            </HeadingContainer>
            <Formik
              initialValues={{
                descriptionRu: data?.description?.ru,
                descriptionEn: data?.description?.en,
                titleRu: data?.title?.ru,
                titleEn: data?.title?.en,
                hidden: data?.hidden,
                fileBody: data?.fileBody
              }}
              enableReinitialize={true}
              validationSchema={formSchema}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={isLoadingUpdate || isLoadingCreate}
                    >
                      {isNew ? getTranslation('user.action.buttonCreate') : getTranslation('user.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <FormSection>
                    <Field
                      autoFocus
                      name="titleRu"
                      label="Наименование на русском"
                      component={InputForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="titleEn"
                      component={InputForm}
                      label="Наименование на английском"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                  <FormSection>
                    <Field
                      name="descriptionRu"
                      label="Описание на русском"
                      component={TextAreaForm}
                      isDisabled={isLoading}
                    />
                    <Field
                      name="descriptionEn"
                      component={TextAreaForm}
                      label="Описание на английском"
                      isDisabled={isLoading}
                    />
                  </FormSection>
                  <FormSection>
                    <Field
                      name="hidden"
                      component={CheckboxForm}
                      label="Скрытый"
                    />
                  </FormSection>
                  <FormSection>
                    <DropZoneFile
                      maxFiles={1}
                      multiple={false}
                      accept={'.jpg'}
                      uploadedFile={props.values?.fileBody}
                      onUpload={(acceptedFiles) => {
                        const file = acceptedFiles[0];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onloadend = () => {
                          props.setFieldValue('fileBody', reader.result.split(';base64,')[1])
                        };
                      }}
                    />
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
          {!isNew && (
            <ContentContainerColumn>
              <RightAside>
                <VStack spacing={5}>
                  <EntityState
                    isLoading={isLoading}
                    colorScheme={TYPE_COLOR_DISABLED[data?.hidden]}
                  >
                    {TYPE_DISABLED[data?.hidden]}
                  </EntityState>
                  <Button
                    colorScheme="blue"
                    width="100%"
                    isDisabled={isLoading}
                    onClick={handleDownload}
                  >
                    Скачать
                  </Button>
                </VStack>
              </RightAside>
            </ContentContainerColumn>
          )}
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
