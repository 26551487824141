import React from 'react';
import { UITextarea } from './textarea';

export const UITextareaForm = React.forwardRef((
{
    field,
    form,
    sizeInput,
    label = null,
    max,
    ...props
}, ref) => {

    const handleChange = (e) => {
        if (max) {
            form.setFieldValue(field.name, e.target.value.substring(0, max))
        } else {
            form.setFieldValue(field.name, e.target.value)
        }
    }

    return (
        <UITextarea
            name={field.name}
            label={label}
            isInvalid={form.errors[field.name] && form.touched[field.name]}
            value={field.value}
            onChange={handleChange}
            {...props}
        />
    );
});
