import React from 'react';
import { Badge, Skeleton, useColorModeValue } from '@chakra-ui/react';

export const EntityState = ({ children, isLoading, ...props }) => {
  const themeBgLoading = useColorModeValue('gray.50', 'gray.600');
  return (
    <Badge
      display="flex"
      padding="1px 10px"
      borderRadius={5}
      fontSize="1rem"
      width="100%"
      textAlign="center"
      height="50px"
      alignItems="center"
      justifyContent="center"
      {...props}
      {...isLoading && {
        backgroundColor: themeBgLoading
      }}
    >
      {isLoading ? (
        <Skeleton
          borderRadius={8}
          height="12px"
          width="100px"
        />
      ) : children}
    </Badge>
  );
};
