import * as Yup from 'yup'
import { isPassportRu } from '../validation/is-passport'

export const testNewSchema = Yup.object().shape({
  internalId: Yup.string()
    .max(30, 'Код лаборатории не должен быть больше 30 символов')
    .required('Required'),
  officeId: Yup.string().required('Required'),
  email: Yup.string().email('Email'),
  testTypeId: Yup.string().required('Required'),
})

export const testNewSchemaRu = Yup.object().shape({
  internalId: Yup.string().required('Required'),
  officeId: Yup.string().required('Required'),
  email: Yup.string().email('Email'),
  passport: Yup.string().test('passportValid', 'passportValid', (value) => isPassportRu(value)),
  testTypeId: Yup.string().required('Required'),
})

export const testNewSchemaAm = Yup.object().shape({
  internalId: Yup.string().required('Required'),
  officeId: Yup.string().required('Required'),
  email: Yup.string().email('Email'),
  passport: Yup.string().test('passportValid', 'passportValid', (value) => isPassportRu(value)),
  testTypeId: Yup.string().required('Required'),
})

export const testNewSchemaBy = Yup.object().shape({
  internalId: Yup.string().required('Required'),
  officeId: Yup.string().required('Required'),
  email: Yup.string().email('Email'),
  passport: Yup.string().test('passportValid', 'passportValid', (value) => isPassportRu(value)),
  testTypeId: Yup.string().required('Required'),
})

export const testAddPassport = Yup.object().shape({
  passport: Yup.string().required('Required'),
})

export const testAddPassportRu = Yup.object().shape({
  passport: Yup.string()
    .test('passportValid', 'passportValid', (value) => isPassportRu(value))
    .required('Required'),
})

export const testAddPassportAm = Yup.object().shape({
  passport: Yup.string()
    .test('passportValid', 'passportValid', (value) => isPassportRu(value))
    .required('Required'),
})

export const testAddPassportBy = Yup.object().shape({
  passport: Yup.string()
    .test('passportValid', 'passportValid', (value) => isPassportRu(value))
    .required('Required'),
})
