import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';

export const ACTION_TYPES = {
    FETCH_SETTINGS_SMTP: 'settings-smtp/FETCH_SETTINGS_SMTP',
    UPDATE_SETTINGS_SMTP: 'settings-smtp/UPDATE_SETTINGS_SMTP',
    TEST_SETTINGS_SMTP: 'settings-smtp/TEST_SETTINGS_SMTP',
    RESET: 'settings-smtp/RESET'
};

const initialState = {
    item: {},
    loading: false,
    loadingTest: false
};

export const settingsSMTPReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_SETTINGS_SMTP):
        case REQUEST(ACTION_TYPES.UPDATE_SETTINGS_SMTP): {
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.TEST_SETTINGS_SMTP): {
            return {
                ...state,
                loadingTest: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_SETTINGS_SMTP):
        case FAILURE(ACTION_TYPES.UPDATE_SETTINGS_SMTP): {
            return {
                ...state,
                loading: false
            }
        }
        case FAILURE(ACTION_TYPES.TEST_SETTINGS_SMTP): {
            return {
                ...state,
                loadingTest: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_SETTINGS_SMTP):
        {
            return {
                ...state,
                loading: false,
                item: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_SETTINGS_SMTP):
        {
            return {
                ...state,
                loading: false,
                item: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.TEST_SETTINGS_SMTP):
        {
            return {
                ...state,
                loadingTest: false
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                loading: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/smtp_settings`;

export const getSettingsSMTP = () => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.FETCH_SETTINGS_SMTP,
        payload: axios.get(requestUrl)
    };
};

export const updateSettingsSMTP = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.UPDATE_SETTINGS_SMTP,
        payload: axios.post(requestUrl, {...dataRequest})
    };
};

export const testSettingsSMTP = (dataRequest) => {
    const requestUrl = `${API_URL}/test`;
    return {
        type: ACTION_TYPES.TEST_SETTINGS_SMTP,
        payload: axios.post(requestUrl, {...dataRequest})
    };
};

export const resetSettingsSMTP = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
