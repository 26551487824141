import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getNextPage, resetFilter, resetTestList, updateFilter } from 'store/test.reducer'
import { useTestResult } from 'components/test-result'
import { getFilterParams } from 'chakra-lib/utils'
import { FILTER_TEST_PARAMS } from 'config/test.config'
import { TestListFilter } from './elements/TestListFilter'
import { getOfficeList, resetOfficeList } from 'store/office.reducer'
import { exportExcelFile } from 'utils/exportExcelFile'
import { makeColumns } from 'pages/laboratory/Test/TestList/TestList.config'
import { makeExcelColumns } from 'pages/laboratory/Test/TestList/TestList.excel'
import { STORAGE_TABLE } from 'model/localStorage'
import { TEST_FIELDS } from 'pages/laboratory/Test/Test.constants'
import Register from 'components/layout/Register'

export const TestList = () => {
  const history = useHistory()
  const tableRef = useRef()
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const { params } = useParams()
  const userAuth = useSelector((state) => state.authentication.user)
  const items = useSelector((state) => state.test.items)
  const activePage = useSelector((state) => state.test.activePage)
  const totalPages = useSelector((state) => state.test.totalPages)
  const loading = useSelector((state) => state.test.loading)
  const { getResult } = useTestResult()

  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_TEST_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])

  useEffect(() => {
    dispatch(getOfficeList())
    return () => {
      dispatch(resetFilter())
      dispatch(resetTestList())
      dispatch(resetOfficeList())
    }
  }, [])

  const exportExcelHandle = useCallback(() => {
    const hiddenColumns = tableRef.current.state.hiddenColumns

    exportExcelFile(items, {
      sheetName: 'Tests',
      columns: makeExcelColumns({ getTranslation, getResult, hiddenColumns, role: userAuth.role }),
    })
  }, [items])

  const onRowClick = useCallback(({ original }) => {
    history.push(`/laboratory/test/show/${original.id}`)
  }, [])

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        TEST_FIELDS.passport,
        TEST_FIELDS.telephone,
        TEST_FIELDS.email,
        TEST_FIELDS.officeTitleEn,
        TEST_FIELDS.officeAddress,
        TEST_FIELDS.resultDate,
        TEST_FIELDS.createdAt,
      ],
    }),
    []
  )

  const columns = useMemo(() => makeColumns({ getTranslation, role: userAuth.role }), [])

  return (
    <Register rightPanel={<TestListFilter params={params} onExportExcel={exportExcelHandle} />}>
      <Table
        ref={tableRef}
        data={items}
        columns={columns}
        isLoading={loading}
        hasNext={activePage < totalPages}
        onNextPage={() => dispatch(getNextPage())}
        onRowClick={onRowClick}
        initialState={initialState}
        storageId={`${STORAGE_TABLE.laboratoryTest}.${userAuth.id}`}
      />
    </Register>
  )
}
