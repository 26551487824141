import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';

const START_PAGE = 1;
const PAGE_SIZE = 20;

export const ACTION_TYPES = {
    FETCH_FAQ_LIST: 'faq/FETCH_FAQ_LIST',
    RESET_FAQ_LIST: 'faq/RESET_FAQ_LIST',
    FETCH_FAQ: 'faq/FETCH_FAQ',
    CREATE_FAQ: 'faq/CREATE_FAQ',
    UPDATE_FAQ: 'faq/UPDATE_FAQ',
    RESET_FAQ: 'faq/RESET_FAQ',
    UPDATE_FILTER: 'faq/UPDATE_FILTER',
    RESET_FILTER: 'faq/RESET_FILTER',
    RESET: 'FAQ/RESET'
};

const initialState = {
    items: [],
    item: {},
    loading: false,
    loadingUpdate: false,
    errorMessage: '',
    filter: {
        text: ''
    }
};

export const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_FAQ_LIST):
        case REQUEST(ACTION_TYPES.FETCH_FAQ): {
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_FAQ):
        case REQUEST(ACTION_TYPES.UPDATE_FAQ):{
            return {
                ...state,
                loadingUpdate: true,
                errorMessage: ''
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_FAQ_LIST):
        case FAILURE(ACTION_TYPES.FETCH_FAQ):
        case FAILURE(ACTION_TYPES.CREATE_FAQ):
        case FAILURE(ACTION_TYPES.UPDATE_FAQ):{
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errorMessage: action.payload.response.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_FAQ_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loading: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage'],
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_FAQ): {
            return {
                ...state,
                item: action.payload.data,
                loading: false
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_FAQ): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_FAQ): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FAQ_LIST: {
            return {
                ...state,
                items: [],
                loading: false,
                loadingUpdate: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE
            }
        }
        case ACTION_TYPES.RESET_FAQ: {
            return {
                ...state,
                item: {},
                loading: false,
                loadingUpdate: false
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                loadingUpdate: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/faq`;

export const getFaqListSearch = (page) => (dispatch, getState) => {
    const filter = getState().faq.filter;
    const requestUrl = `${API_URL}/find?pageSize=${PAGE_SIZE}&page=${page}`;
    dispatch({
        type: ACTION_TYPES.FETCH_FAQ_LIST,
        payload: axios.post(requestUrl, {
            text: checkParam(filter.text)
        })
    });
};

export const updateFilter = (params) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        filter: {
            ...params
        }
    });
    dispatch(getFaqListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().faq.activePage;
    dispatch(getFaqListSearch(activePage + 1))
};


export const resetFilter = () => {
    return {
        type: ACTION_TYPES.RESET_FILTER
    };
};

export const getFaq = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_FAQ,
        payload: axios.get(requestUrl)
    };
};


export const createFaq = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_FAQ,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateFaq = (dataRequest, id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.UPDATE_FAQ,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const resetFaqList = () => {
    return {
        type: ACTION_TYPES.RESET_FAQ_LIST
    };
};

export const resetFaq = () => {
    return {
        type: ACTION_TYPES.RESET_FAQ
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
