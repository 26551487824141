import React from 'react';
import { Route } from 'react-router-dom';

export const getAccess = (authorities, hasAnyRole) => {
    if (authorities && authorities.length !== 0) {
        if (hasAnyRole.length === 0) {
            return true;
        }
        return hasAnyRole.includes(authorities);
    }
    return false;
};

export const PrivateRoute = ({ path, role, hasAnyRole, children, ...props }) => {
    const access = getAccess(role, hasAnyRole);
    return (
        <Route path={path} {...props}>
            {access ? children : 'Нет доступа'}
        </Route>
    );
};

export const Private = ({ role, hasAnyRole, children }) => {
    const access = getAccess(role, hasAnyRole);
    return (
        <>
            {access ? children : null}
        </>
    )
}
