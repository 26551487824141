import React, { useContext, useEffect } from 'react';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, GridField, GridFieldHeading, GridFieldItem,
  HeadingContainer, RightAside
} from '../../../../chakra-lib/layout';
import { Heading } from '../../../../chakra-lib';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { ContentDate } from '../../../../components/content-date';
import { TranslationContext } from '../../../../translation';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAcceptLaboratory,
  getAcceptLaboratory,
  getLaboratory,
  resetAcceptLaboratory,
  resetLaboratory, setAcceptLaboratory
} from '../../../../store/laboratory.reducer';
import { getCountry, getCountryAllList, resetCountry, resetCountryList } from '../../../../store/country.reducer';
import { RequisitesShow } from '../../../../components/requisites';
import { CountrySelection } from '../../../../components/deprecated/country-selection/country-selection';

export const LaboratoryCard = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const item = useSelector(state => state.laboratory.item);
  const loading = useSelector(state => state.laboratory.loading);
  const itemsCountry = useSelector(state => state.country.itemsAll);
  const loadingCountryItem = useSelector(state => state.country.loadingItem);
  const loadingCountryList = useSelector(state => state.country.loadingList);
  const itemsAccept = useSelector(state => state.laboratory.itemsAccept);
  const loadingAccept = useSelector(state => state.laboratory.loadingAccept);
  const itemCountry = useSelector(state => state.country.item);
  const isReadyItem = !isEmpty(item);
  const isReadyItemCountry = !isEmpty(itemCountry);
  const isReadyCountryList = !isEmpty(itemsCountry);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getLaboratory(id));
    dispatch(getAcceptLaboratory(id));
    dispatch(getCountryAllList());
    return () => {
      dispatch(resetLaboratory());
      dispatch(resetAcceptLaboratory());
      dispatch(resetCountryList());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(item) && item.countryId) {
      dispatch(getCountry(item.countryId));
    }
    return () => {
      dispatch(resetCountry());
    }
  }, [item]);

  const handleBack = () => {
    history.goBack();
  };

  const handleCountrySelection = (e, itemId) => {
    if (e.target.checked) {
      dispatch(setAcceptLaboratory(id, {
        ids: [
          itemId
        ]
      }))
    } else {
      dispatch(deleteAcceptLaboratory(id, {
        ids: [
          itemId
        ]
      }))
    }
  };
  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">{getTranslation('laboratory.heading.show')}</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading>
                  {getTranslation('laboratory.section.heading.data')}
                </GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('laboratory.field.name.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.name ? item.name : null}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.shortName.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.shortName ? item.shortName : null}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.telephone.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.telephone ? item.telephone : null}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('laboratory.field.siteUrl.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.siteUrl ? item.siteUrl : null}
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>
                  {getTranslation('laboratory.section.heading.country')}
                </GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('country.field.nameRu.label')}
                  isLoading={loadingCountryItem}
                  isReady={isReadyItemCountry}
                >
                  {itemCountry.name && itemCountry.name.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('country.field.shortNameRu.label')}
                  isLoading={loadingCountryItem}
                  isReady={isReadyItemCountry}
                >
                  {itemCountry.shortName && itemCountry.shortName.ru}
                </GridFieldItem>
              </GridField>
              <GridField>
                <GridFieldHeading>
                  {getTranslation('laboratory.section.heading.requisites')}
                </GridFieldHeading>
                <RequisitesShow
                  item={item}
                  loading={loadingCountryItem}
                  isReadyItem={isReadyItemCountry}
                  initialCountry={itemCountry.countryCode}
                />
              </GridField>
            </ContentContainerColumn>
            <ContentContainerColumn>
              <RightAside>
                <Flex alignItems="center">
                  <Text color="gray.400" mb={2}>
                    {getTranslation('laboratory.aside.showInCountries')}
                  </Text>
                  {(loadingAccept || loadingCountryList) && (
                    <Spinner color="blue.500" size="sm" marginLeft="10px" mb={2}/>
                  )}
                </Flex>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  borderRadius={6}
                  color={
                    itemsAccept.length === 0 ? 'green.800' : 'gray.800'
                  }
                  backgroundColor={
                    itemsAccept.length === 0 ? 'green.100' : 'gray.100'
                  }
                  mb={4}
                  padding="10px"
                >
                  <Box textAlign="center">
                    <Text
                      fontSize="0.8rem"
                      textTransform="uppercase"
                      fontWeight="700"
                    >
                      {itemsAccept.length === 0 ? (
                        getTranslation('laboratory.aside.visibleAll')
                      ) : (
                        getTranslation('laboratory.aside.filtered')
                      )}
                    </Text>
                  </Box>
                </Flex>
                <Text color="gray.400" mb={2}>
                  {getTranslation('laboratory.aside.filterCountries')}
                </Text>
                <CountrySelection
                  items={itemsCountry}
                  selected={itemsAccept}
                  isLoadingCountry={loadingCountryList || !isReadyCountryList}
                  onChange={handleCountrySelection}
                />
                <ContentDate item={item} loading={loading} />
              </RightAside>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
    </>
  )
}
