import React from 'react';
import { TabPanels as ChakraTabPanels } from '@chakra-ui/react';

export const TabPanels = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <ChakraTabPanels
      ref={ref}
      pt={3}
      {...props}
    >
      {children}
    </ChakraTabPanels>
  );
});
