import React from 'react';
import { InputDate } from '../InputDate';

export const InputDateForm = React.forwardRef((
  {
    field,
    form,
    sizeInput,
    label,
    ...props
  }, ref) => {
  const handleChange = (e) => {
    form.setFieldValue(field.name, e);
  }
  return (
    <InputDate
      name={field.name}
      label={label}
      isInvalid={form.errors[field.name] && form.touched[field.name]}
      value={field.value}
      onChange={handleChange}
      {...props}
    />
  );
});
