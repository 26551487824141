import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { TestList } from './TestList/TestList';
import { TestCard } from './TestCard/TestCard';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/show/:id`}>
        <TestCard/>
      </Route>
      <Route path={`${match.url}`}>
        <TestList/>
      </Route>
    </Switch>
  );
};

export default Routes;
