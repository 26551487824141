import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';

const START_PAGE = 1;
const PAGE_SIZE = 20;

export const ACTION_TYPES = {
  FETCH_VACCINE_LIST: 'vaccine/FETCH_VACCINE_LIST',
  RESET_VACCINE_LIST: 'vaccine/RESET_VACCINE_LIST',
  FETCH_VACCINE: 'vaccine/FETCH_VACCINE',
  CREATE_VACCINE: 'vaccine/CREATE_VACCINE',
  UPDATE_VACCINE: 'vaccine/UPDATE_VACCINE',
  RESET_VACCINE: 'vaccine/RESET_VACCINE',
  UPDATE_FILTER: 'vaccine/UPDATE_FILTER',
  RESET_FILTER: 'vaccine/RESET_FILTER',
  RESET: 'vaccine/RESET'
};

const initialState = {
  items: [],
  item: {},
  loading: false,
  loadingUpdate: false,
  errorMessage: '',
  totalItems: 0,
  totalPages: 0,
  activePage: START_PAGE,
  filter: {
    text: ''
  }
};

export const vaccineReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_VACCINE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_VACCINE): {
      return {
        ...state,
        loading: true
      }
    }
    case REQUEST(ACTION_TYPES.CREATE_VACCINE):
    case REQUEST(ACTION_TYPES.UPDATE_VACCINE): {
      return {
        ...state,
        loadingUpdate: true,
        errorMessage: ''
      }
    }
    case FAILURE(ACTION_TYPES.FETCH_VACCINE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_VACCINE):
    case FAILURE(ACTION_TYPES.CREATE_VACCINE):
    case FAILURE(ACTION_TYPES.UPDATE_VACCINE): {
      return {
        ...state,
        loading: false,
        loadingUpdate: false,
        errorMessage: action.payload.response.data
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_VACCINE_LIST): {
      const navigation = JSON.parse(action.payload.headers['x-pagination']);
      const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
      return {
        ...state,
        loading: false,
        items,
        totalItems: navigation['TotalCount'],
        totalPages: navigation['TotalPages'],
        activePage: navigation['CurrentPage'],
        errorMessage: ''
      }
    }
    case SUCCESS(ACTION_TYPES.FETCH_VACCINE): {
      return {
        ...state,
        item: action.payload.data,
        loading: false
      }
    }
    case SUCCESS(ACTION_TYPES.CREATE_VACCINE): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: ''
      }
    }
    case SUCCESS(ACTION_TYPES.UPDATE_VACCINE): {
      return {
        ...state,
        item: action.payload.data,
        loading: false,
        loadingUpdate: false,
        errorMessage: ''
      }
    }
    case ACTION_TYPES.UPDATE_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter,
          ...action.filter
        }
      }
    }
    case ACTION_TYPES.RESET_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter
        }
      }
    }
    case ACTION_TYPES.RESET_VACCINE_LIST: {
      return {
        ...state,
        items: [],
        loading: false,
        loadingUpdate: false,
        totalItems: 0,
        totalPages: 0,
        activePage: START_PAGE
      }
    }
    case ACTION_TYPES.RESET_VACCINE: {
      return {
        ...state,
        item: {},
        loading: false,
        loadingUpdate: false
      }
    }
    case ACTION_TYPES.RESET: {
      return {
        ...state,
        items: [],
        item: {},
        loading: false,
        loadingUpdate: false
      }
    }
    default:
      return state;
  }
};

const API_URL = `${API_SERVER_URL}/vaccine`;

export const getVaccineList = () => {
  const requestUrl = `${API_URL}/find?pageSize=${10000}&page=${1}`;
  return {
    type: ACTION_TYPES.FETCH_VACCINE_LIST,
    payload: axios.post(requestUrl, {})
  }
}

export const getVaccineListSearch = (page) => (dispatch, getState) => {
  const filter = getState().vaccine.filter;
  const requestUrl = `${API_URL}/find?pageSize=${PAGE_SIZE}&page=${page}`;
  dispatch({
    type: ACTION_TYPES.FETCH_VACCINE_LIST,
    payload: axios.post(requestUrl, {
      text: checkParam(filter.text)
    })
  });
};

export const updateFilter = (params) => async (dispatch, getState) => {
  await dispatch({
    type: ACTION_TYPES.UPDATE_FILTER,
    filter: {
      ...params
    }
  });
  dispatch(getVaccineListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
  const activePage = getState().vaccine.activePage;
  dispatch(getVaccineListSearch(activePage + 1))
};

export const resetFilter = () => {
  return {
    type: ACTION_TYPES.RESET_FILTER
  };
};

export const getVaccine = (id) => {
  const requestUrl = `${API_URL}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_VACCINE,
    payload: axios.get(requestUrl)
  };
};


export const createVaccine = (dataRequest) => {
  const requestUrl = `${API_URL}`;
  return {
    type: ACTION_TYPES.CREATE_VACCINE,
    payload: axios.post(requestUrl, {
      ...dataRequest
    })
  };
};

export const updateVaccine = (dataRequest, id) => {
  const requestUrl = `${API_URL}/${id}`;
  return {
    type: ACTION_TYPES.UPDATE_VACCINE,
    payload: axios.put(requestUrl, {
      ...dataRequest
    })
  };
};

export const resetVaccineList = () => {
  return {
    type: ACTION_TYPES.RESET_VACCINE_LIST
  };
};

export const resetVaccine = () => {
  return {
    type: ACTION_TYPES.RESET_VACCINE
  };
};

export const reset = () => {
  return {
    type: ACTION_TYPES.RESET
  };
};
