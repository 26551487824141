import React from 'react';
import { Tag, TagCloseButton } from '@chakra-ui/react';

export const UITag = ({ children, isSelected, onClickTagClose = null, ...props}) => {
    const handleTagButton = (e) => {
        e.stopPropagation();
        onClickTagClose();
    };
    return (
        <Tag
            size="md"
            colorScheme={isSelected ? 'blue' : 'gray'}
            marginRight={2}
            marginBottom={2}
            cursor="pointer"
            transition="0.3s"
            {...props}
            _hover={{
                opacity: 0.8
            }}
        >
            {children}
            {onClickTagClose && (
                <TagCloseButton type="button" marginLeft={1} onClick={handleTagButton}/>
            )}
        </Tag>
    );
};
