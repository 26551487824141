import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import TestTypeList from './TestTypeList'

const Routes = () => {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.url}/list/:params?`}>
        <TestTypeList />
      </Route>
    </Switch>
  )
}

export default Routes;
