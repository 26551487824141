import React from 'react';
import { Flex, useColorModeValue } from '@chakra-ui/react';

export const FilterHeader = ({ children, ...props }) => {
  const themeBg = useColorModeValue('white', 'gray.800');
  const themeBorderColor = useColorModeValue('gray.100', 'gray.700');
  return (
    <Flex
      width="100%"
      flexDirection="column"
      zIndex={2}
      pt="30px"
      pb="10px"
      alignItems="center"
      justifyContent="center"
      borderColor={themeBorderColor}
      backgroundColor={themeBg}
      pl="26px"
      pr="26px"
      {...props}
    >
      {children}
    </Flex>
  );
};
