import React from 'react'
import { Box } from '@chakra-ui/react'
import { FormFooter } from './FormFooter'

export const Form = ({ dataForm, footer, children, onSubmit, ...props }) => {
  const isError = dataForm ? !dataForm.isValid && dataForm.submitCount > 0 : false

  return (
    <form onSubmit={onSubmit}>
      <Box {...props}>{children}</Box>
      {footer && <FormFooter children={footer} isError={isError} />}
    </form>
  )
}
