export const testReports = {
  full: 'full',
  byUser: 'byUser',
}

export const testReportsUrl = {
  [testReports.full]: '/adminapi/reports/full',
  [testReports.byUser]: '/adminapi/reports/laboratory_user',
}

export const testReportsTranslatePath = {
  [testReports.full]: 'reports.heading.full',
  [testReports.byUser]: 'reports.heading.laboratoryUser',
}