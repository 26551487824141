import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { FormSampleList } from './FormSampleList/FormSampleList';
import { FormSampleUpdate } from './FormSampleUpdate/FormSampleUpdate';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <FormSampleUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <FormSampleUpdate/>
      </Route>
      <Route path={`${match.url}`}>
        <FormSampleList/>
      </Route>
    </Switch>
  );
};

export default Routes;
