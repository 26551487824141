import React, { useContext } from 'react';
import { IconList } from '../../../../components/icons';
import {
  Box,
  Button,
  IconButton,
  ModalBody,
  ModalFooter,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useDisclosure, useToast
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@chakra-ui/icons';
import { Field, Formik } from 'formik';
import { FormSection } from '../../../../components/deprecated/layout';
import { UIInputForm } from '../../../../components/deprecated/input';
import { TranslationContext } from '../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import { SnippetsItem, TabPanel, Tab } from '../../../../chakra-lib';
import * as Yup from 'yup';
import { ItemBox } from '../../../../chakra-lib/Snippets/items/ItemBox';
import { ItemIcon, ItemLink } from '../../../../chakra-lib/Snippets';
import { ModalForm } from '../../../../components/deprecated/modal';
import {
  createInformation,
  deleteInformation,
  downInformation,
  setSettingsInformation,
  upInformation
} from '../../../../store/settings-information.reducer';

const validationSchema = Yup.object().shape({
  titleRu: Yup.string().required('Required'),
  titleEn: Yup.string().required('Required'),
  valueRu: Yup.string().required('Required'),
  valueEn: Yup.string().required('Required'),
});

export const InformationUpdate = ({ item, index, length }) => {
  const {getTranslation} = useContext(TranslationContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.settingsInformation.loading);

  const handleDeleteButton = (e) => {
    e.stopPropagation();
    dispatch(deleteInformation(item.id));
  }

  const handleUpButton = (e) => {
    e.stopPropagation();
    dispatch(upInformation(item.id))
  }

  const handleDownButton = (e) => {
    e.stopPropagation();
    dispatch(downInformation(item.id))
  }

  const handleForm = async (values, actions) => {
    const dataRequest = {
      ...item,
      title: {
        ru: values.titleRu,
        en: values.titleEn,
      },
      value: {
        ru: values.valueRu,
        en: values.valueEn,
      }
    };
    if (item) {
      await dispatch(setSettingsInformation(dataRequest, item.id));
      toast({
        title: getTranslation('settingInformation.toast.update.title'),
        description: `${getTranslation('settingInformation.toast.update.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose();
    } else {
      await dispatch(createInformation(dataRequest));
      toast({
        title: getTranslation('settingInformation.toast.create.title'),
        description: `${getTranslation('settingInformation.toast.create.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      onClose();
    }
  };
  return (
    <>
      {item ? (
        <SnippetsItem onClick={onOpen}>
          <ItemIcon icon={IconList}/>
          <ItemLink>
            <Box>{item.title?.ru}</Box>
            <Text fontSize="0.76rem" color="gray.400">
              {item.title?.en}
            </Text>
          </ItemLink>
          <ItemBox>
            <IconButton
              aria-label="Menu"
              mr="10px"
              icon={<ChevronDownIcon/>}
              variant="outline"
              size="sm"
              isRound={true}
              onClick={handleDownButton}
              isDisabled={index === length - 1}
            />
            <IconButton
              aria-label="Menu"
              mr="10px"
              icon={<ChevronUpIcon/>}
              variant="outline"
              size="sm"
              isRound={true}
              onClick={handleUpButton}
              isDisabled={index === 0}
            />
            <IconButton
              aria-label="Menu"
              icon={<DeleteIcon/>}
              variant="outline"
              size="sm"
              isRound={true}
              fontSize="0.76rem"
              onClick={handleDeleteButton}
              isDisabled={typeof item['key'] !== 'undefined'}
            />
          </ItemBox>
        </SnippetsItem>
      ) : (
        <Button
          onClick={onOpen}
          colorScheme="blue"
          width="100%"
        >
          {getTranslation('test.action.buttonCreate')}
        </Button>
      )}
      <ModalForm
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        heading={getTranslation('settingInformation.heading.show')}
      >
        <Formik
          initialValues={{
            titleRu: item?.title?.ru ? item.title.ru : 'Поле',
            titleEn: item?.title?.en ? item.title.en : 'Field',
            valueRu: item?.value?.ru ? item.value.ru : 'Значение',
            valueEn: item?.value?.en ? item.value.en : 'Value'
          }}
          onSubmit={handleForm}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Tabs variant="soft-rounded" size="sm">
                  <TabList>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inRussian')}
                    </Tab>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inEnglish')}
                    </Tab>
                  </TabList>
                  <TabPanels mt={2}>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          autoFocus
                          name="titleRu"
                          component={UIInputForm}
                          placeholder={getTranslation('settingInformation.field.titleRu.placeholder')}
                        />
                        <Field
                          name="valueRu"
                          component={UIInputForm}
                          isDisabled={loading}
                          placeholder={getTranslation('settingInformation.field.valueRu.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          autoFocus
                          name="titleEn"
                          component={UIInputForm}
                          placeholder={getTranslation('settingInformation.field.titleEn.placeholder')}
                        />
                        <Field
                          name="valueEn"
                          component={UIInputForm}
                          isDisabled={loading}
                          placeholder={getTranslation('settingInformation.field.valueEn.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </ModalBody>
              <ModalFooter justifyContent="flex-start">
                <Button
                  colorScheme="blue"
                  type="submit"
                  isLoading={loading}
                >
                  {item ? getTranslation('global.update') : getTranslation('global.create')}
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  );
};
