import { Icon } from '@chakra-ui/react';
import React from 'react';

export const IconPersonLines = (props) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g fill="currentColor">
        <path
          fill="currentColor"
          clipRule="evenodd"
          fillRule="evenodd"
          d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7 1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm2 9a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"
        />
      </g>
    </Icon>
  );
};
