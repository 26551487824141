import React, { useContext, useEffect } from 'react';
import {
  Button,
  ModalBody,
  useDisclosure, ModalFooter, TabList, TabPanels, Tabs,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { FormSection } from '../../../../../../components/deprecated/layout';
import { ModalForm } from '../../../../../../components/deprecated/modal';
import { TranslationContext } from '../../../../../../translation';
import { CheckboxForm, Tab, TabPanel } from '../../../../../../chakra-lib';
import { useTopBannerFetch } from './useTopBannerFetch';
import { UIInputForm } from '../../../../../../components/deprecated/input';
import { UITextareaForm } from '../../../../../../components/textarea';
import { useTopBannerUpdate } from './useTopBannerUpdate';

export const SettingsTopBannerMemo = () => {
  const {getTranslation} = useContext(TranslationContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const { dataFetch, isLoadingFetch, requestFetch } = useTopBannerFetch();
  const { isLoadingUpdate, isSuccessUpdate, requestUpdate } = useTopBannerUpdate();

  const isLoading = isLoadingFetch || isLoadingUpdate;

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (isOpen) {
      requestFetch();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccessUpdate) {
      requestFetch();
      onClose();
    }
  }, [isSuccessUpdate]);

  const handleForm = async (values, actions) => {
    requestUpdate(
      {
        key: 'top_page_banner',
        value: {
          text: {
            en: values.textEn,
            ru: values.textRu
          },
          title: {
            en: values.titleEn,
            ru: values.titleRu
          },
          enabled: values.enabled
        }
      }
    );
  };
  return (
    <>
      <Button
        width="100%"
        onClick={onOpen}
      >
        Верхний баннер
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={onClose}
        heading="Верхний баннер"
      >
        <Formik
          initialValues={{
            enabled: dataFetch?.enabled,
            textRu: dataFetch?.text?.ru,
            textEn: dataFetch?.text?.en,
            titleRu: dataFetch?.title?.ru,
            titleEn: dataFetch?.title?.en
          }}
          onSubmit={handleForm}
          enableReinitialize={true}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Tabs variant="soft-rounded" size="sm">
                  <TabList>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inRussian')}
                    </Tab>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inEnglish')}
                    </Tab>
                  </TabList>
                  <TabPanels mt={2}>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          autoFocus
                          name="titleRu"
                          component={UIInputForm}
                          placeholder={getTranslation('settingInformation.field.titleRu.placeholder')}
                        />
                        <Field
                          name="textRu"
                          minHeight={260}
                          component={UITextareaForm}
                          isDisabled={isLoading}
                          placeholder={getTranslation('settingInformation.field.valueRu.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          autoFocus
                          name="titleEn"
                          component={UIInputForm}
                          placeholder={getTranslation('settingInformation.field.titleEn.placeholder')}
                        />
                        <Field
                          name="textEn"
                          minHeight={260}
                          component={UITextareaForm}
                          isDisabled={isLoading}
                          placeholder={getTranslation('settingInformation.field.valueEn.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <Field
                  name="enabled"
                  component={CheckboxForm}
                  label="Активно"
                />
              </ModalBody>
              <ModalFooter justifyContent="flex-start">
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!props.dirty}
                  isLoading={isLoading}
                >
                  {getTranslation('global.update')}
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  );
};

export const SettingsTopBanner = React.memo(SettingsTopBannerMemo);
