import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CountryList } from './CountryList/CountryList';
import { CountryUpdate } from './CountryUpdate/CountryUpdate';
const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <CountryUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <CountryUpdate/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <CountryList/>
      </Route>
    </Switch>
  );
};

export default Routes;
