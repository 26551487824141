import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import QRCode from 'qrcode.react';
import Certificate1 from '../../images/certificate-1.jpg';

export class VaccinationCertificatePage1 extends React.Component {
  render() {
    return (
      <Box
        width="1000px"
        height="1060px"
        color="#000000"
        fontSize="0.7rem"
        //backgroundImage={`url(${Certificate1})`}
        position="relative"
        flex="none"
        //transform="scale(0.6)"
      >
        <Image
          src={Certificate1}
          width="1000px"
          height='auto'
          position="absolute"
          zIndex={0}
          left={0}
          top={0}
        />
        <Box
          color="#ffffff"
          //backgroundColor="#000000"
          position="absolute"
          right="52px"
          top="368px"
          width="405px"
          height="66px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="center"
          zIndex={2}
        >
          {this.props.medicalFacilityTitle}
        </Box>
        <Box
          position="absolute"
          zIndex={2}
          fontSize="16px"
          textAlign="center"
          color="#fff"
          right="30px"
          bottom="304px"
          width="460px"
        >
          “QR код вакцинации для мобильного приложения системы “Путешествую без COVID-19”
          <Text fontSize="12px">“QR code of vaccination for the mobile application of the system“ Traveling without COVID-19 ”</Text>
        </Box>
        <Box
          backgroundColor="#ffffff"
          borderRadius="14px"
          padding="20px"
          position="absolute"
          right="140px"
          bottom="50px"
          zIndex={2}
        >
          <QRCode
            size={200}
            value={this.props.id ? `https://app.nocovid.su/vaccination/by/${this.props.id}` : ''}
          />
        </Box>
      </Box>
    );
  }
}
