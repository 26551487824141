import React from 'react'
import { Text as ChakraText, Tooltip } from '@chakra-ui/react'

const Text = React.forwardRef((props, ref) => {
  if (props.isTruncated) {
    return (
      <Tooltip label={props.children}>
        <ChakraText ref={ref} {...props} />
      </Tooltip>
    )
  }

  return <ChakraText ref={ref} {...props} />
})

export default Text