import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrintTest } from './PrintTest';
import { isEmpty } from '../../components/deprecated/utilities/is-empty';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingSettings } from '../../components/deprecated/layout';
import { getCountry, getSettingsLaboratory } from '../../store/settings.reducer';
import { Login } from '../../login';

const Routes = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const userAuth = useSelector(state => state.authentication.user);
    const settingsLaboratory = useSelector(state => state.settings.settingsLaboratory);
    const testValidHours = useSelector(state => state.settings.testValidHours);
    const country = useSelector(state => state.settings.country);
    const isAuthenticated = !isEmpty(userAuth);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getSettingsLaboratory());
            dispatch(getCountry());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }, []);

    return (
        <>
            {isAuthenticated ? (
                !isEmpty(settingsLaboratory) && testValidHours && country ? (
                    <Switch>
                        <Route path={`${match.url}/:id`}>
                            <PrintTest/>
                        </Route>
                    </Switch>
                ) : (
                    <LoadingSettings/>
                )
            ) : (
                <Login/>
            )}
        </>
    );
};

export default Routes;
