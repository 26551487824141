import React from 'react';
import { Box } from '@chakra-ui/react';

export const ContentContainerColumn = ({ children }) => {
  return (
    <Box>
      {children}
    </Box>
  );
}
