import { makeColumns } from './LaboratoryList.config'
import { LABORATORY_FIELDS } from '../Laboratory.constants'

export const makeExcelColumns = ({ getTranslation, hiddenColumns }) => {
  const tableColumns = makeColumns({ getTranslation })

  const TYPE_DISABLED = {
    true: getTranslation('disabled.true'),
    false: getTranslation('disabled.false'),
  }

  return tableColumns
    .map((item) => ({
      key: item.id,
      accessor: item.accessor,
      header: item.Header,
    }))
    .map((item) => {
      if (item.key === LABORATORY_FIELDS.state) {
        return {
          ...item,
          accessor: ({ disabled }) => TYPE_DISABLED[disabled],
        }
      }
      return item
    })
    .filter((item) => item.header)
    .filter((item) => !hiddenColumns.includes(item.key))
}


