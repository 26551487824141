import React from 'react';
import { getDateReadFormat, twoDigits } from '../deprecated/utilities/date-formatting';
import { Text } from '@chakra-ui/react';

const getDateTimePeriod = (value) => {
    const date = getDateReadFormat(value.date);
    const timeValue = value.workTimes[0];
    const fromHours = timeValue ? twoDigits(timeValue.fromHours) : null;
    const fromMinutes = timeValue ? twoDigits(timeValue.fromMinutes) : null;
    const toHours = timeValue ? twoDigits(timeValue.toHours) : null;
    const toMinutes = timeValue ? twoDigits(timeValue.toMinutes) : null;
    return `${date}: ${fromHours}:${fromMinutes} - ${toHours}:${toMinutes}`;
}

export const SpecialDate = ({ value }) => {
    return (
        <Text fontSize="0.96rem">{getDateTimePeriod(value)}</Text>
    );
};
