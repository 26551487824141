import React from 'react';
import {
    FormLabel,
    Input,
    FormControl,
    InputGroup,
    InputLeftAddon, Box
} from '@chakra-ui/react';
import MaskedInput from 'react-text-mask';

export const UIInput = React.forwardRef((
{
    name,
    isInvalid = null,
    leftAddon = null,
    label = null,
    mask = null,
    placeholder = null,
    value,
    size = 'md',
    textAlign = 'left',
    ...props
}, ref) => {
    return (
        <FormControl isInvalid={isInvalid}>
            {label && (
                <FormLabel
                    htmlFor={name}
                    fontSize="0.84rem"
                    width="100%"
                    mb="6px"
                >
                    {label}
                </FormLabel>
            )}
            <InputGroup size={size}>
                {leftAddon && (
                    <InputLeftAddon children={leftAddon} />
                )}
                {mask ? (
                    <MaskedInput
                        mask={mask}
                        // guide={false}
                        placeholder={placeholder}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        value={value || ''}
                        {...props}
                        render={(ref, props) => (
                            <Input
                                ref={ref}
                                id={name}
                                size={size}
                                textAlign={textAlign}
                                {...props}
                            />
                        )}
                    />
                ) : (
                    <Input
                        ref={ref}
                        id={name}
                        placeholder={placeholder}
                        size={size}
                        textAlign={textAlign}
                        value={value || ''}
                        {...props}
                    />
                )}
            </InputGroup>
            {props.description && (
                <Box fontSize="0.7rem" color="gray.400" mt={2}>
                    {props.description}
                </Box>
            )}
        </FormControl>
    );
});

