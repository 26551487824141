import React, { useContext } from 'react';
import { WorkScheduleViewItem } from './work-schedule-view-item';
import { TYPE_DAYS } from '../../model/days';
import { Flex, Grid, Box, Spinner, Text } from '@chakra-ui/react';
import { SpecialDate } from './special-date';
import { TranslationContext } from '../../translation';

export const WorkScheduleView = ({ value = {}, isLoading }) => {
    const { getTranslation } = useContext(TranslationContext);
    return (
        !isLoading ? (
            <>
                <Text fontSize="0.9rem" color="gray.400" marginBottom="10px">
                    {getTranslation('workSchedule.heading.schedule')}
                </Text>
                <Grid gridRowGap={3}>
                    {value ? Boolean(value.weekdays.length) && value.weekdays.map((item, index) => (
                        <WorkScheduleViewItem
                            key={index}
                            value={item}
                            label={TYPE_DAYS[index]}
                        />
                    )) : (
                        <Box>{getTranslation('global.noData')}</Box>
                    )}
                </Grid>
                {value && Boolean(value.specialDates.length) && (
                    <Box>
                        <Text
                            fontSize="0.9rem"
                            color="gray.400"
                            marginBottom="8px"
                            marginTop="20px"
                        >
                            {getTranslation('workSchedule.heading.holidays')}
                        </Text>
                        {value.specialDates.map((item, index) => (
                            <SpecialDate
                                key={index}
                                value={item}
                            />
                        ))}
                    </Box>
                )}
            </>
        ) : (
            <Flex height="80px" align="center" justify="center">
                <Spinner color="blue.500" size="sm" marginLeft="16px"/>
            </Flex>
        )
    );
};
