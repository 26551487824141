import {
    laboratorySchemaAm,
    laboratorySchemaRu,
    officeSchemaAm,
    officeSchemaRu,
    officeSchemaBy,
    laboratorySchemaBy,
    testAddPassport,
    testNewSchema
} from '../components/deprecated/validation-schema';

export const TYPE_LABORATORY_SCHEMA = {
    ru: laboratorySchemaRu,
    am: laboratorySchemaAm,
    by: laboratorySchemaBy
}

export const TYPE_OFFICE_SCHEMA = {
    ru: officeSchemaRu,
    am: officeSchemaAm,
    by: officeSchemaBy
}

export const TYPE_NEW_TEST_SCHEMA = {
    ru: testNewSchema,
    am: testNewSchema,
    by: testNewSchema
}

export const TYPE_ADD_PASSPORT_SCHEMA = {
    ru: testAddPassport,
    am: testAddPassport,
    by: testAddPassport
}
