import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { getNextPage, getTestListSearch, resetFilter, resetTestList } from 'store/test.reducer'
import { useTestResult } from 'components/test-result'
import { getLaboratoryList, resetLaboratoryList } from 'store/laboratory.reducer'
import { exportExcelFile } from 'utils/exportExcelFile'
import Register from 'components/layout/Register'
import { TestListFilter } from './elements/TestListFilter'
import { STORAGE_TABLE } from 'model/localStorage'
import { TEST_FIELDS } from 'pages/laboratory/Test/Test.constants'
import { makeExcelColumns } from './TestList.excel'
import { makeColumns } from './TestList.config'

export const TestList = () => {
  const history = useHistory()
  const tableRef = useRef()
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const { getResult } = useTestResult()
  const items = useSelector((state) => state.test.items)
  const activePage = useSelector((state) => state.test.activePage)
  const totalPages = useSelector((state) => state.test.totalPages)
  const loading = useSelector((state) => state.test.loading)
  const userAuth = useSelector((state) => state.authentication.user)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getLaboratoryList())
    dispatch(getTestListSearch())
    return () => {
      dispatch(resetFilter())
      dispatch(resetLaboratoryList())
      dispatch(resetTestList())
    }
  }, [])

  const exportExcelHandle = useCallback(() => {
    const hiddenColumns = tableRef.current.state.hiddenColumns

    exportExcelFile(items, {
      sheetName: 'Tests',
      columns: makeExcelColumns({ getTranslation, getResult, hiddenColumns }),
    })
  }, [items])

  const onRowClick = useCallback(({ original }) => {
    history.push(`/national/test/show/${original.id}`)
  }, [])

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        TEST_FIELDS.passport,
        TEST_FIELDS.officeTitleRu,
        TEST_FIELDS.officeTitleEn,
        TEST_FIELDS.officeAddress,
        TEST_FIELDS.resultSetDate,
        TEST_FIELDS.resultDate,
        TEST_FIELDS.createdAt,
      ],
    }),
    []
  )

  const columns = useMemo(() => makeColumns({ getTranslation }), [])

  return (
    <Register rightPanel={<TestListFilter onExportExcel={exportExcelHandle} />}>
      <Table
        ref={tableRef}
        data={items}
        columns={columns}
        isLoading={loading}
        hasNext={activePage < totalPages}
        onNextPage={() => dispatch(getNextPage())}
        onRowClick={onRowClick}
        initialState={initialState}
        storageId={`${STORAGE_TABLE.laboratoryTest}.${userAuth.id}`}
      />
    </Register>
  )
}
