import { api } from '../../../../api';
import { useQuery } from '../../../../components/useQuery';
import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';

const usePushFetchItem = (id) => {
  const toast = useToast();
  const {
    isError,
    isLoading,
    response,
    request
  } = useQuery(() => api.push.fetchItem(id))

  useEffect(() => {
    if (id) {
      request()
    }
  }, [id])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  return {
    data: response?.data,
    fetchItem: request,
    isLoading
  }
}

export default usePushFetchItem;
