import React, { memo, useContext, useEffect, useMemo } from 'react';

import { Field, Formik } from 'formik';
import { TranslationContext } from 'translation';
import { useConfigUpdate, useConfigFetch } from './hooks';
import { UISwitchForm } from "components/deprecated/switch";
import { Box, Button, ModalFooter } from "@chakra-ui/react";

export const ConfigPanel = ({ configUpdated }) => {
  const { getTranslation } = useContext(TranslationContext);
  const { configData, configIsLoading, fetchConfigSettings } = useConfigFetch();
  const { configIsUpdating, configIsUpdated, updateConfig } = useConfigUpdate();

  useEffect(() => {
    fetchConfigSettings()
  }, [fetchConfigSettings])

  useEffect(() => {
    if (configIsUpdated) {
      configUpdated()
    }
  }, [configIsUpdated, configUpdated])

  const handleForm = async ({ faqIsEnable }) => {
    updateConfig(faqIsEnable)
  };

  const initialValues = useMemo(() => ({
    faqIsEnable: configData.faqIsEnable,
  }), [configData])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleForm}
      enableReinitialize={true}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <Box minH="100px" px="24px" pt="10px">
            <Field
              name="faqIsEnable"
              component={UISwitchForm}
              isDisabled={configIsLoading}
              label="Справочник FAQ виден"
            />
          </Box>
          <ModalFooter justifyContent="flex-start">
            <Button
              colorScheme="blue"
              type="submit"
              isDisabled={!props.dirty || configIsLoading}
              isLoading={configIsUpdating}
            >
              {getTranslation('global.update')}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Formik>
  );
};

export default memo(ConfigPanel)