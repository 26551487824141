import React, { useContext, useEffect } from 'react';
import {
  Button,
  ModalBody,
  useDisclosure, ModalFooter, TabList, TabPanels, Tabs, Box, InputGroup, Input, InputRightElement,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { FormSection } from '../../../../../../components/deprecated/layout';
import { ModalForm } from '../../../../../../components/deprecated/modal';
import { TranslationContext } from '../../../../../../translation';
import { CheckboxForm, FormLabel, Tab, TabPanel } from '../../../../../../chakra-lib';
import { UIInputForm } from '../../../../../../components/deprecated/input';
import { UITextareaForm } from '../../../../../../components/textarea';
import { useAttentionMessageFetch } from './useAttentionMessageFetch';
import { useAttentionMessageUpdate } from './useAttentionMessageUpdate';

export const SettingsAttentionMessageMemo = () => {
  const {getTranslation} = useContext(TranslationContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const { dataFetch, isLoadingFetch, requestFetch } = useAttentionMessageFetch();
  const { isLoadingUpdate, isSuccessUpdate, requestUpdate } = useAttentionMessageUpdate();
  const itemVersion = dataFetch?.version !== undefined ? dataFetch.version : 0;

  const isLoading = isLoadingFetch || isLoadingUpdate;

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (isOpen) {
      requestFetch();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccessUpdate) {
      requestFetch();
      onClose();
    }
  }, [isSuccessUpdate]);

  const handleForm = async (values, actions) => {
    requestUpdate(
      {
        key: 'attention_message',
        value: {
          text: {
            en: values.textEn,
            ru: values.textRu
          },
          title: {
            en: values.titleEn,
            ru: values.titleRu
          },
          enabled: values.enabled,
          version: values.version,
          notifications_count: Number(values.notificationsCount)
        }
      }
    );
  };
  return (
    <>
      <Button
        width="100%"
        onClick={onOpen}
      >
        Важное сообщение
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={onClose}
        heading="Важное сообщение"
      >
        <Formik
          initialValues={{
            enabled: dataFetch?.enabled,
            textRu: dataFetch?.text?.ru,
            textEn: dataFetch?.text?.en,
            titleRu: dataFetch?.title?.ru,
            titleEn: dataFetch?.title?.en,
            version: itemVersion,
            notificationsCount: dataFetch.notifications_count || '0'
          }}
          onSubmit={handleForm}
          enableReinitialize={true}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Tabs variant="soft-rounded" size="sm">
                  <TabList>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inRussian')}
                    </Tab>
                    <Tab padding="0.4rem 0.8rem" mr={1}>
                      {getTranslation('settingInformation.tabs.description.inEnglish')}
                    </Tab>
                  </TabList>
                  <TabPanels mt={2}>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          autoFocus
                          name="titleRu"
                          component={UIInputForm}
                          placeholder={getTranslation('settingInformation.field.titleRu.placeholder')}
                        />
                        <Field
                          name="textRu"
                          minHeight={260}
                          component={UITextareaForm}
                          isDisabled={isLoading}
                          placeholder={getTranslation('settingInformation.field.valueRu.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                    <TabPanel>
                      <FormSection mb={0}>
                        <Field
                          autoFocus
                          name="titleEn"
                          component={UIInputForm}
                          placeholder={getTranslation('settingInformation.field.titleEn.placeholder')}
                        />
                        <Field
                          name="textEn"
                          minHeight={260}
                          component={UITextareaForm}
                          isDisabled={isLoading}
                          placeholder={getTranslation('settingInformation.field.valueEn.placeholder')}
                        />
                      </FormSection>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
               <FormSection>
                 <Field
                   name="enabled"
                   component={CheckboxForm}
                   label="Активно"
                 />
                 <Box>
                   <FormLabel>Версия</FormLabel>
                   <InputGroup size="md">
                     <Input
                       pr="4.5rem"
                       isReadOnly
                       value={String(props.values.version)}
                     />
                     <InputRightElement width="7rem">
                       <Button
                         h="1.75rem"
                         size="sm"
                         disabled={props.values.version !== itemVersion}
                         onClick={() => {
                           props.setFieldValue(`version`, props.values.version + 1);
                         }}
                       >
                         Увеличить
                       </Button>
                     </InputRightElement>
                   </InputGroup>
                 </Box>
                 <Field
                   type="number"
                   name="notificationsCount"
                   component={UIInputForm}
                   isDisabled={isLoading}
                   label="Количество показов"
                   placeholder=""
                 />
               </FormSection>
              </ModalBody>
              <ModalFooter justifyContent="flex-start">
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!props.dirty}
                  isLoading={isLoading}
                >
                  {getTranslation('global.update')}
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  );
};

export const SettingsAttentionMessage = React.memo(SettingsAttentionMessageMemo);
