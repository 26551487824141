import React, { useContext, useEffect } from 'react'
import {
  Container,
  FilterHeader,
  FilterStack,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { FormLabel, Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { IconList } from 'components/icons'
import { TranslationContext } from 'translation'
import { Box, Button, Text } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { PushEnabled } from './elements/PushEnabled'
import { pushStatusColor } from '../constants/pushStatusColor'
import { pushStatusText } from '../constants/pushStatusText'
import { getDateTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import { usePushList } from "./_services";

export const PushList = () => {
  const history = useHistory()
  const { getTranslation } = useContext(TranslationContext)
  const { request, response, next, isLoading } = usePushList()

  useEffect(() => {
    request()
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(200px, 1fr) 320px 220px 160px">
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>Содержание</HeadingItem>
              <HeadingItem>Заголовок</HeadingItem>
              <HeadingItem>Дата отправки</HeadingItem>
              <HeadingItem>Статус</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={response?.currentPage}
              totalPages={response?.totalPages}
              data={response?.list}
              isLoading={isLoading}
              onNext={next}
            >
              {(item) => (
                <SnippetsItem to={`/integration/push/show/${item.id}`}>
                  <ItemIcon icon={IconList} />
                  <ItemLink>
                    <Box>{item.body?.ru}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {item.body?.en}
                    </Text>
                  </ItemLink>
                  <ItemText>
                    <Box>{item.title?.ru}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {item.title?.en}
                    </Text>
                  </ItemText>
                  <ItemText>{getDateTimeReadFormat(item?.sentAt)}</ItemText>
                  <ItemText>
                    <ItemBadge colorScheme={pushStatusColor[item.status]}>
                      {pushStatusText[item.status]}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/integration/push/new`)}
              colorScheme="blue"
            >
              {getTranslation('test.action.buttonCreate')}
            </Button>
          </FilterHeader>
          <FilterStack>
            <Box>
              <FormLabel>Настройки</FormLabel>
              <PushEnabled />
            </Box>
          </FilterStack>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
