import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { v4 as uuidv4 } from 'uuid';

export const ACTION_TYPES = {
    FETCH_SETTINGS_INFORMATION: 'settings-information/FETCH_SETTINGS_INFORMATION',
    UPDATE_SETTINGS_INFORMATION: 'settings-information/UPDATE_SETTINGS_INFORMATION',
    RESET: 'settings-information/RESET'
};

const initialState = {
    items: [],
    loading: false
};

export const settingsInformationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_SETTINGS_INFORMATION):
        case REQUEST(ACTION_TYPES.UPDATE_SETTINGS_INFORMATION): {
            return {
                ...state,
                loading: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_SETTINGS_INFORMATION):
        case FAILURE(ACTION_TYPES.UPDATE_SETTINGS_INFORMATION): {
            return {
                ...state,
                loading: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_SETTINGS_INFORMATION): {
            return {
                ...state,
                loading: false,
                items: [
                    ...action.payload.data.map(item => ({
                        ...item,
                        id: uuidv4()
                    }))
                ]
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_SETTINGS_INFORMATION): {
            return {
                ...state,
                loading: false,
                items: [
                    ...action.payload.data.value.map(item => ({
                        ...item,
                        id: uuidv4()
                    }))
                ]
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                loading: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}`;

const clearRequest = (data) => {
    return data.map(item => ({
        title: item.title,
        value: item.value,
        ...item.key && {key: item.key}
    }))
}

export const getSettingsInformation = () => {
    const requestUrl = `${API_URL}/settings/app_information`;
    return {
        type: ACTION_TYPES.FETCH_SETTINGS_INFORMATION,
        payload: axios.get(requestUrl)
    };
};

export const setSettingsInformation = (dataRequest, id) => (dispatch, getState) => {
    const { items } = getState().settingsInformation;
    const requestUrl = `${API_URL}/settings`;
    const index = items.findIndex(item => item.id === id);
    const newItems = [ ...items]
    newItems[index] = { ...dataRequest }
    const newRequest = {
        key: 'app_information',
        value: [...clearRequest(newItems)]
    }
    dispatch ({
        type: ACTION_TYPES.UPDATE_SETTINGS_INFORMATION,
        payload: axios.post(requestUrl, {
            ...newRequest
        })
    });
};

export const createInformation = (dataRequest) => (dispatch, getState) => {
    const { items } = getState().settingsInformation;
    const requestUrl = `${API_URL}/settings`;
    const newItems = [
        ...items,
        dataRequest
    ];
    const newRequest = {
        key: 'app_information',
        value: [...clearRequest(newItems)]
    }
    dispatch ({
        type: ACTION_TYPES.UPDATE_SETTINGS_INFORMATION,
        payload: axios.post(requestUrl, {
            ...newRequest
        })
    });
};

export const deleteInformation = (id) => (dispatch, getState) => {
    const { items } = getState().settingsInformation;
    const requestUrl = `${API_URL}/settings`;
    const newItems = items.filter(item => item.id !== id);
    const newRequest = {
        key: 'app_information',
        value: [...clearRequest(newItems)]
    }
    dispatch ({
        type: ACTION_TYPES.UPDATE_SETTINGS_INFORMATION,
        payload: axios.post(requestUrl, {
            ...newRequest
        })
    });
};

export const upInformation = (id) => (dispatch, getState) => {
    const { items } = getState().settingsInformation;
    const requestUrl = `${API_URL}/settings`;
    const index = items.findIndex(item => item.id === id);
    const newItems = [ ...items];
    [newItems[index], newItems[index - 1]] = [newItems[index - 1], newItems[index]];
    if (index !== 0) {
        const newRequest = {
            key: 'app_information',
            value: [...clearRequest(newItems)]
        }
        dispatch ({
            type: ACTION_TYPES.UPDATE_SETTINGS_INFORMATION,
            payload: axios.post(requestUrl, {
                ...newRequest
            })
        });
    }
};

export const downInformation = (id) => (dispatch, getState) => {
    const { items } = getState().settingsInformation;
    const requestUrl = `${API_URL}/settings`;
    const index = items.findIndex(item => item.id === id);
    const newItems = [ ...items];
    const length = newItems.length;
    [newItems[index], newItems[index + 1]] = [newItems[index + 1], newItems[index]];

    if (index !== length - 1) {
        const newRequest = {
            key: 'app_information',
            value: [...clearRequest(newItems)]
        }
        dispatch ({
            type: ACTION_TYPES.UPDATE_SETTINGS_INFORMATION,
            payload: axios.post(requestUrl, {
                ...newRequest
            })
        });
    }
};


export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
