import React, { useContext } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Avatar,
  Flex,
  Box,
  FormLabel,
} from '@chakra-ui/react';
import { TranslationContext } from '../../../translation';
import { DropZoneImage } from '../../DropZoneImage';

export const CountrySelectionIcon = ({src = null, label = null, onChange}) => {
  const {getTranslation} = useContext(TranslationContext);
  const handleFlag = (src) => {
    onChange(src);
  }
  return (
    <Box>
      {label && (
        <FormLabel
          fontSize="0.9rem"
          mb={1}
        >
          {label}
        </FormLabel>
      )}
      <Flex alignItems="center" borderRadius={6} borderWidth={1} padding="16px 20px">
        <Avatar
          width="48px"
          height="48px"
          name="F"
          src={src}
          marginRight={6}
        />
        <Popover
          placement="bottom"
          maxWidth="10rem"
        >
          <PopoverTrigger>
            <Button variant="outline">
              {getTranslation('global.choose')}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            ml="16px"
            mr="16px"
            borderRadius={3}
            boxShadow="rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px"
            borderWidth={0}
            maxWidth="200px"
            _focus={{
              outline: 'none'
            }}
          >
            <PopoverBody>
              <DropZoneImage
                maxFiles={1}
                multiple={false}
                uploadedImage={src}
                onUpload={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onloadend = () => {
                    handleFlag(reader.result)
                  };
                }}
                //onError={handleUploadError}
              />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Box>
  );
};
