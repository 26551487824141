import * as Yup from 'yup';
import { isINNLegalEntity, isKPP, isOGTN } from '../validation';

export const officeSchemaRu = Yup.object().shape({
    titleRu: Yup.string().min(5, 'Required'),
    titleEn: Yup.string().min(5, 'Required'),
    shortTitleRu: Yup
        .string()
        .max(20)
        .required('Required')
    ,
    shortTitleEn: Yup
      .string()
      .max(20)
      .required('Required')
    ,
    telephone: Yup.string().required('Required'),
    lat: Yup.string().matches(/\d+\.\d+/g).required('Required'),
    lng: Yup.string().matches(/\d+\.\d+/g).required('Required'),
    addressRu: Yup.string().required('Required'),
    addressEn: Yup.string().required('Required'),
    oid: Yup.string().required('Required'),
    inn: Yup
        .string()
        .test('innValid', 'innValid', value => isINNLegalEntity(value)),
    kpp: Yup
        .string()
        .test('kppValid', 'kppValid', value => isKPP(value)),
    ogrn: Yup
        .string()
        .test('ogtnValid', 'ogtnValid', value => isOGTN(value)),
    ownership: Yup.string(),
    addressLegal: Yup.string(),
    commentRu: Yup.string().max(160),
    commentEn: Yup.string().max(160)
});

export const officeSchemaAm = Yup.object().shape({
    titleRu: Yup.string().min(5, 'Required'),
    titleEn: Yup.string().min(5, 'Required'),
    shortTitleRu: Yup
      .string()
      .max(20)
      .required('Required')
    ,
    shortTitleEn: Yup
      .string()
      .max(20)
      .required('Required')
    ,
    telephone: Yup.string().required('Required'),
    lat: Yup.string().matches(/\d+\.\d+/g).required('Required'),
    lng: Yup.string().matches(/\d+\.\d+/g).required('Required'),
    addressRu: Yup.string().required('Required'),
    addressEn: Yup.string().required('Required'),
    oid: Yup.string().required('Required'),
    commentRu: Yup.string().max(160),
    commentEn: Yup.string().max(160)
});

export const officeSchemaBy = Yup.object().shape({
    titleRu: Yup.string().min(5, 'Required'),
    titleEn: Yup.string().min(5, 'Required'),
    shortTitleRu: Yup
      .string()
      .max(20)
      .required('Required')
    ,
    shortTitleEn: Yup
      .string()
      .max(20)
      .required('Required')
    ,
    telephone: Yup.string().required('Required'),
    lat: Yup.string().matches(/\d+\.\d+/g).required('Required'),
    lng: Yup.string().matches(/\d+\.\d+/g).required('Required'),
    addressRu: Yup.string().required('Required'),
    addressEn: Yup.string().required('Required'),
    oid: Yup.string().required('Required'),
    commentRu: Yup.string().max(160),
    commentEn: Yup.string().max(160)
});
