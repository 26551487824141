import React from 'react';
import { UIInputSearchDropdown } from './input-search-dropdown';

export const UIInputSearchDropdownForm = React.forwardRef((
    {
        field,
        form,
        sizeInput,
        label = null,
        onUpdateQuery = null,
        resultData = null,
        isLoading = false,
        onBlurFormControl = null,
        onBlurInput = null,
        on = null,
        ...props
    }, ref) => {
    return (
        <UIInputSearchDropdown
            onUpdateQuery={onUpdateQuery}
            resultData={resultData}
            isLoading={isLoading}
            name={field.name}
            inputOnChange={value => form.setFieldValue(field.name, value)}
            inputValue={field.value}
            onBlurFormControl={onBlurFormControl}
            onBlurInput={onBlurInput}
            label={label}
            isInvalid={form.errors[field.name] && form.touched[field.name]}
            {...props}
        />
    );
});
