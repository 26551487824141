import React from 'react'
import { Input } from '../Input'

export const InputForm = React.forwardRef(({ field, form, sizeInput, label, ...props }, ref) => {
  const { error, touched } = form.getFieldMeta(field.name)
  return (
    <Input
      name={field.name}
      label={label}
      isInvalid={error && (touched || form.submitCount > 0)}
      {...field}
      {...props}
    />
  )
})
