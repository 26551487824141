import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';

const PAGE_SIZE = 20;
const START_PAGE = 1;

export const ACTION_TYPES = {
    FETCH_MEDICAL_FACILITY_LIST: 'medicalFacility/FETCH_MEDICAL_FACILITY_LIST',
    RESET_MEDICAL_FACILITY_LIST: 'medicalFacility/RESET_MEDICAL_FACILITY_LIST',
    FETCH_MEDICAL_FACILITY: 'medicalFacility/FETCH_MEDICAL_FACILITY',
    CREATE_MEDICAL_FACILITY: 'medicalFacility/CREATE_MEDICAL_FACILITY',
    UPDATE_MEDICAL_FACILITY: 'medicalFacility/UPDATE_MEDICAL_FACILITY',
    UPDATE_FILTER: 'medicalFacility/UPDATE_FILTER',
    RESET_FILTER: 'medicalFacility/RESET_FILTER',
    RESET_MEDICAL_FACILITY: 'medicalFacility/RESET_MEDICAL_FACILITY',
    RESET: 'medicalFacility/RESET'
};

const initialState = {
    items: [],
    item: {},
    loading: false,
    loadingUpdate: false,
    errorMessage: '',
    totalItems: 0,
    totalPages: 0,
    activePage: START_PAGE,
    filter: {
        text: ''
    }
};

export const medicalFacilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_MEDICAL_FACILITY_LIST):
        case REQUEST(ACTION_TYPES.FETCH_MEDICAL_FACILITY):{
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_MEDICAL_FACILITY):
        case REQUEST(ACTION_TYPES.UPDATE_MEDICAL_FACILITY):{
            return {
                ...state,
                errorMessage: '',
                loadingUpdate: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_MEDICAL_FACILITY_LIST): {
            return {
                ...state,
                loading: false,
                loadingUpdate: false
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_MEDICAL_FACILITY):
        case FAILURE(ACTION_TYPES.CREATE_MEDICAL_FACILITY):
        case FAILURE(ACTION_TYPES.UPDATE_MEDICAL_FACILITY):{
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errorMessage: action.payload.response.data,
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_MEDICAL_FACILITY_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loading: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage'],
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_MEDICAL_FACILITY): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                errorMessage: '',
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_MEDICAL_FACILITY): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_MEDICAL_FACILITY): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            }
        }
        case ACTION_TYPES.RESET_MEDICAL_FACILITY_LIST: {
            return {
                ...state,
                items: [],
                loading: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET_MEDICAL_FACILITY: {
            return {
                ...state,
                item: {},
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/medical_facility`;

export const getMedicalFacilityList = () => {
    const requestUrl = `${API_URL}/find?pageSize=${10000}&page=${1}`;
    return {
        type: ACTION_TYPES.FETCH_MEDICAL_FACILITY_LIST,
        payload: axios.post(requestUrl, {})
    }
}

export const getMedicalFacilityListSearch = (page) => (dispatch, getState) => {
    const filter = getState().medicalFacility.filter;
    const requestUrl = `${API_URL}/find?pageSize=${PAGE_SIZE}&page=${page}`;
    dispatch ({
        type: ACTION_TYPES.FETCH_MEDICAL_FACILITY_LIST,
        payload: axios.post(requestUrl, {
            text: checkParam(filter.text)
        })
    });
};

export const updateFilter = (params) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        filter: {
            ...params
        }
    });
    dispatch(getMedicalFacilityListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().medicalFacility.activePage;
    dispatch(getMedicalFacilityListSearch(activePage + 1))
};

export const getMedicalFacility = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_MEDICAL_FACILITY,
        payload: axios.get(requestUrl)
    };
};

export const createMedicalFacility = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_MEDICAL_FACILITY,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateMedicalFacility = (id, dataRequest) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.UPDATE_MEDICAL_FACILITY,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const resetFilter = () => {
    return {
        type: ACTION_TYPES.RESET_FILTER
    };
};

export const resetMedicalFacilityList = () => {
    return {
        type: ACTION_TYPES.RESET_MEDICAL_FACILITY_LIST
    };
};

export const resetMedicalFacility = () => {
    return {
        type: ACTION_TYPES.RESET_MEDICAL_FACILITY
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
