import React, { useContext, useState } from 'react'
import { Button, ModalBody, useDisclosure, Box } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { FormGrid, FormSection } from 'components/deprecated/layout'
import { ModalForm } from 'components/deprecated/modal'
import * as Yup from 'yup'
import { TranslationContext } from 'translation'
import { UIInputDateForm } from 'components/deprecated/input-date'
import { getDateTimeToSubmit } from 'components/deprecated/utilities/date-formatting'
import download from 'downloadjs'
import { UICheckboxForm } from 'components/deprecated/checkbox'
import { SelectForm } from 'chakra-lib'

const formSchema = Yup.object().shape({
  dateFrom: Yup.string().required('Required'),
  dateTo: Yup.string().required('Required'),
})

export const ReportFull = () => {
  const { getTranslation } = useContext(TranslationContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const itemsLaboratory = useSelector((state) => state.laboratory.items)
  const [loading, setLoading] = useState(false)
  const handleForm = async (values) => {
    const dataRequest = {
      laboratories: values.laboratories.length
        ? values.laboratories
        : itemsLaboratory.map((item) => item.id),
      statuses: values.statuses.length ? values.statuses : [null, true, false],
      showOffices: values.showOffices,
      dateFrom: getDateTimeToSubmit(values.dateFrom),
      dateTo: getDateTimeToSubmit(values.dateTo),
    }
    setLoading(true)
    fetch('/adminapi/reports/full', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(dataRequest),
    }).then((res) =>
      res.blob().then((blob) => {
        setLoading(false)
        download(
          blob,
          `${getTranslation('reports.action.reportFull')} ${values.dateFrom}-${values.dateTo}.xlsx`
        )
      })
    )
  }

  const handleCloseModal = () => {
    onClose()
  }

  return (
    <>
      <Button onClick={onOpen} width="100%">
        {getTranslation('reports.action.reportFull')}
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={handleCloseModal}
        heading={getTranslation('reports.heading.full')}
      >
        <Formik
          initialValues={{
            laboratories: [],
            dateFrom: '',
            showOffices: true,
            dateTo: '',
            statuses: [],
          }}
          onSubmit={handleForm}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormSection mb={0}>
                  <FormGrid columns={2}>
                    <Field
                      label={getTranslation('global.dateFrom')}
                      name="dateFrom"
                      component={UIInputDateForm}
                    />
                    <Field
                      label={getTranslation('global.dateTo')}
                      name="dateTo"
                      component={UIInputDateForm}
                    />
                  </FormGrid>
                  <Field
                    name="laboratories"
                    isMulti
                    placeholderColor="#333333"
                    placeholder={getTranslation('global.all')}
                    component={SelectForm}
                    label={getTranslation('reports.field.laboratoryId.label')}
                    options={[
                      ...(itemsLaboratory &&
                        itemsLaboratory.map((item) => ({
                          value: item.id,
                          label: item.name ? item.name : '<Нет имени>',
                        }))),
                    ]}
                  />
                  <Field
                    name="statuses"
                    isMulti
                    placeholderColor="#333333"
                    placeholder={getTranslation('global.all')}
                    component={SelectForm}
                    label={getTranslation('reports.field.testState.label')}
                    options={[
                      { value: null, label: getTranslation('reports.result.waiting') },
                      { value: false, label: getTranslation('reports.result.false') },
                      { value: true, label: getTranslation('reports.result.true') },
                    ]}
                  />
                  <Field
                    name="showOffices"
                    component={UICheckboxForm}
                    label={getTranslation('reports.field.showOffices.label')}
                  />
                </FormSection>
                <Box padding="1.5rem 0 0.5rem">
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isDisabled={!props.dirty}
                    isLoading={loading}
                  >
                    {getTranslation('global.download')}
                  </Button>
                </Box>
              </ModalBody>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  )
}
