import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';

export const ACTION_TYPES = {
    FETCH_SETTINGS_LABORATORY: 'settings/FETCH_SETTINGS_LABORATORY',
    RESET_SETTINGS_LABORATORY: 'settings/RESET_SETTINGS_LABORATORY',
    FETCH_TIME: 'settings/FETCH_TIME',
    FETCH_TEST_VALID_HOURS: 'settings/FETCH_TEST_VALID_HOURS',
    FETCH_AUTO_NUMBERING_ENABLED: 'settings/FETCH_AUTO_NUMBERING_ENABLED',
    FETCH_AUTO_NUMBERING_PREFIX: 'settings/FETCH_AUTO_NUMBERING_PREFIX',
    FETCH_INSURANCE_URL: 'settings/FETCH_INSURANCE_URL',
    FETCH_COUNTRY: 'settings/FETCH_COUNTRY',
    FETCH_ADMIN_PANEL_INFO: 'settings/FETCH_ADMIN_PANEL_INFO',
    SET_SETTINGS: 'settings/SET_SETTINGS',
    SET_INSURANCE_URL: 'settings/SET_INSURANCE_URL',
    FETCH_AGREEMENT: 'settings/FETCH_AGREEMENT',
    SET_AGREEMENT: 'settings/SET_AGREEMENT',
    RESET_AGREEMENT: 'settings/RESET_AGREEMENT',
    RESET: 'settings/RESET'
};

const initialState = {
    settingsLaboratory: {},
    loading: false,
    utc: '+03:00',
    time: '',
    country: 'ru',
    testValidHours: '',
    insuranceUrl: '',
    errorMessage: '',
    agreement: '',
    autoNumberingEnabled: '',
    autoNumberingPrefix: '',
    typeAdminPanel: '',
    adminPanelName: '',
    versionBack: '0.0.0.0',
    updateBack: '0.0.0',
    version: '3.5.15',
    update: '23.04.2022'
};


export const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_COUNTRY):
        case REQUEST(ACTION_TYPES.FETCH_TIME):
        case REQUEST(ACTION_TYPES.FETCH_TEST_VALID_HOURS):
        case REQUEST(ACTION_TYPES.FETCH_AUTO_NUMBERING_ENABLED):
        case REQUEST(ACTION_TYPES.FETCH_AUTO_NUMBERING_PREFIX):
        case REQUEST(ACTION_TYPES.FETCH_INSURANCE_URL):
        case REQUEST(ACTION_TYPES.SET_SETTINGS):
        case REQUEST(ACTION_TYPES.SET_INSURANCE_URL):
        case REQUEST(ACTION_TYPES.SET_AGREEMENT):
        case REQUEST(ACTION_TYPES.FETCH_AGREEMENT):
        case REQUEST(ACTION_TYPES.FETCH_SETTINGS_LABORATORY):
        case REQUEST(ACTION_TYPES.FETCH_ADMIN_PANEL_INFO):
        {
            return {
                ...state,
                loading: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_COUNTRY):
        case FAILURE(ACTION_TYPES.FETCH_TIME):
        case FAILURE(ACTION_TYPES.FETCH_INSURANCE_URL):
        case FAILURE(ACTION_TYPES.FETCH_TEST_VALID_HOURS):
        case FAILURE(ACTION_TYPES.FETCH_AUTO_NUMBERING_ENABLED):
        case FAILURE(ACTION_TYPES.FETCH_AUTO_NUMBERING_PREFIX):
        case FAILURE(ACTION_TYPES.SET_SETTINGS):
        case FAILURE(ACTION_TYPES.SET_INSURANCE_URL):
        case FAILURE(ACTION_TYPES.SET_AGREEMENT):
        case FAILURE(ACTION_TYPES.FETCH_AGREEMENT):
        case FAILURE(ACTION_TYPES.FETCH_SETTINGS_LABORATORY):
        case FAILURE(ACTION_TYPES.FETCH_ADMIN_PANEL_INFO):
        {
            return {
                ...state,
                loading: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_SETTINGS_LABORATORY): {
            return {
                ...state,
                loading: false,
                settingsLaboratory: {
                    ...action.payload.data
                }
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_TIME): {
            return {
                ...state,
                loading: false,
                time: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_TEST_VALID_HOURS): {
            return {
                ...state,
                loading: false,
                testValidHours: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_AUTO_NUMBERING_ENABLED): {
            return {
                ...state,
                loading: false,
                autoNumberingEnabled: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_AUTO_NUMBERING_PREFIX): {
            return {
                ...state,
                loading: false,
                autoNumberingPrefix: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_INSURANCE_URL): {
            return {
                ...state,
                loading: false,
                insuranceUrl: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.SET_INSURANCE_URL): {
            return {
                ...state,
                loading: false,
                insuranceUrl: action.payload.data.value
            }
        }
        case SUCCESS(ACTION_TYPES.SET_SETTINGS): {
            const typeSetting = action.payload.data.key;
            return {
                ...state,
                loading: false,
                ...typeSetting === 'test_valid_hours' && { testValidHours: action.payload.data.value },
                ...typeSetting === 'autonumbering_enabled' && { autoNumberingEnabled: action.payload.data.value },
                ...typeSetting === 'autonumbering_preffix' && { autoNumberingPrefix: action.payload.data.value }
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_COUNTRY): {
            return {
                ...state,
                loading: false,
                //country: 'am'
                country: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_AGREEMENT): {
            return {
                ...state,
                loading: false,
                agreement: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.SET_AGREEMENT): {
            return {
                ...state,
                loading: false,
                agreement: action.payload.data.value
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_ADMIN_PANEL_INFO): {
            return {
                ...state,
                loading: false,
                typeAdminPanel: action.payload?.data?.typeAdminPanel,
                adminPanelName: action.payload?.data?.adminPanelName,
                versionBack: action.payload?.data?.version,
                updateBack: action.payload?.data?.date,
            }
        }
        case ACTION_TYPES.RESET_SETTINGS_LABORATORY: {
            return {
                ...state,
                settingsLaboratory: {},
                loading: false
            }
        }
        case ACTION_TYPES.RESET_AGREEMENT: {
            return {
                ...state,
                loading: false,
                agreement: ''
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                settingsLaboratory: [],
                loading: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}`;

export const getSettingsLaboratory = () => {
    const requestUrl = `${API_URL}/settings/laboratory_details`;
    return {
        type: ACTION_TYPES.FETCH_SETTINGS_LABORATORY,
        payload: axios.get(requestUrl)
    };
};

export const getTime = () => {
    const requestUrl = `${API_URL}/time`;
    return {
        type: ACTION_TYPES.FETCH_TIME,
        payload: axios.get(requestUrl)
    };
};

export const getCountry = () => {
    const requestUrl = `${API_URL}/settings/country_code`;
    return {
        type: ACTION_TYPES.FETCH_COUNTRY,
        payload: axios.get(requestUrl)
    };
};

export const getTestValidHours = () => {
    const requestUrl = `${API_URL}/settings/test_valid_hours`;
    return {
        type: ACTION_TYPES.FETCH_TEST_VALID_HOURS,
        payload: axios.get(requestUrl)
    };
};

export const getAutoNumberingEnabled = () => {
    const requestUrl = `${API_URL}/settings/autonumbering_enabled`;
    return {
        type: ACTION_TYPES.FETCH_AUTO_NUMBERING_ENABLED,
        payload: axios.get(requestUrl)
    };
};

export const getAutoNumberingPrefix = () => {
    const requestUrl = `${API_URL}/settings/autonumbering_preffix`;
    return {
        type: ACTION_TYPES.FETCH_AUTO_NUMBERING_PREFIX,
        payload: axios.get(requestUrl)
    };
};

export const setSettings = (dataRequest) => {
    const requestUrl = `${API_URL}/settings`;
    return {
        type: ACTION_TYPES.SET_SETTINGS,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const getInsuranceUrl = () => {
    const requestUrl = `${API_URL}/settings/insurances_url`;
    return {
        type: ACTION_TYPES.FETCH_INSURANCE_URL,
        payload: axios.get(requestUrl)
    };
};

export const setInsuranceUrl = (dataRequest) => {
    const requestUrl = `${API_URL}/settings`;
    return {
        type: ACTION_TYPES.SET_INSURANCE_URL,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const getAgreement = () => {
    const requestUrl = `${API_URL}/settings/agreement`;
    return {
        type: ACTION_TYPES.FETCH_AGREEMENT,
        payload: axios.get(requestUrl)
    };
};

export const getAdminPanelInfo = () => {
    const requestUrl = `${API_URL}/info`;
    return {
        type: ACTION_TYPES.FETCH_ADMIN_PANEL_INFO,
        payload: axios.get(requestUrl)
    };
};

export const setAgreement = (dataRequest) => {
    const requestUrl = `${API_URL}/settings`;
    return {
        type: ACTION_TYPES.SET_AGREEMENT,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const resetAgreement = () => {
    return {
        type: ACTION_TYPES.RESET_AGREEMENT
    };
};


export const resetSettingsLaboratory = () => {
    return {
        type: ACTION_TYPES.RESET_SETTINGS_LABORATORY
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
