export const OFFICE_FIELDS = {
  title: 'title',
  shortTitleRu: 'shortTitleRu',
  laboratoryName: 'laboratoryName',
  laboratoryShortName: 'laboratoryShortName',
  laboratoryTelephone: 'laboratoryTelephone',
  laboratorySiteUrl: 'laboratorySiteUrl',
  siteUrl: 'siteUrl',
  telephone: 'telephone',
  state: 'state',
  createdAt: 'createdAt',
  oid: 'oid',
  addressRu: 'addressRu',
  inn: 'inn',
  company: 'company',
  ogrn: 'ogrn',
  ownership: 'ownership',
  addressLegal: 'addressLegal',
}

export const OFFICE_FIELDS_TRANSLATION = {
  [OFFICE_FIELDS.title]: 'office.snippets.heading.name',
  [OFFICE_FIELDS.shortTitleRu]: 'office.field.shortTitleRu.label',
  [OFFICE_FIELDS.siteUrl]: 'office.field.siteUrl.label',
  [OFFICE_FIELDS.telephone]: 'office.snippets.heading.telephone',
  [OFFICE_FIELDS.state]: 'office.snippets.heading.state',
  [OFFICE_FIELDS.createdAt]: 'global.createdDate',
  [OFFICE_FIELDS.oid]: 'requisites.oid.label',
  [OFFICE_FIELDS.addressRu]: 'office.field.addressRu.label',
  [OFFICE_FIELDS.inn]: 'requisites.inn.label',
  [OFFICE_FIELDS.company]: 'requisites.company.label',
  [OFFICE_FIELDS.ogrn]: 'requisites.ogrn.label',
  [OFFICE_FIELDS.ownership]: 'requisites.ownership.label',
  [OFFICE_FIELDS.addressLegal]: 'requisites.addressLegal.label',
  [OFFICE_FIELDS.laboratoryName]: 'laboratory.field.name.label',
  [OFFICE_FIELDS.laboratoryShortName]: 'laboratory.field.shortName.label',
  [OFFICE_FIELDS.laboratoryTelephone]: 'laboratory.field.telephone.label',
  [OFFICE_FIELDS.laboratorySiteUrl]: 'laboratory.field.siteUrl.label',
}

export const OWNERSHIP = {
  political: 'political',
  private: 'private',
  municipal: 'municipal',
}

export const makeOwnershipTitle = ({ getTranslation }) => ({
  [OWNERSHIP.political]: getTranslation('requisites.ownership.option.political'),
  [OWNERSHIP.private]: getTranslation('requisites.ownership.option.private'),
  [OWNERSHIP.municipal]: getTranslation('requisites.ownership.option.municipal'),
})
