import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Tab, useToast } from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { isEmpty } from '../../../../components/deprecated/utilities/is-empty';
import { createLaboratory, getLaboratory, resetLaboratory, updateLaboratory } from '../../../../store/laboratory.reducer';
import { getDetailValue, setDetailValue } from '../../../../components/deprecated/utilities/details-field';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormSection
} from '../../../../chakra-lib/layout';
import { Alert, CheckboxForm, InputForm, TabList, TabPanel, TabPanels, Tabs } from '../../../../chakra-lib';
import { TYPE_LABORATORY_SCHEMA } from '../../../../model/schema';
import { TabCheck } from '../../../../components/tab-check';
import { TYPE_REQUISITES_FIELDS } from '../../../../model/requisites-fields';
import { FormGrid } from '../../../../components/deprecated/layout';
import { ReadOnlyForm } from '../../../../components/deprecated/readonly-form';
import { RequisitesForm } from '../../../../components/requisites';
import { TranslationContext } from '../../../../translation';
import { UITextareaForm } from '../../../../components/textarea';

export const LaboratoryUpdate = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const toast = useToast();
  // eslint-disable-next-line no-unused-vars
  const isNew = !id;
  const country = useSelector(state => state.settings.country);
  const item = useSelector(state => state.laboratory.item);
  const loading = useSelector(state => state.laboratory.loading);
  const loadingUpdate = useSelector(state => state.laboratory.loadingUpdate);
  const { error } = useSelector(state => state.laboratory.errorMessage);
  const { duplicate } = !isEmpty(error) && error;
  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleBack = () => {
    history.goBack();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isNew) {
      dispatch(resetLaboratory());
    } else {
      dispatch(getLaboratory(id));
    }
    return () => {
      dispatch(resetLaboratory());
    };
  }, []);

  const handleForm = async (values, actions) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      name: values.name,
      shortName: values.shortName,
      siteUrl: values.siteUrl,
      telephone: values.telephone,
      pubKey: values.pubKey,
      disabled: values.disabled,
      details: {
        oid: setDetailValue(values, 'oid'),
        inn: setDetailValue(values, 'inn'),
        company: setDetailValue(values, 'company'),
        kpp: setDetailValue(values, 'kpp'),
        ogrn: setDetailValue(values, 'ogrn'),
        ownership: setDetailValue(values, 'ownership'),
        addressLegal: setDetailValue(values, 'addressLegal')
      }
    };
    if (isNew) {
      await dispatch(createLaboratory(dataRequest));
      toast({
        title: getTranslation('laboratory.toast.newLaboratory.success.title'),
        description: `${values.name} ${getTranslation('laboratory.toast.newLaboratory.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    } else {
      await dispatch(updateLaboratory(dataRequest));
      toast({
        title: getTranslation('laboratory.toast.updateLaboratory.success.title'),
        description: `${values.name} ${getTranslation('laboratory.toast.updateLaboratory.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    }
  };

  useEffect(() => {
    if (duplicate) {
      toast({
        title: getTranslation('global.error'),
        description: `${getTranslation('message.error.duplicateLaboratory')} (${getTranslation(`requisites.${duplicate}.label`)})`,
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
    }
  }, [duplicate]);

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            {duplicate && (
              <Alert marginBottom="30px">
                {`${getTranslation('message.error.duplicateOffice')} (${getTranslation(`requisites.${duplicate}.label`)})`}
              </Alert>
            )}
            <Formik
              initialValues={{
                name: item.name,
                shortName: item.shortName,
                siteUrl: item.siteUrl,
                telephone: item.telephone,
                pubKey: item.pubKey,
                disabled: item.disabled,
                oid: getDetailValue(item, 'oid'),
                inn: getDetailValue(item, 'inn'),
                company: getDetailValue(item, 'company'),
                kpp: getDetailValue(item, 'kpp'),
                ogrn: getDetailValue(item, 'ogrn'),
                ownership: getDetailValue(item, 'ownership'),
                addressLegal: getDetailValue(item, 'addressLegal')
              }}
              enableReinitialize={true}
              validationSchema={TYPE_LABORATORY_SCHEMA[country]}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew ? getTranslation('laboratory.action.buttonCreate') : getTranslation('laboratory.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <Tabs variant="enclosed-colored">
                    <TabList>
                      <Tab>
                        <TabCheck form={props} fields={['name', 'shortName', 'telephone', 'oid']}>
                          {getTranslation('laboratory.tabs.new.information')}
                        </TabCheck>
                      </Tab>
                      <Tab>{getTranslation('laboratory.tabs.new.safety')}</Tab>
                      <Tab>
                        <TabCheck form={props} fields={TYPE_REQUISITES_FIELDS[country]}>
                          {getTranslation('laboratory.tabs.new.requisites')}
                        </TabCheck>
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel pb={0}>
                        <FormSection>
                          <Field
                            autoFocus
                            name="name"
                            label={getTranslation('laboratory.field.name.label')}
                            component={InputForm}
                            placeholder={getTranslation('laboratory.field.name.placeholder')}
                            isDisabled={loading}
                          />
                          <Field
                            name="shortName"
                            label={getTranslation('laboratory.field.shortName.label')}
                            component={InputForm}
                            placeholder={getTranslation('laboratory.field.shortName.placeholder')}
                            isDisabled={loading}
                          />
                          <Box fontSize="0.7rem" color="gray.400">
                            {getTranslation('laboratory.field.shortName.description')}
                          </Box>
                          <FormGrid columns={2}>
                            <Field
                              name="siteUrl"
                              label={getTranslation('laboratory.field.siteUrl.label')}
                              component={InputForm}
                              placeholder={getTranslation('laboratory.field.siteUrl.placeholder')}
                              isDisabled={loading}
                            />
                            <Field
                              name="telephone"
                              label={getTranslation('laboratory.field.telephone.label')}
                              component={InputForm}
                              placeholder={getTranslation('laboratory.field.telephone.placeholder')}
                              isDisabled={loading}
                            />

                          </FormGrid>
                          <Field
                            name="disabled"
                            component={CheckboxForm}
                            label={getTranslation('laboratory.field.disabled.label')}
                          />
                        </FormSection>
                        <FormSection>
                          <Field
                            name="oid"
                            label={getTranslation('requisites.oid.label')}
                            component={InputForm}
                            isDisabled={loading}
                          />
                          <Box fontSize="0.7rem" color="gray.400">
                            {getTranslation('requisites.oid.description')}
                          </Box>
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <ReadOnlyForm value={isReadOnly} onChange={setIsReadOnly}/>
                          <Field
                            name="pubKey"
                            label={getTranslation('laboratory.field.pubKey.label')}
                            component={UITextareaForm}
                            minHeight="500px"
                            isDisabled={loading || isReadOnly}
                            variant={isReadOnly ? 'filled' : 'outline'}
                          />
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <RequisitesForm loading={loading}/>
                        </FormSection>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
