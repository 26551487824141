import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { FaqUpdate } from './FaqUpdate/FaqUpdate';
import { FaqList } from './FaqList/FaqList';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <FaqUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <FaqUpdate/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <FaqList/>
      </Route>
    </Switch>
  );
};

export default Routes;
