import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';

const PAGE_SIZE = 20;
const START_PAGE = 1;

export const ACTION_TYPES = {
    FETCH_OFFICE_LIST: 'office/FETCH_OFFICE_LIST',
    RESET_OFFICE_LIST: 'office/RESET_OFFICE_LIST',
    FETCH_OFFICE: 'office/FETCH_OFFICE',
    CREATE_OFFICE: 'office/CREATE_OFFICE',
    UPDATE_OFFICE: 'office/UPDATE_OFFICE',
    UPDATE_FILTER: 'office/UPDATE_FILTER',
    RESET_FILTER: 'office/RESET_FILTER',
    RESET_OFFICE: 'office/RESET_OFFICE',
    RESET: 'office/RESET'
};

const initialState = {
    items: [],
    item: {},
    loading: false,
    loadingUpdate: false,
    errorMessage: '',
    totalItems: 0,
    totalPages: 0,
    activePage: START_PAGE,
    filter: {
        text: '',
        laboratoryId: ''
    }
};

export const officeReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_OFFICE_LIST):
        case REQUEST(ACTION_TYPES.FETCH_OFFICE):{
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_OFFICE):
        case REQUEST(ACTION_TYPES.UPDATE_OFFICE):{
            return {
                ...state,
                errorMessage: '',
                loadingUpdate: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_OFFICE_LIST): {
            return {
                ...state,
                loading: false,
                loadingUpdate: false
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_OFFICE):
        case FAILURE(ACTION_TYPES.CREATE_OFFICE):
        case FAILURE(ACTION_TYPES.UPDATE_OFFICE):{
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errorMessage: action.payload.response.data,
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_OFFICE_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loading: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage'],
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_OFFICE): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                errorMessage: '',
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_OFFICE): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_OFFICE): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            }
        }
        case ACTION_TYPES.RESET_OFFICE_LIST: {
            return {
                ...state,
                items: [],
                loading: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET_OFFICE: {
            return {
                ...state,
                item: {},
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/office`;

export const getOfficeList = () => {
    const requestUrl = `${API_URL}?pageSize=${10000}&page=${1}`;
    return {
        type: ACTION_TYPES.FETCH_OFFICE_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getOfficeListSearch = (page) => (dispatch, getState) => {
    const filter = getState().office.filter;
    const requestUrl = `${API_URL}/findtext?pageSize=${PAGE_SIZE}&page=${page}`;
    dispatch ({
        type: ACTION_TYPES.FETCH_OFFICE_LIST,
        payload: axios.post(requestUrl, {
            text: checkParam(filter.text),
            laboratoryId: checkParam(filter.laboratoryId),
        })
    });
};

export const updateFilter = (params) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        filter: {
            ...params
        }
    });
    dispatch(getOfficeListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().office.activePage;
    dispatch(getOfficeListSearch(activePage + 1))
};

export const getOffice = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_OFFICE,
        payload: axios.get(requestUrl)
    };
};

export const createOffice = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_OFFICE,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateOffice = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.UPDATE_OFFICE,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const resetFilter = () => {
    return {
        type: ACTION_TYPES.RESET_FILTER
    };
};

export const resetOfficeList = () => {
    return {
        type: ACTION_TYPES.RESET_OFFICE_LIST
    };
};

export const resetOffice = () => {
    return {
        type: ACTION_TYPES.RESET_OFFICE
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
