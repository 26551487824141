import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';

export const ACTION_TYPES = {
    FETCH_REPORT_TOTAL: 'report/FETCH_REPORT_TOTAL',
    RESET_REPORT_TOTAL: 'report/RESET_REPORT_TOTAL',
    RESET: 'test/RESET'
};

const initialState = {
    reportTotal: {},
    loading: false,
    errorMessage: ''
};

export const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_REPORT_TOTAL): {
            return {
                ...state,
                loading: true,
                errorMessage: ''
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_REPORT_TOTAL): {
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.response.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_REPORT_TOTAL): {
            return {
                ...state,
                reportTotal: action.payload.data,
                loading: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET_REPORT_TOTAL: {
            return {
                ...state,
                reportTotal: {},
                loading: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                reportTotal: {},
                loading: false,
                errorMessage: ''
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/reports`;


export const getReportTotal = (dataRequest) => {
    const requestUrl = `${API_URL}/totals`;
    return {
        type: ACTION_TYPES.FETCH_REPORT_TOTAL,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const resetReportTotal = () => {
    return {
        type: ACTION_TYPES.RESET_REPORT_TOTAL
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
