export const lang = {
  'global.search': {
    'ru': 'Поиск',
    'en': 'Search'
  },
  'global.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'global.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'global.disabled': {
    'ru': 'Заблокирован',
    'en': 'Disabled'
  },
  'global.block': {
    'ru': 'Заблокировать',
    'en': 'Block'
  },
  'global.unblock': {
    'ru': 'Разблокировать',
    'en': 'Unblock'
  },
  'global.time': {
    'ru': 'Время',
    'en': 'Time'
  },
  'global.date': {
    'ru': 'Дата',
    'en': 'Date'
  },
  'global.update': {
    'ru': 'Обновить',
    'en': 'Update'
  },
  'global.create': {
    'ru': 'Создать',
    'en': 'Create'
  },
  'global.download': {
    'ru': 'Скачать',
    'en': 'Download'
  },
  'global.updated': {
    'ru': 'Обновлено',
    'en': 'Updated'
  },
  'global.created': {
    'ru': 'Создано',
    'en': 'Created'
  },
  'global.createdDate': {
    'ru': 'Дата создания',
    'en': 'Created date'
  },
  'global.choose': {
    'ru': 'Выбрать',
    'en': 'Choose'
  },
  'global.synchronization': {
    'ru': 'Синхронизация',
    'en': 'Synchronization'
  },
  'global.add': {
    'ru': 'Добавить',
    'en': 'Add'
  },
  'global.confirm': {
    'ru': 'Подтвердить',
    'en': 'Confirm'
  },
  'global.error': {
    'ru': 'Ошибка',
    'en': 'Error'
  },
  'global.noData': {
    'ru': 'Нет данных',
    'en': 'There is no data'
  },
  'global.expired': {
    'ru': 'Просрочен',
    'en': 'Expired'
  },
  'global.pending': {
    'ru': 'В ожидании',
    'en': 'Pending'
  },
  'global.remained': {
    'ru': 'Осталось',
    'en': 'It remained'
  },
  'global.hour': {
    'ru': 'ч.',
    'en': 'h'
  },
  'global.timeLeft': {
    'ru': 'Осталось',
    'en': 'Time left'
  },
  'global.timeout': {
    'ru': 'Время истекло',
    'en': 'Time is up'
  },
  'global.clear': {
    'ru': 'Очистить',
    'en': 'Clear'
  },
  'global.close': {
    'ru': 'Закрыть',
    'en': 'Close'
  },
  'global.dateFrom': {
    'ru': 'Дата с',
    'en': 'Date From'
  },
  'global.dateTo': {
    'ru': 'Дата по',
    'en': 'Date To'
  },
  'global.all': {
    'ru': 'Все',
    'en': 'All'
  },
  'global.male': {
    'ru': 'Мужчина',
    'en': 'Male'
  },
  'global.female': {
    'ru': 'Женщина',
    'en': 'Female'
  },
  'menu.test': {
    'ru': 'Тесты',
    'en': 'Tests'
  },
  'menu.office': {
    'ru': 'Офисы',
    'en': 'Offices'
  },
  'menu.user': {
    'ru': 'Пользователи',
    'en': 'Users'
  },
  'menu.laboratory': {
    'ru': 'Лаборатории',
    'en': 'Laboratories'
  },
  'menu.country': {
    'ru': 'Страны',
    'en': 'Country'
  },
  'resultTest.false': {
    'ru': 'Отрицательный',
    'en': 'Negative'
  },
  'resultTest.true': {
    'ru': 'Положительный',
    'en': 'Positive'
  },
  'resultTest.invalid': {
    'ru': 'Неактуальный',
    'en': 'Irrelevant'
  },
  'disabled.false': {
    'ru': 'Включено',
    'en': 'Active'
  },
  'disabled.true': {
    'ru': 'Отключено',
    'en': 'Inactive'
  },
  'role.administrator': {
    'ru': 'Администратор',
    'en': 'Administrator'
  },
  'role.user': {
    'ru': 'Пользователь (полный доступ)',
    'en': 'User'
  },
  'role.insurer': {
    'ru': 'Страховой агент',
    'en': 'Insurer'
  },
  'role.userCreator': {
    'ru': 'Пользователь (внесение данных)',
    'en': 'User (making tests)'
  },
  'role.userPrivileged': {
    'ru': 'Пользователь (изменение данных и результатов)',
    'en': 'User (edit tests)'
  },
  'role.userEditor': {
    'ru': 'Пользователь (редактирование)',
    'en': 'User (editing)'
  },
  'role.userResultSetter': {
    'ru': 'Пользователь (внесение результатов)',
    'en': 'User (entering results)'
  },
  'role.userLab': {
    'ru': 'Пользователь (внесение результатов для всех офисов)',
    'en': 'User (entering results)'
  },
  'role.api': {
    'ru': 'Api',
    'en': 'Api'
  },
  'userPanel.settings': {
    'ru': 'Настройки',
    'en': 'Settings'
  },
  'userPanel.language': {
    'ru': 'Язык',
    'en': 'Language'
  },
  'userPanel.logout': {
    'ru': 'Выход',
    'en': 'Sign out'
  },
  'filter.filterInternal': {
    'ru': 'Фильтр по коду',
    'en': 'Filter by code'
  },
  'filter.filterResult': {
    'ru': 'Фильтр по результату',
    'en': 'Filter by result'
  },
  'filter.filterInternalPlaceholder': {
    'ru': 'Введите код',
    'en': 'Enter a code'
  },
  'filter.office': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'filter.officePlaceholder': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'filter.dateFrom': {
    'ru': 'Дата c',
    'en': 'Date from'
  },
  'filter.dateTo': {
    'ru': 'Дата по',
    'en': 'Date to'
  },
  'workSchedule.heading.schedule': {
    'ru': 'Рабочий график',
    'en': 'Work schedule'
  },
  'workSchedule.heading.holidays': {
    'ru': 'Праздничные дни',
    'en': 'Holidays'
  },
  'workSchedule.heading.holiday': {
    'ru': 'Праздничный день',
    'en': 'Holiday'
  },
  'workSchedule.heading.holidayAdd': {
    'ru': 'Добавление праздниного дня',
    'en': 'Adding a holiday day'
  },
  'workSchedule.item.dayOff': {
    'ru': 'Выходной',
    'en': 'Day off'
  },
  'workSchedule.item.roundClock': {
    'ru': 'Круглосуточно',
    'en': 'Round clock'
  },
  'test.heading.list': {
    'ru': 'Тесты',
    'en': 'Tests'
  },
  'test.heading.new': {
    'ru': 'Создание теста',
    'en': 'TestList creation'
  },
  'test.heading.show': {
    'ru': 'Тест',
    'en': 'Test'
  },
  'test.heading.history': {
    'ru': 'История',
    'en': 'History'
  },
  'test.heading.addEmail': {
    'ru': 'Добавление электронной почты',
    'en': 'Add email'
  },
  'test.heading.updateEmail': {
    'ru': 'Обновление электронной почты',
    'en': 'Update email'
  },
  'test.heading.addFio': {
    'ru': 'Добавление ФИО',
    'en': 'Add Full name'
  },
  'test.heading.updateFio': {
    'ru': 'Обновление ФИО',
    'en': 'Update Full name'
  },
  'test.heading.addPassport': {
    'ru': 'Добавление паспорта',
    'en': 'Add passport'
  },
  'test.heading.addTelephone': {
    'ru': 'Добавление телефона',
    'en': 'Add telephone'
  },
  'test.heading.updateTelephone': {
    'ru': 'Обновление телефона',
    'en': 'Update telephone'
  },
  'test.heading.resultTest': {
    'ru': 'Готовность теста',
    'en': 'Result test'
  },
  'test.action.buttonCreate': {
    'ru': 'Создать',
    'en': 'Create'
  },
  'test.action.buttonUpdate': {
    'ru': 'Обновить',
    'en': 'Update'
  },
  'test.action.buttonPrintStatement': {
    'ru': 'Распечатать заявление',
    'en': 'Print statement'
  },
  'test.action.buttonViewStatement': {
    'ru': 'Посмотреть заявление',
    'en': 'View statement'
  },
  'test.action.buttonSetResult': {
    'ru': 'Внести результат',
    'en': 'Set Result'
  },
  'test.action.buttonSendEmail': {
    'ru': 'Отправить клиенту',
    'en': 'Send to client'
  },
  'test.action.buttonSendConsentEmail': {
    'ru': 'Отправить на почту',
    'en': 'Send to email'
  },
  'test.action.buttonViewResult': {
    'ru': 'Посмотреть результат',
    'en': 'View result'
  },
  'test.action.removeCustomerInfo': {
    'ru': 'Удалить данные клиента',
    'en': 'Remove client data'
  },
  'test.aside.resultTest': {
    'ru': 'Результат',
    'en': 'Result'
  },
  'test.aside.statement': {
    'ru': 'Заявление',
    'en': 'Statement'
  },
  'test.aside.actions': {
    'ru': 'Действия',
    'en': 'Actions'
  },
  'test.snippets.heading.fio': {
    'ru': 'ФИО',
    'en': 'Full name'
  },
  'test.snippets.heading.laboratory': {
    'ru': 'Лаборатория',
    'en': 'Laboratory'
  },
  'test.snippets.heading.code': {
    'ru': 'Код',
    'en': 'Code'
  },
  'test.snippets.heading.testingDate': {
    'ru': 'Взятие биоматериала',
    'en': 'Testing Date'
  },
  'test.snippets.heading.result': {
    'ru': 'Результат',
    'en': 'Result'
  },
  'test.snippets.item.fioEmpty': {
    'ru': 'Необходимо заполнить',
    'en': 'Required to fill'
  },
  'test.field.fio.label': {
    'ru': 'ФИО',
    'en': 'Full name'
  },
  'test.field.fio.placeholder': {
    'ru': 'Иванов Андрей Владимирович',
    'en': 'Иванов Андрей Владимирович'
  },
  'test.field.fio.description': {
    'ru': 'Заполните в соответствии с паспортом, по которому пересекается граница',
    'en': 'Fill in according to the passport used to cross the border'
  },
  'test.field.email.label': {
    'ru': 'Адрес электронной почты',
    'en': 'Email'
  },
  'test.field.email.placeholder': {
    'ru': 'user@test.com',
    'en': 'user@test.com'
  },
  'test.field.telephone.label': {
    'ru': 'Телефон',
    'en': 'Telephone'
  },
  'test.field.telephone.placeholder': {
    'ru': '+',
    'en': '+'
  },
  'test.field.passport.label': {
    'ru': 'Серия и номер паспорта',
    'en': 'Passport series and number'
  },
  'test.field.passport.description': {
    'ru': 'Укажите номер паспорта, по которому пересекается граница',
    'en': 'Indicate the passport number by which the border is crossed'
  },
  'test.field.internalId.label': {
    'ru': 'Код лаборатории',
    'en': 'SettingsInformation code'
  },
  'test.field.testType.label': {
    'ru': 'Вид теста',
    'en': 'Test type'
  },
  'test.field.testType.placeholder': {
    'ru': 'Выберите вид теста',
    'en': 'Select test type'
  },
  'test.field.internalId.placeholder': {
    'ru': 'Введите внутренний код',
    'en': 'Enter internal code'
  },
  'test.field.officeId.label': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'test.field.officeId.placeholder': {
    'ru': 'Выберите офис',
    'en': 'Select office'
  },
  'test.field.testingDate.label': {
    'ru': 'Дата взятия биоматериала',
    'en': 'Date of collection of biometrics'
  },
  'test.field.resultDate.label': {
    'ru': 'Дата готовности теста',
    'en': 'TestList readiness date'
  },
  'test.field.resultSetDate.label': {
    'ru': 'Дата внесения результата в систему',
    'en': 'Date of entering the result into the system'
  },
  'test.field.resultTest.label': {
    'ru': 'Результат теста',
    'en': 'Result test'
  },
  'test.field.resultTest.placeholder': {
    'ru': 'Выберите результат теста',
    'en': 'Select test result'
  },
  'test.section.heading.test': {
    'ru': 'Данные теста',
    'en': 'TestList data'
  },
  'test.section.heading.client': {
    'ru': 'Данные клиента',
    'en': 'Client data'
  },
  'test.section.heading.office': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'test.information.delayed': {
    'ru': 'Данные клиента можно заполнить позднее, на этапе внесения результата теста.',
    'en': 'Customer data can be filled in later, at the stage of entering the test result.'
  },
  'test.alert.error.invalid_result_date': {
    'ru': 'Неверная дата внесения результата',
    'en': 'Wrong date of entering the result'
  },
  'test.alert.error.duplicate_internal_id': {
    'ru': 'Тест с таким ID уже существует',
    'en': 'A test with this ID already exists'
  },
  'test.alert.error.user_cannot_create_test': {
    'ru': 'Этот пользователь не может создавать тесты',
    'en': 'This user cannot create tests'
  },
  'test.alert.error.officeConnected': {
    'ru': 'К вашей учетной записи не подключен Офис',
    'en': 'Office is not connected to your account'
  },
  'test.alert.error.all': {
    'ru': 'Ошибка создания теста',
    'en': 'TestList creation error'
  },
  'test.toast.newTest.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'test.toast.newTest.success.description.newTest': {
    'ru': 'Создан новый тест',
    'en': 'New test created'
  },
  'test.toast.newTest.success.description.newTestFor': {
    'ru': 'Создан новый тест для',
    'en': 'Created a new test for'
  },
  'test.toast.emptyClient.title': {
    'ru': 'Ошибка',
    'en': 'Error'
  },
  'test.toast.emptyClient.description': {
    'ru': 'Заполните данные клиента',
    'en': 'Fill in customer details'
  },
  'test.toast.addEmail.title': {
    'ru': 'Добавлена электронная почта',
    'en': 'Added email'
  },
  'test.toast.updateEmail.title': {
    'ru': 'Обновлена электронная почта',
    'en': 'Updated email'
  },
  'test.toast.addFio.title': {
    'ru': 'Добавлено ФИО',
    'en': 'Added Full name'
  },
  'test.toast.updateFio.title': {
    'ru': 'Обновлено ФИО',
    'en': 'Updated Full name'
  },
  'test.toast.addPassport.title': {
    'ru': 'Добавлен паспорт',
    'en': 'Added passport'
  },
  'test.toast.addTelephone.title': {
    'ru': 'Добавлен телефон',
    'en': 'Added telephone'
  },
  'test.toast.updateTelephone.title': {
    'ru': 'Обновлен телефон',
    'en': 'Updated telephone'
  },
  'test.toast.addResult.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'test.toast.addResult.success.description': {
    'ru': 'Результат успешно добавлен',
    'en': 'Result added successfully'
  },
  'test.toast.addResult.invalidTest.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'test.toast.addResult.invalidTest.description': {
    'ru': 'Тест переведен в статус Неактуальный',
    'en': 'The test is transferred to the status Not relevant'
  },
  'test.toast.addResult.errorRepeat.title': {
    'ru': 'Ошибка',
    'en': 'Error'
  },
  'test.toast.addResult.errorRepeat.description': {
    'ru': 'Повторное внесение результата недоступно',
    'en': 'Re-entry of the result is not available'
  },
  'test.toast.sendEmail.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'test.toast.sendEmail.success.description': {
    'ru': 'Результат успешно отправлен клиенту',
    'en': 'The result was successfully sent to the client'
  },
  'test.toast.sendConsentEmail.success.description': {
    'ru': 'Бланк запроса (согласия) на исследование успешно отправлен клиенту',
    'en': 'Request (consent) form for the request has been sent to the client'
  },
  'test.toast.sendEmail.error.title': {
    'ru': 'Ошибка',
    'en': 'Error'
  },
  'test.toast.sendEmail.error.description': {
    'ru': 'Результат не отправлен клиенту',
    'en': 'The result was not sent to the client'
  },
  'office.heading.list': {
    'ru': 'Офисы',
    'en': 'Offices'
  },
  'office.heading.new': {
    'ru': 'Создание офиса',
    'en': 'Office creation'
  },
  'office.heading.update': {
    'ru': 'Редактирование офиса',
    'en': 'Office editing'
  },
  'office.heading.show': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'office.action.buttonCreate': {
    'ru': 'Создать',
    'en': 'Create'
  },
  'office.action.buttonUpdate': {
    'ru': 'Обновить',
    'en': 'Update'
  },
  'office.filter.field.laboratory.label': {
    'ru': 'Фильтровать по лаборатории',
    'en': 'Filter by laboratory'
  },
  'office.filter.field.laboratory.placeholder': {
    'ru': 'Выберите',
    'en': 'Select'
  },
  'office.field.titleRu.label': {
    'ru': 'Наименование',
    'en': 'Name'
  },
  'office.field.titleRu.placeholder': {
    'ru': 'Введите наименование',
    'en': 'Enter the name'
  },
  'office.field.titleEn.placeholder': {
    'ru': 'Наименование на английском',
    'en': 'Name in english'
  },
  'office.field.shortTitleRu.label': {
    'ru': 'Короткое наименование',
    'en': 'Short name'
  },
  'office.field.shortTitleRu.placeholder': {
    'ru': 'Не больше 20 символов',
    'en': 'No more than 20 characters'
  },
  'office.field.shortTitleEn.placeholder': {
    'ru': 'Короткое наименование на английском',
    'en': 'Short name in english'
  },
  'office.field.name.label': {
    'ru': 'Наименование',
    'en': 'Name'
  },
  'office.field.name.placeholder': {
    'ru': 'Введите наименование',
    'en': 'Enter the name'
  },
  'office.field.shortName.label': {
    'ru': 'Короткое наименование',
    'en': 'Short name'
  },
  'office.field.shortName.placeholder': {
    'ru': 'Не больше 20 символов',
    'en': 'No more than 20 characters'
  },
  'office.field.telephone.label': {
    'ru': 'Телефон',
    'en': 'Telephone'
  },
  'office.field.telephone.placeholder': {
    'ru': '+',
    'en': '+'
  },
  'office.field.siteUrl.label': {
    'ru': 'Адрес сайта',
    'en': 'Website'
  },
  'office.field.siteUrl.placeholder': {
    'ru': 'https://',
    'en': 'https://'
  },
  'office.field.disabled.label': {
    'ru': 'Скрыт',
    'en': 'Hidden'
  },
  'office.field.nationalBlocked.label': {
    'ru': 'Заблокирован',
    'en': 'Blocked'
  },
  'office.field.address.label': {
    'ru': 'Адрес',
    'en': 'Address'
  },
  'office.field.addressRu.label': {
    'ru': 'Адрес',
    'en': 'Address'
  },
  'office.field.addressRu.placeholder': {
    'ru': 'Начните вводить адрес',
    'en': 'Start typing the address'
  },
  'office.field.addressEn.placeholder': {
    'ru': 'Адрес на английском',
    'en': 'Address in english'
  },
  'office.field.latitude.label': {
    'ru': 'Широта',
    'en': 'Latitude'
  },
  'office.field.longitude.label': {
    'ru': 'Долгота',
    'en': 'Longitude'
  },
  'office.field.commentRu.placeholder': {
    'ru': 'Комментарий на русском',
    'en': 'Comment in russian'
  },
  'office.field.commentEn.placeholder': {
    'ru': 'Комментарий на английском',
    'en': 'Comment in english'
  },
  'office.section.heading.data': {
    'ru': 'Данные',
    'en': 'Data'
  },
  'office.section.heading.location': {
    'ru': 'Местоположение',
    'en': 'Location'
  },
  'office.section.heading.accessibility': {
    'ru': 'Доступность',
    'en': 'Accessibility'
  },
  'office.section.heading.requisites': {
    'ru': 'Реквизиты',
    'en': 'Requisites'
  },
  'office.section.heading.laboratory': {
    'ru': 'Лаборатория',
    'en': 'Laboratory'
  },
  'office.section.heading.country': {
    'ru': 'Страна',
    'en': 'Country'
  },
  'office.section.heading.comment': {
    'ru': 'Комментарий',
    'en': 'Comment'
  },
  'office.snippets.heading.name': {
    'ru': 'Наименование',
    'en': 'Name'
  },
  'office.snippets.heading.telephone': {
    'ru': 'Телефон',
    'en': 'Telephone'
  },
  'office.snippets.heading.createdDate': {
    'ru': 'Создано',
    'en': 'Created Date'
  },
  'office.snippets.heading.laboratory': {
    'ru': 'Лаборатория',
    'en': 'Laboratory'
  },
  'office.snippets.heading.address': {
    'ru': 'Адрес',
    'en': 'Address'
  },
  'office.snippets.heading.state': {
    'ru': 'Статус',
    'en': 'State'
  },
  'office.snippets.item.inn': {
    'ru': 'ИНН',
    'en': 'INN'
  },
  'office.toast.newOffice.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'office.toast.newOffice.success.description': {
    'ru': 'Офис успешно создан',
    'en': 'Office successfully created'
  },
  'office.toast.updateOffice.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'office.toast.updateOffice.success.description': {
    'ru': 'Офис успешно обновлен',
    'en': 'Office successfully updated'
  },
  'office.tabs.new.information': {
    'ru': 'Информация',
    'en': 'Information'
  },
  'office.tabs.new.schedule': {
    'ru': 'Расписание',
    'en': 'Schedule'
  },
  'office.tabs.new.requisites': {
    'ru': 'Реквизиты',
    'en': 'Requisites'
  },
  'office.tabs.new.comment': {
    'ru': 'Комментарий',
    'en': 'Comment'
  },
  'office.tabs.comment.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'office.tabs.comment.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'office.information.fillingRequisites': {
    'ru': 'Не заполняйте реквизиты, если они совпадают с лабораторией',
    'en': 'Do not fill in the requisites if they match the laboratory'
  },
  'user.heading.list': {
    'ru': 'Пользователи',
    'en': 'Users'
  },
  'user.heading.new': {
    'ru': 'Создание пользователя',
    'en': 'User creation'
  },
  'user.heading.update': {
    'ru': 'Редактирование пользователя',
    'en': 'User editing'
  },
  'user.action.buttonCreate': {
    'ru': 'Создать',
    'en': 'Create'
  },
  'user.action.buttonUpdate': {
    'ru': 'Обновить',
    'en': 'Update'
  },
  'user.alert.error.duplicate_username': {
    'ru': 'Пользователь с таким именем уже существует',
    'en': 'A user with the same name already exists'
  },
  'user.snippets.heading.login': {
    'ru': 'Логин',
    'en': 'Login'
  },
  'user.snippets.heading.name': {
    'ru': 'Имя',
    'en': 'Name'
  },
  'user.snippets.heading.role': {
    'ru': 'Роль',
    'en': 'Role'
  },
  'user.snippets.heading.state': {
    'ru': 'Статус',
    'en': 'State'
  },
  'user.field.name.label': {
    'ru': 'Наименование',
    'en': 'Name'
  },
  'user.field.name.placeholder': {
    'ru': 'Введите наименование',
    'en': 'Enter the name'
  },
  'user.field.username.label': {
    'ru': 'Логин',
    'en': 'Login'
  },
  'user.field.username.placeholder': {
    'ru': 'Введите логин',
    'en': 'Enter login'
  },
  'user.field.password.label': {
    'ru': 'Пароль',
    'en': 'Password'
  },
  'user.field.password.placeholder': {
    'ru': 'Введите пароль',
    'en': 'Enter password'
  },
  'user.field.role.label': {
    'ru': 'Роль',
    'en': 'Role'
  },
  'user.field.role.placeholder': {
    'ru': 'Выберите роль',
    'en': 'Choose a role'
  },
  'user.field.disabled.label': {
    'ru': 'Заблокирован',
    'en': 'Disabled'
  },
  'user.field.officeId.label': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'user.field.officeId.placeholder': {
    'ru': 'Выберите офис',
    'en': 'Select office'
  },
  'user.toast.newUser.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'user.toast.newUser.success.description': {
    'ru': 'успешно создан',
    'en': 'successfully created'
  },
  'user.toast.updateUser.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'user.toast.updateUser.success.description': {
    'ru': 'успешно обновлен',
    'en': 'successfully updated'
  },
  'laboratory.heading.list': {
    'ru': 'Лаборатории',
    'en': 'Laboratories'
  },
  'laboratory.heading.new': {
    'ru': 'Создание лаборатории',
    'en': 'SettingsInformation creation'
  },
  'laboratory.heading.update': {
    'ru': 'Редактирование лаборатории',
    'en': 'SettingsInformation editing'
  },
  'laboratory.heading.show': {
    'ru': 'Лаборатория',
    'en': 'Laboratory'
  },
  'laboratory.action.buttonCreate': {
    'ru': 'Создать',
    'en': 'Create'
  },
  'laboratory.action.buttonUpdate': {
    'ru': 'Обновить',
    'en': 'Update'
  },
  'laboratory.section.heading.data': {
    'ru': 'Данные',
    'en': 'Data'
  },
  'laboratory.section.heading.country': {
    'ru': 'Страна',
    'en': 'Country'
  },
  'laboratory.section.heading.requisites': {
    'ru': 'Реквизиты',
    'en': 'Requisites'
  },
  'laboratory.aside.showInCountries': {
    'ru': 'Видимость в странах',
    'en': 'Show in countries'
  },
  'laboratory.aside.filterCountries': {
    'ru': 'Фильтровать',
    'en': 'Filter'
  },
  'laboratory.aside.visibleAll': {
    'ru': 'Видно везде',
    'en': 'Visible to all'
  },
  'laboratory.aside.filtered': {
    'ru': 'Отфильтровано',
    'en': 'Filtered'
  },
  'laboratory.snippets.heading.name': {
    'ru': 'Имя',
    'en': 'Name'
  },
  'laboratory.snippets.heading.telephone': {
    'ru': 'Телефон',
    'en': 'Telephone'
  },
  'laboratory.snippets.heading.country': {
    'ru': 'Страна',
    'en': 'Country'
  },
  'laboratory.snippets.heading.createdDate': {
    'ru': 'Создание',
    'en': 'Created Date'
  },
  'laboratory.snippets.heading.state': {
    'ru': 'Статус',
    'en': 'State'
  },
  'laboratory.field.name.label': {
    'ru': 'Наименование',
    'en': 'Name'
  },
  'laboratory.field.name.placeholder': {
    'ru': 'Введите наименование',
    'en': 'Enter the name'
  },
  'laboratory.field.shortName.label': {
    'ru': 'Короткое наименование',
    'en': 'Short name'
  },
  'laboratory.field.shortName.placeholder': {
    'ru': 'Не больше 20 символов',
    'en': 'No more than 20 characters'
  },
  'laboratory.field.shortName.description': {
    'ru': 'Короткое наименование будет отображаться в Мобильном Приложении клиента',
    'en': `The short name will be displayed in the client's Mobile Application`
  },
  'laboratory.field.telephone.label': {
    'ru': 'Телефон',
    'en': 'Telephone'
  },
  'laboratory.field.telephone.placeholder': {
    'ru': '+',
    'en': '+'
  },
  'laboratory.field.siteUrl.label': {
    'ru': 'Адрес сайта',
    'en': 'Website'
  },
  'laboratory.field.siteUrl.placeholder': {
    'ru': 'https://',
    'en': 'https://'
  },
  'laboratory.field.disabled.label': {
    'ru': 'Заблокирован',
    'en': 'Disabled'
  },
  'laboratory.field.pubKey.label': {
    'ru': 'Публичный ключ',
    'en': 'Public key'
  },
  'laboratory.toast.newLaboratory.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'laboratory.toast.newLaboratory.success.description': {
    'ru': 'успешно создан',
    'en': 'successfully created'
  },
  'laboratory.toast.updateLaboratory.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'laboratory.toast.updateLaboratory.success.description': {
    'ru': 'успешно обновлен',
    'en': 'successfully updated'
  },
  'laboratory.tabs.new.information': {
    'ru': 'Информация',
    'en': 'Information'
  },
  'laboratory.tabs.new.safety': {
    'ru': 'Безопасность',
    'en': 'Safety'
  },
  'laboratory.tabs.new.requisites': {
    'ru': 'Реквизиты',
    'en': 'Requisites'
  },
  'laboratory.tabs.show.data': {
    'ru': 'Данные',
    'en': 'Data'
  },
  'laboratory.tabs.show.country': {
    'ru': 'Страна',
    'en': 'Country'
  },
  'laboratory.tabs.show.requisites': {
    'ru': 'Реквизиты',
    'en': 'Requisites'
  },
  'limitHours.action.buttonSettingLimits': {
    'ru': 'Лимит',
    'en': 'Limit'
  },
  'limitHours.heading.settingLimits': {
    'ru': 'Настройка лимита',
    'en': 'Limit setting'
  },
  'limitHours.field.validHours.label': {
    'ru': 'Лимит теста в часах',
    'en': 'TestList limit in hours'
  },
  'limitHours.toast.updateLimits.success.title': {
    'ru': 'Обновлен лимит действия теста',
    'en': 'Updated test action limit'
  },
  'limitHours.toast.updateLimits.success.description': {
    'ru': 'Через несколько минут обновится:',
    'en': 'In a few minutes it will update:'
  },
  'settingsAutoNumbering.heading.setting': {
    'ru': 'Настройка автогенерации',
    'en': 'Settings Auto Numbering'
  },
  'settingsAutoNumbering.button.label': {
    'ru': 'Префикс',
    'en': 'Prefix'
  },
  'settingsAutoNumbering.field.autoNumberingEnabled.label': {
    'ru': 'Включить',
    'en': 'Enable'
  },
  'settingsAutoNumbering.field.autoNumberingPrefix.label': {
    'ru': 'Префикс перед номером',
    'en': 'Prefix before number'
  },
  'settingsAutoNumbering.toast.update.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'settingsAutoNumbering.toast.update.success.description': {
    'ru': 'Настройки упешно обновлены',
    'en': 'Settings have been successfully updated'
  },
  'settingInsurance.action.buttonSettingLimits': {
    'ru': 'Страхование',
    'en': 'Insurance'
  },
  'settingInsurance.heading.settingLimits': {
    'ru': 'Настройка страхования',
    'en': 'Insurance setting'
  },
  'settingInsurance.field.insuranceUrl.label': {
    'ru': 'URL',
    'en': 'URL'
  },
  'settingInsurance.toast.updateLimits.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'settingInsurance.toast.updateLimits.success.description': {
    'ru': 'Обновлен URL',
    'en': 'Updated URL'
  },
  'settingDocuments.heading.list': {
    'ru': 'Правовые документы',
    'en': 'Legal document'
  },
  'settingDocuments.heading.show': {
    'ru': 'Документ',
    'en': 'Document'
  },
  'settingDocuments.action.button': {
    'ru': 'Документы',
    'en': 'Documents'
  },
  'settingDocuments.tabs.description.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'settingDocuments.tabs.description.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'settingDocuments.snippets.heading.name': {
    'ru': 'Имя',
    'en': 'name'
  },
  'settingDocuments.snippets.heading.actions': {
    'ru': 'Действия',
    'en': 'Actions'
  },
  'settingDocuments.field.titleRu.placeholder': {
    'ru': 'Заголовок на русском',
    'en': 'Title in russian'
  },
  'settingDocuments.field.titleEn.placeholder': {
    'ru': 'Заголовок на английском',
    'en': 'Title in english'
  },
  'settingDocuments.field.valueRu.placeholder': {
    'ru': 'Описание на русском',
    'en': 'Description in russian'
  },
  'settingDocuments.field.valueEn.placeholder': {
    'ru': 'Описание на английском',
    'en': 'Description in english'
  },
  'settingDocuments.toast.update.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'settingDocuments.toast.update.description': {
    'ru': 'Документ успешно обновлен',
    'en': 'Document updated successfully'
  },
  'settingDocuments.toast.create.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'settingDocuments.toast.create.description': {
    'ru': 'Документ успешно создан',
    'en': 'Document successfully created'
  },
  'settingInformation.heading.list': {
    'ru': 'Информационные поля',
    'en': 'Information fields'
  },
  'settingInformation.heading.show': {
    'ru': 'Поле',
    'en': 'Field'
  },
  'settingInformation.action.button': {
    'ru': 'Инфо',
    'en': 'Info'
  },
  'settingInformation.tabs.description.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'settingInformation.tabs.description.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'settingInformation.snippets.heading.name': {
    'ru': 'Имя',
    'en': 'name'
  },
  'settingInformation.snippets.heading.actions': {
    'ru': 'Действия',
    'en': 'Actions'
  },
  'settingInformation.field.titleRu.placeholder': {
    'ru': 'Заголовок на русском',
    'en': 'Title in russian'
  },
  'settingInformation.field.titleEn.placeholder': {
    'ru': 'Заголовок на английском',
    'en': 'Title in english'
  },
  'settingInformation.field.valueRu.placeholder': {
    'ru': 'Описание на русском',
    'en': 'Description in russian'
  },
  'settingInformation.field.valueEn.placeholder': {
    'ru': 'Описание на английском',
    'en': 'Description in english'
  },
  'settingInformation.toast.update.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'settingInformation.toast.update.description': {
    'ru': 'Поле успешно обновлено',
    'en': 'Field updated successfully'
  },
  'settingInformation.toast.create.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'settingInformation.toast.create.description': {
    'ru': 'Поле успешно создано',
    'en': 'Field created successfully'
  },
  'testType.heading': {
    'ru': 'Вид теста',
    'en': 'Test type'
  },
  'testType.field.title': {
    'ru': 'Наименование',
    'en': 'Title'
  },
  'testType.field.shortTitle': {
    'ru': 'Короткое наименование',
    'en': 'Short title'
  },
  'testType.field.actions': {
    'ru': 'Действия',
    'en': 'Actions'
  },
  'agreement.heading.update': {
    'ru': 'Соглашение',
    'en': 'Agreement'
  },
  'agreement.action.buttonAgreement': {
    'ru': 'Соглашение',
    'en': 'Agreement'
  },
  'agreement.field.descriptionRu.placeholder': {
    'ru': 'На русском',
    'en': 'Description in russian'
  },
  'agreement.field.descriptionEn.placeholder': {
    'ru': 'На английском',
    'en': 'Description in english'
  },
  'agreement.tabs.description.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'agreement.tabs.description.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'agreement.toast.updateAgreement.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'agreement.toast.updateAgreement.success.description': {
    'ru': 'Соглашение успешно обновлено',
    'en': 'Agreement successfully updated'
  },
  'country.heading.list': {
    'ru': 'Страны',
    'en': 'Country'
  },
  'country.heading.new': {
    'ru': 'Создание страны',
    'en': 'Country creation'
  },
  'country.heading.update': {
    'ru': 'Редактирование страны',
    'en': 'Country editing'
  },
  'country.action.buttonCreate': {
    'ru': 'Создать',
    'en': 'Create'
  },
  'country.action.buttonUpdate': {
    'ru': 'Обновить',
    'en': 'Update'
  },
  'country.snippets.heading.login': {
    'ru': 'Логин',
    'en': 'Login'
  },
  'country.snippets.heading.name': {
    'ru': 'Имя',
    'en': 'Name'
  },
  'country.snippets.heading.role': {
    'ru': 'Роль',
    'en': 'Role'
  },
  'country.snippets.heading.state': {
    'ru': 'Статус',
    'en': 'State'
  },
  'country.tabs.new.information': {
    'ru': 'Информация',
    'en': 'Information'
  },
  'country.tabs.new.safety': {
    'ru': 'Безопасность',
    'en': 'Safety'
  },
  'country.tabs.new.description': {
    'ru': 'Правила въезда',
    'en': 'Entry rules'
  },
  'country.tabs.new.testingInfo': {
    'ru': 'Описание тестирования при выезде',
    'en': 'Description of on-site testing'
  },
  'country.tabs.new.vaccination': {
    'ru': 'Вакцинация',
    'en': 'Vaccination'
  },
  'country.tabs.vaccination.setting.on': {
    'ru': 'Включить вакцинацию',
    'en': 'Enable vaccination'
  },
  'country.tabs.vaccination.setting.qr': {
    'ru': 'QR вакцинации',
    'en': 'Vaccination QR'
  },
  'country.tabs.vaccination.setting.type': {
    'ru': 'Тип вакцинации',
    'en': 'Vaccination type'
  },
  'country.tabs.new.recovered': {
    'ru': 'Переболевшие',
    'en': 'Recovered'
  },
  'country.tabs.recovered.setting.on': {
    'ru': 'Включить код переболевшего',
    'en': 'Enable recovered vaccination'
  },
  'country.tabs.recovered.setting.qr': {
    'ru': 'QR переболевшего',
    'en': 'Recovered QR'
  },
  'country.tabs.recovered.setting.type': {
    'ru': 'Тип переболевшего',
    'en': 'Recovered type'
  },
  'country.tabs.description.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'country.tabs.description.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'country.tabs.description.otherSettings': {
    'ru': 'Другие настройки',
    'en': 'Settings'
  },
  'country.tabs.testingInfo.inRussian': {
    'ru': 'На русском',
    'en': 'In russian'
  },
  'country.tabs.testingInfo.inEnglish': {
    'ru': 'На английском',
    'en': 'In english'
  },
  'country.section.heading.location': {
    'ru': 'Местоположение',
    'en': 'Location'
  },
  'country.field.image.label': {
    'ru': 'Картинка',
    'en': 'Image'
  },
  'country.field.nameRu.label': {
    'ru': 'Наименование на русском',
    'en': 'Name in russian'
  },
  'country.field.nameEn.placeholder': {
    'ru': 'Наименование на английском',
    'en': 'Name in english'
  },
  'country.field.shortNameRu.label': {
    'ru': 'Короткое наименование на русском',
    'en': 'Short name in russian'
  },
  'country.field.shortNameRu.placeholder': {
    'ru': 'Не больше 20 символов',
    'en': 'No more than 20 characters'
  },
  'country.field.shortNameEn.placeholder': {
    'ru': 'Короткое наименование на английском',
    'en': 'Short name in english'
  },
  'country.field.nationalUrl.label': {
    'ru': 'API',
    'en': 'API'
  },
  'country.field.nationalUrl.placeholder': {
    'ru': 'https://',
    'en': 'https://'
  },
  'country.field.countryCode.label': {
    'ru': 'Код страны',
    'en': 'Country Code'
  },
  'country.field.disabled.label': {
    'ru': 'Заблокирован',
    'en': 'Disabled'
  },
  'country.field.nationalPubKey.label': {
    'ru': 'Публичный ключ',
    'en': 'Public key'
  },
  'country.field.descriptionRu.placeholder': {
    'ru': 'Описание на русском',
    'en': 'Description in russian'
  },
  'country.field.descriptionEn.placeholder': {
    'ru': 'Описание на английском',
    'en': 'Description in english'
  },
  'country.field.testingInfoRu.placeholder': {
    'ru': 'Описание на русском',
    'en': 'Description in russian'
  },
  'country.field.testingInfoEn.placeholder': {
    'ru': 'Описание на английском',
    'en': 'Description in english'
  },
  'country.toast.newCountry.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'country.toast.newCountry.success.description': {
    'ru': 'Страна успешно создана',
    'en': 'Country successfully created'
  },
  'country.toast.updateCountry.success.title': {
    'ru': 'Готово',
    'en': 'Done'
  },
  'country.toast.updateCountry.success.description': {
    'ru': 'Страна успешно обновлена',
    'en': 'Country successfully updated'
  },
  'country.aside.laboratoryCount': {
    'ru': 'Лабораторий подключено',
    'en': 'Laboratories connected'
  },
  'message.error.duplicateLaboratory': {
    'ru': 'Лаборатория с такими данными заведена в системе',
    'en': 'A laboratory with such data is set up in the system'
  },
  'message.error.duplicateOffice': {
    'ru': 'Офис с такими данными заведен в системе',
    'en': 'An office with such data is set up in the system'
  },
  'message.error.form': {
    'ru': 'Есть ошибки в форме',
    'en': 'There are errors when filling out'
  },
  'requisites.oid.label': {
    'ru': 'OID',
    'en': 'OID'
  },
  'requisites.oid.description': {
    'ru': 'Заполняется согласно Приложения «В12». «О ведении идентификаторов объектов Минздрава России, назначаемых в рамках российского национального сегмента международного дерева идентификаторов объектов» (Реестр идентификаторов объектов Минздрава РФ) «Субъекты системы здравоохранения: ведомства, учреждения, организации, структурные подразделения»\'',
    'en': 'Filled in according to Appendix \'B12\'. \'On maintaining identifiers of objects of the Ministry of Health of Russia, assigned within the Russian national segment of the international tree of object identifiers\' (Register of identifiers of objects of the Ministry of Health of the Russian Federation) \'Subjects of the healthcare system: departments, institutions, organizations, structural units\''
  },
  'requisites.inn.label': {
    'ru': 'ИНН',
    'en': 'INN'
  },
  'requisites.unp.label': {
    'ru': 'УНП',
    'en': 'UNP'
  },
  'requisites.company.label': {
    'ru': 'Юридическое наименование',
    'en': 'Legal name'
  },
  'requisites.kpp.label': {
    'ru': 'КПП',
    'en': 'KPP'
  },
  'requisites.ogrn.label': {
    'ru': 'ОГРН',
    'en': 'OGRN'
  },
  'requisites.ownership.label': {
    'ru': 'Форма собственности',
    'en': 'Type of ownership'
  },
  'requisites.ownership.placeholder': {
    'ru': 'Выберите форму собственности',
    'en': 'Choose a form of ownership'
  },
  'requisites.ownership.option.political': {
    'ru': 'Государственная',
    'en': 'State'
  },
  'requisites.ownership.option.private': {
    'ru': 'Частная',
    'en': 'Private'
  },
  'requisites.ownership.option.municipal': {
    'ru': 'Муниципальная',
    'en': 'Municipal'
  },
  'requisites.addressLegal.label': {
    'ru': 'Фактический адрес',
    'en': 'Address Legal'
  },
  'login.description': {
    'ru': 'Введите логин и пароль',
    'en': 'Enter login and password'
  },
  'login.error': {
    'ru': 'Ошибка авторизации',
    'en': 'Authorisation Error'
  },
  'login.field.username': {
    'ru': 'Логин',
    'en': 'Username'
  },
  'login.field.password': {
    'ru': 'Пароль',
    'en': 'Password'
  },
  'login.action.buttonSubmit': {
    'ru': 'Войти',
    'en': 'Login'
  },
  'loading': {
    'ru': 'Загрузка...',
    'en': 'Loading...'
  },
  'headingAdminPanel.laboratory': {
    'ru': 'Лаборатория',
    'en': 'Laboratory'
  },
  'headingAdminPanel.national': {
    'ru': 'Национальный компонент',
    'en': 'National'
  },
  'headingAdminPanel.integration': {
    'ru': 'Интеграционный компонент',
    'en': 'Integration'
  },
  'readOnlyForm.action.block': {
    'ru': 'Заблокировать',
    'en': 'Block'
  },
  'readOnlyForm.action.unblock': {
    'ru': 'Разблокировать',
    'en': 'Unblock'
  },
  'readOnlyForm.heading': {
    'ru': 'Разблокировка',
    'en': 'Unlocking'
  },
  'readOnlyForm.description': {
    'ru': 'Изменение этих настроек повлияет на работу всего сервиса. Не меняйте их без необходимости.',
    'en': 'Changing these settings will affect the operation of the entire service. Do not change them unnecessarily.'
  },
  'reports.heading.total': {
    'ru': 'Отчет',
    'en': 'Report'
  },
  'reports.heading.full': {
    'ru': 'Полный отчет',
    'en': 'Full report'
  },
  'reports.heading.laboratoryUser': {
    'ru': 'По пользователям',
    'en': 'By users'
  },
  'reports.heading.reportLaboratory': {
    'ru': 'Общий отчет',
    'en': 'General report'
  },
  'reports.update.label': {
    'ru': 'Конфигурация отчетов',
    'en': 'Reports settings'
  },
  'reports.update.reports': {
    'ru': 'Тип отчета',
    'en': 'Reports'
  },
  'reports.update.roles': {
    'ru': 'Роли',
    'en': 'Roles'
  },
  'reports.action.reportTotal': {
    'ru': 'Краткий отчет',
    'en': 'Report'
  },
  'reports.action.reportFull': {
    'ru': 'Полный отчет',
    'en': 'Full report'
  },
  'reports.action.reportLaboratory': {
    'ru': 'Общий отчет',
    'en': 'General report'
  },
  'reports.action.laboratoryUser': {
    'ru': 'По пользователям',
    'en': 'By users'
  },
  'reports.action.reportOverall': {
    'ru': 'Общий отчет по лабораториям',
    'en': 'Overall report on laboratories'
  },
  'reports.field.laboratoryId.label': {
    'ru': 'Лаборатория',
    'en': 'Laboratory'
  },
  'reports.field.format.label': {
    'ru': 'Формат',
    'en': 'Format'
  },
  'reports.field.OfficeId.label': {
    'ru': 'Офис',
    'en': 'Office'
  },
  'reports.field.laboratoryId.optionAll': {
    'ru': 'Все лаборатории',
    'en': 'All laboratory'
  },
  'reports.field.testState.label': {
    'ru': 'Статус',
    'en': 'State'
  },
  'reports.field.showOffices.label': {
    'ru': 'Показывать офисы',
    'en': 'Show Offices'
  },
  'reports.field.groupByOffice.label': {
    'ru': 'Группировать по офисам',
    'en': 'Group By Office'
  },
  'reports.result.allTest': {
    'ru': 'Всего тестов',
    'en': 'Total tests'
  },
  'reports.result.false': {
    'ru': 'Отрицательных',
    'en': 'Negative'
  },
  'reports.result.true': {
    'ru': 'Положительных',
    'en': 'Positive'
  },
  'vaccination.new': {
    'ru': 'Новый',
    'en': 'New'
  },
  'vaccination.partiallyCompleted': {
    'ru': 'Проведена частично',
    'en': 'Partially Completed'
  },
  'vaccination.completed': {
    'ru': 'Завершена',
    'en': 'Completed'
  },
  'vaccination.invalid': {
    'ru': 'Не действительна',
    'en': 'Invalid'
  },
  'vaccination.expired': {
    'ru': 'Просрочена',
    'en': 'Expired'
  },
  'vaccination.empty': {
    'ru': 'Недоступно',
    'en': 'Empty'
  },
  'reports.result.waiting': {
    'ru': 'В ожидании',
    'en': 'Waiting'
  }
}
