import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';

export const ACTION_TYPES = {
    FETCH_COUNTRY_LIST: 'country/FETCH_COUNTRY_LIST',
    RESET_COUNTRY_LIST: 'country/RESET_COUNTRY_LIST',
    FETCH_COUNTRY_ALL_LIST: 'country/FETCH_COUNTRY_ALL_LIST',
    RESET_COUNTRY_ALL_LIST: 'country/RESET_COUNTRY_ALL_LIST',
    FETCH_COUNTRY: 'country/FETCH_COUNTRY',
    CREATE_COUNTRY: 'country/CREATE_COUNTRY',
    UPDATE_COUNTRY: 'country/UPDATE_COUNTRY',
    FETCH_ACCEPT_LABORATORY: 'country/FETCH_ACCEPT_LABORATORY',
    RESET_COUNTRY: 'country/RESET_COUNTRY',
    RESET_ACCEPT_LABORATORY: 'country/RESET_ACCEPT_LABORATORY',
    RESET: 'country/RESET'
};

const PAGE_SIZE = 20;
const START_PAGE = 1;

const initialState = {
    items: [],
    itemsAll: [],
    itemsAccept: [],
    item: {},
    loadingUpdate: false,
    loadingList: false,
    loadingItem: false,
    loadingAccept: false,
    errorMessage: '',
    totalItems: 0,
    totalPages: 0,
    activePage: START_PAGE
};

export const countryReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_COUNTRY): {
            return {
                ...state,
                loadingItem: true
            }
        }
        case REQUEST(ACTION_TYPES.FETCH_COUNTRY_ALL_LIST):
        case REQUEST(ACTION_TYPES.FETCH_COUNTRY_LIST):
        {
            return {
                ...state,
                loadingList: true
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_COUNTRY):
        case REQUEST(ACTION_TYPES.UPDATE_COUNTRY): {
            return {
                ...state,
                loadingUpdate: true
            }
        }
        case REQUEST(ACTION_TYPES.FETCH_ACCEPT_LABORATORY): {
            return {
                ...state,
                loadingAccept: true
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_COUNTRY_ALL_LIST):
        case FAILURE(ACTION_TYPES.FETCH_COUNTRY_LIST):
        {
            return {
                ...state,
                loadingList: false
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_COUNTRY):
        case FAILURE(ACTION_TYPES.CREATE_COUNTRY):
        case FAILURE(ACTION_TYPES.UPDATE_COUNTRY): {
            return {
                ...state,
                loadingItem: false,
                loadingUpdate: false
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_ACCEPT_LABORATORY):{
            return {
                ...state,
                loadingAccept: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_COUNTRY_ALL_LIST): {
            return {
                ...state,
                loadingList: false,
                itemsAll: action.payload.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_COUNTRY_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loadingList: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage']
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_COUNTRY): {
            return {
                ...state,
                item: action.payload.data,
                loadingItem: false
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_COUNTRY): {
            return {
                ...state,
                item: action.payload.data,
                loadingItem: false,
                loadingUpdate: false
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_COUNTRY): {
            return {
                ...state,
                item: action.payload.data,
                loadingItem: false,
                loadingUpdate: false
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_ACCEPT_LABORATORY): {
            return {
                ...state,
                itemsAccept: action.payload.data,
                loadingAccept: false
            }
        }
        case ACTION_TYPES.RESET_COUNTRY_LIST: {
            return {
                ...state,
                items: [],
                loadingList: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE,
            }
        }
        case ACTION_TYPES.RESET_COUNTRY_ALL_LIST: {
            return {
                ...state,
                itemsAll: [],
                loadingList: false
            }
        }
        case ACTION_TYPES.RESET_COUNTRY: {
            return {
                ...state,
                item: {},
                loadingItem: false,
                loadingUpdate: false
            }
        }
        case ACTION_TYPES.RESET_ACCEPT_LABORATORY: {
            return {
                ...state,
                itemsAccept: [],
                loadingAccept: false
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loadingItem: false,
                loadingList: false,
                itemsAccept: [],
                loadingAccept: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/country`;

export const getCountryAllList = () => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.FETCH_COUNTRY_ALL_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getCountryListNavigation = (page = START_PAGE) => {
    const requestUrl = `${API_URL}/?pageSize=${PAGE_SIZE}&page=${page}`;
    return {
        type: ACTION_TYPES.FETCH_COUNTRY_LIST,
        payload: axios.get(requestUrl)
    };
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().country.activePage;
    dispatch(getCountryListNavigation(activePage + 1))
};

export const getCountry = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_COUNTRY,
        payload: axios.get(requestUrl)
    };
};

export const createCountry = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_COUNTRY,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateCountry = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.UPDATE_COUNTRY,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const getAcceptLaboratory = (id) => {
    const requestUrl = `${API_URL}/${id}/acceptlabs`;
    return {
        type: ACTION_TYPES.FETCH_ACCEPT_LABORATORY,
        payload: axios.get(requestUrl)
    };
};

export const resetAcceptLaboratory = () => {
    return {
        type: ACTION_TYPES.RESET_ACCEPT_LABORATORY
    };
};

export const resetCountryList = () => {
    return {
        type: ACTION_TYPES.RESET_COUNTRY_LIST
    };
};

export const resetCountryAllList = () => {
    return {
        type: ACTION_TYPES.RESET_COUNTRY_ALL_LIST
    };
};

export const resetCountry = () => {
    return {
        type: ACTION_TYPES.RESET_COUNTRY
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
