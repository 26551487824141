import React, { useState } from 'react';
import { Box, Collapse, Grid } from '@chakra-ui/react';
import { FormHeading } from './form-heading';

export const FormSection = (
{
    heading,
    children,
    collapse = false,
    showContent = true,
    ...props
}) => {
    const [show, setShow] = useState(showContent);
    const handleToggle = () => setShow(!show);
    return (
        <Box>
            {heading && (
                <FormHeading
                    onClick={handleToggle}
                    icon={collapse && (show ? 'chevron-up' : 'chevron-down' )}
                >
                    {heading}
                </FormHeading>
            )}
            <Collapse mt={2} in={!collapse || show}>
                <Grid gridRowGap={4} mb={6} {...props}>{children}</Grid>
            </Collapse>
        </Box>
    );
};
