import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PushList } from './PushList/PushList';
import { PushUpdate } from './PushUpdate/PushUpdate';
import { PushCard } from './PushCard/PushCard';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <PushUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <PushUpdate/>
      </Route>
      <Route path={`${match.url}/show/:id`} exact>
        <PushCard/>
      </Route>
      <Route path={`${match.url}`}>
        <PushList/>
      </Route>
    </Switch>
  );
};

export default Routes;
