import React, { useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { Laboratory } from 'pages/laboratory'
import { National } from 'pages/national'
import { Integration } from 'pages/integration'
import { Vaccination } from 'pages/vaccination'
import { Logout } from 'logout'
import { Login } from 'login'
import PrintTest from 'pages/PrintTest'
import { Flex } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { checkAuth } from 'store/authentication.reducer'
import { isEmpty } from 'components/deprecated/utilities/is-empty'
import { getAdminPanelInfo, getTestValidHours } from 'store/settings.reducer'
import './style.scss'
import { LoadingSettings } from 'components/deprecated/layout'
import LoadingBar from 'react-redux-loading-bar'
import { PrintVaccinationCertificate } from 'pages/PrintVaccinationCertificate'

export const TYPE_REDIRECT = {
  laboratory: '/laboratory',
  national: '/national/laboratory',
  integration: '/integration/country',
  vaccination: '/vaccination/list',
}

function App() {
  const dispatch = useDispatch()
  const userAuth = useSelector((state) => state.authentication.user)
  const loading = useSelector((state) => state.settings.loading)
  const typeAdminPanel = useSelector((state) => state.settings.typeAdminPanel)
  const isAuthenticated = !isEmpty(userAuth)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getAdminPanelInfo())
  }, [])

  useEffect(() => {
    if (typeAdminPanel !== '') {
      dispatch(checkAuth())
    }
  }, [typeAdminPanel])

  useEffect(() => {
    if (isAuthenticated) {
      if (
        typeAdminPanel === 'laboratory' ||
        typeAdminPanel === 'national' ||
        typeAdminPanel === 'integration'
      ) {
        dispatch(getTestValidHours())
      }
    }
  }, [isAuthenticated])

  return (
    <>
      <LoadingBar updateTime={10} style={{ backgroundColor: '#3182CE', height: '2px' }} />
      {typeAdminPanel !== '' ? (
        <Switch>
          <Route path={`/print`}>
            <PrintTest />
          </Route>
          <Route path={`/print-vaccination-certificate/:id`}>
            <PrintVaccinationCertificate />
          </Route>
          <Route path={`/laboratory`}>
            <Laboratory />
          </Route>
          <Route path={`/national`}>
            <National />
          </Route>
          <Route path={`/integration`}>
            <Integration />
          </Route>
          <Route path={`/vaccination`}>
            <Vaccination />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/">
            {isAuthenticated ? <Redirect to={TYPE_REDIRECT[typeAdminPanel]} /> : <Login />}
          </Route>
        </Switch>
      ) : loading ? (
        <LoadingSettings />
      ) : (
        <Flex alignItems="center" justifyContent="center" width="100%" height="300px">
          Error loading admin configuration
        </Flex>
      )}
    </>
  )
}

export default App;
