import axios from 'axios';
import { API_SERVER_URL } from '../store/config';

const ENTITY = 'settings'

export const settings = Object.freeze({
  async setPushEnabled(value) {
    return await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'status_push_enabled',
      value
    })
  },
  async fetchPushEnabled() {
    return await axios.get(`${API_SERVER_URL}/${ENTITY}/status_push_enabled`)
  },
  async setFormSamplesEnabled(value) {
    return await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'form_samples_enabled',
      value
    })
  },
  async fetchFormSamplesEnabled() {
    return await axios.get(`${API_SERVER_URL}/${ENTITY}/form_samples_enabled`)
  },
  async setFormSamplesBanner(value) {
    return await axios.post(`${API_SERVER_URL}/${ENTITY}`, {
      key: 'form_samples_banner',
      value
    })
  },
  async fetchFormSamplesBanner() {
    return await axios.get(`${API_SERVER_URL}/${ENTITY}/form_samples_banner`)
  },
})
