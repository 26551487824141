import React, { useContext, useEffect } from 'react'
import {
  Container,
  FilterHeader,
  FilterStack,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside,
} from 'chakra-lib/layout'
import { FormLabel, Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from 'chakra-lib'
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from 'chakra-lib/Snippets'
import { IconList } from 'components/icons'
import { TranslationContext } from 'translation'
import { Box, Button, Text, VStack } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { getDateTimeReadFormat } from 'chakra-lib/utils/dateFormatting'
import { FormSamplesEnabled } from './elements/FormSamplesEnabled/FormSamplesEnabled'
import { FormSamplesBanner } from './elements/FormSamplesBanner/FormSamplesBanner'
import { useFormSampleList } from './_services'

const TYPE_COLOR_DISABLED = {
  true: 'red',
  false: 'green',
}

const TYPE_DISABLED = {
  true: 'Скрытый',
  false: 'Активен',
}

export const FormSampleList = () => {
  const history = useHistory()
  const { getTranslation } = useContext(TranslationContext)
  const { request, response, next, isLoading } = useFormSampleList()

  useEffect(() => {
    request()
  }, [])

  return (
    <Container>
      <SnippetsContainer indentTop={120}>
        <SnippetsContainerMain>
          <Snippets columns="42px minmax(200px, 1fr) 320px 220px 160px">
            <SnippetsHeading>
              <HeadingItem />
              <HeadingItem>Наименвоание</HeadingItem>
              <HeadingItem>Дата создания</HeadingItem>
              <HeadingItem>Видимость</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={response?.currentPage}
              totalPages={response?.totalPages}
              data={response?.list}
              isLoading={isLoading}
              onNext={next}
            >
              {(item) => (
                <SnippetsItem to={`/integration/dictionaries/form-sample/edit/${item.id}`}>
                  <ItemIcon icon={IconList} />
                  <ItemLink>
                    <Box>{item.title?.ru}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {item.title?.en}
                    </Text>
                  </ItemLink>
                  <ItemText>{getDateTimeReadFormat(item?.createdAt)}</ItemText>
                  <ItemText>
                    <ItemBadge colorScheme={TYPE_COLOR_DISABLED[item.hidden]}>
                      {TYPE_DISABLED[item.hidden]}
                    </ItemBadge>
                  </ItemText>
                </SnippetsItem>
              )}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/integration/dictionaries/form-sample/new`)}
              colorScheme="blue"
            >
              {getTranslation('test.action.buttonCreate')}
            </Button>
          </FilterHeader>
          <FilterStack>
            <Box>
              <FormLabel>Настройки</FormLabel>
              <VStack spacing={3}>
                <FormSamplesEnabled />
                <FormSamplesBanner />
              </VStack>
            </Box>
          </FilterStack>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  )
}
