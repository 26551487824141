import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

import { Table } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getFilterParams } from 'chakra-lib/utils'
import { FILTER_LABORATORY_PARAMS } from 'config/laboratory.config'
import { getNextPage, resetLaboratoryList, updateFilter } from 'store/laboratory.reducer'
import { getCountryAllList, resetCountryAllList } from 'store/country.reducer'
import { LaboratoryListFilter } from './elements/LaboratoryListFilter'
import { exportExcelFile } from 'utils/exportExcelFile'
import Register from 'components/layout/Register'
import { STORAGE_TABLE } from 'model/localStorage'
import { makeExcelColumns } from './LaboratoryList.excel'
import { makeColumns } from './LaboratoryList.config'
import { LABORATORY_FIELDS } from '../Laboratory.constants'

export const LaboratoryList = () => {
  const tableRef = useRef()
  const history = useHistory()
  const { getTranslation } = useContext(TranslationContext)
  const dispatch = useDispatch()
  const { params } = useParams()
  const itemsCountry = useSelector((state) => state.country.itemsAll)
  const itemsLaboratory = useSelector((state) => state.laboratory.items)
  const activePage = useSelector((state) => state.laboratory.activePage)
  const totalPages = useSelector((state) => state.laboratory.totalPages)
  const loading = useSelector((state) => state.laboratory.loading)
  const userAuth = useSelector((state) => state.authentication.user)

  const isReadySnippets = Boolean(
    itemsLaboratory && itemsLaboratory.length && itemsCountry && itemsCountry.length
  )

  const items = useMemo(() => {
    if (isReadySnippets) {
      return itemsLaboratory.map((item) => ({
        ...item,
        ...mapCountryValues(itemsCountry, item.countryId),
      }))
    }

    return []
  }, [isReadySnippets, itemsLaboratory, itemsCountry])

  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_LABORATORY_PARAMS, params)
      dispatch(updateFilter(objFilterParams))
    } else {
      dispatch(updateFilter())
    }
  }, [params])

  useEffect(() => {
    dispatch(getCountryAllList())
    return () => {
      dispatch(resetCountryAllList())
      dispatch(resetLaboratoryList())
    }
  }, [])

  const exportExcelHandle = useCallback(() => {
    const hiddenColumns = tableRef.current.state.hiddenColumns

    exportExcelFile(items, {
      sheetName: 'Laboratory',
      columns: makeExcelColumns({ getTranslation, hiddenColumns }),
    })
  }, [items])

  const columns = useMemo(() => makeColumns({ getTranslation }), [])

  const onRowClick = useCallback(({ original }) => {
    history.push(`/integration/laboratory/show/${original.id}`)
  }, [])

  const initialState = useMemo(
    () => ({
      hiddenColumns: [
        LABORATORY_FIELDS.shortName,
        LABORATORY_FIELDS.siteUrl,
        LABORATORY_FIELDS.countryRu,
        LABORATORY_FIELDS.countryShortRu,
        LABORATORY_FIELDS.inn,
        LABORATORY_FIELDS.oid,
        LABORATORY_FIELDS.ogrn,
        LABORATORY_FIELDS.company,
        LABORATORY_FIELDS.ownership,
        LABORATORY_FIELDS.addressLegal,
      ],
    }),
    []
  )

  return (
    <Register rightPanel={<LaboratoryListFilter params={params} onExportExcel={exportExcelHandle} />}>
      <Table
        ref={tableRef}
        data={items}
        columns={columns}
        isLoading={loading}
        hasNext={activePage < totalPages}
        onNextPage={() => dispatch(getNextPage())}
        onRowClick={onRowClick}
        initialState={initialState}
        storageId={`${STORAGE_TABLE.laboratoryList}.${userAuth.id}`}
      />
    </Register>
  )
}

const mapCountryValues = (items = [], id) => {
  const item = items.find((item) => item.id === id)
  return {
    countryRu: item.name ? (item.name.ru ? item.name.ru : '') : '',
    countryShortRu: item.shortName ? (item.shortName.ru ? item.shortName.ru : '') : '',
  }
}