import React, { memo, ReactElement } from 'react'

import {
  Box,
  BoxProps,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { ColumnInstance } from 'react-table'
import { SettingsIcon } from '@chakra-ui/icons'
import { Checkbox } from './Checkbox'

export const THeader: React.FC<BoxProps> = memo(({ children, ...props }) => {
  return (
    <Box zIndex={10} position="sticky" top={0} {...props}>
      {children}
    </Box>
  )
})

export const HeaderRow: React.FC<BoxProps> = memo(({ children, ...props }) => {
  return <Grid {...props}>{children}</Grid>
})

export const HeaderCell: React.FC<BoxProps> = memo(({ children, ...props }) => {
  const themeBgContainer = useColorModeValue('gray.50', 'gray.700')
  const themeBorderWidth = useColorModeValue('1px', 'none')

  return (
    <Flex
      px="16px"
      py="8px"
      minH="36px"
      fontSize="0.84rem"
      fontWeight="semibold"
      alignItems="center"
      top={0}
      borderBottomWidth={themeBorderWidth}
      backgroundColor={themeBgContainer}
      {...props}
    >
      {children}
    </Flex>
  )
})

interface ColumnConfigComponent {
  <D extends object>(props: { allColumns: ColumnInstance<D>[] }): ReactElement<any, any> | null

  displayName?: string
}

export const ColumnConfig: ColumnConfigComponent = ({ allColumns }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          variant="solid"
          size="xs"
          icon={<SettingsIcon />}
          aria-label="settings"
          isRound
        />
      </PopoverTrigger>
      <PopoverContent px="16px" py="10px">
        <Heading size="md" mb={3}>
          Видимые колонки
        </Heading>
        <VStack alignItems="flex-start" spacing="8px">
          {allColumns.map((column) => (
            <HStack key={column.id}>
              <Checkbox {...column.getToggleHiddenProps()}>
                <Text>{typeof column.Header === 'function' ? column.id : column.Header}</Text>
              </Checkbox>
            </HStack>
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  )
}