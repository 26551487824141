import React from 'react';
import { Grid } from '@chakra-ui/react';

export const ContentContainer = ({ children, ...props }) => {
  return (
    <Grid
      templateColumns="minmax(600px, 800px) 300px"
      gap="60px"
      pb="80px"
      {...props}
    >
      {children}
    </Grid>
  );
};
