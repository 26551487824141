import React, { useContext, useEffect } from 'react';
import {
  Container,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../chakra-lib/layout';
import { Snippets, SnippetsHeading, SnippetsItem, SnippetsList } from '../../../../chakra-lib';
import { HeadingItem, ItemBadge, ItemIcon, ItemLink, ItemText } from '../../../../chakra-lib/Snippets';
import { getNextPage, resetFilter, resetVaccinationList, updateFilter } from '../../../../store/vaccination.reducer';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../components/deprecated/utilities/date-formatting';
import { TranslationContext } from '../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFilterParams } from '../../../../chakra-lib/utils';
import { FILTER_VACCINATION_PARAMS } from '../../../../config/vaccination.config';
import { VaccinationListFilter } from './elemenst/VaccinationListFilter';
import { PersonFullName } from '../../../../chakra-lib/Snippets/elements/PersonFullName';
import { IoPerson } from 'react-icons/io5';
import { useVaccinationResult } from '../../../../components/useVaccinationResult/useVaccinationResult';
import { getVaccineList, resetVaccineList } from '../../../../store/vaccine.reducer';
import { ROLES } from '../../../../model/roles';
import { getMedicalFacilityList, resetMedicalFacilityList } from '../../../../store/medicalFacility.reducer';

export const VaccinationList = () => {
  const { getTranslation } = useContext(TranslationContext);
  const dispatch = useDispatch();
  const {params} = useParams();
  const { getResult } = useVaccinationResult();
  const userAuth = useSelector(state => state.authentication.user);
  const activePage = useSelector(state => state.vaccination.activePage);
  const items = useSelector(state => state.vaccination.items);
  const totalPages = useSelector(state => state.vaccination.totalPages);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (params !== undefined) {
      const objFilterParams = getFilterParams(FILTER_VACCINATION_PARAMS, params);
      dispatch(updateFilter(objFilterParams));
    } else {
      dispatch(updateFilter());
    }
    dispatch(getVaccineList());
    if (userAuth.role === ROLES.Administrator || userAuth.role === ROLES.UserEditor) {
      dispatch(getMedicalFacilityList());
    }
  }, [params]);
  useEffect(() => {
    return () => {
      dispatch(resetFilter());
      dispatch(resetVaccinationList());
      dispatch(resetVaccineList());
      dispatch(resetMedicalFacilityList());
    }
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={70}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(200px, 1fr) 220px 220px 320px"
            itemWidth="1100px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>ФИО</HeadingItem>
              <HeadingItem>Вакцина</HeadingItem>
              <HeadingItem>{getTranslation('office.snippets.heading.createdDate')}</HeadingItem>
              <HeadingItem>Статус</HeadingItem>
            </SnippetsHeading>
            <SnippetsList
              activePage={activePage}
              totalPages={totalPages}
              data={items}
              onNext={() => dispatch(getNextPage())}
            >
              {(item, index) => {
                const vaccinationResult = getResult(item);
                return (
                  <SnippetsItem to={`/vaccination/list/show/${item.id}`}>
                    <ItemIcon icon={IoPerson} />
                    <ItemLink>
                      <PersonFullName
                        firstName={item.firstName}
                        lastName={item.lastName}
                        middleName={item.patronymicName}
                      />
                    </ItemLink>
                    <ItemText>
                      {item.vaccineName?.ru}
                    </ItemText>
                    <ItemText>{item.createdAt ? moment(item.createdAt).format(DATE_FORMAT) : null}</ItemText>
                    <ItemText>
                      <ItemBadge
                        colorScheme={vaccinationResult?.colorScheme}
                      >
                        {vaccinationResult?.text}
                      </ItemBadge>
                    </ItemText>
                  </SnippetsItem>
                )
              }}
            </SnippetsList>
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <VaccinationListFilter params={params}/>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
