import React, { useContext, useEffect } from 'react';
import {
  Container, FilterHeader,
  SnippetsContainer,
  SnippetsContainerMain,
  SnippetsContainerRightAside
} from '../../../../../chakra-lib/layout';
import {
  HeadingItem, ItemIcon, ItemLink,
  Snippets,
  SnippetsHeading, SnippetsItem
} from '../../../../../chakra-lib/Snippets';
import { TranslationContext } from '../../../../../translation';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDocument, downDocument,
  getSettingsDocuments, resetDocument,
  upDocument
} from '../../../../../store/settings-documents.reducer';
import { Box, Button, IconButton, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { IconList } from '../../../../../components/icons';
import { ItemBox } from '../../../../../chakra-lib/Snippets/items/ItemBox';
import { ChevronDownIcon, ChevronUpIcon, DeleteIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

export const DocumentList = () => {
  const { getTranslation } = useContext(TranslationContext);
  const dispatch = useDispatch();
  const items = useSelector(state => state.settingsDocuments.items);
  const history = useHistory();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getSettingsDocuments());
    return () => {
      dispatch(resetDocument());
    };
  }, []);

  return (
    <Container>
      <SnippetsContainer indentTop={120}>
        <SnippetsContainerMain>
          <Snippets
            columns="42px minmax(200px, 1fr) 180px"
          >
            <SnippetsHeading>
              <HeadingItem/>
              <HeadingItem>{getTranslation('settingDocuments.snippets.heading.name')}</HeadingItem>
              <HeadingItem>{getTranslation('settingDocuments.snippets.heading.actions')}</HeadingItem>
            </SnippetsHeading>
            {!isEmpty(items) && items.map((item, index) => (
              <Box
                key={item.id}
                tabIndex="none"
                _focus={{
                  outline: 'none'
                }}
              >
                <SnippetsItem to={`/integration/dictionaries/documents/edit/${index}`}>
                  <ItemIcon icon={IconList} />
                  <ItemLink>
                    <Box>{item.title?.ru}</Box>
                    <Text fontSize="0.76rem" color="gray.400">
                      {item.title?.en}
                    </Text>
                  </ItemLink>
                  <ItemBox>
                    <IconButton
                      aria-label="Menu"
                      mr="10px"
                      icon={<ChevronDownIcon/>}
                      variant="outline"
                      size="sm"
                      isRound={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        dispatch(downDocument(item.id));
                      }}
                      isDisabled={index === items.length - 1}
                    />
                    <IconButton
                      aria-label="Menu"
                      mr="10px"
                      icon={<ChevronUpIcon/>}
                      variant="outline"
                      size="sm"
                      isRound={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        dispatch(upDocument(item.id))
                      }}
                      isDisabled={index === 0}
                    />
                    <IconButton
                      aria-label="Menu"
                      icon={<DeleteIcon/>}
                      variant="outline"
                      size="sm"
                      isRound={true}
                      fontSize="0.76rem"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        dispatch(deleteDocument(item.id));
                      }}
                      isDisabled={typeof item['key'] !== 'undefined'}
                    />
                  </ItemBox>
                </SnippetsItem>
              </Box>
            ))}
          </Snippets>
        </SnippetsContainerMain>
        <SnippetsContainerRightAside>
          <FilterHeader>
            <Button
              width="100%"
              onClick={() => history.push(`/integration/dictionaries/documents/new`)}
              colorScheme="blue"
            >
              {getTranslation('test.action.buttonCreate')}
            </Button>
          </FilterHeader>
        </SnippetsContainerRightAside>
      </SnippetsContainer>
    </Container>
  );
};
