import React, { useEffect, useState } from 'react';
import ReactMapGL, { NavigationControl } from 'react-map-gl';
import { Box } from '@chakra-ui/react';
import { MAPBOX_TOKEN } from './token';
import { MapPins } from './pins';

export const MapPosition = ({latitude = 55.753215, longitude = 37.622504, height = '260px', zoom = 14 }) => {
    const mapStyle = 'mapbox://styles/maxzinovev/ckcbvvqbo0gvh1imqtuju2aq7?fresh=true';

    const [viewport, setViewport] = useState({
        latitude,
        longitude,
        zoom
    });

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setViewport({
            ...viewport,
            latitude: latitude,
            longitude: longitude,
        });
    }, [latitude, longitude]);

    return (
        <ReactMapGL
            {...viewport}
            width="100%"
            height={height}
            mapStyle={mapStyle}
            onViewportChange={value => setViewport(value)}
            mapboxApiAccessToken={MAPBOX_TOKEN}
        >
            <MapPins latitude={latitude} longitude={longitude} />
            <Box position="absolute" top="10px" right="10px">
                <NavigationControl showCompass={false} onViewportChange={value => setViewport(value)}/>
            </Box>
        </ReactMapGL>
    );
};

export default MapPosition;
