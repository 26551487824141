import { useToast } from '@chakra-ui/react';
import { useQuery } from '../../../../../components/useQuery';
import { api } from '../../../../../api';
import { useEffect } from 'react';

const usePushEnabledFetch = () => {
  const toast = useToast();
  const {
    isError,
    isLoading,
    response,
    request
  } = useQuery(() => api.settings.fetchPushEnabled())

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  return {
    data: response?.data,
    fetchSetting: request,
    isLoading
  }
}

export default usePushEnabledFetch;
