import React, { useContext } from 'react'
import * as Yup from 'yup'
import { Field, Formik } from 'formik'

import { Button, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { TranslationContext } from 'translation'
import { TabPanel, Tab, InputForm, CheckboxForm } from 'chakra-lib'
import { FormSection } from 'chakra-lib/layout'

const validationSchema = Yup.object().shape({
  title: Yup.object().shape({
    ru: Yup.string().required('Required'),
    en: Yup.string().required('Required'),
  }),
  shortTitle: Yup.object().shape({
    ru: Yup.string().required('Required'),
    en: Yup.string().required('Required'),
  }),
})

const TestTypeForm = ({ data, isLoading, onSubmit }) => {
  const { getTranslation } = useContext(TranslationContext)

  return (
    <Formik
      initialValues={data || {}}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Tabs variant="soft-rounded" size="sm">
            <TabList>
              <Tab padding="0.4rem 0.8rem" mr={1}>
                {getTranslation('global.inRussian')}
              </Tab>
              <Tab padding="0.4rem 0.8rem" mr={1}>
                {getTranslation('global.inEnglish')}
              </Tab>
            </TabList>
            <TabPanels mt={2}>
              <TabPanel>
                <FormSection mb={0}>
                  <Field
                    autoFocus
                    name="title.ru"
                    component={InputForm}
                    placeholder={getTranslation('testType.field.title')}
                  />
                  <Field
                    autoFocus
                    name="shortTitle.ru"
                    component={InputForm}
                    placeholder={getTranslation('testType.field.shortTitle')}
                  />
                </FormSection>
              </TabPanel>
              <TabPanel>
                <FormSection mb={0}>
                  <Field
                    autoFocus
                    name="title.en"
                    component={InputForm}
                    placeholder={getTranslation('testType.field.title')}
                  />
                  <Field
                    name="shortTitle.en"
                    component={InputForm}
                    isDisabled={isLoading}
                    placeholder={getTranslation('testType.field.shortTitle')}
                  />
                </FormSection>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <FormSection mb={8}>
            <Field
              name="disabled"
              component={CheckboxForm}
              label={getTranslation('global.disabled')}
            />
          </FormSection>
          <Button colorScheme="blue" type="submit" isLoading={isLoading} isDisabled={!props.isValid}>
            {data ? getTranslation('global.update') : getTranslation('global.create')}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default TestTypeForm
