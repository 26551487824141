export const TEST_TYPE_FIELDS = {
  title: 'title',
  shortTitle: 'shortTitleRu',
  createdAt: 'createdAt',
  disabled: 'disabled',
  actions: 'actions',
}

export const TEST_TYPE_FIELDS_TRANSLATION = {
  [TEST_TYPE_FIELDS.title]: 'testType.field.title',
  [TEST_TYPE_FIELDS.shortTitle]: 'testType.field.shortTitle',
  [TEST_TYPE_FIELDS.actions]: 'testType.field.actions',
  [TEST_TYPE_FIELDS.createdAt]: 'global.createdDate',
  [TEST_TYPE_FIELDS.disabled]: 'global.disabled',
}
