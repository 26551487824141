import React from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import { Link as LinkRouter } from 'react-router-dom'

const Link = React.forwardRef((props, ref) => {
  return (
    <ChakraLink
      ref={ref}
      as={LinkRouter}
      color="blue.300"
      fontSize="0.7rem"
      {...props}
    />
  )
})

export default Link