import React from 'react';
import { Flex } from '@chakra-ui/react';

export const FormHeading = ({ children }) => {
  return (
    <Flex
      alignItems="center"
      height="60px"
      fontSize="1.17rem"
      fontWeight="semibold"
    >
      {children}
    </Flex>
  );
};
