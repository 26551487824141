import { isEmpty } from 'lodash';

export const getValueSelect = (options, value = '') => {
  if (value === '') {
    return []
  }
  if (isEmpty(options)) {
    return []
  }
  const valueArray = value.split(',');
  return options.filter(item => valueArray.includes(item.value));
};

export const getChangeValueSelect = (value) => {
  if (value === null) {
    return ''
  }
  return value.map(item => item.value).join(',')
};
