//  ОГРН

export const isOGTN = (value = '') => {
    if (value === '') {
        return true
    }
    const valueToString = value ? value.toString() : ''
    if(valueToString.length === 13){
        const num12 = Math.floor((value / 10) % 11)
        const dgt13 = (num12 === 10) ? 0 : num12
        return (parseInt(valueToString[12]) === dgt13)
    }
    return false
};
