import React, { useContext, useEffect } from 'react'
import { TranslationContext } from 'translation'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Text, useToast } from '@chakra-ui/react'
import { isEmpty } from 'components/deprecated/utilities/is-empty'
import { createOffice, updateOffice } from 'store/office.reducer'
import { getDetailValue, setDetailValue } from 'components/deprecated/utilities/details-field'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormHeading,
  FormSection,
} from 'chakra-lib/layout'
import { CheckboxForm, InputForm, Tab, TabList, TabPanel, TabPanels, Tabs } from 'chakra-lib'
import { TabCheck } from 'components/tab-check'
import { TYPE_REQUISITES_FIELDS } from 'model/requisites-fields'
import { Alert } from 'components/deprecated/alert'
import { TYPE_OFFICE_SCHEMA } from 'model/schema'
import { Information } from 'components/deprecated/Information'
import { Field, Formik } from 'formik'
import { DaData } from 'components/dadata'
import { UIInputSearchDropdownForm } from 'components/input-search-dropdown'
import { MapSetPosition } from 'components/map'
import { WorkSchedule } from 'components/work-schedule'
import { RequisitesForm } from 'components/requisites'
import { UITextareaForm } from 'components/textarea'

const OfficeUpdate = ({ data, loading, isNew }) => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const toast = useToast()
  const country = useSelector((state) => state.settings.country)
  const loadingUpdate = useSelector((state) => state.office.loadingUpdate)
  const { error } = useSelector((state) => state.office.errorMessage)
  const { duplicate } = !isEmpty(error) && error

  const handleBack = () => {
    history.goBack()
  }

  const handleForm = async (values) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      title: {
        ru: values.titleRu,
        en: values.titleEn,
      },
      shortTitle: {
        ru: values.shortTitleRu,
        en: values.shortTitleEn,
      },
      coordinates: {
        lat: Number(values.lat),
        lng: Number(values.lng),
      },
      siteUrl: values.siteUrl,
      telephone: values.telephone,
      address: {
        ru: values.addressRu,
        en: values.addressEn,
      },
      disabled: values.disabled,
      workingHours: values.workingHours,
      details: {
        oid: setDetailValue(values, 'oid'),
        inn: setDetailValue(values, 'inn'),
        company: setDetailValue(values, 'company'),
        kpp: setDetailValue(values, 'kpp'),
        ogrn: setDetailValue(values, 'ogrn'),
        ownership: setDetailValue(values, 'ownership'),
        addressLegal: setDetailValue(values, 'addressLegal'),
        ...((values.commentRu || values.commentEn) && {
          comment: {
            ru: values.commentRu === undefined ? '' : values.commentRu,
            en: values.commentEn === undefined ? '' : values.commentEn,
          },
        }),
      },
    }
    if (isNew) {
      await dispatch(createOffice(dataRequest))

      toast({
        title: getTranslation('office.toast.newOffice.success.title'),
        description: `${getTranslation('office.toast.newOffice.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      handleBack()
    } else {
      await dispatch(updateOffice(dataRequest))

      toast({
        title: getTranslation('office.toast.updateOffice.success.title'),
        description: `${getTranslation('office.toast.updateOffice.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })

      handleBack()
    }
  }

  const handleCoordinates = (e, name, props) => {
    const value = e.target.value
    const valueRegexp = value.match(/\d+\.\d+/g)
    const valueValid = Array.isArray(valueRegexp) && valueRegexp[0]
    props.setFieldValue(name, valueValid ? valueValid.toString() : '')
  }

  useEffect(() => {
    if (duplicate) {
      toast({
        title: getTranslation('global.error'),
        description: `${getTranslation('message.error.duplicateOffice')} (${getTranslation(
          `requisites.${duplicate}.label`
        )})`,
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [duplicate])

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            {duplicate && (
              <Alert marginBottom="30px">
                {`${getTranslation('message.error.duplicateOffice')} (${getTranslation(
                  `requisites.${duplicate}.label`
                )})`}
              </Alert>
            )}
            <Formik
              initialValues={{
                titleRu: data?.title?.ru,
                titleEn: data?.title?.en,
                shortTitleRu: data?.shortTitle?.ru,
                shortTitleEn: data?.shortTitle?.en,
                lat: !isEmpty(data) ? (data.coordinates ? data.coordinates.lat : '') : '',
                lng: !isEmpty(data) ? (data.coordinates ? data.coordinates.lng : '') : '',
                siteUrl: data.siteUrl,
                telephone: data.telephone,
                addressRu: !isEmpty(data) ? (data.address ? data.address.ru : '') : '',
                addressEn: !isEmpty(data) ? (data.address ? data.address.en : '') : '',
                disabled: data.disabled,
                workingHours: !isEmpty(data) && data.workingHours,
                oid: getDetailValue(data, 'oid'),
                inn: getDetailValue(data, 'inn'),
                company: getDetailValue(data, 'company'),
                kpp: getDetailValue(data, 'kpp'),
                ogrn: getDetailValue(data, 'ogrn'),
                ownership: getDetailValue(data, 'ownership'),
                addressLegal: getDetailValue(data, 'addressLegal'),
                commentRu: !isEmpty(data) ? data.details?.comment?.ru : '',
                commentEn: !isEmpty(data) ? data.details?.comment?.en : '',
              }}
              enableReinitialize={true}
              validationSchema={TYPE_OFFICE_SCHEMA[country]}
              onSubmit={handleForm}
            >
              {(props) => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew
                        ? getTranslation('office.action.buttonCreate')
                        : getTranslation('office.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <Tabs variant="enclosed-colored">
                    <TabList>
                      <Tab>
                        <TabCheck
                          form={props}
                          fields={[
                            'oid',
                            'name',
                            'shortName',
                            'telephone',
                            'addressRu',
                            'addressEn',
                            'lat',
                            'lng',
                          ]}
                        >
                          {getTranslation('office.tabs.new.information')}
                        </TabCheck>
                      </Tab>
                      <Tab>{getTranslation('office.tabs.new.schedule')}</Tab>
                      <Tab>
                        <TabCheck form={props} fields={TYPE_REQUISITES_FIELDS[country]}>
                          {getTranslation('office.tabs.new.requisites')}
                        </TabCheck>
                      </Tab>
                      <Tab>{getTranslation('office.tabs.new.comment')}</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel pb={0}>
                        {data.nationalDisabled && (
                          <Alert marginBottom="30px">
                            Офис скрыт администратором национальной компоненты.
                          </Alert>
                        )}
                        <FormSection>
                          <Field
                            autoFocus
                            name="titleRu"
                            label={getTranslation('office.field.titleRu.label')}
                            component={InputForm}
                            placeholder={getTranslation('office.field.titleRu.placeholder')}
                            isDisabled={loading}
                          />
                          <Field
                            name="titleEn"
                            component={InputForm}
                            placeholder={getTranslation('office.field.titleEn.placeholder')}
                            isDisabled={loading}
                          />
                        </FormSection>
                        <FormSection>
                          <Field
                            name="shortTitleRu"
                            label={getTranslation('office.field.shortTitleRu.label')}
                            component={InputForm}
                            placeholder={getTranslation('office.field.shortTitleRu.placeholder')}
                            isDisabled={loading}
                          />
                          <Field
                            name="shortTitleEn"
                            component={InputForm}
                            placeholder={getTranslation('office.field.shortTitleEn.placeholder')}
                            isDisabled={loading}
                          />
                        </FormSection>
                        <FormSection>
                          <FormGrid columns={2}>
                            <Field
                              name="siteUrl"
                              label={getTranslation('office.field.siteUrl.label')}
                              component={InputForm}
                              placeholder={getTranslation('office.field.siteUrl.placeholder')}
                              isDisabled={loading}
                            />
                            <Field
                              name="telephone"
                              label={getTranslation('office.field.telephone.label')}
                              component={InputForm}
                              placeholder={getTranslation('office.field.telephone.placeholder')}
                              isDisabled={loading}
                            />
                          </FormGrid>
                        </FormSection>
                        <FormSection>
                          <Field
                            name="oid"
                            label={getTranslation('requisites.oid.label')}
                            component={InputForm}
                            isDisabled={loading}
                          />
                          <Box fontSize="0.7rem" color="gray.400">
                            Заполняется согласно Приложения «В12». О ведении идентификаторов
                            объектов Минздрава России, назначаемых в рамках российского
                            национального сегмента международного дерева идентификаторов объектов»"
                          </Box>
                        </FormSection>
                        <FormSection>
                          <FormHeading>
                            {getTranslation('office.section.heading.location')}
                          </FormHeading>
                          <DaData
                            typeOption="address"
                            render={(data, isLoading, onUpdateQuery) => (
                              <Field
                                name="addressRu"
                                label={getTranslation('office.field.addressRu.label')}
                                component={UIInputSearchDropdownForm}
                                placeholder={getTranslation('office.field.addressRu.placeholder')}
                                onUpdateQuery={onUpdateQuery}
                                resultData={data}
                                isLoading={isLoading}
                                isDisabled={loading}
                                onBlurFormControl={(item) => {
                                  item &&
                                    item.data.geo_lat &&
                                    props.setFieldValue('lat', item.data.geo_lat)
                                  item &&
                                    item.data.geo_lon &&
                                    props.setFieldValue('lng', item.data.geo_lon)
                                }}
                              />
                            )}
                          />
                          <Field
                            name="addressEn"
                            component={InputForm}
                            placeholder={getTranslation('office.field.addressEn.placeholder')}
                            isDisabled={loading}
                          />
                          <MapSetPosition
                            latitude={props.values.lat ? props.values.lat : undefined}
                            longitude={props.values.lng ? props.values.lng : undefined}
                            showMarker
                            onChangeMap={(e) => {
                              props.setFieldValue('lat', e.latitude)
                              props.setFieldValue('lng', e.longitude)
                            }}
                          />
                          <FormGrid columns={2}>
                            <Field
                              name="lat"
                              label={getTranslation('office.field.latitude.label')}
                              component={InputForm}
                              placeholder="00.000000"
                              isDisabled={loading}
                              onBlur={(e) => handleCoordinates(e, 'lat', props)}
                            />
                            <Field
                              name="lng"
                              label={getTranslation('office.field.longitude.label')}
                              component={InputForm}
                              placeholder="00.000000"
                              isDisabled={loading}
                              onBlur={(e) => handleCoordinates(e, 'lng', props)}
                            />
                          </FormGrid>
                        </FormSection>
                        <FormSection>
                          <FormHeading>
                            {getTranslation('office.section.heading.accessibility')}
                          </FormHeading>
                          <Field
                            name="disabled"
                            component={CheckboxForm}
                            isDisabled={data.nationalDisabled}
                            label={getTranslation('office.field.disabled.label')}
                          />
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <WorkSchedule
                            value={!isEmpty(data) && data.workingHours}
                            onChange={(e) => props.setFieldValue('workingHours', e)}
                          />
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <RequisitesForm loading={loading} />
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <Tabs variant="soft-rounded">
                            <TabList>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('office.tabs.comment.inRussian')}
                              </Tab>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('office.tabs.comment.inEnglish')}
                              </Tab>
                            </TabList>
                            <TabPanels mt="16px">
                              <TabPanel padding={0}>
                                <Field
                                  name="commentRu"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="300px"
                                  max={160}
                                  placeholder={getTranslation('office.field.commentRu.placeholder')}
                                />
                                <Text mt={2}>
                                  {getTranslation('global.remained')}:{' '}
                                  {160 -
                                    (props.values.commentRu?.length
                                      ? props.values.commentRu?.length
                                      : 0)}
                                </Text>
                              </TabPanel>
                              <TabPanel padding={0}>
                                <Field
                                  name="commentEn"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="300px"
                                  max={160}
                                  placeholder={getTranslation('office.field.commentEn.placeholder')}
                                />
                                <Text mt={2}>
                                  {getTranslation('global.remained')}:{' '}
                                  {160 -
                                    (props.values.commentEn?.length
                                      ? props.values.commentEn?.length
                                      : 0)}
                                </Text>
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        </FormSection>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
          <ContentContainerColumn>
            <Information>{getTranslation('office.information.fillingRequisites')}</Information>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}

export default OfficeUpdate