import React, { useContext } from 'react';
import { TranslationContext } from 'translation';

import { Button, ModalBody, useBoolean } from '@chakra-ui/react';
import { ModalForm } from 'components/deprecated/modal';
import SettingsReportsForm from "./SettingsReportsForm";

const SettingsReports = () => {
  const { getTranslation } = useContext(TranslationContext);
  const [isOpen, setIsOpen] = useBoolean();

  return (
    <>
      <Button onClick={setIsOpen.on} width="100%">{getTranslation('reports.update.label')}</Button>
      <ModalForm
        isOpen={isOpen}
        onClose={setIsOpen.off}
        heading={getTranslation('reports.update.label')}
      >
        <ModalBody>
          {isOpen && (
            <SettingsReportsForm/>
          )}
        </ModalBody>
      </ModalForm>
    </>
  );
};

export default SettingsReports