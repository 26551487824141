export const TYPE_DAYS = {
    ru: [
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб',
        'Вс'
    ],
    en: [
        'Mn',
        'W',
        'Wd',
        'Th',
        'Fr',
        'St',
        'Sn'
    ]
};
