import React, { useEffect, useState, useRef } from 'react';
import ReactMapGL, { GeolocateControl, NavigationControl } from 'react-map-gl';
import { MAPBOX_TOKEN } from './token';
import { Flex, Collapse, Box, Button, Text } from '@chakra-ui/react';
import { MapPins } from './pins';

const truncateToDecimals = (num, dec = 2) => {
    const calcDec = Math.pow(10, dec);
    return Math.trunc(num * calcDec) / calcDec;
};

const regexp = /\d+\.\d+/g;

export const MapSetPosition = ({ latitude = 55.753215, longitude = 37.622504, showMarker = false, onChangeMap }) => {
    const mapStyle = 'mapbox://styles/maxzinovev/ckcbvvqbo0gvh1imqtuju2aq7?fresh=true';
    const [showMarkerState, setShowMarkerState] = useState(showMarker);
    const [markerState, setMarkerState] = useState({
        latitude,
        longitude
    });
    const [showMarkerMouseState, setShowMarkerMouseState] = useState(false);
    const [markerMouseState, setMarkerMouseState] = useState({
        latitude,
        longitude
    });
    const [showNewCoordinates, setShowNewCoordinates] = useState(false);

    const [viewport, setViewport] = useState({
        zoom: 16,
        latitude,
        longitude
    });
    const refContainer = useRef(null);
    const handleMap = value => {
        // eslint-disable-next-line no-unused-vars
        const { isFirst, lngLat } = value;
        setShowMarkerState(true);
        setMarkerState({
            latitude: truncateToDecimals(lngLat[1], 6),
            longitude: truncateToDecimals(lngLat[0], 6)
        });
        setShowNewCoordinates(true);
    };
    const handleMouse = value => {
        // eslint-disable-next-line no-unused-vars
        const { pointerType, lngLat } = value;
        setMarkerMouseState({
            latitude: lngLat[1],
            longitude: lngLat[0]
        });
    };
    const setCoordinates = () => {
        onChangeMap(markerState);
        setShowNewCoordinates(false);
    };
    const resetCoordinates = () => {
        setMarkerState({
            latitude: Number(latitude),
            longitude: Number(longitude)
        });
        setViewport({
            ...viewport,
            zoom: 16,
            latitude: Number(latitude),
            longitude: Number(longitude),
        });
        setShowNewCoordinates(false);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(
        () => {
            const regExpLatitude = latitude.toString().match(regexp);
            const regExpLongitude = longitude.toString().match(regexp);
            if (regExpLatitude && regExpLongitude) {
                setViewport({
                    ...viewport,
                    latitude: Number(regExpLatitude[0]),
                    longitude: Number(regExpLongitude[0]),
                });
                setMarkerState({
                    latitude: Number(regExpLatitude[0]),
                    longitude: Number(regExpLongitude[0])
                });
                setShowMarkerMouseState(false);
            }
        },
        [latitude, longitude]
    );

    useEffect(
        () => {
            setShowMarkerState(true);
        },
        [markerState]
    );

    return (
        <Box>
            <ReactMapGL
                {...viewport}
                width="100%"
                height="300px"
                onNativeClick={handleMap}
                onMouseMove={handleMouse}
                mapStyle={mapStyle}
                onViewportChange={value => setViewport(value)}
                mapboxApiAccessToken={MAPBOX_TOKEN}
                ref={refContainer}
            >
                <Box
                    width="100%"
                    height="100%"
                    onMouseMove={() => setShowMarkerMouseState(true)}
                    onMouseEnter={() => setShowMarkerMouseState(true)}
                    onMouseLeave={() => setShowMarkerMouseState(false)}
                />
                <GeolocateControl
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 2
                    }}
                    positionOptions={{ enableHighAccuracy: true }}
                    trackUserLocation
                />
                <Box position="absolute" top="48px" right="9px" width="30px" zIndex={2}>
                    <NavigationControl showCompass={false} />
                </Box>
                {showMarkerMouseState && <MapPins latitude={markerMouseState.latitude} longitude={markerMouseState.longitude} opacity={0.4} />}
                {showMarkerState && <MapPins latitude={markerState.latitude} longitude={markerState.longitude} />}
            </ReactMapGL>
            <Collapse mt={4} in={showNewCoordinates} animateOpacity>
                <Flex alignItems="center" height="40px">
                    <Text mr={4}>{`${markerState.latitude}, ${markerState.longitude}`}</Text>
                    <Button colorScheme="blue" size="xs" mr={2} onClick={() => setCoordinates()}>
                        Обновить
                    </Button>
                    <Button colorScheme="gray" size="xs" onClick={() => resetCoordinates()}>
                        Отмена
                    </Button>
                </Flex>
            </Collapse>
        </Box>
    );
};
