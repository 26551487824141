import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MedicalFacilityUpdate } from './MedicalFacilityUpdate/MedicalFacilityUpdate';
import { MedicalFacilityList } from './MedicalFacilityList/MedicalFacilityList';
import { MedicalFacilityCard } from './MedicalFacilityCard/MedicalFacilityCard';

const Routes = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact>
        <MedicalFacilityUpdate/>
      </Route>
      <Route path={`${match.url}/edit/:id`} exact>
        <MedicalFacilityUpdate/>
      </Route>
      <Route path={`${match.url}/show/:id`} exact>
        <MedicalFacilityCard/>
      </Route>
      <Route path={`${match.url}/:params?`}>
        <MedicalFacilityList/>
      </Route>
    </Switch>
  );
};

export default Routes;
