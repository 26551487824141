import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterFooter, FilterStack } from 'chakra-lib/layout';
import { useSelector } from 'react-redux';
import { getFilterParams } from 'chakra-lib/utils';
import { getURLFromFilterParams } from 'chakra-lib/utils';
import { InputSearch, Select } from 'chakra-lib';
import { TranslationContext } from 'translation';
import { Button, Text } from '@chakra-ui/react';
import { FILTER_OFFICE_PARAMS } from 'config/office.config';

export const OfficeListFilter = ({ params, onExportExcel }) => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const { text, laboratoryId } = useSelector(state => state.office.filter);
  const countList = useSelector(state => state.office.totalItems);
  const loadingLaboratory = useSelector(state => state.laboratory.loading);
  const itemsLaboratory = useSelector(state => state.laboratory.items);
  const optionsSelect = itemsLaboratory && itemsLaboratory.map(item => ({
    value: item.id,
    label: item.name ? item.name : '<Нет имени>'
  }))
  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0];
    const filedValue = param[fieldName];
    const objFilterParams = getFilterParams(FILTER_OFFICE_PARAMS, params);
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue
    })
    history.push(`/national/office${urlFilterParams}`);
  }

  const handleResetFilter = () => {
    history.push(`/national/office`)
  }

  return (
    <>
      <FilterStack>
        <Button onClick={onExportExcel} width="100%">Выгрузить в excel</Button>

        <InputSearch
          label={getTranslation('global.search')}
          name="text"
          value={text}
          onChange={e => handleFilter({ text: e })}
        />
        <Select
          isLoading={loadingLaboratory}
          name="laboratoryId"
          label={getTranslation('office.filter.field.laboratory.label')}
          placeholder={getTranslation('office.filter.field.laboratory.placeholder')}
          options={optionsSelect}
          value={laboratoryId}
          onChange={e => handleFilter({ laboratoryId: e.value })}
        />
      </FilterStack>
      <FilterFooter>
        <Button
          size="sm"
          width="100%"
          onClick={handleResetFilter}
        >Очистить фильтр</Button>
        <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
      </FilterFooter>
    </>
  )
};
