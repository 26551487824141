import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export const Information = ({ children }) => {
    return (
        <Box
            borderWidth={1}
            padding="20px"
            borderRadius={6}
        >
            <Icon as={InfoIcon} mb={3} color="gray.300"/>
            <Box color="gray.600">
                {children}
            </Box>
        </Box>
    );
};
