import React, { useContext } from 'react'
import { Button, useDisclosure } from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'
import { TYPE_ADD_PASSPORT_SCHEMA } from 'model/schema'
import { InputForm, Modal } from 'chakra-lib'
import { Form, FormSection } from 'chakra-lib/layout'
import { getAccess } from 'components/Private'
import { ROLES } from 'model/roles'
import { TranslationContext } from 'translation'

export const FieldPassport = ({ item, updateItem }) => {
  const { getTranslation } = useContext(TranslationContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useSelector((state) => state.authentication.user)
  const country = useSelector((state) => state.settings.country)
  const loadingUpdate = useSelector((state) => state.test.loadingUpdate)

  const handleForm = async (values) => {
    const dataRequest = {
      ...item,
      passport: values.passport.replace(/\s/g, ''),
    }
    updateItem(dataRequest)
    onClose()
  }

  return (
    <>
      <Button
        variant="link"
        onClick={onOpen}
        colorScheme="blue"
        isDisabled={!getAccess(user.role, [ROLES.User, ROLES.UserCreator, ROLES.UserPrivileged])}
      >
        {getTranslation('global.add')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} title={getTranslation('test.heading.addPassport')}>
        <Formik
          initialValues={{
            passport: '',
          }}
          onSubmit={handleForm}
          validationSchema={TYPE_ADD_PASSPORT_SCHEMA[country]}
          enableReinitialize={true}
        >
          {(props) => (
            <Form dataForm={props} onSubmit={props.handleSubmit}>
              <FormSection>
                <Field
                  autoFocus
                  name="passport"
                  label={getTranslation('test.field.passport.label')}
                  component={InputForm}
                  description={getTranslation('test.field.passport.description')}
                />
              </FormSection>
              <FormSection mb="20px">
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!props.dirty}
                  isLoading={loadingUpdate}
                >
                  {getTranslation('global.add')}
                </Button>
              </FormSection>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
