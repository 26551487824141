import React from 'react';
import {
    FormLabel,
    Textarea,
    FormControl
} from '@chakra-ui/react';

export const UITextarea = React.forwardRef((
{
    name,
    isInvalid = null,
    leftAddon = null,
    label = null,
    mask = null,
    placeholder = null,
    onChange = null,
    value,
    textAlign = 'left',
    ...props
}, ref) => {
    return (
        <FormControl isInvalid={isInvalid}>
            {label && (
                <FormLabel
                    htmlFor={name}
                    fontSize="0.9rem"
                    mb={1}
                    //fontWeight="normal"
                >
                    {label}
                </FormLabel>
            )}
            <Textarea
                ref={ref}
                id={name}
                placeholder={placeholder}
                textAlign={textAlign}
                value={value || ''}
                onChange={onChange}
                {...props}
            />
        </FormControl>
    );
});

