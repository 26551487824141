import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Text,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  MenuGroup,
  MenuItem, useColorMode
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { TranslationContext } from '../../translation';
import { ROLES } from '../../model/roles';

export const ProfileWidget = () => {
  const { language, setLanguage, getTranslation } = useContext(TranslationContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const history = useHistory();
  const user = useSelector(state => state.authentication.user);
  const TYPE_ROLE = {
    [ROLES.Administrator]: getTranslation('role.administrator'),
    [ROLES.User]: getTranslation('role.user'),
    [ROLES.UserPrivileged]: getTranslation('role.userPrivileged'),
    [ROLES.Insurer]: getTranslation('role.insurer'),
    [ROLES.UserCreator]: getTranslation('role.userCreator'),
    [ROLES.UserResultSetter]: getTranslation('role.userResultSetter'),
    [ROLES.UserLab]: getTranslation('role.userLab'),
    [ROLES.UserEditor]: getTranslation('role.userEditor')
  }

  const handleLogout = () => {
    history.push('/logout');
  }

  return (
    <Box>
      <Box
        display="flex"
        //align="center"
        role="group"
        justifyContent="space-between"
        transition="0.3s"
        // borderWidth={1}
      >
        <Box
          flexGrow={1}
          textAlign="right"
        >
          <Text fontSize="sm" transition="0.3s" fontWeight="600">
            {user.name}
          </Text>
          <Text fontSize="xs" color="gray.500">
            {TYPE_ROLE[user.role]}
          </Text>
        </Box>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label={'Menu'}
            {...{
              marginLeft: '14px',
              icon: <ChevronDownIcon/>,
              variant: 'outline',
              size: 'md',
              isRound: true
            }}
          />
          <MenuList zIndex={30} fontSize="1rem">
            <MenuOptionGroup onChange={setLanguage} value={language} title={getTranslation('userPanel.language')} type="radio">
              <MenuItemOption value="ru">Русский</MenuItemOption>
              <MenuItemOption value="en">English</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup title="Тема" type="radio" value={colorMode} onChange={toggleColorMode}>
              <MenuItemOption value="light">Светлая</MenuItemOption>
              <MenuItemOption value="dark">Темная</MenuItemOption>
            </MenuOptionGroup>
            <MenuGroup>
              <MenuItem onClick={handleLogout}>{getTranslation('userPanel.logout')}</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
};
