import React from 'react';
import { Flex, Text, IconButton } from '@chakra-ui/react';

export const FormHeading = ({ children, icon = '', ...props }) => {
    return (
        <Flex
            height="60px"
            justifyContent="space-between"
            alignItems="center"
            cursor={icon && 'pointer'}
            {...props}
        >
            <Text
                fontSize="1.17rem"
                fontWeight="semibold"
            >
                {children}
            </Text>
            {icon && (
                <IconButton
                    isRound
                    aria-label="Hide/Show"
                    icon={icon}
                    variant="ghost"
                />
            )}
        </Flex>

    );
};
