import React, { useContext } from 'react';
import { TranslationContext } from '../../../translation';
import { getDetailValue } from '../../deprecated/utilities/details-field';
import { GridFieldItem } from '../../../chakra-lib/layout';

export const RequisitesShowRu = ({ item, loading, isReadyItem }) => {
    const { getTranslation } = useContext(TranslationContext);
    return (
        <>
            <GridFieldItem
                label={getTranslation('requisites.oid.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'oid')}
            </GridFieldItem>
            <GridFieldItem
                label={getTranslation('requisites.inn.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'inn')}
            </GridFieldItem>
            <GridFieldItem
                label={getTranslation('requisites.company.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'company')}
            </GridFieldItem>
            <GridFieldItem
                label={getTranslation('requisites.kpp.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'kpp')}
            </GridFieldItem>
            <GridFieldItem
                label={getTranslation('requisites.ogrn.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'ogrn')}
            </GridFieldItem>
            <GridFieldItem
                label={getTranslation('requisites.ownership.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'ownership')}
            </GridFieldItem>
            <GridFieldItem
                label={getTranslation('requisites.addressLegal.label')}
                isLoading={loading}
                isReady={isReadyItem}
            >
                {getDetailValue(item, 'addressLegal')}
            </GridFieldItem>
        </>
    );
};
