import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { api } from '../../../../../../../api';
import { useQuery } from '../../../../../../../components/useQuery';

const useUpdate = () => {
  const toast = useToast();
  const {
    isError,
    isLoading,
    isSuccess,
    request
  } = useQuery((e) => api.settings.setFormSamplesBanner(e))

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: 'Готово',
        description: 'Настройки успешно обновлены',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isSuccess])

  return {
    updateSetting: request,
    isLoadingUpdate: isLoading
  }
}

export default useUpdate;
