import React, { useContext, useEffect, useState } from 'react';
import {
  CardContainer, Container,
  ContentContainer,
  ContentContainerColumn, EntityState, GridField, GridFieldHeading, GridFieldItem,
  HeadingContainer, RightAside
} from '../../../../chakra-lib/layout';
import { AlertDialog, Heading } from '../../../../chakra-lib';
import { isEmpty } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPhaseList,
  getVaccination, resetPhaseList,
  resetVaccination, updateVaccination
} from '../../../../store/vaccination.reducer';
import { Box, Button, IconButton, useToast } from '@chakra-ui/react';
import { getDateReadFormat } from '../../../../chakra-lib/utils/dateFormatting';
import { GENDER } from '../../../../model/gender';
import { TranslationContext } from '../../../../translation';
import { ContentDate } from '../../../../components/content-date';
import { HiPencilAlt } from 'react-icons/all';
import { ROLES } from '../../../../model/roles';
import { Private } from '../../../../components/Private';
import { useVaccinationResult } from '../../../../components/useVaccinationResult/useVaccinationResult';
import { VACCINATION_STATE } from '../../../../model/vaccination';

export const VaccinationCard = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const toast = useToast();
  const {getResult} = useVaccinationResult();
  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const userAuth = useSelector(state => state.authentication.user);
  const item = useSelector(state => state.vaccination.item);
  const itemsPhase = useSelector(state => state.vaccination.itemsPhase);
  const loading = useSelector(state => state.vaccination.loading);
  const isReadyItem = !isEmpty(item);
  const vaccinationResult = getResult(item);
  const handleBack = () => {
    history.goBack();
  };
  const setInvalid = () => {
    const dataRequest = {
      ...item,
      id: undefined,
      invalid: true
    }
    dispatch(updateVaccination(id, dataRequest));
    setIsOpenDialog(false);
    toast({
      title: 'Готово',
      description: 'Вакцинация аннулирована',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  }
  const GENDER_LABEL = {
    [GENDER.male]: getTranslation('global.male'),
    [GENDER.female]: getTranslation('global.female')
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getVaccination(id));
    dispatch(getPhaseList(id));
    return () => {
      dispatch(resetVaccination());
      dispatch(resetPhaseList());
    }
  }, []);
  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">Вакцинация</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading
                  action={
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[ROLES.Administrator, ROLES.UserEditor]}
                    >
                      <IconButton
                        variant="outline"
                        aria-label="edit"
                        icon={<HiPencilAlt/>}
                        size="sm"
                        disabled={item?.invalid}
                        onClick={() => history.push(`/vaccination/list/edit/${item.id}`)}
                      />
                    </Private>
                  }
                >
                  Анкета
                </GridFieldHeading>
                <GridFieldItem
                  label="Имя"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.firstName}
                </GridFieldItem>
                <GridFieldItem
                  label="Фамилия"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.lastName}
                </GridFieldItem>
                <GridFieldItem
                  label="Отчество"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.patronymicName}
                </GridFieldItem>
                <GridFieldItem
                  label="Дата рождения"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {getDateReadFormat(item?.birthday)}
                </GridFieldItem>
                <GridFieldItem
                  label="Пол"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {GENDER_LABEL[item?.gender]}
                </GridFieldItem>
                <GridFieldItem
                  label="Паспорт"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.passport}
                </GridFieldItem>
                <GridFieldItem
                  label="Личный номер\ID гражданина"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.uid}
                </GridFieldItem>
                <GridFieldItem
                  label="Наименование инфекции"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.infectionName}
                </GridFieldItem>
                <GridFieldItem
                  label="Наименование вакцины"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.vaccineName?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label="Количество фаз"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.vaccinePhases}
                </GridFieldItem>
                <GridFieldItem
                  label="Срок действия в днях"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.vaccineDaysValid}
                </GridFieldItem>
                <GridFieldItem
                  label="Страна-производитель"
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item?.countryManufacturer}
                </GridFieldItem>
              </GridField>
              {!isEmpty(itemsPhase) && itemsPhase.map((itemPhase, index) => (
                <GridField key={index}>
                  <GridFieldHeading
                    action={
                      <Private
                        role={userAuth.role}
                        hasAnyRole={[ROLES.Administrator, ROLES.UserEditor]}
                      >
                        <IconButton
                          variant="outline"
                          aria-label="edit"
                          icon={<HiPencilAlt/>}
                          disabled={item?.invalid}
                          onClick={() => history.push(`/vaccination/list/show/${id}/phase/${itemPhase.id}`)}
                          size="sm"
                        />
                      </Private>
                    }
                  >
                    Фаза {index + 1}
                  </GridFieldHeading>
                  <GridFieldItem
                    label="Серия вакцины с упаковки"
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {itemPhase?.vaccineSeries}
                  </GridFieldItem>
                  <GridFieldItem
                    label="Доза вакцины"
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {itemPhase?.vaccinationDose}
                  </GridFieldItem>
                  <GridFieldItem
                    label="Дата вакцинации"
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {getDateReadFormat(itemPhase?.vaccinationDate)}
                  </GridFieldItem>
                  <GridFieldItem
                    label="ФИО доктора"
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {itemPhase?.doctor}
                  </GridFieldItem>
                  <GridFieldItem
                    label="Мед. учреждение"
                    isLoading={loading}
                    isReady={isReadyItem}
                  >
                    {itemPhase?.medicalFacilityTitle?.ru}
                  </GridFieldItem>
                </GridField>
              ))}
            </ContentContainerColumn>
            <ContentContainerColumn>
              <RightAside>
                <EntityState
                  colorScheme={vaccinationResult?.colorScheme}
                  isLoading={loading}
                >
                  {vaccinationResult?.text}
                </EntityState>
                <Box pt="20px">
                  <Private
                    role={userAuth.role}
                    hasAnyRole={[ROLES.Administrator, ROLES.UserCreator, ROLES.UserEditor]}
                  >
                    <Button
                      variant="outline"
                      width="100%"
                      mb="14px"
                      disabled={item?.invalid || vaccinationResult.type === VACCINATION_STATE.completed}
                      onClick={() => history.push(`/vaccination/list/show/${id}/phase/new`)}
                      isDisabled={vaccinationResult?.type === VACCINATION_STATE.completed}
                    >
                      Добавить фазу
                    </Button>
                  </Private>
                  <Button
                    mb="14px"
                    variant="outline"
                    width="100%"
                    onClick={() => history.push(`/print-vaccination-certificate/${id}`)}
                    disabled={item?.invalid || vaccinationResult.type === VACCINATION_STATE.new}
                  >
                    Сертификат
                  </Button>
                  <Button
                    variant="outline"
                    width="100%"
                    onClick={() => setIsOpenDialog(true)}
                    disabled={item?.invalid}
                  >
                    Аннулировать
                  </Button>
                  <AlertDialog
                    title="Аннулирование"
                    isOpen={isOpenDialog}
                    onClose={() => setIsOpenDialog(false)}
                    footer={
                      <>
                        <Button onClick={() => setIsOpenDialog(false)}>
                          Отмена
                        </Button>
                        <Button
                          colorScheme="red"
                          ml={3}
                          onClick={setInvalid}>
                          Аннулировать
                        </Button>
                      </>
                    }
                  >
                    Вы действительно хотите перевести вакцинацию в статус "Не действительна"?
                  </AlertDialog>
                </Box>
                <ContentDate item={item} loading={loading}/>
              </RightAside>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
    </>
  );
};
