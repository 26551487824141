import { FAILURE, REQUEST, SUCCESS } from './actions-type';
import axios from 'axios';
import { API_SERVER_URL } from './config';
import { checkParam } from '../chakra-lib/utils';
import { getDateTimeToSubmit } from '../chakra-lib/utils/dateFormatting';

const START_PAGE = 1;
const PAGE_SIZE = 20;

export const ACTION_TYPES = {
    FETCH_VACCINATION_LIST: 'vaccination/FETCH_VACCINATION_LIST',
    RESET_VACCINATION_LIST: 'vaccination/RESET_VACCINATION_LIST',
    FETCH_PHASE_LIST: 'vaccination/FETCH_PHASE_LIST',
    RESET_PHASE_LIST: 'vaccination/RESET_PHASE_LIST',
    FETCH_VACCINATION: 'vaccination/FETCH_VACCINATION',
    CREATE_VACCINATION: 'vaccination/CREATE_VACCINATION',
    UPDATE_VACCINATION: 'vaccination/UPDATE_VACCINATION',
    RESET_VACCINATION: 'vaccination/RESET_VACCINATION',
    FETCH_PHASE: 'vaccination/FETCH_PHASE',
    CREATE_PHASE: 'vaccination/CREATE_PHASE',
    UPDATE_PHASE: 'vaccination/UPDATE_PHASE',
    RESET_PHASE: 'vaccination/RESET_PHASE',
    UPDATE_FILTER: 'vaccination/UPDATE_FILTER',
    RESET_FILTER: 'vaccination/RESET_FILTER',
    RESET: 'vaccination/RESET'
};

const initialState = {
    items: [],
    item: {},
    itemsPhase: [],
    itemPhase: {},
    loading: false,
    loadingUpdate: false,
    errorMessage: '',
    totalItems: 0,
    totalPages: 0,
    activePage: START_PAGE,
    filter: {
        text: '',
        gender: '',
        fromDate: '',
        toDate: '',
        vaccineIdList: '',
        medicalFacilityIdList: ''
    }
};

export const vaccinationReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_PHASE_LIST):
        case REQUEST(ACTION_TYPES.FETCH_PHASE):
        case REQUEST(ACTION_TYPES.FETCH_VACCINATION_LIST):
        case REQUEST(ACTION_TYPES.FETCH_VACCINATION):
        {
            return {
                ...state,
                loading: true
            }
        }
        case REQUEST(ACTION_TYPES.CREATE_PHASE):
        case REQUEST(ACTION_TYPES.UPDATE_PHASE):
        case REQUEST(ACTION_TYPES.CREATE_VACCINATION):
        case REQUEST(ACTION_TYPES.UPDATE_VACCINATION):
        {
            return {
                ...state,
                loadingUpdate: true,
                errorMessage: ''
            }
        }
        case FAILURE(ACTION_TYPES.FETCH_PHASE_LIST):
        case FAILURE(ACTION_TYPES.FETCH_PHASE):
        case FAILURE(ACTION_TYPES.CREATE_PHASE):
        case FAILURE(ACTION_TYPES.UPDATE_PHASE):
        case FAILURE(ACTION_TYPES.FETCH_VACCINATION_LIST):
        case FAILURE(ACTION_TYPES.FETCH_VACCINATION):
        case FAILURE(ACTION_TYPES.CREATE_VACCINATION):
        case FAILURE(ACTION_TYPES.UPDATE_VACCINATION):
        {
            return {
                ...state,
                loading: false,
                loadingUpdate: false,
                errorMessage: action.payload.response.data
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_VACCINATION_LIST): {
            const navigation = JSON.parse(action.payload.headers['x-pagination']);
            const items = navigation['CurrentPage'] === 1 ? action.payload.data : state.items.concat(action.payload.data);
            return {
                ...state,
                loading: false,
                items,
                totalItems: navigation['TotalCount'],
                totalPages: navigation['TotalPages'],
                activePage: navigation['CurrentPage'],
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_VACCINATION): {
            return {
                ...state,
                item: action.payload.data,
                loading: false
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_VACCINATION): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_VACCINATION): {
            return {
                ...state,
                item: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_PHASE_LIST): {
            return {
                ...state,
                itemsPhase: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.FETCH_PHASE): {
            return {
                ...state,
                itemPhase: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.CREATE_PHASE): {
            return {
                ...state,
                itemPhase: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case SUCCESS(ACTION_TYPES.UPDATE_PHASE): {
            return {
                ...state,
                itemPhase: action.payload.data,
                loading: false,
                loadingUpdate: false,
                errorMessage: ''
            }
        }
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                    ...action.filter
                }
            }
        }
        case ACTION_TYPES.RESET_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter
                }
            }
        }
        case ACTION_TYPES.RESET_VACCINATION_LIST: {
            return {
                ...state,
                items: [],
                loading: false,
                loadingUpdate: false,
                totalItems: 0,
                totalPages: 0,
                activePage: START_PAGE
            }
        }
        case ACTION_TYPES.RESET_VACCINATION: {
            return {
                ...state,
                item: {},
                loading: false,
                loadingUpdate: false
            }
        }
        case ACTION_TYPES.RESET_PHASE_LIST: {
            return {
                ...state,
                itemsPhase: {},
                loading: false,
                loadingUpdate: false
            }
        }
        case ACTION_TYPES.RESET_PHASE: {
            return {
                ...state,
                itemPhase: {},
                loading: false,
                loadingUpdate: false
            }
        }
        case ACTION_TYPES.RESET: {
            return {
                ...state,
                items: [],
                item: {},
                loading: false,
                loadingUpdate: false
            }
        }
        default: return state;
    }
};

const API_URL = `${API_SERVER_URL}/vaccination`;

export const getVaccinationListSearch = (page) => (dispatch, getState) => {
    const filter = getState().vaccination.filter;
    const requestUrl = `${API_URL}/find?pageSize=${PAGE_SIZE}&page=${page}`;
    dispatch({
        type: ACTION_TYPES.FETCH_VACCINATION_LIST,
        payload: axios.post(requestUrl, {
            text: checkParam(filter.text),
            fromDate: checkParam(getDateTimeToSubmit(filter.fromDate)),
            toDate: checkParam(getDateTimeToSubmit(filter.toDate)),
            vaccineIdList: checkParam(filter.vaccineIdList, ','),
            medicalFacilityIdList: checkParam(filter.medicalFacilityIdList, ','),
        })
    });
};

export const updateFilter = (params) => async (dispatch, getState) => {
    await dispatch({
        type: ACTION_TYPES.UPDATE_FILTER,
        filter: {
            ...params
        }
    });
    dispatch(getVaccinationListSearch(START_PAGE));
};

export const getNextPage = () => (dispatch, getState) => {
    const activePage = getState().vaccination.activePage;
    dispatch(getVaccinationListSearch(activePage + 1))
};

export const resetFilter = () => {
    return {
        type: ACTION_TYPES.RESET_FILTER
    };
};

export const getVaccination = (id) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.FETCH_VACCINATION,
        payload: axios.get(requestUrl)
    };
};

export const createVaccination = (dataRequest) => {
    const requestUrl = `${API_URL}`;
    return {
        type: ACTION_TYPES.CREATE_VACCINATION,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const updateVaccination = (id, dataRequest) => {
    const requestUrl = `${API_URL}/${id}`;
    return {
        type: ACTION_TYPES.UPDATE_VACCINATION,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const createPhase = (dataRequest) => {
    const requestUrl = `${API_URL}/phase`;
    return {
        type: ACTION_TYPES.CREATE_PHASE,
        payload: axios.post(requestUrl, {
            ...dataRequest
        })
    };
};

export const getPhase = (id, phaseId) => {
    const requestUrl = `${API_URL}/${id}/phase/${phaseId}`;
    return {
        type: ACTION_TYPES.FETCH_PHASE,
        payload: axios.get(requestUrl)
    };
};

export const updatePhase = (id, phaseId, dataRequest) => {
    const requestUrl = `${API_URL}/${id}/phase/${phaseId}`;
    return {
        type: ACTION_TYPES.UPDATE_PHASE,
        payload: axios.put(requestUrl, {
            ...dataRequest
        })
    };
};

export const getPhaseList = (id) => {
    const requestUrl = `${API_URL}/${id}/phases`;
    return {
        type: ACTION_TYPES.FETCH_PHASE_LIST,
        payload: axios.get(requestUrl)
    };
};

export const resetVaccinationList = () => {
    return {
        type: ACTION_TYPES.RESET_VACCINATION_LIST
    };
};

export const resetVaccination = () => {
    return {
        type: ACTION_TYPES.RESET_VACCINATION
    };
};

export const resetPhaseList = () => {
    return {
        type: ACTION_TYPES.RESET_PHASE_LIST
    };
};

export const resetPhase = () => {
    return {
        type: ACTION_TYPES.RESET_PHASE
    };
};

export const reset = () => {
    return {
        type: ACTION_TYPES.RESET
    };
};
