import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { SectionMenu, SectionMenuItem } from 'chakra-lib/layout'
import { PrivateRoute } from 'components/Private'
import { ROLES } from 'model/roles'
import { Login } from 'login'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import DocumentList from './Document'
import { InformationList } from './Information/InformationList'
import FaqList from './Faq'
import VaccineList from './Vaccine'
import FormSampleList from './FormSample'
import TestType from './TestType'

const Routes = () => {
  const match = useRouteMatch()
  const userAuth = useSelector((state) => state.authentication.user)
  const isAuthenticated = !isEmpty(userAuth)
  return isAuthenticated ? (
    <>
      <SectionMenu>
        <SectionMenuItem to="/integration/dictionaries/documents">Документы</SectionMenuItem>
        <SectionMenuItem to="/integration/dictionaries/information">Инфо</SectionMenuItem>
        <SectionMenuItem to="/integration/dictionaries/faq">Вопросы и ответы</SectionMenuItem>
        <SectionMenuItem to="/integration/dictionaries/vaccine">Вакцина</SectionMenuItem>
        <SectionMenuItem to="/integration/dictionaries/form-sample">
          Шаблоны документов
        </SectionMenuItem>
        <SectionMenuItem to="/integration/dictionaries/test-type/list">Вид теста</SectionMenuItem>
      </SectionMenu>
      <Switch>
        <PrivateRoute
          path={`${match.url}/documents`}
          role={userAuth.role}
          hasAnyRole={[ROLES.Administrator]}
        >
          <DocumentList />
        </PrivateRoute>
        <PrivateRoute
          path={`${match.url}/information`}
          role={userAuth.role}
          hasAnyRole={[ROLES.Administrator]}
        >
          <InformationList />
        </PrivateRoute>
        <PrivateRoute
          path={`${match.url}/faq`}
          role={userAuth.role}
          hasAnyRole={[ROLES.Administrator]}
        >
          <FaqList />
        </PrivateRoute>
        <PrivateRoute
          path={`${match.url}/vaccine`}
          role={userAuth.role}
          hasAnyRole={[ROLES.Administrator]}
        >
          <VaccineList />
        </PrivateRoute>
        <PrivateRoute
          path={`${match.url}/form-sample`}
          role={userAuth.role}
          hasAnyRole={[ROLES.Administrator]}
        >
          <FormSampleList />
        </PrivateRoute>
        <PrivateRoute
          path={`${match.url}/test-type`}
          role={userAuth.role}
          hasAnyRole={[ROLES.Administrator]}
        >
          <TestType />
        </PrivateRoute>
        <Route path="/">
          <Redirect to={'/integration/dictionaries/documents'} />
        </Route>
      </Switch>
    </>
  ) : (
    <Login />
  )
}

export default Routes;
