import React, { useContext } from 'react';
import {
  Button,
  ModalBody, ModalFooter,
  useDisclosure
} from '@chakra-ui/react';
import { ModalForm } from '../../../../../components/deprecated/modal';
import { SelectForm } from '../../../../../chakra-lib';
import { Field, Formik } from 'formik';
import { TranslationContext } from '../../../../../translation';
import usePushEnabledFetch from './usePushEnabledFetch';
import usePushEnabledUpdate from './usePushEnabledUpdate';

export const PushEnabled = () => {
  const {getTranslation} = useContext(TranslationContext)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const { data, isLoading, fetchSetting } = usePushEnabledFetch()
  const { updateSetting, isLoadingUpdate } = usePushEnabledUpdate()

  const handleOpen = async () => {
    await fetchSetting()
    onOpen()
  }

  const handleForm = async (values, actions) => {
    await updateSetting(values.enabled)
    onClose();
  };

  return (
    <>
      <Button
        width="100%"
        onClick={handleOpen}
      >
        Режим работы
      </Button>
      <ModalForm
        isOpen={isOpen}
        onClose={onClose}
        heading="PUSH сообщения"
      >
        <Formik
          initialValues={{
            enabled: data,
          }}
          onSubmit={handleForm}
          enableReinitialize={true}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Field
                  isDisabled={isLoading || isLoadingUpdate}
                  name="enabled"
                  label="Режим работы"
                  component={SelectForm}
                  isSearchable={false}
                  options={[
                    { value: true, label: 'Включено' },
                    { value: false, label: 'Выключено' },
                  ]}
                />
              </ModalBody>
              <ModalFooter justifyContent="flex-start">
                  <Button
                    colorScheme="blue"
                    type="submit"
                    isDisabled={!props.dirty}
                    isLoading={isLoading || isLoadingUpdate}
                  >
                    {getTranslation('global.update')}
                  </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  )
}
