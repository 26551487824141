import { useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import { useQuery } from '../../../../../../components/useQuery';
import { api } from '../../../../../../api';

const useFetchItem = (id) => {
  const toast = useToast();
  const {
    isError,
    isLoading,
    response,
    request
  } = useQuery(() => api.formSample.fetchItem(id))

  useEffect(() => {
    if (id) {
      request()
    }
  }, [id])

  useEffect(() => {
    if (isError) {
      toast({
        title: 'Ошибка',
        description: 'Не удалось получить данные',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError])

  return {
    data: response?.data,
    fetchItem: request,
    isLoading
  }
}

export default useFetchItem;
