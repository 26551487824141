import axios from 'axios';
import { useCallback, useState } from 'react';
import { API_SERVER_URL } from '../../../../../../store/config';

export const useAttentionMessageUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const request = useCallback(async (values) => {
    try {
      setIsLoading(true);
      setIsSuccess(false);
      await axios.post(`${API_SERVER_URL}/settings`, values);
      setIsSuccess(true);
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoadingUpdate: isLoading,
    isSuccessUpdate: isSuccess,
    requestUpdate: request
  }
}
