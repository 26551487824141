import { makeColumns } from './OfficeList.config'
import { OFFICE_FIELDS } from '../Office.constants'

export const makeExcelColumns = ({ getTranslation, hiddenColumns }) => {
  const tableColumns = makeColumns({ getTranslation })

  const TYPE_DISABLED = {
    true: getTranslation('disabled.true'),
    false: getTranslation('disabled.false'),
  }

  return tableColumns
    .map((item) => ({
      key: item.id,
      accessor: item.accessor,
      header: item.Header,
    }))
    .map((item) => {
      if (item.key === OFFICE_FIELDS.title) {
        return {
          ...item,
          accessor: ({ title }) => `${title.ru}, ${title.en}`,
        }
      }

      if (item.key === OFFICE_FIELDS.shortTitleRu) {
        return {
          ...item,
          accessor: ({ shortTitle }) => `${shortTitle.ru}, ${shortTitle.en}`,
        }
      }

      if (item.key === OFFICE_FIELDS.state) {
        return {
          ...item,
          accessor: ({ disabled }) => TYPE_DISABLED[disabled],
        }
      }
      return item
    })
    .filter((item) => item.header)
    .filter((item) => !hiddenColumns.includes(item.key))
}


