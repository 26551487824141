import React, { useContext, useEffect } from 'react';
import {
  CardContainer, Container,
  ContentContainer,
  ContentContainerColumn, Form, FormGrid, FormHeading, FormSection,
  HeadingContainer
} from '../../../../../chakra-lib/layout';
import {
  Alert,
  FormLabel,
  Heading,
  InputForm,
  TabPanel,
  Tab
} from '../../../../../chakra-lib';
import { Field, Formik } from 'formik';
import {
  Box,
  Button,
  Input, InputGroup, InputRightElement,
  TabList,
  TabPanels,
  Tabs,
  useToast
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TranslationContext } from '../../../../../translation';
import { UITextareaForm } from '../../../../../components/textarea';
import {
  createDocument,
  getSettingsDocuments,
  resetDocument,
  setSettingsDocuments
} from '../../../../../store/settings-documents.reducer';
import { setAgreement } from '../../../../../store/settings.reducer';

export const DocumentUpdate = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const toast = useToast();
  const {id} = useParams();
  const isNew = !id;
  const items = useSelector(state => state.settingsDocuments.items);
  const item = items[id];
  const itemDocumentVersion = item?.version !== undefined ? item.version : 0;
  const loading = useSelector(state => state.settingsDocuments.loading);
  const loadingSettings = useSelector(state => state.settings.loading);
  const errorMessage = useSelector(state => state.settingsDocuments.errorMessage);
  const handleBack = () => {
    history.goBack();
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getSettingsDocuments());
    return () => {
      dispatch(resetDocument());
    };
  }, []);
  const handleForm = async (values, actions) => {
    const dataRequest = {
      ...item,
      title: {
        ru: values.titleRu,
        en: values.titleEn,
      },
      value: {
        ru: values.valueRu,
        en: values.valueEn,
      },
      key: values.key,
      tag: values.tag,
      version: values.version
    };
    if (item) {
      await dispatch(setSettingsDocuments(dataRequest, item.id));
      toast({
        title: getTranslation('settingDocuments.toast.update.title'),
        description: `${getTranslation('settingDocuments.toast.update.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      if (item.key === 'agreement') {
        const dataRequestAgreement = {
          key: 'agreement',
          value: {
            ru: values.valueRu,
            en: values.valueEn
          }
        };
        await dispatch(setAgreement(dataRequestAgreement));
      }
      handleBack();
    } else {
      await dispatch(createDocument(dataRequest));
      toast({
        title: getTranslation('settingDocuments.toast.create.title'),
        description: `${getTranslation('settingDocuments.toast.create.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      handleBack();
    }
  };
  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание раздела' : 'Обновление раздела'}
              </Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="30px">
                {errorMessage?.error}
              </Alert>
            )}
            <Formik
              initialValues={{
                titleRu: item?.title?.ru ? item.title.ru : 'Документ',
                titleEn: item?.title?.en ? item.title.en : 'Document',
                valueRu: item?.value?.ru ? item.value.ru : 'Описание',
                valueEn: item?.value?.en ? item.value.en : 'Description',
                key: item?.key,
                tag: item?.tag,
                version: itemDocumentVersion,
              }}
              enableReinitialize={true}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loading || loadingSettings}
                    >
                      {isNew ? getTranslation('global.create') : getTranslation('global.update')}
                    </Button>
                  }
                >
                  <Tabs variant="soft-rounded" size="sm">
                    <TabList>
                      <Tab padding="0.4rem 0.8rem" mr={1}>
                        {getTranslation('settingDocuments.tabs.description.inRussian')}
                      </Tab>
                      <Tab padding="0.4rem 0.8rem" mr={1}>
                        {getTranslation('settingDocuments.tabs.description.inEnglish')}
                      </Tab>
                    </TabList>
                    <TabPanels mt={2}>
                      <TabPanel>
                        <FormSection mb={0}>
                          <Field
                            autoFocus
                            name="titleRu"
                            component={InputForm}
                            placeholder={getTranslation('settingDocuments.field.titleRu.placeholder')}
                          />
                          <Field
                            name="valueRu"
                            component={UITextareaForm}
                            isDisabled={loading}
                            minHeight="400px"
                            placeholder={getTranslation('settingDocuments.field.valueRu.placeholder')}
                          />
                        </FormSection>
                      </TabPanel>
                      <TabPanel>
                        <FormSection mb={0}>
                          <Field
                            autoFocus
                            name="titleEn"
                            component={InputForm}
                            placeholder={getTranslation('settingDocuments.field.titleEn.placeholder')}
                          />
                          <Field
                            name="valueEn"
                            component={UITextareaForm}
                            isDisabled={loading}
                            minHeight="400px"
                            placeholder={getTranslation('settingDocuments.field.valueEn.placeholder')}
                          />
                        </FormSection>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                  <FormSection>
                    <FormHeading>Дополнительно</FormHeading>
                    <Field
                      name="key"
                      label="Ключ"
                      component={InputForm}
                      isDisabled={loading}
                    />
                    <Field
                      name="tag"
                      component={InputForm}
                      label="Тэг"
                      isDisabled={loading}
                    />
                    <FormGrid columns={2}>
                      <Box>
                        <FormLabel>Версия</FormLabel>
                        <InputGroup size="md">
                          <Input
                            pr="4.5rem"
                            isReadOnly
                            value={String(props.values.version)}
                          />
                          <InputRightElement width="7rem">
                            <Button
                              h="1.75rem"
                              size="sm"
                              disabled={props.values.version !== itemDocumentVersion}
                              onClick={() => {
                                props.setFieldValue(`version`, props.values.version + 1);
                              }}
                            >
                              Увеличить
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                      </Box>
                    </FormGrid>
                  </FormSection>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
