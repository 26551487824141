import { Icon } from '@chakra-ui/react';
import React from 'react';

export const IconPerson = (props) => {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <g fill="currentColor">
        <path
          fill="currentColor"
          clipRule="evenodd"
          fillRule="evenodd"
          d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
        />
      </g>
    </Icon>
  );
};
