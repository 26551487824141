import { createStandaloneToast, IToast } from '@chakra-ui/react'

const defaultOptions: IToast = {
  duration: 9000,
  isClosable: true,
}

class Toast {
  toast = createStandaloneToast()

  showInfo(options: IToast) {
    this.toast({
      ...defaultOptions,
      title: 'Сообщение',
      status: 'info',
      ...options,
    })
  }

  showWarning(options: IToast) {
    this.toast({
      ...defaultOptions,
      title: 'Внимание!',
      status: 'warning',
      ...options,
    })
  }

  showSuccess(options: IToast) {
    this.toast({
      ...defaultOptions,
      title: 'Готово',
      status: 'success',
      ...options,
    })
  }

  showError(options: IToast) {
    this.toast({
      ...defaultOptions,
      title: 'Ошибка',
      status: 'error',
      ...options,
    })
  }

  closeAll() {
    this.toast.closeAll()
  }
}

export const toast = new Toast()
export default toast;
