import React from 'react';
import { Grid } from '@chakra-ui/react';

export const FormGrid = ({ columns, templateColumns, children, gap = null, ...props }) => {
  const column = {
    2: 'repeat(2, 1fr)',
    3: 'repeat(3, 1fr)'
  };
  return (
    <Grid
      gap={gap ? gap : 6}
      alignItems="end"
      templateColumns={templateColumns ? templateColumns : column[columns]}
      {...props}
    >
      {children}
    </Grid>
  );
};
