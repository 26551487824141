import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { TranslationContext } from '../../translation';
import { VACCINATION_STATE } from '../../model/vaccination';

export const useVaccinationResult = () => {
  const { getTranslation } = useContext(TranslationContext);
  const getResult = (item) => {

    if (isEmpty(item)) {
      return ({
        text: getTranslation('vaccination.empty'),
        type: VACCINATION_STATE.empty,
        colorScheme: 'gray'
      });
    }
    if (item?.invalid) {
      return ({
        text: getTranslation('vaccination.invalid'),
        type: VACCINATION_STATE.invalid,
        colorScheme: 'red'
      });
    }
    if (item?.phasesCount === 0) {
      return ({
        text: getTranslation('vaccination.new'),
        type: VACCINATION_STATE.new,
        colorScheme: 'blue'
      });
    }
    if (item?.vaccinePhases > item?.phasesCount) {
      return ({
        text: getTranslation('vaccination.partiallyCompleted'),
        type: VACCINATION_STATE.partiallyCompleted,
        colorScheme: 'orange'
      });
    }
    return ({
      text: getTranslation('vaccination.completed'),
      type: VACCINATION_STATE.completed,
      colorScheme: 'green'
    });
  };
  return { getResult }
}
