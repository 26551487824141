import { push } from './push'
import { settings } from './settings'
import { formSample } from './formSample'
import { tests } from './tests'
import { testType } from './testType'

export const api = {
  push,
  settings,
  formSample,
  tests,
  testType,
}
