import { useEffect, useState } from 'react';

export const useFetch = (initialUrl, initialParams = {}, skip = false) => {
    const [url, updateUrl] = useState(initialUrl)
    const [params, updateParams] = useState(initialParams)
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [refetchIndex, setRefetchIndex] = useState(0)

    /*const queryString = Object.keys(params)
        .map((key) => encodeURIComponent(key) + '=' +
            encodeURIComponent(params[key])).join('&')*/

    const reFetch = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1)

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        let cleanupFunction = false;
        const fetchData = async () => {
            if (skip) return
            !cleanupFunction && setIsLoading(true)
            try {
                const response = await fetch(`${url}`, params)
                const result = await response.json()
                if (response.ok) {
                    !cleanupFunction && setData(result)
                } else {
                    !cleanupFunction && setHasError(true)
                    !cleanupFunction && setErrorMessage(result)
                }
            } catch (err) {
                !cleanupFunction && setHasError(true)
                !cleanupFunction && setErrorMessage(err.message)
            } finally {
                !cleanupFunction && setIsLoading(false)
            }
        }
        fetchData()
        return () => cleanupFunction = true;
    }, [url, params, refetchIndex])

    return { data, isLoading, hasError, errorMessage, updateUrl, updateParams, reFetch }
};
