import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Flex, Icon,
  IconButton, Text, useColorModeValue,
  useToast
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Field, FieldArray, Formik } from 'formik';
import { find, isEmpty } from 'lodash';
import { createVaccine, getVaccine, resetVaccine, updateVaccine } from '../../../../../store/vaccine.reducer';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, Form, FormGrid, FormHeading, FormSection,
  HeadingContainer
} from '../../../../../chakra-lib/layout';
import {
  Alert,
  Heading,
  InputForm,
  Modal,
  Snippets, SnippetsHeading,
  SnippetsItem, Tab, TabList, TabPanel, TabPanels, Tabs,
} from '../../../../../chakra-lib';
import { HeadingItem, ItemIcon, ItemLink } from '../../../../../chakra-lib/Snippets';
import { IconList } from '../../../../../components/icons';
import { ItemBox } from '../../../../../chakra-lib/Snippets/items/ItemBox';
import { DeleteIcon } from '@chakra-ui/icons';
import { TranslationContext } from '../../../../../translation';
import { getCountryAllList, resetCountryList } from '../../../../../store/country.reducer';
import { ItemAvatar } from '../../../../../components/deprecated/snippets';
import { RiCheckboxCircleFill } from 'react-icons/all';

const formSchema = Yup.object().shape({
  nameRu: Yup.string().required('Required'),
  nameEn: Yup.string().required('Required'),
  phases: Yup.string().required('Required')
});

export const VaccineUpdate = () => {
  const {getTranslation} = useContext(TranslationContext);
  const themeColorCheck = useColorModeValue('green.400', 'green.200');
  const themeColorClose = useColorModeValue('gray.200', 'gray.600');
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const toast = useToast();
  const isNew = !id;
  const itemsCountry = useSelector(state => state.country.itemsAll);
  const [activeIndex, setActiveIndex] = useState(-1);
  const item = useSelector(state => state.vaccine.item);
  const loading = useSelector(state => state.vaccine.loading);
  const loadingUpdate = useSelector(state => state.vaccine.loadingUpdate);
  const errorMessage = useSelector(state => state.vaccine.errorMessage);
  const getCountryAccepted = () => {
    if (isEmpty(itemsCountry) || isEmpty(item)) {
      return [];
    }
    return itemsCountry.map(i => {
      const itemDaysValid = find(item?.countryAccepted, { countryId: i.id })
      return {
        countryId: i.id,
        flag: i.flag,
        name: i.name,
        daysValid: !isEmpty(itemDaysValid) ? itemDaysValid.daysValid : 0
      }
    })
  }
  const getCountryAcceptedToSubmit = (data = []) => {
    let result = [];
    data.forEach(i => {
      if (i.daysValid > 0) {
        result.push({
          countryId: i.countryId,
          daysValid: i.daysValid
        })
      }
    });
    return result;
  }
  const handleBack = () => {
    history.goBack();
  };
  const close = () => {
    setActiveIndex(-1);
  }
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isNew) {
      dispatch(resetVaccine());
    } else {
      dispatch(getVaccine(id));
      dispatch(resetCountryList());
    }
    dispatch(getCountryAllList());
    return () => {
      dispatch(resetVaccine());
    };
  }, []);

  const handleForm = async (values) => {
    const dataRequest = {
      name: {
        ru: values.nameRu,
        en: values.nameEn,
      },
      phases: Number(values.phases),
      nameVariants: values.nameVariants.map(i => {
        if (i.name === '') {
          return { id: i.id }
        }
        if (i.id === undefined) {
          return { name: i.name }
        }
        return { name: i.name, id: i.id }
      }),
      countryAccepted: getCountryAcceptedToSubmit(values.countryAccepted)
    };
    if (isNew) {
      await dispatch(createVaccine(dataRequest));
      toast({
        title: 'Готово',
        description: `Вакцина успешно создана`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    } else {
      await dispatch(updateVaccine(dataRequest, id));
      toast({
        title: 'Готово',
        description: `Вакцина успешно обновлена`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    }
  };
  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={120}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>
                {isNew ? 'Создание вакцины' : 'Обновление вакцины'}
              </Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="30px">
                {errorMessage?.error}
              </Alert>
            )}
            <Formik
              initialValues={{
                nameRu: !isEmpty(item) ? (item.name ? item.name.ru : '') : '',
                nameEn: !isEmpty(item) ? (item.name ? item.name.en : '') : '',
                phases: item.phases !== undefined ? String(item.phases) : '0',
                nameVariants: !isEmpty(item) ? item.vaccineNames?.map(i => ({ id: i.id, name: i.name })) : [],
                countryAccepted: getCountryAccepted()
              }}
              enableReinitialize={true}
              validationSchema={formSchema}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew ? getTranslation('user.action.buttonCreate') : getTranslation('user.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <Tabs variant="enclosed-colored">
                    <TabList>
                      <Tab>
                        Описание
                      </Tab>
                      <Tab>
                        Срок
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel pb={0}>
                        <FormSection>
                          <Field
                            autoFocus
                            name="nameRu"
                            label="Наименование на русском"
                            component={InputForm}
                            isDisabled={loading}
                          />
                          <Field
                            name="nameEn"
                            component={InputForm}
                            label="Наименование на английском"
                            isDisabled={loading}
                          />
                          <FormGrid columns={2}>
                            <Field
                              type="number"
                              name="phases"
                              component={InputForm}
                              label="Дозировка"
                              isDisabled={loading}
                            />
                          </FormGrid>
                        </FormSection>
                        <FormSection>
                          <FormHeading>Другие имена</FormHeading>
                          <FieldArray
                            name="nameVariants"
                            render={arrayHelpers => (
                              <>
                                {props.values.nameVariants && props.values.nameVariants.length > 0 ? (
                                  <Snippets
                                    columns="42px 1fr 80px"
                                  >
                                    {props.values.nameVariants.map((friend, index) => {
                                      const formFieldName = props.values.nameVariants[index].name;
                                      const formFieldId = props.values.nameVariants[index].id;
                                      return (
                                        <React.Fragment key={index}>
                                          <SnippetsItem onClick={() => setActiveIndex(index)}>
                                            <ItemIcon icon={IconList}/>
                                            <ItemLink>{formFieldName || <Text color="gray.300">
                                              {(formFieldName === '' && formFieldId) ? 'Будет удалено' : 'Нет имени'}
                                            </Text> }</ItemLink>
                                            <ItemBox justifyContent="flex-end">
                                              <IconButton
                                                isDisabled={formFieldName === '' && formFieldId}
                                                aria-label="Menu"
                                                icon={<DeleteIcon/>}
                                                variant="outline"
                                                size="sm"
                                                isRound={true}
                                                fontSize="0.76rem"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (!formFieldId) {
                                                    arrayHelpers.remove(index);
                                                    return;
                                                  }
                                                  props.setFieldValue(`nameVariants[${index}].name`, '');
                                                }}
                                              />
                                            </ItemBox>
                                          </SnippetsItem>
                                          <Modal
                                            title="Другое имя"
                                            isOpen={activeIndex === index}
                                            onClose={close}
                                            footer={
                                              <Button
                                                colorScheme="blue"
                                                type="submit"
                                                width="100%"
                                                onClick={() => close()}
                                              >
                                                Закрыть
                                              </Button>
                                            }
                                          >
                                            <FormSection mb={0}>
                                              <Field
                                                autoFocus
                                                name={`nameVariants[${activeIndex}].name`}
                                                component={InputForm}
                                                label="Имя"
                                              />
                                            </FormSection>
                                          </Modal>
                                        </React.Fragment>
                                      )
                                    })}
                                  </Snippets>
                                ) : (
                                  <Flex
                                    textAlign="center"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="40px"
                                    fontSize="0.9rem"
                                    color="gray.400"
                                  >
                                    Нет имен
                                  </Flex>
                                )}
                                <Button
                                  size="sm"
                                  width="100%"
                                  disabled={loading}
                                  onClick={() => arrayHelpers.push({
                                    name: 'Другое имя'
                                  })}
                                >
                                  Добавить
                                </Button>
                              </>
                            )}
                          />
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        {props.values.countryAccepted && props.values.countryAccepted.length > 0 && (
                          <Snippets
                            columns="42px 1fr 80px 40px"
                          >
                            <SnippetsHeading>
                              <HeadingItem/>
                              <HeadingItem>Страна</HeadingItem>
                              <HeadingItem>Дни</HeadingItem>
                            </SnippetsHeading>
                            {props.values.countryAccepted?.map((item, index) => (
                              <SnippetsItem key={item.id} cursor="default">
                                <ItemAvatar src={item.flag} height="32px" width="32px" />
                                <ItemLink>{item.name && item.name.ru}</ItemLink>
                                <ItemBox justifyContent="flex-end">
                                  <Field
                                    type="number"
                                    name={`countryAccepted[${index}].daysValid`}
                                    component={InputForm}
                                    size="sm"
                                    borderRadius={6}
                                    placeholder="0"
                                  />
                                </ItemBox>
                                <ItemBox justifyContent="center">
                                  {item.daysValid > 0 ? (
                                    <Icon as={RiCheckboxCircleFill} w={5} h={5} color={themeColorCheck}/>
                                  ) : (
                                    <Icon as={RiCheckboxCircleFill} w={5} h={5} color={themeColorClose}/>
                                  )}
                                </ItemBox>
                              </SnippetsItem>
                            ))}
                          </Snippets>
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
