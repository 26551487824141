export const resultFilterList = [
  {
    value: "Expired",
    title: {
      ru: "просрочен",
      en: "expired"
    }
  },
  {
    value: "Positive",
    title: {
      ru: "положительный",
      en: "positive"
    }
  },
  {
    value: "Negative",
    title: {
      ru: "отрицательный",
      en: "negative"
    }
  },
  {
    value: "NoActual",
    title: {
      ru: "неактуальный",
      en: "not actual"
    }
  },
  {
    value: "Pending",
    title: {
      ru: "в ожидании",
      en: "pending"
    }
  },
]