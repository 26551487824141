import React, { useContext, useEffect } from 'react';
import {
  Button,
  Heading,
  Text,
  Box, Alert, Image, useColorModeValue
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { UIInputForm } from '../components/deprecated/input';
import { loginAuth } from '../store/authentication.reducer';
import { useDispatch, useSelector } from 'react-redux';
import Logo from './logo192.png'
import { TranslationContext } from '../translation';
import { useHistory } from 'react-router-dom';
import { useWindowHeight } from '../chakra-lib';

const validateInput = value => {
  let error;
  if (!value) {
    error = 'Поле не может быть пустым';
  }
  return error;
};

export const Login = () => {
  const {getTranslation} = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();
  const themeBg = useColorModeValue('gray.50', 'gray.800');
  const themeBgContainer = useColorModeValue('white', 'gray.700');
  const themeBorderWidth = useColorModeValue('1px', 'none');
  const loading = useSelector(state => state.authentication.loading);
  const errorMessage = useSelector(state => state.authentication.errorMessage);
  const error = useSelector(state => state.authentication.error);
  const version = useSelector(state => state.settings.version);
  const update = useSelector(state => state.settings.update);
  const adminPanelName = useSelector(state => state.settings.adminPanelName);
  const versionBack = useSelector(state => state.settings.versionBack);
  const handleForm = async (values, actions) => {
    const dataRequest = {
      username: values.username,
      password: values.password
    };
    await dispatch(loginAuth(dataRequest));
  };

  const TYPE_HEADING_ADMIN_PANEL = {
    'laboratory': getTranslation('headingAdminPanel.laboratory'),
    'national': getTranslation('headingAdminPanel.national'),
    'integration': getTranslation('headingAdminPanel.integration')
  }

  useEffect(() => {
    if (error && history.location.pathname !== '/') {
      history.push('/')
    }
  })

  return (
    <Box
      width="100%"
      height={`${windowHeight}px`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundColor={themeBg}
    >
      <Box
        width="370px"
        backgroundColor={themeBgContainer}
        p="40px"
        borderRadius={8}
        borderWidth={themeBorderWidth}
      >
        <Box
          display="flex"
          justifyContent="center"
          marginBottom="30px"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Image src={Logo} width="96px" height="96px" alt="" marginBottom="20px"/>
          <Heading as="h3" size="md" mb={2}>
            {adminPanelName !== '' ? adminPanelName : TYPE_HEADING_ADMIN_PANEL[adminPanelName]}
          </Heading>
          <Text mb={3} fontSize="1rem">
            {getTranslation('login.description')}
          </Text>
        </Box>
        {errorMessage && (
          <Alert mb={4} status="error" borderRadius={6} justifyContent="center">
            {getTranslation('login.error')}
          </Alert>
        )}
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={handleForm}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <Box mb={4}>
                <Field
                  name="username"
                  autoFocus
                  component={UIInputForm}
                  validate={validateInput}
                  placeholder={getTranslation('login.field.username')}
                />
              </Box>
              <Box mb={8}>
                <Field
                  name="password"
                  component={UIInputForm}
                  validate={validateInput}
                  placeholder={getTranslation('login.field.password')}
                  type="password"
                />
              </Box>
              <Box>
                <Button
                  type="submit"
                  colorScheme="blue"
                  width="100%"
                  isLoading={loading}
                >
                  {getTranslation('login.action.buttonSubmit')}
                </Button>
              </Box>
              <Box mt={8} color="gray.300" fontSize="xs" style={{textAlign: 'center'}}>
                {`Frontend: ${version} (${update})`} <br/>
                {`Backend: ${versionBack}`}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
