import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

export const RightAside = ({ children, ...props }) => {
  const themeBgContainer = useColorModeValue('white', 'gray.700');
  const themeBorderWidth = useColorModeValue('1px', 'none');
  return (
    <Box
      borderWidth={themeBorderWidth}
      padding={4}
      borderRadius={6}
      borderColor="gray.100"
      position="sticky"
      background={themeBgContainer}
      top="0"
      mt="0"
      {...props}
    >
      {children}
    </Box>
  );
};
