import React, { useContext, useEffect } from 'react';
import { TranslationContext } from '../../../../translation';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn, GridField, GridFieldHeading, GridFieldItem,
  HeadingContainer
} from '../../../../chakra-lib/layout';
import { Heading } from '../../../../chakra-lib';
import { getMedicalFacility, resetMedicalFacility } from '../../../../store/medicalFacility.reducer';
import { getDetailValue } from '../../../../components/deprecated/utilities/details-field';

export const MedicalFacilityCard = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const item = useSelector(state => state.medicalFacility.item);
  const loading = useSelector(state => state.medicalFacility.loading);
  const isReadyItem = !isEmpty(item);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(getMedicalFacility(id));
    return () => {
      dispatch(resetMedicalFacility());
    }
  }, []);

  const handleBack = () => {
    history.goBack();
  };
  return (
    <>
      <Container>
        <CardContainer indentTop={70} onBack={handleBack}>
          <ContentContainer>
            <ContentContainerColumn>
              <HeadingContainer>
                <Heading fontSize="1.4rem">Медицинское учреждение</Heading>
              </HeadingContainer>
              <GridField>
                <GridFieldHeading>{getTranslation('office.section.heading.data')}</GridFieldHeading>
                <GridFieldItem
                  label={getTranslation('office.field.titleRu.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.title?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.titleEn.placeholder')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.title?.en}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.shortTitleRu.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.shortTitle?.ru}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.shortTitleEn.placeholder')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.shortTitle?.en}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.telephone.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.telephone && item.telephone}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('office.field.siteUrl.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {item.siteUrl && item.siteUrl}
                </GridFieldItem>
                <GridFieldItem
                  label={getTranslation('requisites.oid.label')}
                  isLoading={loading}
                  isReady={isReadyItem}
                >
                  {getDetailValue(item, 'oid')}
                </GridFieldItem>
              </GridField>
            </ContentContainerColumn>
          </ContentContainer>
        </CardContainer>
      </Container>
    </>
  )
}
