import React from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';
import Certificate2 from '../../images/certificate-2.jpg';
import { getDateReadFormat } from '../../utils/dateFormatting';
import { isEmpty } from 'lodash';

export class VaccinationCertificatePage2 extends React.Component {
  render() {
    const itemPhase1 = this.props.itemsPhase[0];
    const itemPhase2 = this.props.itemsPhase[1];
    return (
      <Box
        width="1000px"
        height="1060px"
        color="#000000"
        fontSize="0.7rem"
        //backgroundImage={`url(${Certificate1})`}
        position="relative"
        flex="none"
        //transform="scale(0.6)"
      >
        <Image
          src={Certificate2}
          width="1000px"
          height='auto'
          position="absolute"
          zIndex={0}
          left={0}
          top={0}
        />
        <Flex
          top="217px"
          color="#000000"
          position="absolute"
          left="52px"
          width="405px"
          height="50px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="left"
          alignItems="center"
          pl={2}
          pr={3}
          zIndex={2}
        >
          {this.props.item?.firstName}
        </Flex>
        <Flex
          top="304px"
          color="#000000"
          position="absolute"
          left="52px"
          width="405px"
          height="50px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="left"
          alignItems="center"
          pl={2}
          pr={3}
          zIndex={2}
        >
          {this.props.item?.lastName}
        </Flex>
        <Flex
          top="387px"
          color="#000000"
          position="absolute"
          left="52px"
          width="405px"
          height="50px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="left"
          alignItems="center"
          pl={2}
          pr={3}
          zIndex={2}
        >
          {this.props.item?.patronymicName}
        </Flex>
        <Flex
          top="494px"
          color="#000000"
          position="absolute"
          left="52px"
          width="405px"
          height="50px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="left"
          alignItems="center"
          pl={2}
          pr={3}
          zIndex={2}
        >
          {getDateReadFormat(this.props.item?.birthday)}
        </Flex>
        <Flex
          top="580px"
          color="#000000"
          position="absolute"
          left="52px"
          width="405px"
          height="50px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="left"
          alignItems="center"
          pl={2}
          pr={3}
          zIndex={2}
        >
          {this.props.item?.passport}
        </Flex>
        <Flex
          top="666px"
          color="#000000"
          position="absolute"
          left="52px"
          width="405px"
          height="50px"
          fontSize="18px"
          lineHeight="32px"
          textAlign="left"
          alignItems="center"
          pl={2}
          pr={3}
          zIndex={2}
        >
          {this.props.item?.uid}
        </Flex>
        {!isEmpty(itemPhase1) && (
          <>
            <Flex
              top="322px"
              //backgroundColor="#000000"
              color="#000000"
              position="absolute"
              right="52px"
              width="405px"
              height="50px"
              fontSize="18px"
              lineHeight="32px"
              textAlign="left"
              alignItems="center"
              pl={3}
              pr={3}
              zIndex={2}
            >
              {getDateReadFormat(itemPhase1?.vaccinationDate)}
            </Flex>
            <Flex
              top="402px"
              //backgroundColor="#000000"
              color="#000000"
              position="absolute"
              right="52px"
              width="405px"
              height="50px"
              fontSize="13px"
              lineHeight="32px"
              textAlign="left"
              alignItems="center"
              pl={3}
              pr={3}
              zIndex={2}
            >
              {`${this.props.item?.vaccineName?.ru} ${this.props.item?.countryManufacturer ? this.props.item?.countryManufacturer : ''} ${itemPhase1?.vaccineSeries}`}
            </Flex>
            <Flex
              top="484px"
              //backgroundColor="#000000"
              color="#000000"
              position="absolute"
              right="52px"
              width="405px"
              height="50px"
              fontSize="18px"
              lineHeight="32px"
              textAlign="left"
              alignItems="center"
              pl={3}
              pr={3}
              zIndex={2}
            >
              {itemPhase1?.doctor}
            </Flex>
          </>
        )}
        {!isEmpty(itemPhase2) && (
          <>
            <Flex
              top="745px"
              //backgroundColor="#000000"
              color="#000000"
              position="absolute"
              right="52px"
              width="405px"
              height="50px"
              fontSize="18px"
              lineHeight="32px"
              textAlign="left"
              alignItems="center"
              pl={3}
              pr={3}
              zIndex={2}
            >
              {getDateReadFormat(itemPhase2?.vaccinationDate)}
            </Flex>
            <Flex
              top="824px"
              //backgroundColor="#000000"
              color="#000000"
              position="absolute"
              right="52px"
              width="405px"
              height="50px"
              fontSize="13px"
              lineHeight="32px"
              textAlign="left"
              alignItems="center"
              pl={3}
              pr={3}
              zIndex={2}
            >
              {`${this.props.item?.vaccineName?.ru} ${this.props.item?.countryManufacturer ? this.props.item?.countryManufacturer : ''} ${itemPhase2?.vaccineSeries}`}
            </Flex>
            <Flex
              top="904px"
              //backgroundColor="#000000"
              color="#000000"
              position="absolute"
              right="52px"
              width="405px"
              height="50px"
              fontSize="18px"
              lineHeight="32px"
              textAlign="left"
              alignItems="center"
              pl={3}
              pr={3}
              zIndex={2}
            >
              {itemPhase2?.doctor}
            </Flex>
          </>
        )}
      </Box>
    );
  }
}
