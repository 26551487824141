import React, { useContext } from 'react';
import {
  Button,
  ModalBody,
  useToast,
  useDisclosure, ModalFooter,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormSection } from '../../../../../components/deprecated/layout';
import { ModalForm } from '../../../../../components/deprecated/modal';
import * as Yup from 'yup';
import { UIInputForm } from '../../../../../components/deprecated/input';
import { setSettings } from '../../../../../store/settings.reducer';
import { TranslationContext } from '../../../../../translation';
import { UICheckboxForm } from '../../../../../components/deprecated/checkbox';

const formSchema = Yup.object().shape({
  autoNumberingPrefix: Yup.string().required('Required')
});

export const SettingsAutoNumbering = () => {
  const {getTranslation} = useContext(TranslationContext);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.settings.loading);
  const autoNumberingEnabled = useSelector(state => state.settings.autoNumberingEnabled);
  const autoNumberingPrefix = useSelector(state => state.settings.autoNumberingPrefix);
  const handleForm = async (values, actions) => {
    const dataRequestNumberingEnabled = {
      key: 'autonumbering_enabled',
      value: Boolean(values.autoNumberingEnabled)
    };
    const dataRequestNumberingPrefix = {
      key: 'autonumbering_preffix',
      value: values.autoNumberingPrefix
    };
    onClose();
    await dispatch(setSettings(dataRequestNumberingEnabled));
    await dispatch(setSettings(dataRequestNumberingPrefix));
    toast({
      title: getTranslation('settingsAutoNumbering.toast.update.success.title'),
      description: `${getTranslation('settingsAutoNumbering.toast.update.success.description')}`,
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  };
  return (
    <>
      {autoNumberingEnabled !== '' && autoNumberingPrefix && (
        <Button
          onClick={onOpen}
          isLoading={loading}
          width="100%"
        >
          {getTranslation('settingsAutoNumbering.button.label')}
        </Button>
      )}
      <ModalForm
        isOpen={isOpen}
        onClose={onClose}
        heading={getTranslation('settingsAutoNumbering.heading.setting')}
      >
        <Formik
          initialValues={{
            autoNumberingEnabled: autoNumberingEnabled === true,
            autoNumberingPrefix: autoNumberingPrefix.toString()
          }}
          onSubmit={handleForm}
          validationSchema={formSchema}
          enableReinitialize={true}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormSection mb={0}>
                  <Field
                    name="autoNumberingEnabled"
                    component={UICheckboxForm}
                    label={getTranslation('settingsAutoNumbering.field.autoNumberingEnabled.label')}
                  />
                  <Field
                    autoFocus
                    name="autoNumberingPrefix"
                    label={getTranslation('settingsAutoNumbering.field.autoNumberingPrefix.label')}
                    component={UIInputForm}
                    placeholder="ABC_"
                  />
                </FormSection>
              </ModalBody>
              <ModalFooter justifyContent="flex-start">
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!props.dirty}
                >
                  {getTranslation('global.update')}
                </Button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </ModalForm>
    </>
  );
};
