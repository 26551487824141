
export const TEST_FIELDS = {
  internalId: 'internalId',
  testingDate: 'testingDate',
  resultDate: 'resultDate',
  resultSetDate: 'resultSetDate',
  passport: 'passport',
  officeTitleRu: 'officeTitleRu',
  officeTitleEn: 'officeTitleEn',
  officeAddress: 'officeAddress',
  testType: 'testType',
  result: 'result',
  createdAt: 'createdAt',
}

export const TEST_FIELDS_TRANSLATION = {
  [TEST_FIELDS.internalId]: 'test.field.internalId.label',
  [TEST_FIELDS.testingDate]: 'test.field.testingDate.label',
  [TEST_FIELDS.resultDate]: 'test.field.resultDate.label',
  [TEST_FIELDS.resultSetDate]: 'test.field.resultSetDate.label',
  [TEST_FIELDS.passport]: 'test.field.passport.label',
  [TEST_FIELDS.officeTitleRu]: 'office.field.titleRu.label',
  [TEST_FIELDS.officeTitleEn]: 'office.field.titleEn.placeholder',
  [TEST_FIELDS.officeAddress]: 'office.field.address.label',
  [TEST_FIELDS.result]: 'test.field.resultTest.label',
  [TEST_FIELDS.testType]: 'test.field.testType.label',
  [TEST_FIELDS.createdAt]: 'global.createdDate',
}