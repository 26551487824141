
export const TYPE_RESULT_VARIANT_COLOR = {
    'pending' : 'gray',
    'delay' : 'orange',
    'true' : 'red',
    'false' : 'green'
}

export const TYPE_RESULT_COLOR = {
    'pending' : {
        colorBg: 'gray.100',
        colorFont: 'gray.800',
    },
    'delay' : {
        colorBg: 'orange.100',
        colorFont: 'orange.800',
    },
    'true' : {
        colorBg: 'red.100',
        colorFont: 'red.800',
    },
    'false' : {
        colorBg: 'green.100',
        colorFont: 'green.800',
    }
}

export const getResultTest = (result, testingDate, currentTime, testValidHours = 0, diffDate) => {
    if (diffDate > testValidHours) {
        return {
            resultTestText: 'Просрочен',
            resultTestType: 'delay',
        }
    }
    if (result === null) {
        return {
            resultTestText: 'В ожидании',
            resultTestType: 'pending',
        }
    }
    return {
        resultTestText: result ? 'Положительный' : 'Отрицательный',
        resultTestType: result ? 'true' : 'false',
    }
};
