import React, { useContext } from 'react';
import { Button, ModalBody, ModalFooter, Box, useDisclosure } from '@chakra-ui/react';
import { ModalForm } from '../modal';
import { TranslationContext } from '../../../translation';

export const ReadOnlyForm = ({ value = true, onChange }) => {
    const { getTranslation } = useContext(TranslationContext);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleUnblock = () => {
        if (value) {
            onOpen();
        } else {
            onChange && onChange(true);
        }
    }

    const handleButton = () => {
        onChange && onChange(false);
        onClose();
    };

    return (
        <>
            <Button
                onClick={handleUnblock}
                size="sm"
            >
                {value ? (
                    getTranslation('readOnlyForm.action.unblock')
                ) : (
                    getTranslation('readOnlyForm.action.block')
                )}
            </Button>
            <ModalForm
                isOpen={isOpen}
                onClose={onClose}
                heading={getTranslation('readOnlyForm.heading')}
            >
                <ModalBody>
                    <Box>
                        {getTranslation('readOnlyForm.description')}
                    </Box>
                </ModalBody>
                <ModalFooter justifyContent="flex-start">
                    <Button
                        mr={3}
                        colorScheme="blue"
                        onClick={onClose}
                    >
                        {getTranslation('global.close')}
                    </Button>
                    <Button
                        onClick={handleButton}
                    >
                        {getTranslation('readOnlyForm.action.unblock')}
                    </Button>
                </ModalFooter>
            </ModalForm>
        </>
    );
};

