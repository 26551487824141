import React, { useContext, useEffect, useMemo } from 'react'
import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form,
  FormGrid,
  FormSection,
  HeadingContainer,
} from 'chakra-lib/layout'
import { Alert, Heading, InputForm, SelectForm } from 'chakra-lib'
import { TranslationContext } from 'translation'
import { useHistory } from 'react-router-dom'
import { Button, Text, useToast } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { ROLES } from 'model/roles'
import { getOfficeList, resetOfficeList } from 'store/office.reducer'
import { createTest, getTestNumber, resetTest, resetTestNumber } from 'store/test.reducer'
import { getTime } from 'store/settings.reducer'
import { TYPE_NEW_TEST_SCHEMA } from 'model/schema'
import { Field, Formik } from 'formik'
import { Private } from 'components/Private'
import { Information } from 'components/deprecated/Information'
import {
  getDateReadFormat,
  getDateTimeToSubmit,
  getTimeReadFormat,
} from 'chakra-lib/utils/dateFormatting'
import { UIInputTimeForm } from 'components/deprecated/input'
import { UIInputDateForm } from 'components/deprecated/input-date'
import { isEmpty } from 'lodash'
import { useTestTypeList } from './_services'

export const TestNew = () => {
  const { getTranslation } = useContext(TranslationContext)
  const history = useHistory()
  const toast = useToast()
  const dispatch = useDispatch()
  const country = useSelector((state) => state.settings.country)
  const time = useSelector((state) => state.settings.time)
  const autoNumberingEnabled = useSelector((state) => state.settings.autoNumberingEnabled)
  const autoNumberingPrefix = useSelector((state) => state.settings.autoNumberingPrefix)
  const user = useSelector((state) => state.authentication.user)

  const itemTest = useSelector((state) => state.test.item)
  const loading = useSelector((state) => state.test.loading)
  const loadingUpdate = useSelector((state) => state.test.loadingUpdate)
  const errorMessage = useSelector((state) => state.test.errorMessage)
  const testNumber = useSelector((state) => state.test.testNumber)

  const officeList = useSelector((state) => state.office.items)
  const officeLoading = useSelector((state) => state.office.loading)
  const officeIdLoaded = user.role !== ROLES.Administrator && !user.officeId

  const {
    response: testTypes,
    request: fetchTestTypes,
    isLoading: testTypesIsLoading,
  } = useTestTypeList()

  useEffect(() => {
    fetchTestTypes()
  }, [])

  useEffect(() => {
    if (!isEmpty(itemTest)) {
      history.replace(`/laboratory/test/show/${itemTest.id}`)
    }
  }, [itemTest])

  const handleBack = () => {
    history.goBack()
  }

  useEffect(() => {
    if (user.role === ROLES.Administrator) {
      dispatch(getOfficeList())
    }
    return () => {
      dispatch(resetOfficeList())
      dispatch(resetTest())
    }
  }, [])

  const handleForm = async (values) => {
    const dataRequest = {
      fio: values.fio,
      email: values.email,
      telephone: values.telephone,
      passport: values.passport?.replace(/\s/g, ''),
      internalId: values.internalId,
      testingDate: getDateTimeToSubmit(`${values.testingDate} ${values.testingTime}`),
      officeId: values.officeId,
      testTypeId: values.testTypeId,
    }

    await dispatch(createTest(dataRequest))

    toast({
      title: getTranslation('test.toast.newTest.success.title'),
      description: values.fio
        ? `${getTranslation('test.toast.newTest.success.description.newTestFor')} ${values.fio}`
        : getTranslation('test.toast.newTest.success.description.newTest'),
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  }

  useEffect(() => {
    dispatch(getTime())

    if (autoNumberingEnabled) {
      dispatch(getTestNumber())
    }

    return () => {
      if (autoNumberingEnabled) {
        dispatch(resetTestNumber())
      }
    }
  }, [])

  const testTypeOptions = useMemo(() => {
    if (testTypes?.list) {
      return testTypes.list.map((item) => ({
        label: item.title.ru,
        value: item.id,
      }))
    }

    return []
  }, [testTypes])

  return (
    <Container>
      <CardContainer onBack={handleBack}>
        <ContentContainer>
          <ContentContainerColumn>
            <HeadingContainer>
              <Heading>{getTranslation('test.heading.new')}</Heading>
            </HeadingContainer>
            {errorMessage && (
              <Alert marginBottom="30px">
                {getTranslation(`test.alert.error.${errorMessage?.error}`)}
              </Alert>
            )}
            {officeIdLoaded && (
              <Alert marginBottom="30px">
                {getTranslation('test.alert.error.officeConnected')}
              </Alert>
            )}
            <Formik
              initialValues={{
                internalId: autoNumberingEnabled ? `${autoNumberingPrefix}${testNumber}` : '',
                testingDate: getDateReadFormat(time),
                testingTime: getTimeReadFormat(time),
                officeId: user.officeId ? user.officeId : '',
                testTypeId: testTypes?.list.length === 1 ? testTypes?.list[0].id : undefined,
              }}
              onSubmit={handleForm}
              validationSchema={TYPE_NEW_TEST_SCHEMA[country]}
              enableReinitialize={true}
            >
              {(props) => {
                return (
                  <Form
                    dataForm={props}
                    onSubmit={props.handleSubmit}
                    footer={
                      <Button
                        type="submit"
                        colorScheme="blue"
                        isDisabled={officeIdLoaded}
                        isLoading={loadingUpdate || loading}
                      >
                        {getTranslation('test.action.buttonCreate')}
                      </Button>
                    }
                  >
                    <FormSection>
                      <Field
                        autoFocus
                        name="fio"
                        label={getTranslation('test.field.fio.label')}
                        component={InputForm}
                        placeholder={getTranslation('test.field.fio.placeholder')}
                        description={getTranslation('test.field.fio.description')}
                      />
                      <FormGrid columns={2}>
                        <Field
                          name="email"
                          label={getTranslation('test.field.email.label')}
                          component={InputForm}
                          placeholder={getTranslation('test.field.email.placeholder')}
                        />
                        <Field
                          name="telephone"
                          label={getTranslation('test.field.telephone.label')}
                          component={InputForm}
                          placeholder={getTranslation('test.field.telephone.placeholder')}
                        />
                      </FormGrid>
                      <Field
                        name="passport"
                        label={getTranslation('test.field.passport.label')}
                        component={InputForm}
                        description={getTranslation('test.field.passport.description')}
                      />
                    </FormSection>
                    <FormSection heading={getTranslation('test.section.heading.test')}>
                      <Field
                        name="testTypeId"
                        label={getTranslation('test.field.testType.label')}
                        component={SelectForm}
                        isLoading={testTypesIsLoading}
                        placeholder={getTranslation('test.field.testType.placeholder')}
                        isSearchable={false}
                        options={testTypeOptions}
                      />
                      <Field
                        name="internalId"
                        label={getTranslation('test.field.internalId.label')}
                        component={InputForm}
                        placeholder={getTranslation('test.field.internalId.placeholder')}
                      />
                      {props?.errors?.internalId && (
                        <Text color="red.500" fontSize="0.87rem">
                          {props?.errors?.internalId}
                        </Text>
                      )}
                      <FormGrid columns={2}>
                        <Field
                          label={getTranslation('global.time')}
                          name="testingTime"
                          component={UIInputTimeForm}
                          placeholder="00:00"
                        />
                        <Field
                          label={getTranslation('global.date')}
                          name="testingDate"
                          component={UIInputDateForm}
                          placeholder="01.01.2020"
                        />
                      </FormGrid>
                      <Private role={user.role} hasAnyRole={[ROLES.Administrator]}>
                        <Field
                          name="officeId"
                          label={getTranslation('test.field.officeId.label')}
                          component={SelectForm}
                          placeholder={getTranslation('test.field.officeId.placeholder')}
                          isLoading={officeLoading}
                          options={
                            officeList &&
                            officeList.map((item) => ({
                              value: item.id,
                              label: item.name ? item.name : '<Нет имени>',
                            }))
                          }
                        />
                      </Private>
                    </FormSection>
                  </Form>
                )
              }}
            </Formik>
          </ContentContainerColumn>
          <ContentContainerColumn>
            <Information>{getTranslation('test.information.delayed')}</Information>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  )
}
