import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { TranslationContext } from 'translation';

import {
  CardContainer,
  Container,
  ContentContainer,
  ContentContainerColumn,
  Form, FormGrid, FormHeading, FormSection
} from 'chakra-lib/layout';
import { CheckboxForm, InputForm, Tab, TabList, TabPanel, TabPanels, Tabs } from 'chakra-lib';
import { createCountry, getCountry, resetCountry, updateCountry } from 'store/country.reducer';
import { isEmpty } from 'components/deprecated/utilities/is-empty';
import { TabCheck } from 'components/tab-check';
import { CountrySelectionIcon } from 'components/deprecated/country-selection-icon';
import { Field, Formik } from 'formik';
import { MapSetPosition } from 'components/map';
import { ReadOnlyForm } from 'components/deprecated/readonly-form';
import { UITextareaForm } from 'components/textarea';
import { Box, Button, Collapse, useToast } from '@chakra-ui/react';
import Recovered from "./Recovered";

const formSchema = Yup.object().shape({
  nameRu: Yup.string().required('Required'),
  nameEn: Yup.string().required('Required'),
  shortNameRu: Yup.string().required('Required'),
  shortNameEn: Yup.string().required('Required')
});

export const CountryUpdate = () => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const toast = useToast();
  // eslint-disable-next-line no-unused-vars
  const isNew = !id;
  const item = useSelector(state => state.country.item);
  const loading = useSelector(state => state.country.loadingItem);
  const loadingUpdate = useSelector(state => state.country.loadingUpdate);
  const [hiddenSettings, setHiddenSettings] = useState(true)
  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleBack = () => {
    history.goBack();
  };

  const handleCoordinates = (e, name, props) => {
    const value = e.target.value;
    const valueRegexp = value.match(/\d+\.\d+/g);
    const valueValid = Array.isArray(valueRegexp) && valueRegexp[0];
    props.setFieldValue(name, valueValid ? valueValid.toString() : '');
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isNew) {
      dispatch(resetCountry());
    } else {
      dispatch(getCountry(id));
    }
    return () => {
      dispatch(resetCountry());
    };
  }, []);

  const handleForm = async (values, actions) => {
    const dataRequest = {
      id: !isNew ? id : undefined,
      name: {
        ru: values.nameRu,
        en: values.nameEn
      },
      shortName: {
        ru: values.shortNameRu,
        en: values.shortNameEn
      },
      nationalUrl: values.nationalUrl,
      disabled: values.disabled,
      nationalPubKey: values.nationalPubKey,
      description: {
        ru: values.descriptionRu,
        en: values.descriptionEn
      },
      countryCode: values.countryCode,
      flag: values.flag,
      defaultCoordinates: {
        lat: Number(values.lat),
        lng: Number(values.lng)
      },
      testingInfo: {
        ru: values.testingInfoRu,
        en: values.testingInfoEn
      },
      vaccinationInfo: {
        ru: values.vaccinationInfoRu,
        en: values.vaccinationInfoEn
      },
      vaccinationUrl: values.vaccinationUrl,
      vaccinationType: values.vaccinationType,
      vaccinationEnabled: values.vaccinationEnabled,
      recoveredInfo: {
        ru: values.recoveredInfoRu,
        en: values.recoveredInfoEn
      },
      recoveredUrl: values.recoveredUrl,
      recoveredQrEnabled: values.recoveredType === 'QrEnabled',
      recoveredUseVaccination: values.recoveredType === 'trueVaccination',
    };

    if (isNew) {
      await dispatch(createCountry(dataRequest));
      toast({
        title: getTranslation('country.toast.newCountry.success.title'),
        description: `${getTranslation('country.toast.newCountry.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    } else {
      await dispatch(updateCountry(dataRequest));
      toast({
        title: getTranslation('country.toast.updateCountry.success.title'),
        description: `${getTranslation('country.toast.updateCountry.success.description')}`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      handleBack();
    }
  };

  return (
    <Container>
      <CardContainer onBack={handleBack} indentTop={70}>
        <ContentContainer>
          <ContentContainerColumn>
            <Formik
              initialValues={{
                nameRu: !isEmpty(item) ? (item.name ? item.name.ru : '') : '',
                nameEn: !isEmpty(item) ? (item.name ? item.name.en : '') : '',
                shortNameRu: !isEmpty(item) ? (item.shortName ? item.shortName.ru : '') : '',
                shortNameEn: !isEmpty(item) ? (item.shortName ? item.shortName.en : '') : '',
                nationalUrl: item.nationalUrl,
                disabled: item.disabled,
                nationalPubKey: item.nationalPubKey,
                descriptionRu: !isEmpty(item) ? (item.description ? item.description.ru : '') : '',
                descriptionEn: !isEmpty(item) ? (item.description ? item.description.en : '') : '',
                countryCode: item.countryCode,
                flag: item.flag,
                lat: !isEmpty(item) ? (item.defaultCoordinates ? item.defaultCoordinates.lat : '') : '',
                lng: !isEmpty(item) ? (item.defaultCoordinates ? item.defaultCoordinates.lng : '') : '',
                testingInfoRu: !isEmpty(item) ? (item.testingInfo ? item.testingInfo.ru : '') : '',
                testingInfoEn: !isEmpty(item) ? (item.testingInfo ? item.testingInfo.en : '') : '',
                vaccinationInfoRu: !isEmpty(item) ? (item.vaccinationInfo ? item.vaccinationInfo.ru : '') : '',
                vaccinationInfoEn: !isEmpty(item) ? (item.vaccinationInfo ? item.vaccinationInfo.en : '') : '',
                vaccinationUrl: item.vaccinationUrl,
                vaccinationType: item.vaccinationType,
                vaccinationEnabled: item.vaccinationEnabled,
                recoveredInfoRu: item?.recoveredInfo?.ru || '',
                recoveredInfoEn: item?.recoveredInfo?.en || '',
                recoveredUrl: item.recoveredUrl,
                recoveredType: item.recoveredQrEnabled ? 'QrEnabled' : item.recoveredUseVaccination ? 'trueVaccination' : 'QrDisabled',
              }}
              enableReinitialize={true}
              validationSchema={formSchema}
              onSubmit={handleForm}
            >
              {props => (
                <Form
                  dataForm={props}
                  onSubmit={props.handleSubmit}
                  footer={
                    <Button
                      type="submit"
                      colorScheme="blue"
                      isDisabled={!props.dirty}
                      isLoading={loadingUpdate}
                    >
                      {isNew ? getTranslation('country.action.buttonCreate') : getTranslation('country.action.buttonUpdate')}
                    </Button>
                  }
                >
                  <Tabs variant="enclosed-colored">
                    <TabList>
                      <Tab>
                        <TabCheck form={props} fields={['nameRu', 'nameEn', 'shortNameRu', 'shortNameEn']}>
                          {getTranslation('country.tabs.new.information')}
                        </TabCheck>
                      </Tab>
                      <Tab>{getTranslation('country.tabs.new.safety')}</Tab>
                      <Tab>{getTranslation('country.tabs.new.description')}</Tab>
                      <Tab>{getTranslation('country.tabs.new.testingInfo')}</Tab>
                      <Tab>{getTranslation('country.tabs.new.vaccination')}</Tab>
                      <Tab>{getTranslation('country.tabs.new.recovered')}</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel pb={0}>
                        <FormSection>
                          <CountrySelectionIcon
                            label={getTranslation('country.field.image.label')}
                            src={props.values.flag}
                            onChange={src => (
                              props.setFieldValue('flag', src)
                            )}
                          />
                          <Field
                            autoFocus
                            name="nameRu"
                            label={getTranslation('country.field.nameRu.label')}
                            component={InputForm}
                            isDisabled={loading}
                          />
                          <Field
                            name="nameEn"
                            component={InputForm}
                            placeholder={getTranslation('country.field.nameEn.placeholder')}
                            isDisabled={loading}
                          />
                          <Field
                            name="shortNameRu"
                            label={getTranslation('country.field.shortNameRu.label')}
                            component={InputForm}
                            placeholder={getTranslation('country.field.shortNameRu.placeholder')}
                            isDisabled={loading}
                          />
                          <Field
                            name="shortNameEn"
                            component={InputForm}
                            placeholder={getTranslation('country.field.shortNameEn.placeholder')}
                            isDisabled={loading}
                          />
                          <FormGrid columns={2}>
                            <Field
                              name="countryCode"
                              label={getTranslation('country.field.countryCode.label')}
                              component={InputForm}
                              placeholder="ru"
                              isDisabled={loading}
                            />
                          </FormGrid>
                          <Field
                            name="disabled"
                            component={CheckboxForm}
                            label={getTranslation('country.field.disabled.label')}
                          />
                        </FormSection>
                        <FormSection>
                          <FormHeading>
                            {getTranslation('country.section.heading.location')}
                          </FormHeading>
                          <MapSetPosition
                            latitude={props.values.lat ? props.values.lat : undefined}
                            longitude={props.values.lng ? props.values.lng : undefined}
                            showMarker
                            onChangeMap={e => {
                              props.setFieldValue('lat', e.latitude);
                              props.setFieldValue('lng', e.longitude);
                            }}
                          />
                          <FormGrid columns={2}>
                            <Field
                              name="lat"
                              label={getTranslation('office.field.latitude.label')}
                              component={InputForm}
                              placeholder="00.000000"
                              isDisabled={loading}
                              onBlur={(e) => handleCoordinates(e, 'lat', props)}
                            />
                            <Field
                              name="lng"
                              label={getTranslation('office.field.longitude.label')}
                              component={InputForm}
                              placeholder="00.000000"
                              isDisabled={loading}
                              onBlur={(e) => handleCoordinates(e, 'lng', props)}
                            />
                          </FormGrid>
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <ReadOnlyForm value={isReadOnly} onChange={setIsReadOnly}/>
                          <Field
                            name="nationalUrl"
                            label={getTranslation('country.field.nationalUrl.label')}
                            component={InputForm}
                            placeholder={getTranslation('country.field.nationalUrl.placeholder')}
                            isDisabled={loading || isReadOnly}
                            variant={isReadOnly ? 'filled' : 'outline'}
                          />
                          <Field
                            name="nationalPubKey"
                            label={getTranslation('country.field.nationalPubKey.label')}
                            component={UITextareaForm}
                            minHeight="500px"
                            isDisabled={loading || isReadOnly}
                            variant={isReadOnly ? 'filled' : 'outline'}
                          />
                        </FormSection>
                      </TabPanel>
                      <TabPanel pb={0}>
                        <FormSection>
                          <Tabs variant="soft-rounded">
                            <TabList>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('country.tabs.description.inRussian')}
                              </Tab>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('country.tabs.description.inEnglish')}
                              </Tab>
                            </TabList>
                            <TabPanels mt="16px">
                              <TabPanel padding={0}>
                                <Field
                                  name="descriptionRu"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="500px"
                                  placeholder={getTranslation('country.field.descriptionRu.placeholder')}
                                  fontFamily="Menlo, monospace"
                                />
                              </TabPanel>
                              <TabPanel padding={0}>
                                <Field
                                  name="descriptionEn"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="500px"
                                  placeholder={getTranslation('country.field.descriptionEn.placeholder')}
                                  fontFamily="Menlo, monospace"
                                />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        </FormSection>
                      </TabPanel>
                      <TabPanel>
                        <FormSection>
                          <Tabs variant="soft-rounded">
                            <TabList>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('country.tabs.testingInfo.inRussian')}
                              </Tab>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('country.tabs.testingInfo.inEnglish')}
                              </Tab>
                            </TabList>
                            <TabPanels mt="16px">
                              <TabPanel padding={0}>
                                <Field
                                  name="testingInfoRu"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="500px"
                                  placeholder={getTranslation('country.field.testingInfoRu.placeholder')}
                                  fontFamily="Menlo, monospace"
                                />
                              </TabPanel>
                              <TabPanel padding={0}>
                                <Field
                                  name="testingInfoEn"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="500px"
                                  placeholder={getTranslation('country.field.testingInfoEn.placeholder')}
                                  fontFamily="Menlo, monospace"
                                />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        </FormSection>
                      </TabPanel>
                      <TabPanel>
                        <FormSection>
                          <Tabs variant="soft-rounded">
                            <TabList>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('country.tabs.testingInfo.inRussian')}
                              </Tab>
                              <Tab padding="0.4rem 0.8rem" mr={1}>
                                {getTranslation('country.tabs.testingInfo.inEnglish')}
                              </Tab>
                            </TabList>
                            <TabPanels mt="16px">
                              <TabPanel padding={0}>
                                <Field
                                  name="vaccinationInfoRu"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="500px"
                                  placeholder={getTranslation('country.field.testingInfoRu.placeholder')}
                                  fontFamily="Menlo, monospace"
                                />
                              </TabPanel>
                              <TabPanel padding={0}>
                                <Field
                                  name="vaccinationInfoEn"
                                  component={UITextareaForm}
                                  isDisabled={loading}
                                  minHeight="500px"
                                  placeholder={getTranslation('country.field.testingInfoEn.placeholder')}
                                  fontFamily="Menlo, monospace"
                                />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                          <Field
                            name="vaccinationEnabled"
                            component={CheckboxForm}
                            label={getTranslation('country.tabs.vaccination.setting.on')}
                          />
                          <Box mt="20px">
                            <Button variant="outline" size="sm" onClick={() => setHiddenSettings(e => !e)}>{getTranslation('country.tabs.description.otherSettings')}</Button>
                          </Box>
                          <Collapse in={!hiddenSettings}>
                            <FormSection mb={0}>
                              <Field
                                name="vaccinationUrl"
                                label={getTranslation('country.tabs.vaccination.setting.qr')}
                                component={InputForm}
                                isDisabled={loading}
                              />
                              <Field
                                name="vaccinationType"
                                label={getTranslation('country.tabs.vaccination.setting.type')}
                                component={InputForm}
                                isDisabled={loading}
                              />
                            </FormSection>
                          </Collapse>
                        </FormSection>
                      </TabPanel>
                      <TabPanel>
                        <Recovered />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Form>
              )}
            </Formik>
          </ContentContainerColumn>
        </ContentContainer>
      </CardContainer>
    </Container>
  );
};
