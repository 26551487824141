import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';

import { Modal } from 'chakra-lib';
import { Button, useBoolean, useDisclosure, useToast } from '@chakra-ui/react';
import { DropZoneFile } from 'components/DropZoneFile';
import { ModalForm } from 'components/deprecated/modal';
import { getFaqListSearch } from 'store/faq.reducer';

import { ConfigPanel } from "./ConfigPanel";

export const FaqListUpload = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [configIsOpen, setOpenConfig] = useBoolean();
  const [fileUploaded, setFileUploaded] = useState({});

  const close = () => {
    onClose();
    setLoading(false);
    setFileUploaded({});
  }

  const handleUpload = async () => {
    try {
      const dataRequest = {
        fileBody: fileUploaded.result.split(';base64,')[1]
      }
      setLoading(true);
      await axios.post('/adminapi/faq/upload', dataRequest);
      await dispatch(getFaqListSearch());
      toast({
        description: `Документ загружен`, variant: 'solid', status: 'success'
      })
      onClose();
    } catch (err) {
      setLoading(false);
      if (err.response?.data?.error) {
        toast({
          title: 'Ошибка загрузки', description: `${err.response?.data?.error}`, variant: 'solid', status: 'error'
        })
      }
    }
  }

  const configUpdatedHandle = useCallback(() => {
    setOpenConfig.off()
  }, [setOpenConfig])

  return (<>
    <Button
      width="100%"
      onClick={onOpen}
    >
      Загрузить вопросы
    </Button>
    <Button
      width="100%"
      onClick={setOpenConfig.on}
    >
      Настройки
    </Button>
    <Modal
      title="Загрузка вопросов"
      isOpen={isOpen}
      onClose={close}
      footer={<Button
        colorScheme="blue"
        width="100%"
        type="submit"
        onClick={handleUpload}
        isLoading={loading}
        isDisabled={isEmpty(fileUploaded)}
      >
        Загрузить документ
      </Button>}
    >
      <DropZoneFile
        maxFiles={1}
        multiple={false}
        uploadedFile={fileUploaded}
        onUpload={(acceptedFiles) => {
          const file = acceptedFiles[0];
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setFileUploaded({
              path: file.path, result: reader.result
            })
          };
        }}
      />
    </Modal>
    <ModalForm
      isOpen={configIsOpen}
      onClose={setOpenConfig.off}
      heading="Настройки"
    >
      <ConfigPanel configUpdated={configUpdatedHandle}/>
    </ModalForm>
  </>)
}
