import moment from 'moment'
import momentTimezone from 'moment-timezone'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'

export const getTimeZoneMoment = () => {
  const timeZoneName = momentTimezone.tz.guess()
  return momentTimezone.tz(timeZoneName).format('Z')
}

export const getDiffDateHours = (start, end) => {
  const startMoment = moment(start)
  const endMoment = moment(end)
  return startMoment.diff(endMoment, 'hours')
}

export const getDiffDateMinutes = (start, end) => {
  const startMoment = moment(start)
  const endMoment = moment(end)
  return startMoment.diff(endMoment, 'minutes')
}

export const getDateToPicker = (value) => {
  const formattedDate = moment(value, DATE_FORMAT).toDate()
  return formattedDate
}

export const getDateToSubmit = (value) => {
  const formattedDate = moment(value, DATE_FORMAT).format()
  return formattedDate
}

export const getDateTimeToSubmit = (value) => {
  const formattedDate = moment(value, `${DATE_FORMAT} ${TIME_FORMAT}`).format()
  return formattedDate
}

export const getMoment = (utc = getTimeZoneMoment()) => {
  return moment().utcOffset(utc).format()
}

export const getDateReadFormat = (value) => {
  const date = moment(value)
  return date.isValid() ? date.format(DATE_FORMAT) : undefined
}

export const getTimeReadFormat = (value) => {
  const date = moment(value)
  return date.isValid() ? moment(value).format(TIME_FORMAT) : undefined
}

export const isInvalidDate = (date) => {
  return moment(date, DATE_FORMAT, true).isValid()
}

export const isValidTime = (value) => {
  return moment(value, TIME_FORMAT, true).isValid()
  //return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
}

export const twoDigits = (num) => {
  return ('0' + num).slice(-2)
}

