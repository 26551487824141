import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TranslationContext } from 'translation';

import {
  FormLabel,
  InputDate,
  InputSearch,
  SegmentControl,
  Select,
  TabPanel,
  TabPanels,
  Tabs
} from 'chakra-lib';
import { FilterFooter, FilterHeader, FilterStack, FilterStackGroup } from 'chakra-lib/layout';
import { getURLFromFilterParams } from 'chakra-lib/utils';
import { getFilterParams } from 'chakra-lib/utils';
import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { getDateReadFormat } from 'components/deprecated/utilities/date-formatting';
import { FILTER_TEST_PARAMS } from 'config/test.config';
import { getChangeValueSelect, getValueSelect } from 'chakra-lib/utils/select';
import { ROLES } from 'model/roles';
import { Private } from 'components/Private';

import { ReportFull } from './ReportFull';
import { SettingsAutoNumbering } from './SettingsAutoNumbering';
import { ReportLaboratoryUser } from './ReportLaboratoryUser';
import { SettingsSMTP } from './SettingsSMTP';
import { resultFilterList } from "./TestListFilter.constants";
import SettingsReports from "./SettingsReports";
import { useFetchReportSettings } from "./hooks";
import { testReports, testReportsUrl } from "model/reports";

export const TestListFilter = ({ params, onExportExcel }) => {
  const { getTranslation } = useContext(TranslationContext);
  const history = useHistory();
  const {
    text,
    internalId,
    officesIds,
    fromDate,
    toDate,
    byResult
  } = useSelector(state => state.test.filter);
  const officeList = useSelector(state => state.office.items);
  const userAuth = useSelector(state => state.authentication.user);
  const { reportListSettings, fetchReportListSettings } = useFetchReportSettings()

  const officeListSelect = officeList?.map(item => ({
    value: item.id,
    label: item.title?.ru ? item.title?.ru : '<Нет имени>'
  }));
  const resultFilterOptions = resultFilterList.map(item => ({
    value: item.value,
    label: item.title?.ru ? item.title?.ru : '<Нет имени>'
  }));
  const countList = useSelector(state => state.test.totalItems);
  const loadingOffice = useSelector(state => state.office.loading);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    fetchReportListSettings()
  }, [])

  const handleFilter = (param) => {
    const fieldName = Object.keys(param)[0];
    const filedValue = param[fieldName];
    const objFilterParams = getFilterParams(FILTER_TEST_PARAMS, params);
    const urlFilterParams = getURLFromFilterParams({
      ...objFilterParams,
      [fieldName]: filedValue
    })
    history.push(`/laboratory/test${urlFilterParams}`);
  }

  const handleResetFilter = () => {
    history.push(`/laboratory/test`)
  }

  const checkAvailableReport = (reportId) => {
    const roles = reportListSettings?.find(({ url }) => url === reportId).roles
    return !roles || roles.includes(userAuth.role)
  }

  const reportFullIsAvailable = checkAvailableReport(testReportsUrl[testReports.full])
  const reportByUserIsAvailable = checkAvailableReport(testReportsUrl[testReports.byUser])

  return (
    <>
      <Private
        role={userAuth.role}
        hasAnyRole={[ROLES.User, ROLES.UserPrivileged, ROLES.UserCreator]}
      >
        <FilterHeader>
          <Button
            width="100%"
            onClick={() => history.push(`/laboratory/test/new`)}
            colorScheme="blue"
          >
            {getTranslation('test.action.buttonCreate')}
          </Button>
        </FilterHeader>
      </Private>
      <FilterHeader>
        <SegmentControl
          width="100%"
          value={tabIndex}
          onChange={index => setTabIndex(index)}
          options={[
            {
              label: 'Фильтр',
              value: '0'
            },
            {
              label: 'Опции',
              value: '1'
            }
          ]}
        />
      </FilterHeader>
      <Tabs pt={0} mt={0} index={Number(tabIndex)}>
        <TabPanels pt={0} mt={0}>
          <TabPanel pt={0} mt={0}>
            <FilterStack>
              <InputSearch
                label={getTranslation('global.search')}
                name="text"
                value={text}
                onChange={e => handleFilter({ text: e })}
              />
              <InputSearch
                hideIcon
                label={getTranslation('filter.filterInternal')}
                name="internalId"
                value={internalId}
                onChange={e => handleFilter({ internalId: e })}
                placeholder={getTranslation('filter.filterInternalPlaceholder')}
              />
              <Select
                isMulti
                name="byResult"
                closeMenuOnSelect={false}
                label={getTranslation('filter.filterResult')}
                placeholder={getTranslation('filter.filterResult')}
                options={resultFilterOptions}
                value={getValueSelect(resultFilterOptions, byResult)}
                onChange={e => handleFilter({ byResult: getChangeValueSelect(e) })}
              />
              <Private
                role={userAuth.role}
                hasAnyRole={[ROLES.Administrator, ROLES.UserLab]}
              >
                <Select
                  isMulti
                  isLoading={loadingOffice}
                  name="officesIds"
                  closeMenuOnSelect={false}
                  label={getTranslation('filter.office')}
                  placeholder={getTranslation('filter.officePlaceholder')}
                  options={officeListSelect}
                  value={getValueSelect(officeListSelect, officesIds)}
                  onChange={e => handleFilter({ officesIds: getChangeValueSelect(e) })}
                />
              </Private>
              <FilterStackGroup>
                <InputDate
                  value={fromDate}
                  showCurrentDate={false}
                  name="identificationDateFrom"
                  placement="bottom"
                  onChange={e => handleFilter({ fromDate: e })}
                  label={getTranslation('filter.dateFrom')}
                  placeholder={getDateReadFormat()}
                />
                <InputDate
                  value={toDate}
                  showCurrentDate={false}
                  name="identificationDateTo"
                  placement="bottom"
                  onChange={e => handleFilter({ toDate: e })}
                  placeholder={getDateReadFormat()}
                />
              </FilterStackGroup>
            </FilterStack>
            <FilterFooter>
              <Button
                size="sm"
                width="100%"
                onClick={handleResetFilter}
              >Очистить фильтр</Button>
              <Text fontSize="0.8rem" color="gray.300" mt={2}>{`Найдено: ${countList}`}</Text>
            </FilterFooter>
          </TabPanel>
          <TabPanel pt={0} mt={0}>
            <FilterStack>
              <Box>
                <FormLabel>Отчеты</FormLabel>
                <VStack spacing="10px">
                  {reportFullIsAvailable && <ReportFull/>}
                  {reportByUserIsAvailable && <ReportLaboratoryUser/>}
                  <Button onClick={onExportExcel} width="100%">Выгрузить в excel</Button>
                </VStack>
              </Box>
              <Private
                role={userAuth.role}
                hasAnyRole={[ROLES.Administrator]}
              >
                <Box>
                  <FormLabel>Настройки</FormLabel>
                  <VStack spacing="10px">
                    <SettingsAutoNumbering/>
                    <Private
                      role={userAuth.role}
                      hasAnyRole={[ROLES.Administrator]}
                    >
                      <SettingsSMTP/>
                      <SettingsReports/>
                    </Private>
                  </VStack>
                </Box>
              </Private>
            </FilterStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
};
